import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { BsFillXCircleFill } from 'react-icons/bs'
import { useIntl } from 'react-intl'
import { useAtom } from 'jotai'
import { useSelector } from 'react-redux'

import {
  idAtom,
  nameAtom,
  newTemplateAtom,
  rulesUseItAtom
} from '../../atoms/abmTemplates'
import Service from '../../services'
import { RootState } from '../../state/store'
import { useClientContext } from '../../contexts/ClientContext'
import { ShowAlert } from '../Globals'

const FormGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

const ModalWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const ModalContent = styled.div`
  background: #fff;
  width: 80rem;
  margin: 100px auto;
  padding: 50px;
  border-radius: 10px;
  position: relative;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const InputField = styled.input`
  width: 20rem;
  padding: 10px;
  margin-right: 2rem;
  border: 1px solid #ddd;
  border-radius: 5px;
`

const Label = styled.label`
  font-weight: bold;
  margin-right: 50px;
  min-width: 150px;
`

const FileInput = styled.div`
  width: '100%';
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledButton = styled.button<{ bgColor: string }>`
  background-color: transparent;
  width: 15rem;
  color: #00868b;
  font-weight: 700;
  padding: 10px 20px;
  margin-right: 2rem;
  border: 3px solid #00868b;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: transparent;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background-color: ${({ bgColor }) => bgColor};
  }
`

const StyledBottomButton = styled.button<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 10rem;
  color: #fff;
  padding: 10px 20px;
  margin-right: 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ bgColor }) => bgColor};
  }
  &:focus {
    outline: none;
  }
  &:active {
    background-color: ${({ bgColor }) => bgColor};
  }
`

interface ModalProps {
  isVisible: boolean
  onClose: () => void
  GetAutomationTemplatesWithRules: any
}

interface FileData {
  name: string
  file: File | null
}

const AbmModal: React.FC<ModalProps> = ({
  isVisible,
  onClose,
  GetAutomationTemplatesWithRules
}) => {
  if (!isVisible) return null
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [saveWarning, setSaveWarning] = useState<boolean>(true)
  const [fileData, setFileData] = useState<FileData>({ name: '', file: null })
  const [newTemplate] = useAtom<boolean>(newTemplateAtom)
  const [nameTemplate, setNameTemplate] = useAtom<string>(nameAtom)
  const [idTemplate] = useAtom(idAtom)
  const [rulesUseIt] = useAtom<[]>(rulesUseItAtom)
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const intl = useIntl()
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file != null) {
      setFileData({ name: file.name, file })
    }
  }

  const handleClick = () => {
    if (fileInputRef.current != null) {
      fileInputRef.current.click()
    }
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameValue = event.target.value
    if (nameValue != '') {
      setNameTemplate(nameValue)
    }
  }

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: 'ABM TEMPLATES',
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      confirmButtonText: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      width: 500,
      timerProgressBar: true
    })
  }

  const saveTemplate = () => {
    if (!newTemplate && saveWarning) {
      MyAlert(
        intl.formatMessage({
          id: 'app.emailmarketing.ambtemplate.upload.modal.alert.text',
          defaultMessage:
            'Esta seguro de sobre escribir el archivo? Para resguardar la versión anterior, descargue el archivo previamente'
        }),
        3
      )
      setSaveWarning(false)
    } else {
      const formData = new FormData()
      const apiSave = newTemplate
        ? `/Automation/SaveTemplate?idHashScript=${selectedScript?.idHashScript}`
        : `/Automation/SaveTemplate?idHashScript=${selectedScript?.idHashScript}&idTemplate=${idTemplate}`
      formData.append('File', fileData.file as Blob)
      formData.append('Description', nameTemplate)
      Service.post<any, any>(apiSave, formData, {
        method: 'POST',
        headers: {
          token: user.data.token,
          'Content-Type': 'application/json'
        }
      })
        .then((res) => {
          if (res.data.Error == '') {
            MyAlert(res.data.Response, 1)
            onClose()
            GetAutomationTemplatesWithRules()
          } else {
            MyAlert(res.data.Error, 2)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <ModalWrapper isVisible={isVisible}>
      <ModalContent>
        <div className="d-flex justify-content-between">
          <div>
            <h2>
              {intl.formatMessage({
                id: 'app.emailmarketing.ambtemplate.upload.modal.title.text',
                defaultMessage: 'Plantilla'
              })}
            </h2>
          </div>
          <div>
            <BsFillXCircleFill
              onClick={onClose}
              size={40}
              style={{ cursor: 'pointer', color: '#6a1b9a' }}
            />
          </div>
        </div>
        <FormGroup>
          <Label>
            {intl.formatMessage({
              id: 'app.emailmarketing.ambtemplate.upload.modal.label.name',
              defaultMessage: 'Nombre de Plantilla'
            })}
            :
          </Label>
          <InputField
            type="text"
            value={nameTemplate}
            onChange={handleNameChange}
          />
        </FormGroup>
        <FormGroup>
          <Label style={{ marginRight: '54px' }}>
            {intl.formatMessage({
              id: 'app.emailmarketing.ambtemplate.upload.modal.label.file',
              defaultMessage: 'Archivo'
            })}
            :
          </Label>
          <FileInput>
            <InputField type="text" value={fileData.name} readOnly />
            <div>
              <input
                type="file"
                style={{ display: 'none' }}
                id="fileUpload"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div>
            <label htmlFor="fileUpload" style={{ marginBottom: 'unset' }}>
              <StyledButton bgColor="#00868B" onClick={handleClick}>
                {intl.formatMessage({
                  id: 'app.emailmarketing.ambtemplate.upload.modal.btn.text',
                  defaultMessage: ' Seleccionar archivo'
                })}
              </StyledButton>
            </label>
          </FileInput>
        </FormGroup>
        {!newTemplate && (
          <FormGroup>
            <Label>
              {intl.formatMessage({
                id: 'app.emailmarketing.ambtemplate.upload.modal.label.footer',
                defaultMessage: 'Template Activo'
              })}
              :
            </Label>
            <div>
              <p>
                {rulesUseIt.length !== 0
                  ? intl.formatMessage({
                      id: 'app.emailmarketing.ambtemplate.upload.modal.label.footer.text.one',
                      defaultMessage:
                        'Este template está siendo usado por alguna regla. Si se edita, las reglas que tienen esta plantilla se desactivarán.'
                    })
                  : intl.formatMessage({
                      id: 'app.emailmarketing.ambtemplate.upload.modal.label.footer.text.two',
                      defaultMessage:
                        'Este template no está siendo usado por alguna regla'
                    })}
              </p>
            </div>
          </FormGroup>
        )}
        <ButtonGroup>
          <StyledBottomButton bgColor="#6a1b9a" onClick={saveTemplate}>
            {intl.formatMessage({
              id: 'app.common.save',
              defaultMessage: 'Guardar'
            })}
          </StyledBottomButton>
          <StyledBottomButton bgColor="#333" onClick={onClose}>
            {intl.formatMessage({
              id: 'app.common.cancel',
              defaultMessage: 'Cancelar'
            })}
          </StyledBottomButton>
        </ButtonGroup>
      </ModalContent>
    </ModalWrapper>
  )
}

export default AbmModal
