/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useCallback, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { CDPButton } from './css/DashboardCampaignComponents'
import Service from '../../services'
import { useClientContext } from '../../contexts/ClientContext'
import { RootState } from '../../state/store'
import { LoadingMetrics } from './css/CampaignCreateContainerComponents'

const CampaignSummary = ({
  campaign,
  isDynamicTemplate
}: {
  campaign: any
  isDynamicTemplate: boolean
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingCampaign, setLoadingCampaign] = useState<boolean>(true)
  const [isDynamic, setIsDynamic] = useState<boolean>(false)

  const { id } = useParams()
  const intl = useIntl()
  const { selectedScript } = useClientContext()
  const userData = useSelector<RootState, any>(
    (state: RootState) => state.userAuth
  )
  const user = userData.data

  const getCampaignById = useCallback(
    async (idParam: number | string) => {
      try {
        setLoadingCampaign(true)
        const { data } = await Service.get<any>(
          `/Automation/GetCampaignById?idHashScript=${selectedScript?.idHashScript}&idRule=${idParam}`,
          {
            headers: {
              token: user.token,
              'Content-Type': 'application/json'
            }
          }
        )
        if (data.Status == 'Ok') {
          if (data.Entity) {
            setIsDynamic(
              [data.Entity.EndPointSource, data.Entity.EndPointSource2].some(
                (el) => {
                  const urlRegex =
                    /<%CLIENT.BRANCHOFFICE%>|<%CLIENT.WHITELABEL%>|<%IDCATEGORY%>|{categorycode}/gi
                  if (urlRegex.test(el)) return true
                  return false
                }
              )
            )
          }
        }
        setLoadingCampaign(false)
      } catch (error) {
        setLoadingCampaign(false)
        console.log(error)
      }
    },
    [user, selectedScript]
  )
  const getTemplatePreview = useCallback(async () => {
    try {
      setLoading(true)
      const { data }: { data: any } = await Service.get<any>(
        `Automation/RenderTemplate?idHashScript=${selectedScript?.idHashScript}&idRule=${campaign.IdRule}`,
        {
          headers: {
            token: user.token,
            'Content-Type': 'application/json'
          }
        }
      )
      const tab = window.open('about:blank', '_blank')
      if (tab) {
        tab.document.write(data)
        tab.document.close()
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }, [])

  useEffect(() => {
    if (id) {
      getCampaignById(id)
    }
  }, [id])

  return (
    <div className="d-flex justify-content-end align-items-center mt-3 mr-5">
      <div className="regla_frame container mb-3">
        <div>
          <h1>
            {intl.formatMessage({
              id: 'app.emailmarketing.create.campaign.step.three.sumary.title',
              defaultMessage: 'Resumen'
            })}
          </h1>
          <hr />
          <div className="configuracion">
            <div className="row mb-3">
              <div className="col-2 text-center">
                <div
                  className="icon-container"
                  style={{ width: 'min-content' }}
                >
                  <img
                    src={require('../../img/iconoir_community.png')}
                    className="ml-2"
                  />
                  <label className="fw-bold mb-2">
                    {intl.formatMessage({
                      id: 'app.emailmarketing.create.campaign.step.three.sumary.recipients',
                      defaultMessage: 'Destinatarios'
                    })}
                  </label>
                  <p style={{ color: '#00868B' }}>@número-user</p>
                </div>
              </div>
              <div className="col-10">
                <div className="mb-3">
                  <label className="form-label">
                    {intl.formatMessage({
                      id: 'app.emailmarketing.create.campaign.step.three.sumary.campaignname',
                      defaultMessage: 'Nombre de la campaña'
                    })}
                  </label>
                  <p>{campaign.Name}</p>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-3 d-flex" style={{ columnGap: 8 }}>
                    <label className="form-label mr-3">
                      {intl.formatMessage({
                        id: 'app.emailmarketing.create.campaign.step.three.sumary.group',
                        defaultMessage: 'Grupo'
                      })}
                      :
                    </label>
                    <p>{campaign.GroupSend}</p>
                  </div>
                  <div className="col-md-3 d-flex" style={{ columnGap: 8 }}>
                    <label className="form-label mr-3">
                      {intl.formatMessage({
                        id: 'app.common.priority',
                        defaultMessage: 'Prioridad'
                      })}
                      :
                    </label>
                    <p>{campaign.Priority}</p>
                  </div>
                  <div className="col-md-3 d-flex" style={{ columnGap: 8 }}>
                    <label className="form-label mr-3">
                      {intl.formatMessage({
                        id: 'app.emailmarketing.create.campaign.step.three.sumary.forcesend',
                        defaultMessage: 'Forzar Envío'
                      })}
                      :{' '}
                    </label>
                    <p>{campaign.ForceSend == 1 ? 'Si' : 'No'}</p>
                  </div>
                </div>
                <hr style={{ marginTop: 'unset' }} />

                <div className="mb-3">
                  <div className="d-flex">
                    <label className="form-label">
                      {intl.formatMessage({
                        id: 'app.common.cdp',
                        defaultMessage: 'Segmento'
                      })}
                    </label>
                  </div>
                  <p>{campaign.SegmentAliasName}</p>
                </div>
                <hr />

                <div className="mb-3">
                  <label className="form-label">
                    {intl.formatMessage({
                      id: 'app.emailmarketing.create.campaign.step.three.sumary.subject',
                      defaultMessage: 'Asunto'
                    })}
                  </label>
                  <p>{campaign.Subject}</p>
                </div>
                <hr />

                {campaign?.StartDate ? (
                  <>
                    <div className="mb-3 row">
                      <div className="col-md-4">
                        <label className="form-label" style={{ margin: 0 }}>
                          {intl.formatMessage({
                            id: 'app.emailmarketing.create.campaign.step.three.sumary.expire',
                            defaultMessage: 'Vencimiento'
                          })}
                        </label>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label" style={{ margin: 0 }}>
                          {intl.formatMessage({
                            id: 'app.emailmarketing.create.campaign.step.three.sumary.expire.from',
                            defaultMessage: 'Desde'
                          })}{' '}
                          {format(campaign.StartDate, 'dd/MM/yyyy HH:mm')}
                        </label>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label" style={{ margin: 0 }}>
                          {intl.formatMessage({
                            id: 'app.emailmarketing.create.campaign.step.three.sumary.expire.to',
                            defaultMessage: 'Hasta'
                          })}{' '}
                          {format(campaign.EndDate, 'dd/MM/yyyy HH:mm')}
                        </label>
                      </div>
                    </div>
                    <hr />
                  </>
                ) : null}
                {isDynamic && (
                  <div
                    className="mb-3"
                    style={{
                      color: '#666',
                      fontSize: '14px',
                      fontStyle: 'italic'
                    }}
                  >
                    {intl.formatMessage({
                      id: 'app.emailmarketing.create.campaign.step.three.sumary.isdynamic.text',
                      defaultMessage:
                        'Se está configurando un endpoint dinámico por lo tanto el preview utilizará la regla Más vistos del sitio con el objetivo de visualizar la pieza. No son los productos que recibirá el usuario en el envió del email.'
                    })}
                  </div>
                )}
                <CDPButton
                  onClick={async (e) => {
                    if (!isDynamicTemplate) {
                      e.preventDefault()
                      window.open(campaign.TemplateSource, '_blank')
                      return
                    }
                    await getTemplatePreview()
                  }}
                  style={{
                    borderRadius: '0px',
                    borderColor: '#00868B',
                    textTransform: 'uppercase'
                  }}
                  disabled={loading}
                >
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.three.sumary.previewtemplate',
                    defaultMessage: 'Pre-visualizar Template'
                  })}{' '}
                  {loading && <LoadingMetrics size={16} />}
                </CDPButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignSummary
