/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react'
import { BsLockFill, BsPersonCircle } from 'react-icons/bs'
import { useIntl, FormattedMessage } from 'react-intl'
import '../RelatedCategories/relatedCategories.css'
import '../Global.css'
import ProfileForm from './ProfileForm'
import UserPass from './UserPass'
import { TabHeader, TabHeaderTitle } from './ProfileMshopContainer'

const ProfileAndPassUser = () => {
  const intl = useIntl()
  const [tabProfile, setTabProfile] = useState<boolean>(true)

  useEffect(() => {}, [])

  return (
    <>
      <div className="container mg-t-30">
        <div className="row" style={{ backgroundColor: '#fff' }}>
          <TabHeader
            style={{ backgroundColor: tabProfile ? 'white' : '#E8E8ED' }}
            className="col d-flex col-sm-6"
            enabled={tabProfile}
            onClick={() => {
              // reset({
              //   oldPass: '',
              //   newPass: '',
              //   newPassConfirm: '',
              //   ...userInfo
              // })
              setTabProfile(true)
            }}
          >
            <BsPersonCircle color="#00868B" size="35px" />
            <TabHeaderTitle style={{ margin: 0 }}>Perfil</TabHeaderTitle>
          </TabHeader>
          <TabHeader
            style={{ backgroundColor: !tabProfile ? 'white' : '#E8E8ED' }}
            className="col d-flex col-sm-6"
            enabled={tabProfile}
            onClick={() => {
              // reset()
              setTabProfile(false)
            }}
          >
            <BsLockFill color="#00868B" size="35px" />
            <TabHeaderTitle style={{ margin: 0 }}>
              <FormattedMessage
                id="app.system-tab-pass"
                defaultMessage="Cambiar contraseña"
              />
            </TabHeaderTitle>
          </TabHeader>
        </div>
      </div>

      {tabProfile ? <ProfileForm /> : <UserPass />}
    </>
  )
}

export default ProfileAndPassUser
