/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
// import { useDispatch } from 'react-redux'
import { useLogged } from '../../hooks'
// import { login } from '../../state/actions'
import Auth from './Auth'
import LoginForm from './LoginForm'
import './style.css'
import LanguageSelector from '../Header/LanguageSelector'

const LoginContainer = () => {
  const isLogged = useLogged()
  const [loginData, setLoginData] = useState('')
  const [title, setTitle] = useState(
    <FormattedMessage id="app.login" defaultMessage="Iniciar Sesión" />
  )
  // const dispatch = useDispatch()
  useEffect(() => {
    if (isLogged == true && loginData !== '') {
      window.history.replaceState(null, '', '/authtoken')
      setTitle(
        <FormattedMessage id="app.auth" defaultMessage="Autenticación" />
      )
    } else if (isLogged == true && loginData === '') {
      window.history.replaceState(null, '', '/login')
      // dispatch(login({ username: '', password: '' }))
    }
  }, [isLogged, loginData])

  const infoLogin = (data: any) => {
    setLoginData(data)
  }

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  return (
    <div className="login-content">
      <div style={{ position: 'absolute', top: 20, right: 20, width: 120 }}>
        <LanguageSelector outside={true} />
      </div>
      <div className="nk-block toggled" id="l-login">
        <div className="nk-form">
          <h2 className="bdw-login-title">{title}</h2>
          {isLogged === false || isLogged === undefined || loginData === '' ? (
            <LoginForm infoLogin={infoLogin} />
          ) : (
            <Auth loginInfo={loginData} />
          )}
        </div>
        <div className="bdw-logo">
          <img
            className="logo-img"
            src={require('../../img/Brain-Marca.png')}
            alt="Logo de Braindw"
          />
          <p>
            © {year}{' '}
            <FormattedMessage
              id="app.rights"
              defaultMessage="Todos los Derechos Reservados."
            />
          </p>
        </div>
      </div>
    </div>
  )
}

export default LoginContainer
