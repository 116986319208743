/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useState } from 'react'
import { ValueEditor, ValueEditorProps } from 'react-querybuilder'
import Service from '../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import { logout } from '../../../state/actions'
import { useClientContext } from '../../../contexts/ClientContext'

export const CustomValueEditor = (props: ValueEditorProps) => {
  // if(props.values !== null){
  // const selectValues = Object.entries(props.values)
  // }
  const [selectValue, setSelectValue] = useState<any>(props.value)
  const [selectValue2, setSelectValue2] = useState<any>(props.value)
  const [specific, setSpecific] = useState<any>([])
  const token = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()
  const [viewSpinner, setViewSpinner] = useState<boolean>(false)
  const dispatch = useDispatch()
  
  const getSpecifications = async (fieldId: any) => {    
    Service.get<any>(
      `Automation/GetSpecifications?idHashScript=${selectedScript?.idHashScript}&objectName=productview&field=${fieldId}`,
      { headers: { token: token.data.token } }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          /* newArr[index].value = response.data.map(
            (i: any) => [{ v: i.Value, vId: i.FieldValueId }][0]
          ) */
          setSpecific(response.data)
          setViewSpinner(false)
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(token.data.token))
        } else {
          console.log('API-ERROR: ' + `${error}`, 3)
        }
        setViewSpinner(false)
      })
  }

  const cargarSelect2 = (e:any) => {        
    getSpecifications(e)
  }

  if (props.fieldData.input == 'select') {
    return (
      <>
      <select
        className={
          props.value == undefined || props.value == ''
            ? 'queryBuilder-invalid'
            : 'editor-select'
        }        
        value={selectValue}
        title={props.title}
        onChange={(e) => {
          props.handleOnChange(e.target.value)
          setSelectValue(e.target.value)
          if(props.field.includes('._AllSpecifications')){
            setViewSpinner(true)
            cargarSelect2(e.target.value)            
          }          
        }}
      >
        <option value={''}>Seleccionar</option>
        {props.values?.map((v: any, key: any) => (
          <option key={key} value={v[0]}>
            {v[0]}
          </option>
        ))}
      </select>

      {viewSpinner ?
            (         
            <div id="spinner" className="spinner-border text-success " style={{width: '15px',height: '15px'}} role="status">
              <span className="sr-only"></span>
            </div>
            ): null
          }

      {specific && specific.length>0
        ?(
          <select
            className={
              props.value == undefined || props.value == ''
                ? 'queryBuilder-invalid'
                : 'editor-select'
            }            
            value={selectValue2}
            title={props.title}
            onChange={(e) => {
              props.handleOnChange(selectValue+"."+e.target.value)
              setSelectValue2(e.target.value)
            }}
          >
            <option value={''}>Seleccionar</option>
            {specific?.map((v: any, key: any) => (
              <option key={key} value={v.id}>
                {v.name}
              </option>
            ))}
          </select>
        ) : null
      }
    </>
    )
  } else if (
    props.fieldData.type == 'date' ||
    props.fieldData.type == 'datetime'
  ) {
    if (props.operator == 'between') {
      let date2 = document.getElementById(
        'rule-date-value' + JSON.stringify(props.path)
      ) as HTMLInputElement
      date2 =
        date2 == null
          ? props.value[1]
          : (
              document.getElementById(
                'rule-date-value' + JSON.stringify(props.path)
              ) as HTMLInputElement
            ).value
      return (
        <>
          <input
            type="date"
            value={Array.isArray(props.value) ? props.value[0] : props.value}
            className={
              props.value[0] == '' || props.value[0] == undefined
                ? 'queryBuilder-invalid'
                : ''
            }
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            onChange={(e) =>
              props.handleOnChange(
                date2 !== null ? [e.target.value, date2] : [e.target.value]
              )
            }
          />
          <span>-</span>
          <input
            type="date"
            id={'rule-date-value' + JSON.stringify(props.path)}
            value={props.value[1]}
            className={
              props.value[1] == '' || props.value[1] == undefined
                ? 'queryBuilder-invalid'
                : ''
            }
            // eslint-disable-next-line no-return-assign
            onChange={(e) =>
              props.handleOnChange([props.value[0], e.target.value])
            }
          />
        </>
      )
    } else {
      return (
        <input
          type="date"
          value={props.value}
          className={props.value == '' ? 'queryBuilder-invalid' : ''}
          onChange={(e) => props.handleOnChange(e.target.value)}
        />
      )
    }
  } else if (props.fieldData.input == 'number') {
    if (props.operator == 'between') {
      let number2 = document.getElementById(
        'rule-number-value' + JSON.stringify(props.path)
      ) as HTMLInputElement
      number2 =
        number2 == null
          ? props.value[1]
          : (
              document.getElementById(
                'rule-number-value' + JSON.stringify(props.path)
              ) as HTMLInputElement
            ).value
      return (
        <>
          <input
            type="number"
            min="0"
            value={Array.isArray(props.value) ? props.value[0] : props.value}
            className={
              props.value[0] == '' || props.value[0] == undefined
                ? 'queryBuilder-invalid'
                : ''
            }
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                props.handleOnChange(
                  number2 !== null ? [value, number2] : [value]
                );
              }
            }}
          />
          <span>-</span>
          <input
            type="number"
            id={'rule-number-value' + JSON.stringify(props.path)}
            value={props.value[1]}
            min="0"
            className={
              props.value[1] == '' || props.value[1] == undefined
                ? 'queryBuilder-invalid'
                : ''
            }
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                props.handleOnChange(
                  number2 !== null ? [value, number2] : [value]
                );
              }
            }}
          />
        </>
      )
    } else {
      return (
        <input
          type="number"
          min="0"
          value={props.value}
          className={props.value == '' ? 'queryBuilder-invalid' : ''}
          onChange={(e) => props.handleOnChange(e.target.value)}
        />
      )
    }
  } else if (props.fieldData.input == 'select') {
    return (
      <> nada
      { /* 
            <select
               className={props.className}
               title={props.title}
               value={props.value}
               onChange={(e) => props.handleOnChange(e.target.value)}
            >
               {props.values?.map((v) => (
                 <option key={v.name} value={v.name}>
                   {v.label}
                 </option>
               ))}
            </select>
               */ }
               </>
           )
  } else{
    return (
      <input
        type={props.inputType ?? 'text'}
        value={props.value}
        title={props.title}
        className={props.value == '' ? 'queryBuilder-invalid' : ''}
        onChange={(e) => props.handleOnChange(e.target.value)}
      />
    )
  }
  // switch (props.fieldData.input) {
  //   case 'number':
  //   case 'integer':
  //     return (
  //       <input
  //         type="number"
  //         value={props.value}
  //         onChange={(e) => props.handleOnChange(e.target.value)}
  //       />
  //     )
  //   case 'select':
  //     return (
  //       <select
  //         className={props.className}
  //         title={props.title}
  //         value={props.value}
  //         onChange={(e) => props.handleOnChange(e.target.value)}
  //       >
  //         {props.values?.map((v) => (
  //           <option key={v.name} value={v.name}>
  //             {v.label}
  //           </option>
  //         ))}
  //       </select>
  //     )
  //   case 'date':
  //   case 'datetime':
  //     return (
  //       <input
  //         type="date"
  //         value={props.value}
  //         onChange={(e) => props.handleOnChange(e.target.value)}
  //       />
  //     )
  //   case 'text':
  //     return (
  //       <input
  //         type={props.inputType ?? 'text'}
  //         value={props.value}
  //         title={props.title}
  //         className={props.className}
  //         onChange={(e) => props.handleOnChange(e.target.value)}
  //       />
  //     )
  //   default:
  //     return (
  //       <input
  //         type={props.inputType ?? 'text'}
  //         value={props.value}
  //         title={props.title}
  //         className={props.className}
  //         onChange={(e) => props.handleOnChange(e.target.value)}
  //       />
  //     )
  // }
  // eslint-disable-next-line no-unreachable
  return <ValueEditor {...props} />
}
