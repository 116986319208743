/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Service from '../services'
import { RootState } from '../state/store'
import { ShowAlert } from '../components/Globals'
import { logout } from '../state/actions'

interface ClientContextProviderProps {
  children: ReactNode
}

interface ClientContextProps {
  clients: ScriptClient[] | null
  selectedClient: ScriptClient | null
  selectedScript: Scripts | null | undefined
  selectScript: (arg: Scripts) => void
  selectClient: (arg: ScriptClient) => void
  changeModeAlertToken: () => void
  mode: boolean
  platforms: string[]
  clientLoading: boolean
  selectedPlatform: string
  setPlatform: (arg: string, arg2: any) => void
}

export const ClientContext = createContext<ClientContextProps | null>(null)

export const ClientContextProvider = ({
  children
}: ClientContextProviderProps) => {
  const [clients, setClients] = useState<ScriptClient[] | null>(null)
  const [clientsByPlatform, setClientsByPlatform] = useState<any>(null)
  const navigate = useNavigate()
  const [mode, setMode] = useState<boolean>(() => {
    const localDataScript = localStorage.getItem('scriptSelected')
    const localDataScriptParsed = localDataScript
      ? JSON.parse(localDataScript)
      : null
    return localDataScriptParsed
      ? localDataScriptParsed.mode == 'live'
        ? true
        : false
      : false
  })
  const intl = useIntl()
  const [selectedClient, setSelectedClient] = useState<ScriptClient | null>(
    () => {
      const localDataClient = localStorage.getItem('clientSelected')
      return localDataClient ? JSON.parse(localDataClient) : null
    }
  )
  const [selectedScript, setSelectedScript] = useState<
    Scripts | null | undefined
  >(() => {
    const localDataScript = localStorage.getItem('scriptSelected')
    return localDataScript ? JSON.parse(localDataScript) : null
  })

  const [selectedPlatform, setPlatformState] = useState<string>(() => {
    const localDataPlatform = localStorage.getItem('platformSelected')
    return localDataPlatform ? JSON.parse(localDataPlatform) : 'Todos'
  })
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const dispatch = useDispatch()
  const [clientLoading, setClientLoading] = useState<boolean>(true)
  const getClients = useCallback(async () => {
    try {
      setClientLoading(true)
      const { headers, data } = await Service.get<ScriptClient[]>(
        '/ScriptClient/GetClient',
        {
          headers: { token: user.data.token }
        }
      )
      if (headers.statuscode == '0') {
        if (data.length > 0) {
          setClients(data)
          setClientsByPlatform(() =>
            data.reduce<Record<any, any>>((acc, cv) => {
              if (
                cv.scripts &&
                cv.scripts.length >= 1 &&
                cv.scripts[0].clientPlatform
              ) {
                acc[cv.scripts[0].clientPlatform] = [
                  ...(acc[cv.scripts[0].clientPlatform] || []),
                  cv
                ]
              }
              return acc
            }, {})
          )
          if (!selectedClient) {
            setSelectedClient(data[0])
            localStorage.setItem('clientSelected', JSON.stringify(data[0]))
          }
          if (!selectedScript) {
            setSelectedScript(data[0].scripts?.[0])
            setMode(data[0].scripts?.[0].mode == 'live' ? true : false)
            localStorage.setItem(
              'scriptSelected',
              JSON.stringify(data[0].scripts?.[0])
            )
          }
        }
      }
      setClientLoading(false)
    } catch (error: any) {
      setClientLoading(false)
      console.log(error?.response?.status)
      if (error?.response?.status == 401) {
        dispatch(logout(user.data.token))
      }
      console.log('error client', error)
    }
  }, [selectedScript, selectedClient])

  const setPlatform = useCallback(
    (platform: string, clts: ScriptClient[]) => {
      if (!clts) {
        return
      }
      setPlatformState(platform)
      localStorage.setItem('platformSelected', JSON.stringify(platform))
      if (platform == 'Todos') {
        setSelectedClient(clts[0])
        localStorage.setItem('clientSelected', JSON.stringify(clts[0]))
        setSelectedScript(clts[0].scripts?.[0])
        localStorage.setItem(
          'scriptSelected',
          JSON.stringify(clts[0].scripts?.[0])
        )
        setMode(clts[0].scripts?.[0].mode == 'live' ? true : false)
        return
      }
      if (!clientsByPlatform && clts) {
        const filterClientsByPlatform = clts.reduce<Record<any, any>>(
          (acc, cv) => {
            if (
              cv.scripts &&
              cv.scripts.length >= 1 &&
              cv.scripts[0].clientPlatform
            ) {
              acc[cv.scripts[0].clientPlatform] = [
                ...(acc[cv.scripts[0].clientPlatform] || []),
                cv
              ]
            }
            return acc
          },
          {}
        )
        setSelectedClient(filterClientsByPlatform[platform]?.[0])
        localStorage.setItem(
          'clientSelected',
          JSON.stringify(filterClientsByPlatform[platform]?.[0])
        )
        setSelectedScript(filterClientsByPlatform[platform]?.[0].scripts?.[0])
        localStorage.setItem(
          'scriptSelected',
          JSON.stringify(filterClientsByPlatform[platform]?.[0].scripts?.[0])
        )
        setMode(
          filterClientsByPlatform[platform]?.[0].scripts?.[0].mode == 'live'
            ? true
            : false
        )
        return
      }
      setSelectedClient(clientsByPlatform[platform]?.[0])
      localStorage.setItem(
        'clientSelected',
        JSON.stringify(clientsByPlatform[platform]?.[0])
      )
      setSelectedScript(clientsByPlatform[platform]?.[0].scripts?.[0])
      localStorage.setItem(
        'scriptSelected',
        JSON.stringify(clientsByPlatform[platform]?.[0].scripts?.[0])
      )
      setMode(
        clientsByPlatform[platform]?.[0].scripts?.[0].mode == 'live'
          ? true
          : false
      )
    },
    [clientsByPlatform, clients]
  )
  const selectClient = useCallback((client: ScriptClient) => {
    setSelectedClient(client)
    setSelectedScript(client.scripts?.[0])
    setMode(client.scripts?.[0].mode == 'live' ? true : false)
    localStorage.setItem('clientSelected', JSON.stringify(client))
    localStorage.setItem('scriptSelected', JSON.stringify(client.scripts?.[0]))
    navigate('/dashboard/homebdw')
  }, [])

  const selectScript = useCallback((script: Scripts) => {
    setSelectedScript(script)
    setMode(script.mode == 'live' ? true : false)
    localStorage.setItem('scriptSelected', JSON.stringify(script))
  }, [])

  const MyAlert = (text: string, iconStr: number) => {
    ShowAlert({
      title: text,
      icon: iconStr == 1 ? 'success' : iconStr == 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: 3000,
      closeButtonHtml: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      cancelButtonText: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      width: 500,
      timerProgressBar: true
    })
  }

  const changeMode = useCallback(
    async (tokenGoogle: any) => {
      try {
        const modeStr = mode ? 'debug' : 'live'
        const { data } = await Service.post<any, any>(
          `/ScriptClient/SetModeScript?idHashScript=${selectedScript?.idHashScript}&mode=${modeStr}`,
          '',
          {
            method: 'POST',
            headers: {
              token: user.data.token,
              username: user.data.username,
              tokenGoogle,
              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
        if (data.StatusCode !== 0) {
          return MyAlert(
            intl.formatMessage({
              id: 'app.api.error',
              defaultMessage: 'Ocurrió un Error al actualizar los datos.'
            }),
            2
          )
        }
        setMode(!mode)
        console.log(data)
      } catch (error) {
        console.log('error on update modeScript')
        MyAlert(
          intl.formatMessage({
            id: 'app.api.error',
            defaultMessage: 'Ocurrió un Error al actualizar los datos.'
          }),
          2
        )
      }
    },
    [user, mode]
  )

  const changeModeAlertToken = () => {
    const textMode = mode
      ? 'Esta acción pondrá fuera de línea (debug) todas las funcionalidades de Brain. Está seguro que desea hacerlo? Deberá confirmar ingresando el Token.'
      : 'Esta acción pondrá todas las funcionalidades configuradas, en modo productivo y visibles para todos los usuarios. Está seguro que desea hacerlo? Deberá confirmar ingresando el Token.'
    ShowAlert({
      title: intl.formatMessage({
        id: 'app.modal.token.title',
        defaultMessage: 'Ingrese el <strong>token</strong>'
      }),
      input: 'text',
      html: '<span class="m-4 text-left">' + textMode + '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: '',
      confirmButtonText: intl.formatMessage({
        id: 'app.modal.btn.enviar',
        defaultMessage: 'Enviar'
      }),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage({
            id: 'app.token-inexistente',
            defaultMessage: 'Debe ingresar el token.'
          })
        } else {
          return ''
        }
      },
      width: 600
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        changeMode(result.value)
      }
    })
  }
  useEffect(() => {
    getClients()
  }, [])
  return (
    <ClientContext.Provider
      value={{
        clients:
          selectedPlatform == 'Todos'
            ? clients
            : clientsByPlatform
            ? clientsByPlatform[selectedPlatform]
            : null,
        selectedClient,
        selectedScript,
        selectClient,
        selectScript,
        changeModeAlertToken,
        mode,
        platforms: ['Todos', ...Object.keys(clientsByPlatform ?? [])],
        clientLoading,
        selectedPlatform,
        setPlatform
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}

export const useClientContext = () => {
  const context = useContext(ClientContext)
  if (context === undefined || context === null) {
    throw new Error('there is something wrong with clients context')
  }
  return context
}
