/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { FaEquals, FaTimes } from '../../utils/icons'
import './style.css'
import React, { useEffect, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSize'

const MenuContainer: React.FC<MenuContainerProps> = ({
  children,
  option,
  isSubmenu = false
}) => {
  const size = useWindowSize()
  const [toggle, setToggle] = useState<boolean>(size)
  useEffect(() => {
    setToggle(size)
  }, [size])

  useEffect(() => {
    if (toggle) {
      setToggle(false)
    }
  }, [option])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setToggle(!toggle)
  }

  return (
    <>
      <div className="main-menu-area mobile-menu-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="bdw-menu-mobile">
                <div className="bdw-menu-mobile-title">Menu</div>
                <button className="bdw-menu-mobile-icon" onClick={handleClick}>
                  {!toggle && !size ? <FaEquals /> : <FaTimes />}
                </button>
              </div>
              <ul
                style={
                  !toggle && !size
                    ? { display: 'none' }
                    : {
                        display: 'grid',
                        gridTemplateColumns: `repeat(${
                          children?.props.options.filter(
                            (op: any) => !op?.dynamic
                          ).length
                        },auto)`,
                        gap: 20
                      }
                }
                className={`nav nav-tabs bdw-menu-wrap ${
                  isSubmenu ? 'bdw-submenu' : ''
                } menu-it-icon-pro`}
              >
                {children}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MenuContainer
