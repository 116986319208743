/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { TooltipPanel } from '../../Globals/TooltipPanel'
import { useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import Service from '../../../services'
import { useClientContext } from '../../../contexts/ClientContext'
import { ShowAlert } from '../../Globals'

enum Config {
  SMTP = 'SMTP',
  SEND_GRID = 'SEND_GRID',
  AWS = 'AWS'
}

interface ConfigPayload {
  SmtpHost: string | null
  SmtpPort: number | null
  SmtpUserName: string | null
  SmtpPassword: string | null
  SmtpEnableSsl: number | null
  SendgridApiKey: string | null
  AWSSESAccessKey: string | null
  AWSSESAccessSecret: string | null
  AWSSESConfigSet: string | null
}

export const Button = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 186px;
  height: 34px;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #fff;
  background-color: #00868b;
  text-transform: capitalize;
  margin-inline: auto;
  margin-block: 20px;
`

const EmailMarketingConfig = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    clearErrors,
    setValue
  } = useForm()
  const userData = useSelector<RootState, any>(
    (state: RootState) => state.userAuth
  )
  const [config, setConfig] = useState<Config>(Config.SMTP)
  const { selectedScript } = useClientContext()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataConfig, setDataConfig] = useState<ConfigPayload>()
  const user = userData.data
  const intl = useIntl()

  const saveUpdateConfig = useCallback(
    async (data: any, config) => {
      try {
        const payload: ConfigPayload = {
          SmtpHost: null,
          SmtpPort: null,
          SmtpUserName: null,
          SmtpPassword: null,
          SmtpEnableSsl: null,
          SendgridApiKey: null,
          AWSSESAccessKey: null,
          AWSSESAccessSecret: null,
          AWSSESConfigSet: null
        }
        if (config == Config.SMTP) {
          if (!data?.smtp?.host) {
            setError('smtp.host', { type: 'required' })
            return
          }
          if (!data?.smtp.password) {
            setError('smtp.password', { type: 'required' })
            return
          }
          if (!data?.smtp?.port) {
            setError('smtp.port', { type: 'required' })
            return
          }
          if (isNaN(data?.smtp?.port)) {
            setError('smtp.port', { type: 'required' })
            return
          }
          if (!data?.smtp?.user) {
            setError('smtp.user', { type: 'required' })
            return
          }
          payload.SmtpHost = data?.smtp?.host
          payload.SmtpPort = parseInt(data?.smtp?.port)
          payload.SmtpUserName = data?.smtp?.user
          payload.SmtpPassword = data?.smtp?.password
          payload.SmtpEnableSsl = data?.smtp?.ssl
        }
        if (config == Config.SEND_GRID) {
          if (!data?.sendGridApi?.key) {
            setError('sendGridApi.key', { type: 'required' })
            return
          }
          payload.SendgridApiKey = data?.sendGridApi?.key
        }
        if (config == Config.AWS) {
          if (!data?.aws?.publicKey) {
            setError('aws.publicKey', { type: 'required' })
            return
          }
          if (!data?.aws?.secretKey) {
            setError('aws.secretKey', { type: 'required' })
            return
          }
          if (!data?.aws?.configSet) {
            setError('aws.configSet', { type: 'required' })
            return
          }
          payload.AWSSESAccessKey = data?.aws?.publicKey
          payload.AWSSESAccessSecret = data?.aws?.secretKey
          payload.AWSSESConfigSet = data?.aws?.configSet
        }
        if (!selectedScript) {
          return
        }
        const { value, isConfirmed } = await ShowAlert({
          title: intl.formatMessage({
            id: 'app.modal.token.title',
            defaultMessage: 'Ingrese el <strong>token</strong>'
          }),
          input: 'text',
          html:
            '<span class="m-4 text-left">' +
            intl.formatMessage({
              id: 'app.modal.token.texto',
              defaultMessage: 'Ingrese el <strong>token</strong>'
            }) +
            '</span>',
          inputLabel: '',
          inputPlaceholder: 'Token',
          color: '#1c684c',
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.enviar',
            defaultMessage: 'Enviar'
          }),
          showCancelButton: true,
          cancelButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          width: 600,
          inputValidator: (result) => {
            if (result === '') {
              return intl.formatMessage({
                id: 'app.token-inexistente',
                defaultMessage: 'El token no fue informado.'
              })
            } else {
              return ''
            }
          }
        })
        if (!isConfirmed) return null
        const { data: responseData } = await Service.post(
          `/ScriptClient/SaveSmtpClient?idHashScript=${selectedScript?.idHashScript}`,
          payload,
          {
            headers: {
              token: user.token,
              'Content-Type': 'application/json',
              tokenGoogle: value
            }
          }
        )
        if (responseData) {
          ShowAlert({
            title: 'Success',
            text: 'Se han actualizado los datos',
            icon: 'success',
            showConfirmButton: true,
            timer: 2500,
            closeButtonHtml: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            confirmButtonText: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            width: 500
          })
        }
      } catch (error) {
        ShowAlert({
          title: 'Error',
          text: intl.formatMessage({
            id: 'app.common.modal.error',
            defaultMessage:
              'Ops, algo va mal. Refresca la pagina o vuelve en unos minutos'
          }),
          icon: 'warning',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
        console.log(error)
      }
    },
    [selectedScript, user]
  )

  const onSubmit = useCallback(
    (data: any) => {
      try {
        saveUpdateConfig(data, config)
      } catch (error) {
        console.log(error)
      }
    },
    [config]
  )

  const getConfig = useCallback(async () => {
    try {
      const { data }: { data: ConfigPayload } = await Service.get(
        `/ScriptClient/GetSmtpConfigByClient?idHashScript=${selectedScript?.idHashScript}`,
        {
          headers: {
            token: user.token,
            'Content-Type': 'application/json'
          }
        }
      )
      if (data) {
        setDataConfig(data)
        setValue('smtp.host', data.SmtpHost)
        setValue('smtp.port', data.SmtpPort)
        setValue('smtp.user', data.SmtpUserName)
        setValue('smtp.ssl', data.SmtpEnableSsl)
        setValue('smtp.password', data.SmtpPassword)
        setValue('sendGridApi.key', data.SendgridApiKey)
        setValue('aws.publicKey', data.AWSSESAccessKey)
        setValue('aws.secretKey', data.AWSSESAccessSecret)
        setValue('aws.configSet', data.AWSSESConfigSet)
      }
    } catch (error) {
      console.log(error)
      ShowAlert({
        title: 'Error',
        text: intl.formatMessage({
          id: 'app.common.modal.error',
          defaultMessage:
            'Ops, algo va mal. Refresca la pagina o vuelve en unos minutos'
        }),
        icon: 'warning',
        showConfirmButton: true,
        timer: 2500,
        closeButtonHtml: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        confirmButtonText: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        toast: true,
        width: 500
      })
    }
  }, [selectedScript, user])

  useEffect(() => {
    getConfig()
  }, [])

  const configSelector = useCallback(() => {
    return (
      <div>
        <div style={{ display: config === Config.SMTP ? 'block' : 'none' }}>
          <h2 style={{ fontSize: 18 }}>SMTP</h2>
          <Form.Group className="mb-3 col-sm-5" controlId="title">
            <Form.Label>
              {intl.formatMessage({
                id: 'app.system.automation.form.smtp.label.host',
                defaultMessage: 'Host'
              })}{' '}
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      'app.system.automation.form.smtp.label.host'
                  ) ?? 'app.system.automation.form.smtp.label.host'
                }
              />
            </Form.Label>
            <Form.Control
              type="text"
              {...register('smtp.host')}
              style={{
                border: `1px solid ${
                  (errors.smtp as any)?.host ? '#DB164B' : '#ced4da'
                }`
              }}
              className={(errors.smtp as any)?.host ? 'is-invalid' : ''}
              aria-describedby="title"
              autoComplete="off"
              placeholder={
                (errors.smtp as any)?.host
                  ? intl.formatMessage({
                      id: 'app.system.automation.form.smtp.label.host.error',
                      defaultMessage: 'Debe ingresar el Host'
                    })
                  : ''
              }
            />
          </Form.Group>
          <Form.Group className="mb-3 col-sm-5" controlId="title">
            <Form.Label>
              {intl.formatMessage({
                id: 'app.system.automation.form.smtp.label.user',
                defaultMessage: 'Usuario'
              })}{' '}
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      'app.system.automation.form.smtp.label.user'
                  ) ?? 'app.system.automation.form.smtp.label.user'
                }
              />
            </Form.Label>
            <Form.Control
              type="text"
              {...register('smtp.user')}
              style={{
                border: `1px solid ${
                  (errors.smtp as any)?.user ? '#DB164B' : '#ced4da'
                }`
              }}
              className={(errors.smtp as any)?.user ? 'is-invalid' : ''}
              aria-describedby="title"
              autoComplete="off"
              placeholder={
                (errors.smtp as any)?.user
                  ? intl.formatMessage({
                      id: 'app.system.automation.form.smtp.label.user.error',
                      defaultMessage: 'Debe ingresar el usuario'
                    })
                  : ''
              }
            />
          </Form.Group>
          <Form.Group className="mb-3 col-sm-5" controlId="title">
            <Form.Label>
              {intl.formatMessage({
                id: 'app.system.automation.form.smtp.label.password',
                defaultMessage: 'Contraseña'
              })}{' '}
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      'app.system.automation.form.smtp.label.password'
                  ) ?? 'app.system.automation.form.smtp.label.password'
                }
              />
            </Form.Label>
            <Form.Control
              type="text"
              {...register('smtp.password')}
              style={{
                border: `1px solid ${
                  (errors.smtp as any)?.password ? '#DB164B' : '#ced4da'
                }`
              }}
              className={(errors.smtp as any)?.password ? 'is-invalid' : ''}
              aria-describedby="title"
              autoComplete="off"
              placeholder={
                (errors.smtp as any)?.password
                  ? intl.formatMessage({
                      id: 'app.system.automation.form.smtp.label.password.error',
                      defaultMessage: 'Debe ingresar una contraseña'
                    })
                  : ''
              }
            />
          </Form.Group>
          <Form.Group className="mb-3 col-sm-5" controlId="title">
            <Form.Label>
              {intl.formatMessage({
                id: 'app.system.automation.form.smtp.label.port',
                defaultMessage: 'Puerto'
              })}{' '}
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      'app.system.automation.form.smtp.label.port'
                  ) ?? 'app.system.automation.form.smtp.label.port'
                }
              />
            </Form.Label>
            <Form.Control
              type="text"
              {...register('smtp.port')}
              style={{
                border: `1px solid ${
                  (errors.smtp as any)?.port ? '#DB164B' : '#ced4da'
                }`
              }}
              className={(errors.smtp as any)?.port ? 'is-invalid' : ''}
              aria-describedby="title"
              autoComplete="off"
              placeholder={
                (errors.smtp as any)?.port
                  ? intl.formatMessage({
                      id: 'app.system.automation.form.smtp.label.port.error',
                      defaultMessage: 'Debe ingresar el puerto'
                    })
                  : ''
              }
            />
          </Form.Group>

          <Form.Group className="mb-3 col-sm-5" controlId="title">
            <Form.Label>
              SSL
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      'app.system.automation.form.smtp.label.ssl'
                  ) ?? 'app.system.automation.form.smtp.label.ssl'
                }
              />
            </Form.Label>
            <Controller
              control={control}
              name={'smtp.ssl'}
              render={({ field: { name, onChange, value } }) => (
                <label className="cfc_switch">
                  <input
                    type="checkbox"
                    name={name}
                    checked={value == 1 ? true : false}
                    onChange={(e) => onChange(e.target.checked ? 1 : 0)}
                  />
                  <span className="cfc_slider"></span>
                </label>
              )}
            />
          </Form.Group>
        </div>
        <div
          style={{ display: config === Config.SEND_GRID ? 'block' : 'none' }}
        >
          <h2 style={{ fontSize: 18 }}>SendGrid API</h2>
          <Form.Group className="mb-3 col-sm-5" controlId="title">
            <Form.Label>
              SendGrid APY KEY
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip === 'app.system.automation.form.sendgrid'
                  ) ?? 'app.system.automation.form.sendgrid'
                }
              />
            </Form.Label>
            <Form.Control
              type="text"
              {...register('sendGridApi.key')}
              style={{
                border: `1px solid ${
                  (errors.sendGridApi as any)?.key ? '#DB164B' : '#ced4da'
                }`
              }}
              className={(errors.sendGridApi as any)?.key ? 'is-invalid' : ''}
              aria-describedby="title"
              autoComplete="off"
              placeholder={
                (errors.sendGridApi as any)?.key
                  ? intl.formatMessage({
                      id: 'app.system.automation.form.sendgrid.label.api.error',
                      defaultMessage: 'Debe ingresar la  API KEY'
                    })
                  : 'XXXXX-XXXXX-XXXX'
              }
            />
          </Form.Group>
        </div>
        <div style={{ display: config === Config.AWS ? 'block' : 'none' }}>
          <h2 style={{ fontSize: 18 }}>AWS SES</h2>
          <Form.Group className="mb-3 col-sm-5" controlId="title">
            <Form.Label>
              {intl.formatMessage({
                id: 'app.system.automation.form.aws.label.public',
                defaultMessage: 'Llave Publica'
              })}{' '}
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      'app.system.automation.form.aws.label.public'
                  ) ?? 'app.system.automation.form.aws.label.public'
                }
              />
            </Form.Label>
            <Form.Control
              type="text"
              {...register('aws.publicKey')}
              style={{
                border: `1px solid ${
                  (errors.aws as any)?.publicKey ? '#DB164B' : '#ced4da'
                }`
              }}
              className={(errors.aws as any)?.publicKey ? 'is-invalid' : ''}
              aria-describedby="title"
              autoComplete="off"
              placeholder={
                (errors.aws as any)?.publicKey
                  ? intl.formatMessage({
                      id: 'app.system.automation.form.aws.label.public.error'
                    })
                  : 'XXXX-XXXX-XXXX'
              }
            />
          </Form.Group>
          <Form.Group className="mb-3 col-sm-5" controlId="title">
            <Form.Label>
              {intl.formatMessage({
                id: 'app.system.automation.form.aws.label.secret'
              })}{' '}
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      'app.system.automation.form.aws.label.secret'
                  ) ?? 'app.system.automation.form.aws.label.secret'
                }
              />
            </Form.Label>
            <Form.Control
              type="text"
              {...register('aws.secretKey')}
              style={{
                border: `1px solid ${
                  (errors.aws as any)?.secretKey ? '#DB164B' : '#ced4da'
                }`
              }}
              className={(errors.aws as any)?.secretKey ? 'is-invalid' : ''}
              aria-describedby="title"
              autoComplete="off"
              placeholder={
                (errors.aws as any)?.secretKey
                  ? intl.formatMessage({
                      id: 'app.system.automation.form.aws.label.secret.error'
                    })
                  : 'XXXX-XXXX-XXXX'
              }
            />
          </Form.Group>
          <Form.Group className="mb-3 col-sm-5" controlId="title">
            <Form.Label>
              {intl.formatMessage({
                id: 'app.system.automation.form.aws.label.configset'
              })}{' '}
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      'app.system.automation.form.aws.label.configset'
                  ) ?? 'app.system.automation.form.aws.label.configset'
                }
              />
            </Form.Label>
            <Form.Control
              type="text"
              {...register('aws.configSet')}
              style={{
                border: `1px solid ${
                  (errors.aws as any)?.user ? '#DB164B' : '#ced4da'
                }`
              }}
              className={(errors.aws as any)?.configSet ? 'is-invalid' : ''}
              aria-describedby="title"
              autoComplete="off"
              placeholder={
                (errors.aws as any)?.configSet
                  ? intl.formatMessage({
                      id: 'app.system.automation.form.aws.label.configset.error',
                      defaultMessage: 'Debe ingresar un titulo al carousel'
                    })
                  : ''
              }
            />
          </Form.Group>
        </div>
      </div>
    )
  }, [errors, config])

  return (
    <div className="container mg-t-30" style={{ backgroundColor: '#fff' }}>
      <div className="row">
        <h1 style={{ padding: '2rem 2rem 0rem 2rem', fontSize: 26 }}>
          CONFIGURACIÓN EMAIL MARKETING - SMTP
        </h1>
        <div className="col-2">
          <div
            style={{
              padding: '2rem',
              cursor: 'pointer',
              backgroundColor: config !== Config.SMTP ? '#fff' : '#e8e8ed'
            }}
            onClick={() => {
              clearErrors()
              setConfig(Config.SMTP)
            }}
          >
            SMTP
          </div>
          <div
            style={{
              padding: '2rem',
              cursor: 'pointer',
              backgroundColor: config !== Config.SEND_GRID ? '#fff' : '#e8e8ed'
            }}
            onClick={() => {
              clearErrors()
              setConfig(Config.SEND_GRID)
            }}
          >
            SEND GRID
          </div>
          <div
            style={{
              padding: '2rem',
              cursor: 'pointer',
              backgroundColor: config !== Config.AWS ? '#fff' : '#e8e8ed'
            }}
            onClick={() => {
              clearErrors()
              setConfig(Config.AWS)
            }}
          >
            AWS SES
          </div>
        </div>

        <div className="col-10">
          <form style={{ padding: '2rem' }} onSubmit={handleSubmit(onSubmit)}>
            {configSelector()}
            <Button
              type="submit"
              style={{ marginInline: 'inherit' }}
              // onClick={() => handleSubmit(onSubmit)}
            >
              {intl.formatMessage({
                id: 'app.common.save',
                defaultMessage: 'Guardar'
              })}
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EmailMarketingConfig
