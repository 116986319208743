/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { config } from '../../config'
import InfoIcon from '../Globals/svg/InfoIcon'
import { ShowAlert } from '../../components/Globals'
import { RootState } from '../../state/store'
import Service from '../../services'
import { getTooltips } from '../../state/actions/userAction'

export const TooltipPanel = ({ ObjectTooltip }: any) => {
  const intl = useIntl()
  const local = useSelector<RootState, Lang>((state) => state.langSelected)
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const dispatch = useDispatch()

  const MyAlert = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.manage-carrousel' },
        { defaultMessage: 'Manejo de carruseles' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  const MyAlertAdmin = (text: string, id: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.carrousel.rules.tooltip.title' },
        { defaultMessage: 'Ayuda' }
      ),
      toast: true,
      html: text + '<hr/>',

      inputValue: text,
      input: 'textarea',
      // inputLabel: 'Modificá el contenido desde aquí',
      inputPlaceholder: 'Ingresá el contenido',

      inputAttributes: {
        autocapitalize: 'off'
      },
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal-regla.guardar' },
        { defaultMessage: 'Guardar' }
      ),
      color: '#1c684c',
      showCancelButton: true,
      showConfirmButton: true,
      showCloseButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      icon: 'info',
      position: 'top-end',
      width: 600,
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      }
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        saveTooltip(result.value, id)
      }
    })
  }

  const MyAlertUser = (text: string, time = 25000) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.carrousel.rules.tooltip.title' },
        { defaultMessage: 'Ayuda' }
      ),
      toast: true,
      timer: time,
      html: text,

      icon: 'info',
      showConfirmButton: false,
      showCloseButton: true,
      position: 'top-end',
      width: 500,
      timerProgressBar: true
    })
  }

  const saveTooltip = (text: string, id: string) => {
    try {
      const objData: any = {
        idTooltip: id,
        lang: local.lang,
        // text_es: text,
        // text_en: text,
        module: '',
        userUpdated: user.data.IdDashboardUser
      }

      switch (local.lang) {
        case 'es':
          objData.text_es = text
          break
        case 'en':
          objData.text_en = text
          break
        case 'br':
          objData.text_br = text
          break
        default:
          break
      }

      const requestOptions = {
        method: 'POST',
        headers: {
          token: user.data.token,
          userName: user.data.username,
          'Content-Type': 'application/json',
          Accept: '* / *',
          credentials: 'include'
        },
        body: JSON.stringify(objData)
      }
      fetch(
        `${config.base_server_path}/Panel/UpdateTooltipPanel`,
        requestOptions
      )
        .then(async (response) => {
          return await response.json()
        })
        .then(async (result) => {
          if (result.StatusCode < 0 || result < 0) {
            if (result !== -5 && result !== -6)
              MyAlert(
                intl.formatMessage(
                  { id: 'app.api.error' },
                  {
                    defaultMessage:
                      'Ocurrió un Error al actualizar los datos de Tooltips.'
                  }
                ),
                2
              )
          } else {
            MyAlert(
              'Se actualizó con éxito el texto del tooltip: ' + `${id}`,
              1
            )
            dispatch(getTooltips(user.data.token))
            // Si desea, se puede traer todos los tooltips de nuevo y recargar
            // user.data.tooltips = []
            // user.data.tooltips = await useTooltips(user.data.token)
          }
        })
    } catch (error) {
      console.log('API ERROR: ' + `${error}`, 3)
    }
  }

  const registerNewTooltip = useCallback(
    async (id: string) => {
      try {
        const { data: tooltip } = await Service.get<any>(
          `Panel/GetOneTooltipPanel?idTooltip=${id}`,
          {
            method: 'GET',
            headers: {
              token: user.data.token,
              userName: user.data.username,
              'Content-Type': 'application/json',
              Accept: '* / *',
              credentials: 'include'
            }
          }
        )
        if (tooltip?.idTooltip && tooltip?.id != 0) {
          if (user.data.userType == 1) {
            MyAlertAdmin(
              local.lang == 'es' ? tooltip.text_es : tooltip.text_en,
              tooltip?.idTooltip
            )
          } else {
            MyAlertUser(
              local.lang == 'es' ? tooltip.text_es : tooltip.text_en,
              tooltip?.idTooltip
            )
          }
          return
        }

        if (user.data.userType == 1) {
          MyAlertAdmin('', id)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [user]
  )
  return (
    <>
      <a
        id={'btn-nada'}
        key={'btn-nada'}
        style={{
          cursor: 'pointer',
          display: 'contents'
        }}
        onClick={() => {
          if (typeof ObjectTooltip == 'string') {
            registerNewTooltip(ObjectTooltip)
            return
          }
          if (ObjectTooltip == null || ObjectTooltip == undefined) {
            MyAlert(
              'No ha sido definido aun un id  para almacenar el texto de este tooltip',
              2
            )
          } else {
            if (user.data.userType == 1) {
              MyAlertAdmin(
                local.lang == 'es'
                  ? ObjectTooltip.text_es
                  : ObjectTooltip.text_en,
                ObjectTooltip.idTooltip
              )
            } else {
              MyAlertUser(
                local.lang == 'es'
                  ? ObjectTooltip.text_es
                  : ObjectTooltip.text_en,
                ObjectTooltip.idTooltip
              )
            }
          }
        }}
      >
        <InfoIcon />
      </a>
    </>
  )
}
