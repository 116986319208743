/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { MdApps } from 'react-icons/md'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import { useIntl } from 'react-intl'

import {
  automationEndpointSelectStyle,
  CustomInputGroup,
  DisclaimerTextArea,
  EditStepContainer,
  EditStepWrapper,
  EndpointButtonContainer,
  EndpointParametersContainer,
  endPointPreviewSelectStyle,
  EndpointSelectorContainer,
  Label,
  LabelKey,
  ThumbContainer,
  ThumbContentEdit,
  ThumbContentInput,
  ThumbContentInputContainer,
  ThumbContentInputError
} from './css/EditTemplateComponents'
import Service from '../../services'
import { RootState } from '../../state/store'
import { useClientContext } from '../../contexts/ClientContext'
import { TooltipPanel } from '../Globals/TooltipPanel'
import { CDPButton } from './css/DashboardCampaignComponents'
import { Gallery } from './editTemplateStep/Gallery'
import { UploadImage } from './editTemplateStep/UploadImage'
import { EndpointPreview } from './editTemplateStep/EndpointPreview'

const EditTemplateStep = ({
  endPoints = [],
  setEndPoints,
  campaign
}: {
  setEndPoints: any
  endPoints: any
  campaign: any
}) => {
  const {
    register,
    // formState: { errors },
    control,
    setValue,
    watch,
    setError,
    clearErrors
  } = useFormContext()
  const userData = useSelector<RootState, any>(
    (state: RootState) => state.userAuth
  )
  const intl = useIntl()
  const user = userData.data
  const { selectedScript } = useClientContext()
  const [gallery, setGallery] = useState<any>([])
  const [openGallery, setOpenGallery] = useState<any>(null)
  const [categories, setCategories] = useState<any>([])
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false)
  const [galleryLoading, setGalleryLoading] = useState<boolean>(false)
  const [previewProducts, setPreviewProducts] = useState<any[]>([])
  const [previewProductsLoading, setPreviewProductsLoading] =
    useState<boolean>(false)
  const [openPreviewProducts, setOpenPreviewProducts] = useState<boolean>(false)
  const [endPointLoading, setEndPointLoading] = useState<boolean>(true)
  const date = new Date()
  const [galleryFilter, setGalleryFilter] = useState({
    year: date.getFullYear(),
    month: date.getMonth()
  })
  const notShowInput = [
    'hash',
    'ext',
    'company',
    'refreshmetadata',
    'refreshMetaData'
  ]

  const categoryParams = ['idcategory', 'category', 'categories', 'parentcode']

  const branchOfficeAndWhiteLabelParams = ['whitelabel', 'branchOffice', 'sc']

  const isParamDynamic = (param: string) =>
    param.substring(0, 2) == '<%' &&
    param.substring(param.length - 2, param.length) == '%>'

  const checkIfEndpointIsDynamic = useCallback((name) => {
    try {
      const values = watch(name)
      return Object.values(values).some((x) => x == 'dynamic')
    } catch (error) {
      console.log(error)
    }
  }, [])

  const getAutomationEndPoint = useCallback(async () => {
    try {
      setEndPointLoading(true)
      const { data } = await Service.get<any>(
        `Automation/GetAutomationEndpoints?idHashScript=${selectedScript?.idHashScript}`,
        {
          headers: {
            token: user.token
          }
        }
      )
      if (data?.length) {
        setEndPoints(
          data.filter(
            (i: any) => i.UrlGeneric !== 'https://u.braindw.com/els/{company}'
          )
        )
        let categoryList: any[] = []
        if (selectedScript?.clientPlatform == 'Vtex') {
          categoryList = await getCategories()
        }
        if (campaign?.IdAutomationEndpoint) {
          const first = data.find(
            (f: any) => campaign?.IdAutomationEndpoint === f.Id
          )
          if (first) {
            setValue('endPointOne.value', first.Id)
            setValue('endPointOne.label', first.Name)
            endPointSave(
              null,
              'endPointOne',
              campaign.EndPointSource,
              first.Parameters,
              categoryList
            )
          }
          const second = data.find(
            (f: any) => campaign?.IdAutomationEndpoint2 === f.Id
          )
          if (second) {
            setValue('endPointTwo.value', second.Id)
            setValue('endPointTwo.label', second.Name)
            endPointSave(
              null,
              'endPointTwo',
              campaign.EndPointSource2,
              second.Parameters,
              categoryList
            )
          }
        }
      }
      setEndPointLoading(false)

      // setLoading(false)
    } catch (error) {
      setEndPointLoading(false)

      console.log(error)

      // setLoading(false)
    }
  }, [user, selectedScript, campaign, categories])

  const getCategories = useCallback(async () => {
    try {
      setCategoriesLoading(true)
      const { data } = await Service.get<any>(
        `ConfigurationRule/GetAllCategoriesVtex?idHashScript=${selectedScript?.idHashScript}`,
        {
          headers: {
            token: user.token
          }
        }
      )
      if (data.length) {
        setCategories(data)
      }
      setCategoriesLoading(false)
      return data
    } catch (error) {
      setCategoriesLoading(false)
      console.log(error)
    }
  }, [])

  const getGalleryImages = useCallback(
    async (year, month, day = null) => {
      try {
        setGalleryLoading(true)
        const { data } = await Service.get<any>(
          `Automation/GetImagesUrlBlocks?idHashScript=${selectedScript?.idHashScript}&yearFilter=${year}&monthFilter=${month}&dayFilter=${day}`,
          {
            headers: {
              token: user.token
            }
          }
        )
        if (data) {
          setGallery(data)
        }
        setGalleryLoading(false)
      } catch (error) {
        setGalleryLoading(false)
        console.log(error)
      }
    },
    [user, selectedScript]
  )

  const endPointsParams = useCallback(
    (name: string, endpoint: any) => {
      // limpiar datos dinámicos al seleccionar
      let input
      return endpoint?.Parameters.map((param: any, idx: any) => {
        if (notShowInput.some((key) => key === param.Key)) {
          return null
        }

        switch (param?.Type) {
          case 'Text': {
            if (
              selectedScript?.clientPlatform !== 'Vtex' &&
              categoryParams.some((key) => key === param.Key)
            ) {
              input = (
                <>
                  <CustomInputGroup
                    key={idx}
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'start',
                      rowGap: '8px',
                      columnGap: '20px',
                      flexWrap: 'wrap',
                      gridColumn: '1/4',
                      alignItems: 'center',
                      display: 'grid',
                      gridTemplateColumns: 'repeat(3, 1fr)'
                      // gridAutoRows: '1fr'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gridColumn: '1/4'
                      }}
                    >
                      <Label htmlFor="">
                        {param.Title}{' '}
                        <TooltipPanel
                          ObjectTooltip={
                            user.tooltips.find(
                              (t: any) =>
                                t.idTooltip ===
                                `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                            ) ??
                            `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                          }
                        />
                      </Label>
                      <LabelKey htmlFor="">[{param.Key}]</LabelKey>
                    </div>
                    <Form.Check
                      type="radio"
                      label="Estática"
                      value="static"
                      id={`category-type-2-radio-${name}`}
                      {...register(`${name}.category-type`)}
                    />
                    <Form.Check
                      type="radio"
                      label="Dinámica"
                      id={`category-type-1-radio-${name}`}
                      value="dynamic"
                      {...register(`${name}.category-type`)}
                    />

                    {watch(`${name}.category-type`) == 'static' && (
                      <ThumbContentInput
                        type="text"
                        style={{ width: '100%', gridColumn: '1/2' }}
                        {...register(`${name}.${param.Key}`)}
                      />
                    )}
                  </CustomInputGroup>
                </>
              )
              return input
            }
            if (
              branchOfficeAndWhiteLabelParams.some((key) => key === param.Key)
            ) {
              input = (
                <>
                  <CustomInputGroup
                    key={idx}
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'start',
                      columnGap: '20px',
                      rowGap: '8px',
                      flexWrap: 'wrap',
                      gridColumn: '1/4',
                      alignItems: 'center',
                      display: 'grid',
                      gridTemplateColumns: 'repeat(3, 1fr)',
                      gridAutoRows: '1fr'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gridColumn: '1/4'
                      }}
                    >
                      <Label htmlFor="">
                        {param.Title}{' '}
                        <TooltipPanel
                          ObjectTooltip={
                            user.tooltips.find(
                              (t: any) =>
                                t.idTooltip ===
                                `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                            ) ??
                            `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                          }
                        />
                      </Label>
                      <LabelKey htmlFor="">[{param.Key}]</LabelKey>
                    </div>
                    <Form.Check
                      type="radio"
                      label="Estática"
                      value="static"
                      id={`${name}.${param.Key}-type-2-radio-${name}`}
                      {...register(`${name}.${param.Key}-type`)}
                    />
                    <Form.Check
                      type="radio"
                      label="No Aplica"
                      value="empty"
                      id={`${name}.${param.Key}-type-3-radio-${name}`}
                      {...register(`${name}.${param.Key}-type`)}
                    />
                    {/* <Form.Check
                      type="radio"
                      label="Dinámica"
                      id={`${name}.${param.Key}-type-1-radio-${name}`}
                      value="dynamic"
                      {...register(`${name}.${param.Key}-type`)}
                    /> */}

                    {watch(`${name}.${param.Key}-type`) == 'static' && (
                      <ThumbContentInput
                        type="text"
                        style={{ width: '100%' }}
                        {...register(`${name}.${param.Key}`)}
                      />
                    )}
                  </CustomInputGroup>
                </>
              )
              return input
            }
            input = (
              <CustomInputGroup key={idx}>
                <Label htmlFor="">
                  {param.Title}{' '}
                  <TooltipPanel
                    ObjectTooltip={
                      user.tooltips.find(
                        (t: any) =>
                          t.idTooltip ===
                          `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                      ) ??
                      `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                    }
                  />
                </Label>
                <LabelKey htmlFor="">[{param.Key}]</LabelKey>
                <ThumbContentInput
                  type="text"
                  {...register(`${name}.${param.Key}`)}
                />
              </CustomInputGroup>
            )
            return input
          }
          case 'Number': {
            input = (
              <CustomInputGroup
                key={idx}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Label htmlFor="">
                  {param.Title}{' '}
                  <TooltipPanel
                    ObjectTooltip={
                      user.tooltips.find(
                        (t: any) =>
                          t.idTooltip ===
                          `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                      ) ??
                      `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                    }
                  />
                </Label>
                <LabelKey htmlFor="">[{param.Key}]</LabelKey>
                <ThumbContentInput
                  type="number"
                  min={0}
                  {...register(`${name}.${param.Key}`)}
                />
              </CustomInputGroup>
            )
            return input
          }
          case 'List': {
            if (
              selectedScript?.clientPlatform == 'Vtex' &&
              categoryParams.some((key) => key === param.Key)
            ) {
              input = (
                <CustomInputGroup
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'start',
                    columnGap: '20px',
                    rowGap: '8px',
                    flexWrap: 'wrap',
                    gridColumn: '1/4',
                    alignItems: 'center',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridAutoRows: '1fr'
                  }}
                  key={idx}
                >
                  <Controller
                    name={`${name}.${param.Key}`}
                    control={control}
                    render={({
                      field: { onChange, value, name: fieldName },
                      formState: { errors }
                    }) => (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gridColumn: '1/4'
                          }}
                        >
                          <Label htmlFor="">
                            {param.Title}{' '}
                            <TooltipPanel
                              ObjectTooltip={
                                user.tooltips.find(
                                  (t: any) =>
                                    t.idTooltip ===
                                    `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                                ) ??
                                `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                              }
                            />
                          </Label>
                          <LabelKey htmlFor="">[{param.Key}]</LabelKey>
                        </div>
                        {!endpoint.UrlAbsolute.includes('u.braindw.com') && (
                          <Form.Check
                            type="radio"
                            label="Dinámica"
                            id={`category-type-1-radio-${name}`}
                            value="dynamic"
                            {...register(`${name}.category-type`)}
                          />
                        )}
                        {!endpoint.UrlAbsolute.includes('u.braindw.com') && (
                          <Form.Check
                            type="radio"
                            label="Estática"
                            value="static"
                            id={`category-type-2-radio-${name}`}
                            {...register(`${name}.category-type`)}
                          />
                        )}
                        {endpoint.UrlAbsolute.includes('a.braindw.com') && (
                          <Form.Check
                            type="radio"
                            label="Colección"
                            value="collection"
                            id={`category-type-3-radio-${name}`}
                            {...register(`${name}.category-type`)}
                          />
                        )}
                        {watch(`${name}.category-type`) == 'collection' && (
                          <ThumbContentInput
                            type="text"
                            name={fieldName}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                        {watch(`${name}.category-type`) == 'static' ||
                        endpoint.UrlAbsolute.includes('u.braindw.com') ? (
                          <Select
                            name={fieldName}
                            isClearable
                            isSearchable
                            options={categories.map((cat: any) => ({
                              label: cat.categoryPathString,
                              value: cat.id
                            }))}
                            isLoading={categoriesLoading}
                            placeholder={'Seleccionar'}
                            styles={endPointPreviewSelectStyle}
                            value={value}
                            onChange={(e: any) => {
                              if (e == null) {
                                onChange(null)
                              }
                              if (e?.value != undefined) {
                                setValue(`${name}.category-type`, e?.value)
                                onChange(e)
                              }
                            }}
                          />
                        ) : null}
                      </>
                    )}
                  />
                </CustomInputGroup>
              )
              return input
            }

            input = (
              <Controller
                key={idx}
                name={`${name}.${param.Key}`}
                control={control}
                render={({
                  field: { onChange, value, name },
                  formState: { errors }
                }) => (
                  <CustomInputGroup>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gridColumn: '1/4'
                      }}
                    >
                      <Label htmlFor="">
                        {param.Title}{' '}
                        <TooltipPanel
                          ObjectTooltip={
                            user.tooltips.find(
                              (t: any) =>
                                t.idTooltip ===
                                `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                            ) ??
                            `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                          }
                        />
                      </Label>
                      <LabelKey htmlFor="">[{param.Key}]</LabelKey>
                    </div>
                    <Select
                      name={name}
                      isClearable
                      options={param?.Value}
                      placeholder={'Seleccionar'}
                      styles={endPointPreviewSelectStyle}
                      value={value}
                      onChange={(e: any) => {
                        if (e == null) {
                          onChange(null)
                        }
                        if (e?.value != undefined) {
                          onChange(e)
                        }
                      }}
                    />
                  </CustomInputGroup>
                )}
              />
            )
            return input
          }
          default: {
            input = (
              <CustomInputGroup key={idx}>
                <Label htmlFor="">
                  {param.Title}{' '}
                  <TooltipPanel
                    ObjectTooltip={
                      user.tooltips.find(
                        (t: any) =>
                          t.idTooltip ===
                          `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                      ) ??
                      `app.emailmarketing.create.campaign.step.two.label.${param.Key}`
                    }
                  />
                </Label>
                <LabelKey htmlFor="">[{param.Key}]</LabelKey>
                <ThumbContentInput
                  type="text"
                  {...register(`${name}.${param.Key}`)}
                />
              </CustomInputGroup>
            )
            return input
          }
        }
        // return input
      })
    },
    [categories, categoriesLoading]
  )

  const endPointSave = useCallback(
    (
      e,
      name,
      staticUrl: string | null = null,
      params: null | any[] = null,
      categoryList: null | any[] = null
    ) => {
      try {
        if (e) {
          e.preventDefault()
        }
        if (!selectedScript) {
          return
        }
        const notShowInput = new Set([
          'hash',
          'ext',
          'company',
          'refreshmetadata',
          'refreshMetaData'
        ])
        const endPoint = endPoints.find(
          (end: any) => end.Id === watch(name)?.value
        )
        let abUrl

        if (!staticUrl) {
          abUrl = new URL(endPoint.UrlAbsolute)
        }
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (staticUrl) {
          abUrl = new URL(staticUrl)
        }
        if (!abUrl) {
          return
        }
        // replace company on url if is custom service
        abUrl.href = abUrl.href.replaceAll(
          '%3C%COMPANY%%3E',
          selectedScript.companyVtex
        )

        abUrl.href = abUrl.href.replaceAll(
          '%3C%COMPANYCUSTOM%%3E',
          selectedScript.companyVtex
        )

        const abUrlParam = new URLSearchParams(abUrl.search)

        // static content
        if (abUrlParam.has('hash')) {
          abUrlParam.set('hash', selectedScript.idHashScript)
        }

        if (abUrlParam.has('ext')) {
          abUrlParam.set('ext', 'json')
        }

        if (abUrlParam.has('company')) {
          abUrlParam.set('company', selectedScript.companyVtex)
        }

        if (abUrlParam.has('refreshmetadata')) {
          abUrlParam.set('refreshmetadata', 'true')
        }
        if (abUrlParam.has('refreshMetaData')) {
          abUrlParam.set('refreshMetaData', 'true')
        }

        // params from endpoint url
        const paramsObj: any = Array.from(abUrlParam.keys()).reduce(
          (acc, val) => ({ ...acc, [val]: abUrlParam.get(val) }),
          {}
        )

        // filtered params
        const filterParams: any = Object.keys(paramsObj)
          .filter((key) => !notShowInput.has(key))
          .reduce((acc, cu) => ({ ...acc, [cu]: paramsObj[cu] }), {})
        Object.keys(filterParams).forEach((key) => {
          switch (key) {
            case 'branchOffice':
            case 'sc': {
              if (staticUrl) {
                if (isParamDynamic(filterParams[key])) {
                  setValue(`${name}.${key}-type`, 'dynamic')
                  break
                }
                if (filterParams[key] == '') {
                  setValue(`${name}.${key}-type`, 'empty')
                  break
                }
                setValue(`${name}.${key}-type`, 'static')
                setValue(`${name}.${key}`, filterParams[key])
                break
              }
              if (!watch(name)?.[`${key}-type`]) {
                abUrlParam.set(key, filterParams[key])
                break
              }
              if (watch(name)?.[`${key}-type`] === 'empty') {
                abUrlParam.set(key, '')
                break
              }
              if (watch(name)?.[`${key}-type`] === 'dynamic') {
                abUrlParam.set(key, filterParams[key])

                break
              }
              if (watch(name)?.[`${key}-type`] === 'static') {
                abUrlParam.set(key, watch(`${name}.${key}`))
              }
              break
            }
            case 'whitelabel': {
              if (staticUrl) {
                if (isParamDynamic(filterParams[key])) {
                  setValue(`${name}.whitelabel-type`, 'dynamic')
                  break
                }
                if (filterParams[key] == '') {
                  setValue(`${name}.whitelabel-type`, 'empty')
                  break
                }
                setValue(`${name}.whitelabel-type`, 'static')
                setValue(`${name}.${key}`, filterParams[key])
                break
              }
              if (!watch(name)?.['whitelabel-type']) {
                abUrlParam.set(key, filterParams[key])
                break
              }
              if (watch(name)?.['whitelabel-type'] === 'empty') {
                abUrlParam.set(key, '')
                break
              }
              if (watch(name)?.['whitelabel-type'] === 'dynamic') {
                abUrlParam.set(key, filterParams[key])
                break
              }
              if (watch(name)?.['whitelabel-type'] === 'static') {
                abUrlParam.set(key, watch(`${name}.whitelabel`))
              }
              break
            }
            case 'idcategory':
            case 'category':
            case 'categories':
            case 'parentcode': {
              if (staticUrl) {
                if (isParamDynamic(filterParams[key])) {
                  setValue(`${name}.category-type`, 'dynamic')
                  break
                }

                if (filterParams[key] == '') {
                  setValue(`${name}.category-type`, 'empty')
                  break
                }

                if (
                  selectedScript.clientPlatform === 'Vtex' &&
                  filterParams[key].startsWith('cl_')
                ) {
                  setValue(`${name}.category-type`, 'collection')
                  setValue(`${name}.${key}`, filterParams[key].substring(3))
                  break
                }

                setValue(`${name}.category-type`, 'static')
                if (
                  selectedScript.clientPlatform === 'Vtex' &&
                  filterParams[key]?.length
                ) {
                  if (categoryList?.length) {
                    const cat = categoryList.find(
                      (i: any) => i.id == parseInt(filterParams[key])
                    )
                    setValue(`${name}.${key}`, {
                      value: cat.id,
                      label: cat.categoryPathString
                    })
                  }
                }
                break
              }

              if (!watch(name)?.['category-type']) {
                abUrlParam.set(key, filterParams[key])
                return
              }
              if (
                selectedScript.clientPlatform === 'Vtex' &&
                watch(name)?.['category-type'] === 'dynamic'
              ) {
                abUrlParam.set(key, '{categorycode}')
                break
              }

              if (
                selectedScript.clientPlatform === 'Vtex' &&
                watch(name)?.['category-type'] === 'collection'
              ) {
                abUrlParam.set(key, `cl_${watch(name)?.[key]}`)
                break
              }

              if (watch(name)?.['category-type'] === 'dynamic') {
                abUrlParam.set(key, filterParams[key])
                break
              }

              if (
                selectedScript.clientPlatform === 'Vtex' &&
                watch(name)?.['category-type'] === 'static'
              ) {
                abUrlParam.set(key, watch(name)?.[key].value)
                break
              }

              if (
                selectedScript.clientPlatform === 'Vtex' &&
                watch(name)?.['category-type'] === 'collection'
              ) {
                abUrlParam.set(key, watch(name)?.[key])
                break
              }

              if (watch(name)?.['category-type'] === 'static') {
                abUrlParam.set(key, watch(name)?.[key])
                break
              }

              abUrlParam.set(key, watch(name)?.[key].value)
              break
            }

            default:
              if (staticUrl) {
                if (!params) break
                const paramKey = params.find((p: any) => p.Key == key)
                if (!paramKey) break
                if (paramKey.Type == 'Text') {
                  setValue(`${name}.${key}`, filterParams[key])
                  break
                }
                if (paramKey.Type == 'List') {
                  setValue(`${name}.${key}.value`, filterParams[key])
                  const paramKeyValue = paramKey.Value.find(
                    (v: any) => v.value == filterParams[key]
                  )
                  setValue(`${name}.${key}.label`, paramKeyValue.label)
                  break
                }
              }
              if (typeof watch(name)?.[key] === 'string') {
                abUrlParam.set(key, watch(name)?.[key])
                break
              }
              if (watch(name)?.[key]?.value) {
                abUrlParam.set(key, watch(name)?.[key]?.value)
              }
              break
          }
        })
        setValue(
          `${name}.endPointSource`,
          abUrl.origin +
            abUrl.pathname +
            '?' +
            decodeURIComponent(abUrlParam.toString())
        )
        const url =
          abUrl.origin +
          abUrl.pathname +
          '?' +
          decodeURIComponent(abUrlParam.toString())
        return url
      } catch (error) {
        console.log(error)
      }
    },
    [selectedScript, endPoints, categories, campaign]
  )
  const endPointGenerator = useCallback(
    async (e, name) => {
      try {
        e.preventDefault()
        if (!selectedScript) {
          return
        }
        setOpenPreviewProducts(true)
        setPreviewProductsLoading(true)

        const url = endPointSave(e, name)
        if (!url) {
          return
        }
        const { data } = await axios.get(url)
        if (data) {
          setPreviewProducts(data)
        }
        setPreviewProductsLoading(false)
      } catch (error) {
        setPreviewProducts([])
        setPreviewProductsLoading(false)
        console.log(error)
      }
    },
    [selectedScript, endPoints]
  )

  const handleGalleryFilter = useCallback(
    async (year, month) => {
      try {
        getGalleryImages(year, month)
      } catch (error) {
        console.log(error)
      }
    },
    [galleryFilter]
  )

  const utmOnChange = useCallback((e, onChange) => {
    if (!/^\w+$/.test(e.target.value) && e.target?.value?.length) {
      setError(e.target.name, {
        type: 'patter',
        message: 'No se permiten caracteres especiales'
      })
      setTimeout(() => {
        clearErrors(e.target.name)
      }, 3000)
      return
    }
    clearErrors(e.target.name)
    onChange(e)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)

    if (campaign) {
      // header
      setValue('header', campaign.HeaderImageUrl)
      setValue('header-url', campaign.HeaderLink)
      setValue('header-utm-term', campaign.HeaderUTMTerm)
      setValue('header-utm-content', campaign.HeaderUTMContent)

      // body
      setValue('body', campaign.BodyImageUrl)
      setValue('body-url', campaign.BodyLink)
      setValue('body-utm-term', campaign.BodyUTMTerm)
      setValue('body-utm-content', campaign.BodyUTMContent)

      // footer
      setValue('footer', campaign.FooterImageUrl)
      setValue('footer-url', campaign.FooterLink)
      setValue('footer-utm-term', campaign.FooterUTMTerm)
      setValue('footer-utm-content', campaign.FooterUTMContent)

      setValue('disclaimer', campaign.Disclaimer)

      // endpoints
      if (campaign.EndPointSource) {
        setValue('endPointOne.endPointSource', campaign.EndPointSource)
      }
      if (campaign.EndPointSource2) {
        setValue('endPointTwo.endPointSource2', campaign.EndPointSource2)
      }
    }
    getAutomationEndPoint()

    getGalleryImages(date.getFullYear(), date.getMonth() + 1)
  }, [])

  return (
    <EditStepContainer>
      <EditStepWrapper>
        <ThumbContainer>
          <div
            style={{
              position: 'absolute',
              right: -44,
              top: '1%',
              cursor: 'pointer',
              backgroundColor: '#E8E8ED'
            }}
            onClick={() => setOpenGallery('header')}
          >
            <MdApps size={35} color="6529A1" />
          </div>
          {openGallery === 'header' && (
            <Gallery
              loading={galleryLoading}
              handleGalleryFilter={handleGalleryFilter}
              open={!!openGallery}
              gallery={gallery}
              setOpenGallery={setOpenGallery}
              title={'Header'}
              name="header"
              filter={galleryFilter}
              setFilter={setGalleryFilter}
            />
          )}
          <UploadImage
            isUpdate={false}
            src={watch('header')}
            name="header"
            height={'250px'}
            width={'100%'}
          />
          <ThumbContentEdit>
            <div
              className="input-group-bdw"
              style={{
                display: 'flex',
                columnGap: '0px',
                justifyContent: 'center'
              }}
            >
              <Label htmlFor="" style={{ width: '15%' }}>
                {intl.formatMessage({
                  id: 'app.emailmarketing.create.campaign.step.two.label.utm.url',
                  defaultMessage: 'Url Destino'
                })}{' '}
                :
              </Label>
              <ThumbContentInput
                type="text"
                {...register('header-url')}
                style={{ width: '100%' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                columnGap: '12px',
                justifyContent: 'center'
              }}
            >
              <div
                className="input-group-bdw"
                style={{
                  display: 'flex',
                  columnGap: '12px',
                  justifyContent: 'center'
                }}
              >
                <Label htmlFor="" style={{ width: '32%' }}>
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.two.label.utm.term',
                    defaultMessage: 'Termino'
                  })}
                  :
                </Label>
                <Controller
                  name="header-utm-term"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error }
                  }) => (
                    <ThumbContentInputContainer>
                      <ThumbContentInput
                        type="text"
                        name={name}
                        value={value}
                        onChange={(e) => utmOnChange(e, onChange)}
                        data-tip={error?.message}
                        style={{
                          width: '100%',
                          borderColor: error ? '#DB164B' : '#ced4da'
                        }}
                      />
                      {error && (
                        <ThumbContentInputError>
                          {error?.message}
                        </ThumbContentInputError>
                      )}
                    </ThumbContentInputContainer>
                  )}
                />
              </div>
              <div
                className="input-group-bdw"
                style={{ display: 'flex', columnGap: '12px' }}
              >
                <Label htmlFor="">
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.two.label.utm.content',
                    defaultMessage: 'Contenido'
                  })}
                  :
                </Label>
                <Controller
                  name="header-utm-content"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error }
                  }) => (
                    <ThumbContentInputContainer>
                      <ThumbContentInput
                        type="text"
                        name={name}
                        value={value}
                        onChange={(e) => utmOnChange(e, onChange)}
                        data-tip={error?.message}
                        style={{
                          width: '100%',
                          borderColor: error ? '#DB164B' : '#ced4da'
                        }}
                      />
                      {error && (
                        <ThumbContentInputError>
                          {error?.message}
                        </ThumbContentInputError>
                      )}
                    </ThumbContentInputContainer>
                  )}
                />
              </div>
            </div>
          </ThumbContentEdit>
        </ThumbContainer>
        {!endPointLoading && (
          <EndpointSelectorContainer
            className="endpointSelector"
            style={{
              flexDirection: 'column',
              rowGap: 14,
              alignItems: 'baseline',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          >
            <Controller
              name="endPointOne"
              control={control}
              render={({
                field: { onChange, value, name },
                formState: { errors }
              }) => (
                <Select
                  name={name}
                  isClearable
                  options={endPoints.map((e: any) => ({
                    label: e.Name,
                    value: e.Id
                  }))}
                  placeholder={intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.two.label.selectrule',
                    defaultMessage: 'Seleccione una regla'
                  })}
                  styles={automationEndpointSelectStyle}
                  onChange={(e: any) => {
                    setValue('endPointOne', null)
                    if (e == null) {
                      onChange(null)
                    }
                    if (e?.value != undefined) {
                      // resetField('endPointOne')
                      if (value) {
                        endPoints
                          .find((end: any) => end.Id === value.value)
                          ?.Parameters.forEach((element: any) => {
                            setValue(`${name}.category-type`, '')
                            setValue(`${name}.${element.Key}`, '')
                            setValue(`${name}.${element.Key}-type`, '')
                          })
                      }
                      onChange(e)
                    }
                  }}
                  value={
                    value ? { label: value.label, value: value.value } : null
                  }
                />
              )}
            />
            {watch('endPointOne')?.value ? (
              <EndpointParametersContainer>
                {endPointsParams(
                  'endPointOne',
                  endPoints.find(
                    (end: any) => end.Id === watch('endPointOne')?.value
                  )
                )}
              </EndpointParametersContainer>
            ) : null}
            {watch('endPointOne')?.value ? (
              <EndpointButtonContainer>
                {!checkIfEndpointIsDynamic('endPointOne') && (
                  <CDPButton
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async (e) =>
                      await endPointGenerator(e, 'endPointOne')
                    }
                    style={{
                      borderRadius: '0px',
                      borderColor: '#00868B',
                      textTransform: 'uppercase'
                    }}
                  >
                    {intl.formatMessage({
                      id: 'app.emailmarketing.create.campaign.step.two.label.previewendpoint',
                      defaultMessage: 'Pre-visualizar endpoint'
                    })}
                  </CDPButton>
                )}
                <CDPButton
                  onClick={(e) => endPointSave(e, 'endPointOne')}
                  style={{
                    borderRadius: '0px',
                    borderColor: '#00868B',
                    textTransform: 'uppercase'
                  }}
                >
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.two.label.saveendpoint',
                    defaultMessage: 'Guardar Configuración'
                  })}
                </CDPButton>
              </EndpointButtonContainer>
            ) : null}
            <EndpointPreview
              open={openPreviewProducts}
              products={previewProducts}
              loading={previewProductsLoading}
              setOpenPreviewModal={setOpenPreviewProducts}
            />
          </EndpointSelectorContainer>
        )}
        <ThumbContainer>
          <div
            style={{
              position: 'absolute',
              right: -44,
              top: '1%',
              cursor: 'pointer',
              backgroundColor: '#E8E8ED'
            }}
            onClick={() => setOpenGallery('body')}
          >
            <MdApps size={35} color="6529A1" />
          </div>
          {openGallery === 'body' && (
            <Gallery
              loading={galleryLoading}
              handleGalleryFilter={handleGalleryFilter}
              open={!!openGallery}
              gallery={gallery}
              setOpenGallery={setOpenGallery}
              title={'Body'}
              name="body"
              filter={galleryFilter}
              setFilter={setGalleryFilter}
            />
          )}
          <UploadImage
            isUpdate={false}
            name="body"
            height={'250px'}
            width={'100%'}
            src={watch('body')}
          />
          <ThumbContentEdit>
            <div
              className="input-group-bdw"
              style={{
                display: 'flex',
                columnGap: '0px',
                justifyContent: 'center'
              }}
            >
              <Label htmlFor="" style={{ width: '15%' }}>
                {intl.formatMessage({
                  id: 'app.emailmarketing.create.campaign.step.two.label.utm.url',
                  defaultMessage: 'Url Destino'
                })}{' '}
                :
              </Label>
              <ThumbContentInput
                type="text"
                {...register('body-url')}
                style={{ width: '100%' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                columnGap: '12px',
                justifyContent: 'center'
              }}
            >
              <div
                className="input-group-bdw"
                style={{
                  display: 'flex',
                  columnGap: '12px',
                  justifyContent: 'center'
                }}
              >
                <Label htmlFor="" style={{ width: '32%' }}>
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.two.label.utm.term',
                    defaultMessage: 'Termino'
                  })}
                  :
                </Label>
                <Controller
                  name="body-utm-term"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error }
                  }) => (
                    <ThumbContentInputContainer>
                      <ThumbContentInput
                        type="text"
                        name={name}
                        value={value}
                        onChange={(e) => utmOnChange(e, onChange)}
                        data-tip={error?.message}
                        style={{
                          width: '100%',
                          borderColor: error ? '#DB164B' : '#ced4da'
                        }}
                      />
                      {error && (
                        <ThumbContentInputError>
                          {error?.message}
                        </ThumbContentInputError>
                      )}
                    </ThumbContentInputContainer>
                  )}
                />
              </div>
              <div
                className="input-group-bdw"
                style={{ display: 'flex', columnGap: '12px' }}
              >
                <Label htmlFor="">
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.two.label.utm.content',
                    defaultMessage: 'Contenido'
                  })}
                  :
                </Label>
                <Controller
                  name="body-utm-content"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error }
                  }) => (
                    <ThumbContentInputContainer>
                      <ThumbContentInput
                        type="text"
                        name={name}
                        value={value}
                        onChange={(e) => utmOnChange(e, onChange)}
                        data-tip={error?.message}
                        style={{
                          width: '100%',
                          borderColor: error ? '#DB164B' : '#ced4da'
                        }}
                      />
                      {error && (
                        <ThumbContentInputError>
                          {error?.message}
                        </ThumbContentInputError>
                      )}
                    </ThumbContentInputContainer>
                  )}
                />
              </div>
            </div>
          </ThumbContentEdit>
        </ThumbContainer>
        {!endPointLoading && (
          <EndpointSelectorContainer
            className="endpointSelector"
            style={{
              flexDirection: 'column',
              rowGap: 14,
              alignItems: 'baseline',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          >
            <Controller
              name="endPointTwo"
              control={control}
              render={({
                field: { onChange, value, name },
                formState: { errors }
              }) => (
                <Select
                  name={name}
                  isClearable
                  options={endPoints.map((e: any) => ({
                    label: e.Name,
                    value: e.Id
                  }))}
                  placeholder={intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.two.label.selectrule',
                    defaultMessage: 'Seleccione una regla'
                  })}
                  value={value}
                  styles={automationEndpointSelectStyle}
                  onChange={(e: any) => {
                    if (e == null) {
                      onChange(null)
                    }
                    if (e?.value != undefined) {
                      if (value) {
                        endPoints
                          .find((end: any) => end.Id === value.value)
                          ?.Parameters.forEach((element: any) => {
                            setValue(`${name}.category-type`, '')
                            setValue(`${name}.${element.Key}`, '')
                            setValue(`${name}.${element.Key}-type`, '')
                          })
                      }
                      onChange(e)
                    }
                  }}
                />
              )}
            />
            {watch('endPointTwo')?.value ? (
              <EndpointParametersContainer>
                {endPointsParams(
                  'endPointTwo',
                  endPoints.find(
                    (end: any) => end.Id === watch('endPointTwo')?.value
                  )
                )}
              </EndpointParametersContainer>
            ) : null}
            {watch('endPointTwo')?.value ? (
              <EndpointButtonContainer>
                {!checkIfEndpointIsDynamic('endPointTwo') && (
                  <CDPButton
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async (e) =>
                      await endPointGenerator(e, 'endPointTwo')
                    }
                    style={{
                      borderRadius: '0px',
                      borderColor: '#00868B',
                      textTransform: 'uppercase'
                    }}
                  >
                    {intl.formatMessage({
                      id: 'app.emailmarketing.create.campaign.step.two.label.previewendpoint',
                      defaultMessage: 'Pre-visualizar endpoint'
                    })}
                  </CDPButton>
                )}
                <CDPButton
                  onClick={(e) => endPointSave(e, 'endPointTwo')}
                  style={{
                    borderRadius: '0px',
                    borderColor: '#00868B',
                    textTransform: 'uppercase'
                  }}
                >
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.two.label.saveendpoint',
                    defaultMessage: 'Guardar Configuración'
                  })}
                </CDPButton>
              </EndpointButtonContainer>
            ) : null}
          </EndpointSelectorContainer>
        )}
        <ThumbContainer>
          <div
            style={{
              position: 'absolute',
              right: -44,
              top: '1%',
              cursor: 'pointer',
              backgroundColor: '#E8E8ED'
            }}
            onClick={() => setOpenGallery('footer')}
          >
            <MdApps size={35} color="6529A1" />
          </div>
          {openGallery === 'footer' && (
            <Gallery
              loading={galleryLoading}
              handleGalleryFilter={handleGalleryFilter}
              open={!!openGallery}
              gallery={gallery}
              setOpenGallery={setOpenGallery}
              title={'Footer'}
              name="footer"
              filter={galleryFilter}
              setFilter={setGalleryFilter}
            />
          )}
          <UploadImage
            isUpdate={false}
            name="footer"
            height={'250px'}
            width={'100%'}
            src={watch('footer')}
          />
          <ThumbContentEdit>
            <div
              className="input-group-bdw"
              style={{
                display: 'flex',
                columnGap: '0px',
                justifyContent: 'center'
              }}
            >
              <Label htmlFor="" style={{ width: '15%' }}>
                {intl.formatMessage({
                  id: 'app.emailmarketing.create.campaign.step.two.label.utm.url',
                  defaultMessage: 'Url Destino'
                })}{' '}
                :
              </Label>
              <ThumbContentInput
                type="text"
                {...register('footer-url')}
                style={{ width: '100%' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                columnGap: '12px',
                justifyContent: 'center'
              }}
            >
              <div
                className="input-group-bdw"
                style={{
                  display: 'flex',
                  columnGap: '12px',
                  justifyContent: 'center'
                }}
              >
                <label htmlFor="" style={{ width: '32%' }}>
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.two.label.utm.term',
                    defaultMessage: 'Termino'
                  })}
                  :
                </label>
                <Controller
                  name="footer-utm-term"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error }
                  }) => (
                    <ThumbContentInputContainer>
                      <ThumbContentInput
                        type="text"
                        name={name}
                        value={value}
                        onChange={(e) => utmOnChange(e, onChange)}
                        data-tip={error?.message}
                        style={{
                          width: '100%',
                          borderColor: error ? '#DB164B' : '#ced4da'
                        }}
                      />
                      {error && (
                        <ThumbContentInputError>
                          {error?.message}
                        </ThumbContentInputError>
                      )}
                    </ThumbContentInputContainer>
                  )}
                />
              </div>
              <div
                className="input-group-bdw"
                style={{ display: 'flex', columnGap: '12px' }}
              >
                <Label htmlFor="">
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.two.label.utm.content',
                    defaultMessage: 'Contenido'
                  })}
                  :
                </Label>
                <Controller
                  name="footer-utm-content"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error }
                  }) => (
                    <ThumbContentInputContainer>
                      <ThumbContentInput
                        type="text"
                        name={name}
                        value={value}
                        onChange={(e) => utmOnChange(e, onChange)}
                        data-tip={error?.message}
                        style={{
                          width: '100%',
                          borderColor: error ? '#DB164B' : '#ced4da'
                        }}
                      />
                      {error && (
                        <ThumbContentInputError>
                          {error?.message}
                        </ThumbContentInputError>
                      )}
                    </ThumbContentInputContainer>
                  )}
                />
              </div>
            </div>
          </ThumbContentEdit>
        </ThumbContainer>
        <EndpointSelectorContainer className="endpointSelector">
          <div
            className="input-group-bdw"
            style={{
              display: 'flex',
              columnGap: '12px',
              flexDirection: 'column'
            }}
          >
            <Label htmlFor="">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.two.label.disclaimer',
                defaultMessage: 'Descargo de responsabilidad'
              })}
              :
            </Label>
            <DisclaimerTextArea
              {...register('disclaimer')}
              style={{ width: '100%' }}
            />
          </div>
        </EndpointSelectorContainer>
      </EditStepWrapper>
    </EditStepContainer>
  )
}

export default EditTemplateStep
