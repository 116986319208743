// pagination table custom

import styled from 'styled-components'
import { CSSObjectWithLabel } from 'react-select'

export const NavPaginationTable = styled.nav`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  color: #000;
  font-size: 14px;
  min-height: 56px;
  background-color: transparent;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  column-gap: 20px;
`
export const PaginationSpanTable = styled.span`
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const PaginationTableButtonContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
`
export const PaginationTableButton = styled.button`
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 8px;
  margin: 5px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: #fff;
  fill: rgba(0, 0, 0, 0.54);
  background-color: #e9ecef;
  :disabled {
    cursor: unset;
    color: #2fad7e;
    fill: rgba(0, 0, 0, 0.18);
  }
`
export const ContainerTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  width: 100%;
  padding: 6px 12px;
  min-width: 400px;
`

export const InputSearchTableHeaderContainer = styled(ContainerTableHeader)`
  min-width: auto;
  position: relative;
  margin-left: 8px;
  border-bottom: 2px solid #ccc !important;
`

export const InputSearchTableHeader = styled.input`
  font-family: 'Poppins';
  line-height: 1.5rem;
  font-size: 15px;
  border: none;
  width: 100%;
  background-color: transparent;
  color: #444;
`

export const subHeaderTableSelectStyle = {
  container: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    width: '100%'
  }),
  control: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    borderWidth: 0,
    backgroundColor: '#f8f9fa',
    ':hover': {
      ...prevStyles[':hover'],
      border: 'none'
    },
    ':focus-visible': {
      ...prevStyles[':focus-visible'],
      outline: 'none'
    }
  }),
  option: (
    prevStyles: CSSObjectWithLabel,
    { isSelected }: { isSelected: boolean }
  ) => ({
    ...prevStyles,
    ':hover': {
      ...prevStyles[':hover'],
      backgroundColor: isSelected ? '#6529A1' : 'rgb(101 41 161 / 70%)',
      color: '#fff'
    },
    backgroundColor: isSelected ? '#6529A1' : '#fff',
    ':active': {
      ...prevStyles[':active'],
      backgroundColor: '#6529A1',
      color: '#fff'
    }
  })
}
