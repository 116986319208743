/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable  @typescript-eslint/restrict-plus-operands */
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { RootState } from '../../../state/store'

// import './system.css'

const ProfileForm = () => {
  const user = useSelector<RootState, any>((state) => state.userAuth)
  return (
    <div
      className="container mg-t-0 table-bordered "
      style={{ display: 'block' }}
    >
      <div className="rule-config__wrapper sombra_caja row">
        <div className="rule-config__title-container row">
          <div className="">
            <div className=" col-lg-8 col-md-8 col-sm-8 col-xs-8 ">
              <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                <div
                  className="col-lg-9 col-md-9 col-sm-9 col-xs-9"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textTransform: 'capitalize'
                  }}
                >
                  <label
                    style={{
                      color: '#666',
                      fontSize: '16px',
                      fontWeight: 500
                    }}
                  >
                    Nombre y Apellido
                  </label>
                  <div
                    style={{
                      color: '#666',
                      fontSize: '16px',
                      fontWeight: 400
                    }}
                  >
                    {user.data?.name ?? ''} {user.data?.lastname ?? ''}
                  </div>
                </div>
              </div>

              <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                <div
                  className="col-lg-9 col-md-9 col-sm-9 col-xs-9"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <label
                    style={{
                      color: '#666',
                      fontSize: '16px',
                      fontWeight: 500
                    }}
                  >
                    <FormattedMessage
                      id="app.system..profile-form.email"
                      defaultMessage="Email"
                    />{' '}
                  </label>
                  <div
                    style={{
                      color: '#666',
                      fontSize: '16px',
                      fontWeight: 400
                    }}
                  >
                    {user.data?.email ?? ''}
                  </div>

                  <span
                    style={{
                      color: '#666',
                      fontSize: '10px',
                      fontStyle: 'italic',
                      fontWeight: 300
                    }}
                  >
                    El Email será utilizado para registro y recuperación de
                    cuenta del panel
                  </span>
                </div>
              </div>

              <div className="row mb-4 mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__endpoint-selector-container">
                <div
                  className="col-lg-9 col-md-9 col-sm-9 col-xs-9"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <label
                    style={{
                      color: '#666',
                      fontSize: '16px',
                      fontWeight: 500
                    }}
                  >
                    <FormattedMessage
                      id="app.system..profile-form.username"
                      defaultMessage="Nombre de Usuario"
                    />{' '}
                  </label>
                  <div
                    style={{
                      color: '#666',
                      fontSize: '16px',
                      fontWeight: 400
                    }}
                  >
                    {user.data?.username ?? ''}
                  </div>

                  <span
                    style={{
                      color: '#666',
                      fontSize: '10px',
                      fontStyle: 'italic',
                      fontWeight: 300
                    }}
                  >
                    El Username será utilizado para el ingreso al panel
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileForm
