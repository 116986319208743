/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useCallback, useEffect, useState } from 'react'
import './Campain.css'
import './Automation.css'
import Form from 'react-bootstrap/Form'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { IMask } from 'react-imask'
import MaskedInput from '../Home/Images/imageBinds/maskInput'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IoTimeOutline } from 'react-icons/io5'
import { Gallery } from './editTemplateStep/Gallery'
import { UploadImage } from './editTemplateStep/UploadImage'
import Service from '../../services'
import { useSelector } from 'react-redux'
import { RootState } from '../../state/store'
import { useClientContext } from '../../contexts/ClientContext'
import {
  Label,
  ThumbContainer,
  ThumbContentEdit,
  ThumbContentInput
} from './css/EditTemplateComponents'
import { MdApps } from 'react-icons/md'
import { idRuleAtom, nameRuleAtom } from '../../atoms/automation'
import { useAtom } from 'jotai'
import AutomationDaysPopUp from './AutomationDaysPopUp'
import { useNavigate } from 'react-router-dom'
import AutomationRules from './AutomationRules'
import { Loading } from '../Globals/Loading'
import { ShowAlert } from '../Globals'
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl'

interface Rule {
  key: string
  value: string
}

const labelMap: Record<string, string> = {
  excludelastdays: 'Excluir últimos días',
  checkcountbeforedays: 'Dias a contemplar',
  checkCountBeforeDays: 'Dias a contemplar',
  hoursabandoned: 'Horas de abandono',
  geographicscompanyenabled: 'Empresa habilitada geográficamente',
  minstock: 'Stock mínimo',
  dontsendsameuserdays: 'No enviar al mismo usuario en días',
  CartUnClosedDay: 'Carro abandonado',
  TotalSession: 'Sesión total',
  TotalPageView: 'Vistas totales de página',
  limitproduct: 'Límite de productos',
  mstimeoutwsproducts: 'Tiempo de espera de WS',
  geographicsCompanyEnabled: 'Zona geografica',
  excludeLastDays: 'Excluir ultimos dias',
  hoursAbandoned: 'Horas a contemplar',
  dontSendSameUserDays: 'Cada cuantos se envia el mail'
}

interface SenderBlock {
  sender: {
    senderName: string
    senderEmail: string
    subject: string
    preHeaderText: string
    disclaimer: string
    listEmailAlert: string
    templateSource: string
    active: boolean
    hourRun: string
    hourStop: string
    IdAutomationTemplate: string
  }
  blocks: {
    idAutomationEndpoint: number | ''
    idAutomationEndpoint2: number | ''
    endPointSource: string
    endPointSource2: string
    headerLink: string
    bodyLink: string
    footerLink: string
    headerImageUrl: string
    bodyImageUrl: string
    footerImageUrl: string
    headerUTMContent: string
    headerUTMTerm: string
    bodyUTMContent: string
    bodyUTMTerm: string
    footerUTMContent: string
    footerUTMTerm: string
  }
}

interface FileData {
  blockImage: string
  file: File | null
}

export interface FormDataValues {
  urlBody: string | number | readonly string[] | undefined
  urlFooter: string | number | readonly string[] | undefined
  urlHeader: string | number | readonly string[] | undefined
  startTime: string
  limiteProductos: string
  endTime: string
  active: any
  template: string
  templateUrl: string
  diasContemplar: string
  frecuenciaEnvio: string
  utmFuente: string
  utmCampania: string
  utmMedio: string
  plantilla: string
  urlPlantilla: string
  remitente: string
  asunto: string
  email: string
  preHeader: string
  legales: string
  listaEmailsTestigo: string
  urlAbsolutaImagenHeader: string
  linkHeader: string
  utmContenidoHeader: string
  utmTermHeader: string
  endpointHeader: string
  contenidoEndpoint2Header: string
  linkBody: string
  utmContenidoBody: string
  utmTermBody: string
  endpointBody: string
  contenidoEndpoint2Body: string
  linkFooter: string
  endpointFooter: string
  contenidoEndpoint2Footer: string
  utmContenidoFooter: string
  utmTermFooter: string
  emailPrueba: string
  emailCuota: string
}

const initialFormValues: Record<string, FormDataValues> = {
  Lunes: {
    startTime: '',
    endTime: '',
    active: false,
    template: 'Mail Mixto',
    templateUrl: '',
    diasContemplar: '',
    frecuenciaEnvio: '',
    utmFuente: '',
    utmCampania: '',
    utmMedio: '',
    plantilla: '',
    urlPlantilla: '',
    remitente: '',
    asunto: '',
    email: '',
    preHeader: '',
    legales: '',
    listaEmailsTestigo: '',
    urlAbsolutaImagenHeader: '',
    linkHeader: '',
    utmContenidoHeader: '',
    utmTermHeader: '',
    endpointHeader: '',
    contenidoEndpoint2Header: '',
    linkBody: '',
    utmContenidoBody: '',
    utmTermBody: '',
    endpointBody: '',
    contenidoEndpoint2Body: '',
    linkFooter: '',
    utmContenidoFooter: '',
    endpointFooter: '',
    contenidoEndpoint2Footer: '',
    utmTermFooter: '',
    emailPrueba: '',
    emailCuota: '',
    urlHeader: '',
    urlBody: '',
    urlFooter: '',
    limiteProductos: ''
  },
  Martes: {
    startTime: '',
    endTime: '',
    active: false,
    template: 'Mail Mixto',
    templateUrl: '',
    diasContemplar: '',
    frecuenciaEnvio: '',
    utmFuente: '',
    utmCampania: '',
    utmMedio: '',
    plantilla: '',
    urlPlantilla: '',
    remitente: '',
    asunto: '',
    email: '',
    preHeader: '',
    legales: '',
    listaEmailsTestigo: '',
    urlAbsolutaImagenHeader: '',
    linkHeader: '',
    utmContenidoHeader: '',
    utmTermHeader: '',
    endpointHeader: '',
    contenidoEndpoint2Header: '',
    linkBody: '',
    utmContenidoBody: '',
    utmTermBody: '',
    endpointBody: '',
    contenidoEndpoint2Body: '',
    linkFooter: '',
    utmContenidoFooter: '',
    endpointFooter: '',
    contenidoEndpoint2Footer: '',
    utmTermFooter: '',
    emailPrueba: '',
    emailCuota: '',
    urlHeader: '',
    urlBody: '',
    urlFooter: '',
    limiteProductos: ''
  },
  Miercoles: {
    startTime: '',
    endTime: '',
    active: false,
    template: 'Mail Mixto',
    templateUrl: '',
    diasContemplar: '',
    frecuenciaEnvio: '',
    utmFuente: '',
    utmCampania: '',
    utmMedio: '',
    plantilla: '',
    urlPlantilla: '',
    remitente: '',
    asunto: '',
    email: '',
    preHeader: '',
    legales: '',
    listaEmailsTestigo: '',
    urlAbsolutaImagenHeader: '',
    linkHeader: '',
    utmContenidoHeader: '',
    utmTermHeader: '',
    endpointHeader: '',
    contenidoEndpoint2Header: '',
    linkBody: '',
    utmContenidoBody: '',
    utmTermBody: '',
    endpointBody: '',
    contenidoEndpoint2Body: '',
    linkFooter: '',
    utmContenidoFooter: '',
    endpointFooter: '',
    contenidoEndpoint2Footer: '',
    utmTermFooter: '',
    emailPrueba: '',
    emailCuota: '',
    urlHeader: '',
    urlBody: '',
    urlFooter: '',
    limiteProductos: ''
  },
  Jueves: {
    startTime: '',
    endTime: '',
    active: false,
    template: 'Mail Mixto',
    templateUrl: '',
    diasContemplar: '',
    frecuenciaEnvio: '',
    utmFuente: '',
    utmCampania: '',
    utmMedio: '',
    plantilla: '',
    urlPlantilla: '',
    remitente: '',
    asunto: '',
    email: '',
    preHeader: '',
    legales: '',
    listaEmailsTestigo: '',
    urlAbsolutaImagenHeader: '',
    linkHeader: '',
    utmContenidoHeader: '',
    utmTermHeader: '',
    endpointHeader: '',
    contenidoEndpoint2Header: '',
    linkBody: '',
    utmContenidoBody: '',
    utmTermBody: '',
    endpointBody: '',
    contenidoEndpoint2Body: '',
    linkFooter: '',
    utmContenidoFooter: '',
    endpointFooter: '',
    contenidoEndpoint2Footer: '',
    utmTermFooter: '',
    emailPrueba: '',
    emailCuota: '',
    urlHeader: '',
    urlBody: '',
    urlFooter: '',
    limiteProductos: ''
  },
  Viernes: {
    startTime: '',
    endTime: '',
    active: false,
    template: 'Mail Mixto',
    templateUrl: '',
    diasContemplar: '',
    frecuenciaEnvio: '',
    utmFuente: '',
    utmCampania: '',
    utmMedio: '',
    plantilla: '',
    urlPlantilla: '',
    remitente: '',
    asunto: '',
    email: '',
    preHeader: '',
    legales: '',
    listaEmailsTestigo: '',
    urlAbsolutaImagenHeader: '',
    linkHeader: '',
    utmContenidoHeader: '',
    utmTermHeader: '',
    endpointHeader: '',
    contenidoEndpoint2Header: '',
    linkBody: '',
    utmContenidoBody: '',
    utmTermBody: '',
    endpointBody: '',
    contenidoEndpoint2Body: '',
    linkFooter: '',
    utmContenidoFooter: '',
    endpointFooter: '',
    contenidoEndpoint2Footer: '',
    utmTermFooter: '',
    emailPrueba: '',
    emailCuota: '',
    urlHeader: '',
    urlBody: '',
    urlFooter: '',
    limiteProductos: ''
  },
  Sabado: {
    startTime: '',
    endTime: '',
    active: false,
    template: 'Mail Mixto',
    templateUrl: '',
    diasContemplar: '',
    frecuenciaEnvio: '',
    utmFuente: '',
    utmCampania: '',
    utmMedio: '',
    plantilla: '',
    urlPlantilla: '',
    remitente: '',
    asunto: '',
    email: '',
    preHeader: '',
    legales: '',
    listaEmailsTestigo: '',
    urlAbsolutaImagenHeader: '',
    linkHeader: '',
    utmContenidoHeader: '',
    utmTermHeader: '',
    endpointHeader: '',
    contenidoEndpoint2Header: '',
    linkBody: '',
    utmContenidoBody: '',
    utmTermBody: '',
    endpointBody: '',
    contenidoEndpoint2Body: '',
    linkFooter: '',
    utmContenidoFooter: '',
    endpointFooter: '',
    contenidoEndpoint2Footer: '',
    utmTermFooter: '',
    emailPrueba: '',
    emailCuota: '',
    urlHeader: '',
    urlBody: '',
    urlFooter: '',
    limiteProductos: ''
  },
  Domingo: {
    startTime: '',
    endTime: '',
    active: false,
    template: 'Mail Mixto',
    templateUrl: '',
    diasContemplar: '',
    frecuenciaEnvio: '',
    utmFuente: '',
    utmCampania: '',
    utmMedio: '',
    plantilla: '',
    urlPlantilla: '',
    remitente: '',
    asunto: '',
    email: '',
    preHeader: '',
    legales: '',
    listaEmailsTestigo: '',
    urlAbsolutaImagenHeader: '',
    linkHeader: '',
    utmContenidoHeader: '',
    utmTermHeader: '',
    endpointHeader: '',
    contenidoEndpoint2Header: '',
    linkBody: '',
    utmContenidoBody: '',
    utmTermBody: '',
    endpointBody: '',
    contenidoEndpoint2Body: '',
    linkFooter: '',
    utmContenidoFooter: '',
    endpointFooter: '',
    contenidoEndpoint2Footer: '',
    utmTermFooter: '',
    emailPrueba: '',
    emailCuota: '',
    urlHeader: '',
    urlBody: '',
    urlFooter: '',
    limiteProductos: ''
  }
}

const AutomationEdit = () => {
  const methods = useForm()
  const navigate = useNavigate()
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const { selectedScript, selectedClient } = useClientContext()

  const [modalReplicar, setModalReplicar] = useState(false)
  const [activeDay, setActiveDay] = useState<string>('Lunes')
  const [activeDayLoading, setActiveDayLoading] = useState<boolean>(false)
  const [checkCountBeforeDays, setCheckCountBeforeDays] = useState('')
  const [limitProducts, setLimitProducts] = useState('')
  const [emailTesting, setEmailTesting] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [emailDailyQuota, setEmailDailyQuota] = useState('')
  const [senderBlocks, setSenderBlocks] = useState<any>([])
  const [fileData, setFileData] = useState<FileData>({
    blockImage: '',
    file: null
  })
  const [formValues, setFormValues] =
    useState<Record<string, FormDataValues>>(initialFormValues)
  const [rules, setRules] = useState<Rule[]>([])
  const [formValuesRules, setFormValuesRules] = useState<Record<string, any>>(
    {}
  )
  const [gallery, setGallery] = useState<any>([])
  const [openGallery, setOpenGallery] = useState<any>(null)
  const [utms, setUtms] = useState<any>({})
  const [mixTemplates, setMixTemplates] = useState<any>()
  const [selectedUrl, setSelectedUrl] = useState<string>('')
  const [nameRule] = useAtom<string>(nameRuleAtom)
  const [idRule] = useAtom<string>(idRuleAtom)
  const [galleryLoading, setGalleryLoading] = useState<boolean>(false)
  const date = new Date()
  const intl = useIntl()
  const [galleryFilter, setGalleryFilter] = useState({
    year: date.getFullYear(),
    month: date.getMonth() + 1
  })

  const MyAlertImage = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: 'AUTOMATION',
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: 'Cerrar',
      confirmButtonText: 'Cerrar',
      width: 500,
      timerProgressBar: true
    })
  }

  const MyAlertSave = (
    text: string,
    iconStr: number,
    onClickCallback?: () => void
  ) => {
    ShowAlert({
      title: 'AUTOMATION',
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: false,
      loaderHtml: 'espere...',
      width: 500,
      timerProgressBar: true,
      html:
        iconStr === 2
          ? `
        <div>
          <p>${text}</p>
          <button id="error-button" style="background: #d33; color: white; padding: 5px 10px; border: none; cursor: pointer;">
            Intentar de nuevo
          </button>
        </div>
      `
          : `
          <div>
          <p>${text}</p>
          <button id="custom-button" className="swal2-confirm swal2-styled" style="background: #3085d6; color: white; padding: 5px 10px; border: none; cursor: pointer;">
        Volver
      </button>
      </div>`,
      didOpen: () => {
        const button = document.getElementById('custom-button')
        if (button && onClickCallback) {
          button.addEventListener('click', () => {
            onClickCallback()
            Swal.close()
          })
        }
      }
    })
  }

  const handleInputChange = (field: keyof FormDataValues, value: string) => {
    setFormValues((prevState) => ({
      ...prevState,
      [activeDay]: {
        ...prevState[activeDay],
        [field]: value
      }
    }))
  }

  const handleMixEmailSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedDescription = event.target.value
    const selectedTemplate = mixTemplates.find(
      (template: any) => template.Id == selectedDescription
    )

    if (selectedTemplate) {
      handleInputChange('plantilla', selectedDescription)
      handleInputChange('templateUrl', selectedTemplate.urlTemplate)
      setSelectedUrl(selectedTemplate.urlTemplate)
    }
  }

  const handleDayClick = (day: string) => {
    setActiveDayLoading(true)

    const headerValue = methods.getValues(`${day}.header`)
    const bodyValue = methods.getValues(`${day}.body`)
    const footerValue = methods.getValues(`${day}.footer`)

    if (!headerValue) methods.setValue(`${day}.header`, null)
    if (!bodyValue) methods.setValue(`${day}.body`, null)
    if (!footerValue) methods.setValue(`${day}.footer`, null)

    setActiveDay(day)

    setTimeout(() => {
      setActiveDayLoading(false)
    }, 200)
  }

  const handleCloseModal = () => {
    setModalReplicar(false)
  }

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setEmailTesting(value)

    if (!validateEmail(value) && value.length > 0) {
      setErrorMessage('Por favor, ingresa un email válido.')
    } else {
      setErrorMessage('')
    }
  }

  const formatTimeMethods = (time: string): string => {
    const [hours, minutes] = time.split(':')
    return `${hours}${minutes}`
  }

  const handleGalleryFilter = useCallback(
    async (year, month) => {
      try {
        getGalleryImages(year, month)
      } catch (error) {
        console.log(error)
      }
    },
    [galleryFilter]
  )

  const getGalleryImages = useCallback(
    async (year, month, day = null) => {
      try {
        setGalleryLoading(true)
        const { data } = await Service.get<any>(
          `Automation/GetImagesUrlBlocks?idHashScript=${selectedScript?.idHashScript}&yearFilter=${year}&monthFilter=${month}&dayFilter=${day}`,
          {
            headers: {
              token: user.data.token
            }
          }
        )
        if (data) {
          setGallery(data)
        }
        setGalleryLoading(false)
      } catch (error) {
        setGalleryLoading(false)
        console.log(error)
      }
    },
    [user, selectedScript]
  )

  const TIME_MASK = [
    {
      mask: ''
    },
    {
      overwrite: true,
      autofix: true,
      mask: 'HH:MM',
      blocks: {
        HH: {
          mask: IMask.MaskedRange,
          placeholderChar: 'HH',
          from: 0,
          to: 23,
          maxLength: 2
        },
        MM: {
          mask: IMask.MaskedRange,
          placeholderChar: 'MM',
          from: 0,
          to: 59,
          maxLength: 2
        }
      }
    }
  ]

  const saveStaticCampaign = () => {
    const formBody = {
      campaignSetting: {
        idRule: idRule,
        Parameters: rules.map((rule) => ({
          key: rule.key,
          value: rule.value,
          type: null,
          title: null
        })),
        EmailsTesting: emailTesting,
        ExcludeEmailsDailyQuota: emailTesting
      },
      utms: {
        utmSource: utms?.utmSource,
        utmMedium: utms?.utmMedium,
        utmCampaign: utms?.utmCampaign
      },
      senderBlocks: Object.keys(initialFormValues).map(
        (day: any, index: any) => ({
          sender: {
            senderName: formValues[day]?.remitente,
            senderEmail: formValues[day]?.email,
            subject: formValues[day]?.asunto,
            preHeaderText: formValues[day]?.preHeader,
            disclaimer: formValues[day]?.legales,
            listEmailAlert: formValues[day]?.listaEmailsTestigo,
            templateSource: formValues[day]?.templateUrl,
            active: methods.getValues()[day]?.active,
            hourRun: formValues[day]?.startTime,
            hourStop: formValues[day]?.endTime,
            IdAutomationTemplate: formValues[day]?.plantilla
          },
          block: {
            idAutomationEndpoint:
              senderBlocks[index]?.blocks?.idAutomationEndpoint,
            idAutomationEndpoint2:
              senderBlocks[index]?.blocks?.idAutomationEndpoint2,
            endPointSource: senderBlocks[index]?.blocks?.endPointSource,
            endPointSource2: senderBlocks[index]?.blocks?.endPointSource2,
            headerLink: formValues[day]?.urlHeader,
            bodyLink: formValues[day]?.urlBody,
            footerLink: formValues[day]?.urlFooter,
            headerImageUrl:
              methods.getValues()[day]?.header == undefined
                ? ''
                : methods.getValues()[day]?.header,
            bodyImageUrl:
              methods.getValues()[day]?.body == undefined
                ? ''
                : methods.getValues()[day]?.body,
            footerImageUrl:
              methods.getValues()[day]?.footer == undefined
                ? ''
                : methods.getValues()[day]?.footer,
            headerUTMContent: formValues[day]?.utmContenidoHeader,
            headerUTMTerm: formValues[day]?.utmTermHeader,
            bodyUTMContent: formValues[day]?.utmContenidoBody,
            bodyUTMTerm: formValues[day]?.utmTermBody,
            footerUTMContent: formValues[day]?.utmContenidoFooter,
            footerUTMTerm: formValues[day]?.utmTermFooter
          }
        })
      )
    }
    Service.post<any, any>(
      `/automation/SaveStaticCampaign?idHashScript=${selectedScript?.idHashScript}`,
      JSON.stringify(formBody),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: user.data.token
        }
      }
    )
      .then((response: any) => {
        console.log(response)
        MyAlertSave('Se guardó correctamente', 1, () => {
          navigate(`/dashboard/campaign/automation`)
        })
      })
      .catch((error: any) => {
        if (error.message === '-6') {
          console.log(error)
        } else {
          console.log(error)
        }
      })
  }

  const saveBlockImage = (image: any) => {
    if (
      !image ||
      !(image instanceof File) ||
      !image.type.startsWith('image/')
    ) {
      MyAlertImage('El archivo proporcionado no es una imagen válida.', 3)
      return
    }
    const formData = new FormData()
    formData.append('blockImage', image)
    Service.post<any, any>(`/api/SaveBlockImage`, formData, {
      method: 'POST',
      headers: {
        token: user.data.token
      }
    })
      .then((res) => {
        MyAlertImage('Imagen subida exitosamente', 1)
      })
      .catch(() => {
        MyAlertImage('Hubo un error al subir la imagen', 2)
      })
  }

  const getTemplates = () => {
    Service.get<any>(
      `Automation/GetAutomationTemplates?idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          'Content-Type': 'application/json',
          token: user.data.token
        }
      }
    )
      .then((response) => {
        setMixTemplates(response.data)
        setFormValues((prevState) => ({
          ...prevState,
          [activeDay]: {
            ...prevState[activeDay],
            templateUrl: response.data[0].urlTemplate
          }
        }))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const GetAllCampaignById = () => {
    Service.get<any>(
      `/Automation/GetStaticCampaignById?idHashScript=${selectedScript?.idHashScript}&idRule=${idRule}`,
      {
        headers: {
          'Content-Type': 'application/json',
          token: user.data.token
        }
      }
    )
      .then((response) => {
        const apiData = response.data.Entity.campaignSetting.Parameters

        const uniqueRules = apiData.reduce((acc: Rule[], item: Rule) => {
          if (!acc.find((rule) => rule.key === item.key)) {
            acc.push(item)
          }
          return acc
        }, [])

        const newFormValues = uniqueRules.reduce((acc: any, item: Rule) => {
          acc[item.key] = item.value ?? ''
          return acc
        }, {})

        setRules(uniqueRules)
        setFormValuesRules(newFormValues)
        setUtms(response.data.Entity.utms)
        setSenderBlocks(response.data.Entity.senderBlocks.slice(0, 7))
        setEmailTesting(response.data.Entity.campaignSetting.EmailsTesting)
        setEmailDailyQuota(
          response.data.Entity.campaignSetting.ExcludeEmailsDailyQuota
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const initializeFormValuesFromSenderBlocks = (
    senderBlocks: SenderBlock[]
  ) => {
    const updatedFormValues: Record<string, FormDataValues> = {}

    senderBlocks.forEach((block, index) => {
      const dayMap = [
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
        'Domingo'
      ]
      const activeDay = dayMap[index]

      updatedFormValues[activeDay] = {
        remitente: block.sender.senderName,
        email: block.sender.senderEmail,
        asunto: block.sender.subject,
        preHeader: block.sender.preHeaderText,
        legales: block.sender.disclaimer,
        listaEmailsTestigo: block.sender.listEmailAlert,
        templateUrl: block.sender.templateSource,
        active: block.sender.active,
        startTime: block.sender.hourRun,
        endTime: block.sender.hourStop,
        plantilla: block.sender.IdAutomationTemplate,
        linkHeader: block?.blocks?.headerLink,
        linkBody: block?.blocks?.bodyLink,
        linkFooter: block?.blocks?.footerLink,
        utmContenidoHeader: block?.blocks?.headerUTMContent,
        utmTermHeader: block?.blocks?.headerUTMTerm,
        utmContenidoBody: block?.blocks?.bodyUTMContent,
        utmTermBody: block?.blocks?.bodyUTMTerm,
        utmContenidoFooter: block?.blocks?.footerUTMContent,
        utmTermFooter: block?.blocks?.footerUTMTerm,
        urlBody: block?.blocks?.bodyLink,
        urlFooter: block?.blocks?.footerLink,
        urlHeader: block?.blocks?.headerLink,
        limiteProductos: '',
        template: '',
        diasContemplar: '',
        frecuenciaEnvio: '',
        utmFuente: '',
        utmCampania: '',
        utmMedio: '',
        urlPlantilla: '',
        urlAbsolutaImagenHeader: '',
        endpointHeader: '',
        contenidoEndpoint2Header: '',
        endpointBody: '',
        contenidoEndpoint2Body: '',
        endpointFooter: '',
        contenidoEndpoint2Footer: '',
        emailPrueba: '',
        emailCuota: ''
      }
    })

    setFormValues(updatedFormValues)
  }

  useEffect(() => {
    getTemplates()
    getGalleryImages(date.getFullYear(), date.getMonth() + 1)
    GetAllCampaignById()
  }, [])

  useEffect(() => {
    initializeFormValuesFromSenderBlocks(senderBlocks)
  }, [senderBlocks])

  useEffect(() => {
    handleInputChange('active', methods.watch(`${activeDay}active`))
    handleInputChange('urlHeader', methods.watch(`${activeDay}header-url`))
    handleInputChange(
      'utmTermHeader',
      methods.watch(`${activeDay}header-utm-term`)
    )
    handleInputChange(
      'utmContenidoHeader',
      methods.watch(`${activeDay}header-utm-content`)
    )
    handleInputChange('urlBody', methods.watch(`${activeDay}body-url`))
    handleInputChange('utmTermBody', methods.watch(`${activeDay}body-utm-term`))
    handleInputChange(
      'utmContenidoBody',
      methods.watch(`${activeDay}body-utm-content`)
    )
    handleInputChange('urlFooter', methods.watch(`${activeDay}footer-url`))
    handleInputChange(
      'utmTermFooter',
      methods.watch(`${activeDay}footer-utm-term`)
    )
    handleInputChange(
      'utmContenidoFooter',
      methods.watch(`${activeDay}footer-utm-content`)
    )
  }, [
    methods.watch(`${activeDay}active`),
    methods.watch(`${activeDay}header-url`),
    methods.watch(`${activeDay}header-utm-term`),
    methods.watch(`${activeDay}header-utm-content`),
    methods.watch(`${activeDay}body-url`),
    methods.watch(`${activeDay}body-utm-term`),
    methods.watch(`${activeDay}body-utm-content`),
    methods.watch(`${activeDay}footer-url`),
    methods.watch(`${activeDay}footer-utm-term`),
    methods.watch(`${activeDay}footer-utm-content`)
  ])
  useEffect(() => {
    setActiveDayLoading(true)
    let setSender = true
    if (senderBlocks.length && setSender) {
      setSender = false
      const dayMap = [
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
        'Domingo'
      ]

      dayMap.forEach((day, index) => {
        const { headerImageUrl, bodyImageUrl, footerImageUrl } =
          senderBlocks[index]?.blocks || {}
        const { active } = senderBlocks[index].sender
        console.log(senderBlocks[index]);
        methods.setValue(`${day}.active`, active)

        methods.setValue(
          `${day}.timeStart`,
          senderBlocks[index].sender.hourRun == undefined ? '1200' : formatTimeMethods(senderBlocks[index].sender.hourRun)
        )

        methods.setValue(
          `${day}.timeEnd`,
          senderBlocks[index].sender.hourStop == undefined ? '1200' : formatTimeMethods(senderBlocks[index].sender.hourStop)
        )

        methods.setValue(
          `${day}.header`,
          headerImageUrl == ' ' ? undefined : headerImageUrl
        )
        methods.setValue(
          `${day}.body`,
          bodyImageUrl == ' ' ? undefined : bodyImageUrl
        )
        methods.setValue(
          `${day}.footer`,
          footerImageUrl == ' ' ? undefined : footerImageUrl
        )
      })
    }
    setTimeout(() => {
      setActiveDayLoading(false)
    }, 200)
  }, [senderBlocks.length])

  const onSubmit = (data: any) => console.log('')
  const handleConfirmReplication = (
    updatedValues: Record<string, FormDataValues>
  ) => {
    setFormValues(updatedValues)
    setModalReplicar(false)
  }

  return (
    <div>
      <div>
        {modalReplicar && (
          <AutomationDaysPopUp
            isOpen={modalReplicar}
            onClose={handleCloseModal}
            onConfirm={handleConfirmReplication}
            activeDay={activeDay}
            formValues={formValues}
            formMethods={methods}
          />
        )}
        <div className="regla_frame automation-edit justify-content-end align-items-center mt-3 mr-5">
          <div className="regla-titulo">
            <div className="regla-nombre">
              <span className="regla-nombre-span">
                {intl.formatMessage({
                  defaultMessage: 'Regla',
                  id: 'app.automation.edit.rule'
                })}
                :{' '}
              </span>{' '}
              <span className="regla-nombre-span-2">{nameRule}</span>
            </div>
            <div className="regla-cliente">{selectedClient?.clientName}</div>
            <h1>
              {intl.formatMessage({
                defaultMessage: 'CONFIGURACIÓN',
                id: 'app.automation.edit.setting'
              })}
            </h1>
          </div>
          <div className="regla_frame container">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {/* <div className="mb-4">
                  <h2>Variables</h2>
                  <hr />
                </div> */}
                <div className="cfa-formulario">
                  <section className="cfa-variables">
                    <AutomationRules
                      rules={rules}
                      nameRule={utms}
                      formValues={formValues}
                      labelMap={labelMap}
                      handleInputChange={(key, value) =>
                        setFormValues((prev) => ({
                          ...prev,
                          [key]: value
                        }))
                      }
                    />

                    {nameRule == 'ActivityUser' && (
                      <>
                        <label className="label-automation">
                          {intl.formatMessage({
                            defaultMessage: 'Limite de Productos',
                            id: 'app.automation.edit.limitProducts'
                          })}
                        </label>
                        <select
                          className="select-automation"
                          value={limitProducts}
                          onChange={(e) => setLimitProducts(e.target.value)}
                        >
                          <option>0</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                        </select>
                      </>
                    )}
                  </section>

                  <section className="cfa-utm">
                    <h1>UTM</h1>
                    <hr />

                    <div className="form-grupo">
                      <label>
                        {intl.formatMessage({
                          defaultMessage: 'UTM Fuente',
                          id: 'app.automation.edit.utmFuente'
                        })}
                      </label>
                      <input
                        type="text"
                        value={utms?.utmSource}
                        onChange={(e) =>
                          handleInputChange('diasContemplar', e.target.value)
                        }
                        placeholder="Brain"
                        disabled
                      />
                    </div>

                    <div className="form-grupo">
                      <label>
                        {intl.formatMessage({
                          defaultMessage: 'UTM Campaña',
                          id: 'app.automation.edit.utmCampaign'
                        })}
                      </label>
                      <input
                        type="text"
                        value={utms?.utmCampaign}
                        placeholder="Actividad en la Web y Actividad en el email"
                        disabled
                      />
                    </div>

                    <div className="form-grupo">
                      <label>
                        {intl.formatMessage({
                          defaultMessage: 'UTM Medio',
                          id: 'app.automation.edit.utmMiddle'
                        })}
                      </label>
                      <input
                        type="text"
                        value={utms?.utmMedium}
                        onChange={(e) =>
                          handleInputChange('utmMedio', e.target.value)
                        }
                        placeholder="Email"
                        disabled
                      />
                    </div>
                  </section>

                  <section className="cfa-campana">
                    <h1>
                      {intl.formatMessage({
                        defaultMessage: 'Campaña',
                        id: 'app.automation.edit.campaign'
                      })}
                    </h1>
                    <hr />
                    <div className="dias">
                      <label>
                        {intl.formatMessage({
                          defaultMessage: 'Configuración de tareas',
                          id: 'app.automation.edit.taskConfiguration'
                        })}
                      </label>
                      <div className="dias-semana">
                        {Object.keys(initialFormValues).map((day) => (
                          <button
                            key={day}
                            type="button"
                            onClick={() => handleDayClick(day)}
                            className={`cfa-tab ${
                              activeDay === day ? 'activo' : ''
                            }`}
                          >
                            {day}
                          </button>
                        ))}
                      </div>
                    </div>
                    {!activeDayLoading ? (
                      <>
                        <div className="intervalo-envio">
                          <label className="my-3">
                            {intl.formatMessage({
                              defaultMessage: 'Intervalo de envío diario',
                              id: 'app.automation.edit.dailyShippingInterval'
                            })}
                          </label>
                          <div className="intervalo">
                            <label>
                              {intl.formatMessage({
                                defaultMessage: 'Hora de inicio',
                                id: 'app.automation.edit.beginningHour'
                              })}
                              :
                            </label>
                            <Controller
                              name={`${activeDay}.timeStart`}
                              control={methods.control}
                              render={({ field: { onChange, value } }) => (
                                <MaskedInput
                                  defaultValue={value || 1200}
                                  onChange={onChange}
                                  mask={TIME_MASK}
                                  placeholder="HH:MM"
                                  style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    padding: '2px 10px',
                                    margin: '0px 0',
                                    color: '#37393a',
                                    width: '72px',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    gridArea: '1 / 5 / 2 / 6'
                                  }}
                                />
                              )}
                            />
                            <IoTimeOutline
                              style={{
                                marginLeft: 3,
                                gridArea: '1 / 6 / 2 / 7'
                              }}
                              size={25}
                              color="#00868B"
                            />
                            <span>
                              {intl.formatMessage({
                                defaultMessage: 'Hasta',
                                id: 'app.emailmarketing.create.campaign.step.one.label.dailyinterval.end'
                              })}
                            </span>
                            <Controller
                              name={`${activeDay}.timeEnd`}
                              control={methods.control}
                              render={({ field: { onChange, value } }) => (
                                <MaskedInput
                                  defaultValue={value || 1200}
                                  onChange={onChange}
                                  mask={TIME_MASK}
                                  placeholder="HH:MM"
                                  style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    padding: '2px 10px',
                                    margin: '0px 0',
                                    color: '#37393a',
                                    width: '72px',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    gridArea: '1 / 5 / 2 / 6'
                                  }}
                                />
                              )}
                            />
                            <IoTimeOutline
                              style={{
                                marginLeft: 3,
                                gridArea: '1 / 6 / 2 / 7'
                              }}
                              size={25}
                              color="#00868B"
                            />
                          </div>
                        </div>
                        <div className="form-grupo">
                          <label style={{ flexBasis: '10%' }}>
                            {intl.formatMessage({
                              defaultMessage: 'Día Activo',
                              id: 'app.automation.edit.activeDay'
                            })}
                          </label>
                          <Controller
                            name={`${activeDay}.active`}
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                              <Form.Check
                                type="switch"
                                id="active-switch"
                                className="custom-switch"
                                checked={value}
                                onChange={onChange}
                              />
                            )}
                          />
                          {/* <Form.Check
                            type="switch"
                            id="enable-switch"
                            className="custom-switch"
                            checked={formValues[activeDay].active}
                            onChange={(e) => {
                              const isChecked = e.target.checked
                              handleInputChange('active', isChecked.toString())
                              methods.setValue('active', isChecked)
                            }}
                          /> */}
                        </div>
                        <hr />
                        <h3>
                          {intl.formatMessage({
                            defaultMessage: 'Plantilla',
                            id: 'app.automation.edit.template'
                          })}
                        </h3>

                        <div className="form-grupo">
                          <label>
                            {intl.formatMessage({
                              defaultMessage: 'Plantilla',
                              id: 'app.automation.edit.template'
                            })}
                            :
                          </label>
                          <select
                            className="select-automation"
                            value={formValues[activeDay]?.plantilla}
                            onChange={(e) => handleMixEmailSelect(e)}
                          >
                            {mixTemplates?.map((template: any) => (
                              <option key={template.Id} value={template.Id}>
                                {template.Description}
                              </option>
                            ))}
                          </select>
                        </div>
                        <hr />

                        <h3>Email</h3>

                        <div className="form-grupo">
                          <label>
                            {intl.formatMessage({
                              defaultMessage: 'Remitente',
                              id: 'app.automation.edit.sender'
                            })}
                            :
                          </label>
                          <input
                            type="text"
                            value={formValues[activeDay]?.remitente}
                            onChange={(e) =>
                              handleInputChange('remitente', e.target.value)
                            }
                          />
                        </div>
                        <div className="form-grupo">
                          <label>Email:</label>
                          <input
                            type="text"
                            value={formValues[activeDay]?.email}
                            onChange={(e) =>
                              handleInputChange('email', e.target.value)
                            }
                          />
                        </div>
                        <div className="form-grupo">
                          <label>
                            {intl.formatMessage({
                              defaultMessage: 'Asunto',
                              id: 'app.automation.edit.affair'
                            })}
                            :
                          </label>
                          <input
                            type="text"
                            value={formValues[activeDay]?.asunto}
                            onChange={(e) =>
                              handleInputChange('asunto', e.target.value)
                            }
                          />
                        </div>
                        <div className="form-grupo">
                          <label>
                            {intl.formatMessage({
                              defaultMessage: 'Texto Pre-Header',
                              id: 'app.automation.edit.preHeaderText'
                            })}
                            ::
                          </label>
                          <input
                            type="text"
                            value={formValues[activeDay]?.preHeader}
                            onChange={(e) =>
                              handleInputChange('preHeader', e.target.value)
                            }
                          />
                        </div>
                        <div className="form-grupo">
                          <label className="label-automation">
                            {intl.formatMessage({
                              defaultMessage: 'Lista de Emails Testigo',
                              id: 'app.automation.edit.witnessEmailList'
                            })}
                            :
                          </label>
                          <textarea
                            className='automation-textarea'
                            value={formValues[activeDay]?.listaEmailsTestigo}
                            onChange={(e) =>
                              handleInputChange(
                                'listaEmailsTestigo',
                                e.target.value
                              )
                            }
                          ></textarea>
                        </div>
                        <div className="form-grupo">
                          <label>
                            {intl.formatMessage({
                              defaultMessage: 'Legales',
                              id: 'app.automation.edit.legals'
                            })}
                            :
                          </label>
                          <textarea
                            className='automation-textarea'
                            value={formValues[activeDay]?.legales}
                            onChange={(e) =>
                              handleInputChange('legales', e.target.value)
                            }
                          ></textarea>
                        </div>
                        <section className="cfa-configuracion">
                          <h1>Header</h1>
                          <div className="form-grupo" style={{ gap: '19px' }}>
                            <ThumbContainer style={{ width: '100%' }}>
                              <div
                                style={{
                                  position: 'absolute',
                                  right: -44,
                                  top: '1%',
                                  cursor: 'pointer',
                                  backgroundColor: '#E8E8ED'
                                }}
                                onClick={() => setOpenGallery('header')}
                              >
                                <MdApps size={35} color="6529A1" />
                              </div>
                              {openGallery === 'header' && !activeDayLoading ? (
                                <Gallery
                                  loading={galleryLoading}
                                  handleGalleryFilter={handleGalleryFilter}
                                  open={!!openGallery}
                                  gallery={gallery}
                                  setOpenGallery={setOpenGallery}
                                  title={'Header'}
                                  name={`${activeDay}.header`}
                                  filter={galleryFilter}
                                  setFilter={setGalleryFilter}
                                />
                              ) : null}
                              <UploadImage
                                isUpdate={false}
                                src={methods.watch(`${activeDay}.header`)}
                                name={`${activeDay}.header`}
                                height={'250px'}
                                width={'100%'}
                              />
                              <ThumbContentEdit>
                                <div
                                  className="input-group-bdw"
                                  style={{
                                    display: 'flex',
                                    columnGap: '56px',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Label htmlFor="">Url:</Label>
                                  <ThumbContentInput
                                    type="text"
                                    value={formValues[activeDay]?.urlHeader}
                                    {...methods.register(
                                      `${activeDay}header-url`
                                    )}
                                    style={{ width: '100%' }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    columnGap: '12px',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <div
                                    className="input-group-bdw"
                                    style={{
                                      display: 'flex',
                                      columnGap: '12px',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Label htmlFor="">
                                      {intl.formatMessage({
                                        defaultMessage: 'Termino',
                                        id: 'app.automation.edit.term'
                                      })}
                                      :
                                    </Label>
                                    <ThumbContentInput
                                      type="text"
                                      value={
                                        formValues[activeDay]?.utmTermHeader
                                      }
                                      {...methods.register(
                                        `${activeDay}header-utm-term`
                                      )}
                                      style={{ width: '100%' }}
                                    />
                                  </div>
                                  <div
                                    className="input-group-bdw"
                                    style={{
                                      display: 'flex',
                                      columnGap: '12px'
                                    }}
                                  >
                                    <Label htmlFor="">
                                      {intl.formatMessage({
                                        defaultMessage: 'Contenido',
                                        id: 'app.automation.edit.content'
                                      })}
                                      :
                                    </Label>
                                    <ThumbContentInput
                                      type="text"
                                      value={
                                        formValues[activeDay]?.utmContenidoHeader
                                      }
                                      {...methods.register(
                                        `${activeDay}header-utm-content`
                                      )}
                                      style={{ width: '100%' }}
                                    />
                                  </div>
                                </div>
                              </ThumbContentEdit>
                            </ThumbContainer>
                          </div>
                          <div
                            className="form-grupo"
                            style={{ paddingLeft: '13rem' }}
                          >
                            <button
                              type="button"
                              onClick={() =>
                                saveBlockImage(
                                  methods.getValues(activeDay).header
                                )
                              }
                              className="configurar-regla"
                            >
                              {intl.formatMessage({
                                defaultMessage: 'Subir Imagen',
                                id: 'app.automation.edit.uploadImage'
                              })}
                            </button>
                          </div>
                          <hr />

                          <h1>Body</h1>

                          <div className="form-grupo" style={{ gap: '19px' }}>
                            <ThumbContainer style={{ width: '100%' }}>
                              <div
                                style={{
                                  position: 'absolute',
                                  right: -44,
                                  top: '1%',
                                  cursor: 'pointer',
                                  backgroundColor: '#E8E8ED'
                                }}
                                onClick={() => setOpenGallery('body')}
                              >
                                <MdApps size={35} color="6529A1" />
                              </div>
                              {openGallery === 'body' && !activeDayLoading ? (
                                <Gallery
                                  loading={galleryLoading}
                                  handleGalleryFilter={handleGalleryFilter}
                                  open={!!openGallery}
                                  gallery={gallery}
                                  setOpenGallery={setOpenGallery}
                                  title={'Body'}
                                  name={`${activeDay}.body`}
                                  filter={galleryFilter}
                                  setFilter={setGalleryFilter}
                                />
                              ) : null}
                              <UploadImage
                                isUpdate={false}
                                src={methods.watch(`${activeDay}.body`)}
                                name={`${activeDay}.body`}
                                height={'250px'}
                                width={'100%'}
                              />

                              <ThumbContentEdit>
                                <div
                                  className="input-group-bdw"
                                  style={{
                                    display: 'flex',
                                    columnGap: '56px',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Label htmlFor="">Url:</Label>
                                  <ThumbContentInput
                                    type="text"
                                    value={formValues[activeDay]?.urlBody}
                                    {...methods.register(
                                      `${activeDay}body-url`
                                    )}
                                    style={{ width: '100%' }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    columnGap: '12px',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <div
                                    className="input-group-bdw"
                                    style={{
                                      display: 'flex',
                                      columnGap: '12px',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Label htmlFor="">
                                      {intl.formatMessage({
                                        defaultMessage: 'Termino',
                                        id: 'app.automation.edit.term'
                                      })}
                                      :
                                    </Label>
                                    <ThumbContentInput
                                      type="text"
                                      value={formValues[activeDay]?.utmTermBody}
                                      {...methods.register(
                                        `${activeDay}body-utm-term`
                                      )}
                                      style={{ width: '100%' }}
                                    />
                                  </div>
                                  <div
                                    className="input-group-bdw"
                                    style={{
                                      display: 'flex',
                                      columnGap: '12px'
                                    }}
                                  >
                                    <Label htmlFor="">
                                      {intl.formatMessage({
                                        defaultMessage: 'Contenido',
                                        id: 'app.automation.edit.content'
                                      })}
                                      :
                                    </Label>
                                    <ThumbContentInput
                                      type="text"
                                      value={
                                        formValues[activeDay]?.utmContenidoBody
                                      }
                                      {...methods.register(
                                        `${activeDay}body-utm-content`
                                      )}
                                      style={{ width: '100%' }}
                                    />
                                  </div>
                                </div>
                              </ThumbContentEdit>
                            </ThumbContainer>
                          </div>
                          <div
                            className="form-grupo"
                            style={{ paddingLeft: '13rem' }}
                          >
                            <button
                              type="button"
                              onClick={() =>
                                saveBlockImage(
                                  methods.getValues(activeDay).body
                                )
                              }
                              className="configurar-regla"
                            >
                              {intl.formatMessage({
                                defaultMessage: 'Subir Imagen',
                                id: 'app.automation.edit.uploadImage'
                              })}
                            </button>
                          </div>
                          <hr />

                          <h1>Footer</h1>
                          <div className="form-grupo" style={{ gap: '19px' }}>
                            <ThumbContainer style={{ width: '100%' }}>
                              <div
                                style={{
                                  position: 'absolute',
                                  right: -44,
                                  top: '1%',
                                  cursor: 'pointer',
                                  backgroundColor: '#E8E8ED'
                                }}
                                onClick={() => setOpenGallery('footer')}
                              >
                                <MdApps size={35} color="6529A1" />
                              </div>
                              {openGallery === 'footer' && !activeDayLoading ? (
                                <Gallery
                                  loading={galleryLoading}
                                  handleGalleryFilter={handleGalleryFilter}
                                  open={!!openGallery}
                                  gallery={gallery}
                                  setOpenGallery={setOpenGallery}
                                  title={'Footer'}
                                  name={`${activeDay}.footer`}
                                  filter={galleryFilter}
                                  setFilter={setGalleryFilter}
                                />
                              ) : null}
                              <UploadImage
                                isUpdate={false}
                                src={methods.watch(`${activeDay}.footer`)}
                                name={`${activeDay}.footer`}
                                height={'250px'}
                                width={'100%'}
                              />
                              <ThumbContentEdit>
                                <div
                                  className="input-group-bdw"
                                  style={{
                                    display: 'flex',
                                    columnGap: '56px',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Label htmlFor="">Url:</Label>
                                  <ThumbContentInput
                                    type="text"
                                    value={formValues[activeDay]?.urlFooter}
                                    {...methods.register(
                                      `${activeDay}footer-url`
                                    )}
                                    style={{ width: '100%' }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    columnGap: '12px',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <div
                                    className="input-group-bdw"
                                    style={{
                                      display: 'flex',
                                      columnGap: '12px',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Label htmlFor="">
                                      {intl.formatMessage({
                                        defaultMessage: 'Termino',
                                        id: 'app.automation.edit.term'
                                      })}
                                      :
                                    </Label>
                                    <ThumbContentInput
                                      type="text"
                                      value={
                                        formValues[activeDay]?.utmTermFooter
                                      }
                                      {...methods.register(
                                        `${activeDay}footer-utm-term`
                                      )}
                                      style={{ width: '100%' }}
                                    />
                                  </div>
                                  <div
                                    className="input-group-bdw"
                                    style={{
                                      display: 'flex',
                                      columnGap: '12px'
                                    }}
                                  >
                                    <Label htmlFor="">
                                      {intl.formatMessage({
                                        defaultMessage: 'Contenido',
                                        id: 'app.automation.edit.content'
                                      })}
                                      :
                                    </Label>
                                    <ThumbContentInput
                                      type="text"
                                      value={
                                        formValues[activeDay]?.utmContenidoFooter
                                      }
                                      {...methods.register(
                                        `${activeDay}footer-utm-content`
                                      )}
                                      style={{ width: '100%' }}
                                    />
                                  </div>
                                </div>
                              </ThumbContentEdit>
                            </ThumbContainer>
                          </div>
                          <div
                            className="form-grupo"
                            style={{ paddingLeft: '13rem' }}
                          >
                            <button
                              type="button"
                              onClick={() =>
                                saveBlockImage(
                                  methods.getValues(activeDay).footer
                                )
                              }
                              className="configurar-regla"
                            >
                              {intl.formatMessage({
                                defaultMessage: 'Subir Imagen',
                                id: 'app.automation.edit.uploadImage'
                              })}
                            </button>
                          </div>
                          <div
                            className="form-grupo"
                            style={{ justifyContent: 'center' }}
                          >
                            <button
                              type="button"
                              className="replicar-datos"
                              onClick={() => setModalReplicar(true)}
                            >
                              {intl.formatMessage({
                                defaultMessage: 'Replicar datos en los días',
                                id: 'app.automation.edit.replyEveryDay'
                              })}
                            </button>
                          </div>
                        </section>
                      </>
                    ) : (
                      <div className="automation-edit-loading">
                        <Loading textLoading="" />
                      </div>
                    )}
                    {/* <div className="email-form-group">
                      <button type="button" className="email-button-enviar">
                        enviar correo de prueba
                      </button>
                      <button type="button" className="email-button-preview">
                        vista previa de la prueba
                      </button>
                    </div> */}
                  </section>
                  <hr />
                  <section className="cfa-circuito">
                    <h1>
                      {intl.formatMessage({
                        defaultMessage: 'Circuito de prueba',
                        id: 'app.automation.edit.circuitTesting'
                      })}
                    </h1>
                    <hr />

                    <label style={{marginRight: '5px'}}>
                      {intl.formatMessage({
                        defaultMessage: 'Emails de prueba',
                        id: 'app.automation.edit.emailsTesting'
                      })}
                       {' '}
                    </label>
                    <label className="label-subtitulo">
                      {intl.formatMessage({
                        defaultMessage:
                          'Cargar separados por comas o * tomar todos los correos de ese dominio',
                        id: 'app.automation.edit.emailsExample'
                      })}
                      (info@braindw.com,other@braindw.com,*@braindw.com)
                    </label>
                    <input
                      type="email"
                      value={emailTesting}
                      onChange={handleEmailChange}
                      placeholder="Ingresa un email válido"
                    />
                    {errorMessage && (
                      <p style={{ color: 'red' }}>{errorMessage}</p>
                    )}
                    {/* <label>Emails liberados de la cuota diaria</label>
                    <label className="label-subtitulo">
                      Cargar separados por comas
                      (info@braindw.com,other@braindw.com)
                    </label>
                    <input
                      type="email"
                      value={emailDailyQuota}
                      onChange={(e) => setEmailDailyQuota(e.target.value)}
                      placeholder="Ingresa un email válido"
                    /> */}
                  </section>

                  <div className="guardar-form-group">
                    <button
                      type="submit"
                      className="guardar-button"
                      disabled={errorMessage !== '' ? true : false}
                      onClick={saveStaticCampaign}
                    >
                      {intl.formatMessage({
                        defaultMessage: 'GUARDAR',
                        id: 'app.vitrinas-editar.guardar'
                      })}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        navigate(`/dashboard/campaign/automation`)
                      }}
                      className="volver-button"
                    >
                      {intl.formatMessage({
                        defaultMessage: 'Volver',
                        id: 'app.vitrinas-editar.volver'
                      })}
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AutomationEdit
