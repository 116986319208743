import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import './AutomationDaysPopUp.css'
import { FormDataValues } from './AutomationEdit'

interface AutomationDaysPopUpProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: (updatedValues: Record<string, FormDataValues>) => void
  activeDay: string
  formValues: Record<string, FormDataValues>
  formMethods: any
}

const daysOfWeek = [
  'Lunes',
  'Martes',
  'Miercoles',
  'Jueves',
  'Viernes',
  'Sabado',
  'Domingo'
]

const AutomationDaysPopUp: React.FC<AutomationDaysPopUpProps> = ({
  isOpen,
  onClose,
  onConfirm,
  activeDay,
  formValues,
  formMethods
}) => {
  const [selectedDays, setSelectedDays] = useState<string[]>([])
  const toggleDay = (day: string) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    )
  }

  const handleConfirm = () => {
    const updatedValues = { ...formValues }
    const methodsDay = formMethods.getValues(activeDay)
    selectedDays.forEach((day) => {
      updatedValues[day] = { ...formValues[activeDay] }
      formMethods.setValue(`${day}.active`, methodsDay.active)
      formMethods.setValue(`${day}.timeStart`, methodsDay.timeStart)
      formMethods.setValue(`${day}.timeEnd`, methodsDay.timeEnd)
      
    })

    onConfirm(updatedValues)
    onClose()
  }
  
  useEffect(() => {
    setSelectedDays([activeDay])
  }, [])

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Seleccione los días para replicar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {daysOfWeek.map((day) => (
          <div
            key={day}
            className="d-flex justify-content-between align-items-center mb-2"
          >
            <span>{day}</span>
            <Form.Check
              type="checkbox"
              checked={selectedDays.includes(day)}
              disabled={day === activeDay}
              onChange={() => toggleDay(day)}
              style={{
                accentColor: selectedDays.includes(day) ? '#5E2882' : '#ccc'
              }}
            />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleConfirm}
          style={{ backgroundColor: '#5E2882', borderColor: '#5E2882' }}
        >
          Reproducir exactamente
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AutomationDaysPopUp
