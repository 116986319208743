/* eslint-disable */

import React, { useState, useMemo, useCallback, useEffect } from 'react'
import './Automation.css'
import { BsPencilSquare } from 'react-icons/bs'
import Form from 'react-bootstrap/Form'
import { useDrag } from '@use-gesture/react'
import { useSprings, animated, config } from '@react-spring/web'
import clamp from 'lodash.clamp'
import swap from 'lodash-move'
import styled from 'styled-components'
import { RxDragHandleDots2 } from 'react-icons/rx'
import { useNavigate } from 'react-router'
import { useAtom } from 'jotai'
import { idRuleAtom, nameRuleAtom } from '../../atoms/automation'
import Service from '../../services'
import { useClientContext } from '../../contexts/ClientContext'
import { RootState } from '../../state/store'
import { useSelector } from 'react-redux'
import { GetCDPButton } from './DashboardCampain'
import { ShowAlert } from '../Globals'
import { useIntl } from 'react-intl'
import { TooltipPanel } from '../Globals/TooltipPanel'

const HeadBlock = styled.div`
  margin: auto;
  font-weight: 500;
`
const BodyBlock = styled.div`
  margin: auto;
  font-weight: 300;
  color: #444;
  font-size: 12px;
`
const BodyBlockStatus = styled.div`
  margin: auto;
  font-weight: 300;
  font-size: 12px;
`
interface CDPI {
  id: number
  name: string
}
interface BocksI {
  TooltipName: any
  IdRule: number
  Name: string
  EnabledStatus: boolean
  Priority: number
  Group: number
  EmailsSentToday: number
  EmailsSentYesterday: number
}

const fn =
  (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
  (index: number) =>
    active && index === originalIndex
      ? {
          y: curIndex * 90 + y,
          scale: 1.05,
          zIndex: 1,
          shadow: 15,
          immediate: (key: string) => key === 'zIndex',
          config: (key: string) => (key === 'y' ? config.stiff : config.default)
        }
      : {
          y: order.indexOf(index) * 90,
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: false
        }

const Automation = () => {
  const [loading] = useState(false)
  const { selectedScript, selectedClient } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const intl = useIntl()
  const [activeRowIndex, setActiveRowIndex] = useState(false)
  const [nameRule, setNameRule] = useAtom(nameRuleAtom)
  const [idRule, setIdRule] = useAtom(idRuleAtom)
  const navigate = useNavigate()
  const [blocks, setBlocks] = useState<BocksI[]>([
    {
      IdRule: 1,
      Name: 'Carro Abandonado',
      EnabledStatus: false,
      Priority: 1,
      Group: 1,
      EmailsSentToday: 1,
      EmailsSentYesterday: 1,
      TooltipName: undefined
    },
    {
      IdRule: 2,
      Name: 'Últimos Navegados',
      EnabledStatus: false,
      Priority: 2,
      Group: 1,
      EmailsSentToday: 1,
      EmailsSentYesterday: 1,
      TooltipName: undefined
    },
    {
      IdRule: 3,
      Name: 'Última Categoría Navegada',
      EnabledStatus: false,
      Priority: 3,
      Group: 1,
      EmailsSentToday: 1,
      EmailsSentYesterday: 1,
      TooltipName: undefined
    },
    {
      IdRule: 4,
      Name: 'Actividad del Usuario',
      EnabledStatus: false,
      Priority: 4,
      Group: 1,
      EmailsSentToday: 1,
      EmailsSentYesterday: 1,
      TooltipName: undefined
    },
    {
      IdRule: 5,
      Name: 'Actividad en el Mail',
      EnabledStatus: false,
      Priority: 5,
      Group: 1,
      EmailsSentToday: 1,
      EmailsSentYesterday: 1,
      TooltipName: undefined
    }
  ])
  const order = useMemo(
    () => ({ current: blocks.map((_, index) => index) }),
    [blocks.length]
  )

  const nameTranslations: Record<string, string> = {
    ActivityUser: 'Actividad del Usuario',
    BaseUser: 'Actividad en el Mail',
    CartUnClosedDay: 'Carro Abandonado',
    LastCategory: 'Última Categoría Navegada',
    LastNavigated: 'Últimos Navegados'
  }

  const GetAllCampaigns = () => {
    Service.get<any>(
      `/Automation/GetAllCampaigns?idHashScript=${selectedScript?.idHashScript}&staticRules=true&enabledStatus=&filter=`,
      {
        headers: {
          'Content-Type': 'application/json',
          token: user.data.token
        }
      }
    )
      .then((response) => {
        setBlocks(
          response.data.map((item: any) => ({
            IdRule: item.IdRule,
            Name: nameTranslations[item.Name] || item.Name,
            EnabledStatus: item.EnabledStatus,
            Priority: item.Priority,
            Group: item.Group,
            EmailsSentToday: item.EmailsSentToday,
            EmailsSentYesterday: item.EmailsSentYesterday,
            TooltipName: item.Name
          }))
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const statusCampaignHandler = useCallback(
    async (id, e) => {
      try {
        if (!selectedScript || !id) {
          return
        }

        const { isConfirmed } = await ShowAlert({
          title: 'Cambiar estatus de Campaña',
          color: '#1c684c',
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.enviar',
            defaultMessage: 'Encender'
          }),
          showCancelButton: true,
          cancelButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          width: 600
        })

        if (!isConfirmed) return null

        const { data } = await Service.post<any, any>(
          `Automation/ConfirmCampaign?idHashScript=${
            selectedScript?.idHashScript
          }&idRule=${id}&isActive=${!e.target.checked}`,
          {},
          {
            headers: {
              token: user.data.token,
              'Content-Type': 'application/json'
            }
          }
        )
        if (data?.Status === 'Ok') {
          const updatedTableData = blocks.map((el: any) => {
            if (el.IdRule == id) {
              el.EnabledStatus = !el.EnabledStatus
            }
            return el
          })
          setBlocks(updatedTableData)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [selectedScript, blocks, user]
  )

  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    if (activeRowIndex) {
      const curIndex = order.current.indexOf(originalIndex)
      const curRow = clamp(
        Math.round((curIndex * 100 + y) / 100),
        0,
        blocks.length - 1
      )
      const newOrder = swap(order.current, curIndex, curRow)
      api.start(fn(newOrder, active, originalIndex, curIndex, y))
      if (!active) {
        const lastOrder = order.current
        order.current = newOrder
        handleImgOrder(newOrder, lastOrder)
      }
    }
  })

  const [springs, api] = useSprings(blocks.length, fn(order.current))

  const handleImgOrder = useCallback(
    (newOrder: number[], lastOrder: number[]) => {
      if (lastOrder.toString() === newOrder.toString()) {
        return
      }
      setBlocks([...blocks])
    },
    [blocks]
  )

  useEffect(() => {
    GetAllCampaigns()
  }, [])

  return (
    <div className="justify-content-evenly align-items-center mt-3 mx-5">
      <div
        className="justify-content-center mt-3  row"
        style={{ padding: '30px' }}
      >
        <div
          className="justify-content-center mt-3 row"
          style={{ padding: '30px' }}
        >
          <div className="col-md-12">
            <div
              style={{
                backgroundColor: '#fff',
                padding: '.5rem 2rem',
                marginTop: 20,
                height: (blocks.length + 1) * 100,
                position: 'relative'
              }}
            >
              <div
                style={{
                  position: 'relative',
                  height: '80px',
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(7,1fr)'
                }}
              >
                <HeadBlock>
                  {intl.formatMessage({
                    defaultMessage: 'ORDENAR',
                    id: 'app.automation.table.order'
                  })}
                </HeadBlock>
                <HeadBlock>
                  {intl.formatMessage({
                    defaultMessage: 'REGLA',
                    id: 'app.automation.table.rule'
                  })}
                </HeadBlock>
                <HeadBlock>
                  {intl.formatMessage({
                    defaultMessage: 'ESTADO',
                    id: 'app.automation.table.state'
                  })}
                </HeadBlock>
                <HeadBlock>
                  {intl.formatMessage({
                    defaultMessage: 'PRIORIDAD',
                    id: 'app.automation.table.priority'
                  })}
                </HeadBlock>
                <HeadBlock>
                  {intl.formatMessage({
                    defaultMessage: 'GRUPO',
                    id: 'app.automation.table.group'
                  })}
                </HeadBlock>
                <HeadBlock>
                  {intl.formatMessage({
                    defaultMessage: 'AUDIENCIAS',
                    id: 'app.automation.table.audience'
                  })}
                </HeadBlock>
                <HeadBlock>
                  {intl.formatMessage({
                    defaultMessage: 'ACCIONES',
                    id: 'app.automation.table.action'
                  })}
                </HeadBlock>
              </div>
              {springs.map(({ zIndex, shadow, y, scale }, i) => (
                <animated.div
                  {...bind(i)}
                  key={i}
                  style={{
                    zIndex,
                    boxShadow: shadow.to(
                      (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                    ),
                    y,
                    scale,
                    position: 'absolute',
                    height: '80px',
                    width: '95%',
                    backgroundColor: '#ffffff',
                    touchAction: 'none'
                  }}
                >
                  <div
                    style={{
                      height: 80,
                      display: 'grid',
                      gridTemplateColumns: 'repeat(7,1fr)'
                    }}
                  >
                    <BodyBlock>
                      <RxDragHandleDots2
                        cursor={'grab'}
                        onMouseDown={() => setActiveRowIndex(true)}
                        onMouseUp={() => setActiveRowIndex(false)}
                      />
                    </BodyBlock>
                    <BodyBlock
                      style={{
                        fontSize: 12,
                        textAlign: 'left',
                        display: 'flex'
                      }}
                    >
                      <span style={{ width: 'max-content' }}>
                        {blocks[i].Name}
                      </span>
                      <TooltipPanel
                        ObjectTooltip={
                          user.data.tooltips.find(
                            (t: any) =>
                              t.idTooltip ===
                              `app.emailmarketing.automation.title.${blocks[i].TooltipName}`
                          ) ??
                          `app.emailmarketing.automation.title.${blocks[i].TooltipName}`
                        }
                      />
                    </BodyBlock>
                    <BodyBlockStatus
                      style={{ fontSize: 12, textAlign: 'left' }}
                    >
                      <div className="d-flex align-items-end gap-3">
                        <label className="cfc_switch">
                          <input
                            type="checkbox"
                            name={'status-action'}
                            checked={blocks[i].EnabledStatus}
                            onChange={async (e) =>
                              await statusCampaignHandler(blocks[i].IdRule, e)
                            }
                          />
                          <span
                            className="cfc_slider"
                            style={{ lineHeight: 0, wordBreak: 'initial' }}
                          ></span>
                        </label>
                      </div>
                    </BodyBlockStatus>
                    <BodyBlock style={{ position: 'relative' }}>
                      {blocks[i].Priority}
                    </BodyBlock>
                    <BodyBlock>{blocks[i].Group}</BodyBlock>
                    <BodyBlock style={{ fontSize: 12, textAlign: 'left' }}>
                      <GetCDPButton id={blocks[i].IdRule} />
                    </BodyBlock>
                    <BodyBlock style={{ display: 'flex' }}>
                      <BsPencilSquare
                        className="smart-icons"
                        size={20}
                        cursor={'pointer'}
                        data-tip={'Update'}
                        style={{ marginRight: 15 }}
                        color="#2eac7e"
                        onClick={() => {
                          navigate(
                            `/dashboard/campaign/automation-rule/${blocks[i].IdRule}`
                          ),
                            setNameRule(blocks[i].Name)
                          setIdRule(blocks[i].IdRule)
                        }}
                      />
                    </BodyBlock>
                  </div>
                </animated.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Automation
