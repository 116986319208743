/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useIntl } from 'react-intl'

import {
  StepsContainer,
  Step,
  StepText,
  StepButtonContainer,
  StepButton
} from './css/CampaignCreateContainerComponents'
import EditTemplateStep from './EditTemplateStep'
import CampaignSummary from './CampaignSummary '
import ConfigCampaign from './ConfigCampain'
import { useClientContext } from '../../contexts/ClientContext'
import { RootState } from '../../state/store'
import { useNavigate, useParams } from 'react-router-dom'
import Service from '../../services'
import { Loading } from '../Globals/Loading'
import { ShowAlert } from '../Globals'

interface CampaignSteps {
  position: number
  key: string
  name: string
  back: boolean
  next: boolean
}

const CampaignStepOneCreateFormSchema = yup.object().shape({
  campaignSetting: yup.object().shape({
    Name: yup.string().required().max(50),
    Description: yup.string().required().max(200),
    // ForceSend: yup.number().required().min(0).max(1),
    GroupSend: yup.number().required().min(0),
    Segment: yup.object().shape({
      label: yup.string(),
      value: yup.number()
    }),
    daysActive: yup.object().shape({
      Monday: yup.boolean(),
      Tuesday: yup.boolean(),
      Wednesday: yup.boolean(),
      Thursday: yup.boolean(),
      Friday: yup.boolean(),
      Saturday: yup.boolean(),
      Sunday: yup.boolean()
    }),
    enableExpire: yup.number().required().min(0).max(1)
  }),

  sender: yup.object().shape({
    senderName: yup.string().required(),
    senderEmail: yup.string().email().required(),
    subject: yup.string().required(),
    preHeaderText: yup.string().required().max(150),
    IdAutomationTemplate: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required()
    }),
    hourRun: yup.string(),
    hourStop: yup.string(),
    startDate: yup.string(),
    startDateHour: yup.string(),
    endDate: yup.string(),
    endDateHour: yup.string(),
    cc: yup.string()
  }),
  utms: yup.object().shape({
    utmCampaign: yup.string(),
    utmSource: yup.string(),
    utmMedium: yup.string(),
    utmContent: yup.string(),
    utmTerms: yup.string()
  })
})

const CampaignStepTwoCreateFormSchema = yup.object().shape({
  disclaimer: yup.string(),
  //  header: yup.
  'header-url': yup.string(),
  'header-utm-term': yup.string(),
  'header-utm-content': yup.string(),
  'body-url': yup.string(),
  'body-utm-term': yup.string(),
  'body-utm-content': yup.string(),
  'footer-url': yup.string(),
  'footer-utm-term': yup.string(),
  'footer-utm-content': yup.string(),
  endPointOne: yup.object().shape({
    label: yup.string(),
    value: yup.string()
  }),
  endPointTwo: yup.object().shape({
    label: yup.string(),
    value: yup.string()
  })
})

const CampaignCreateContainer = () => {
  const { selectedScript } = useClientContext()
  const intl = useIntl()
  const userData = useSelector<RootState, any>(
    (state: RootState) => state.userAuth
  )
  const user = userData.data

  const [endPoints, setEndPoints] = useState<any[]>([])
  const { id } = useParams()
  const [loadingCampaign, setLoadingCampaign] = useState<boolean>(
    id && id !== 'new' ? true : false
  )
  const [campaign, setCampaign] = useState<any>(null)
  const [templates, setTemplates] = useState<any>(null)
  const [isDynamicTemplate, setIsDynamicTemplate] = useState<boolean>(true)
  const [isActivityCampaign, setIsActivityCampaign] = useState<boolean>(false)
  const methodsStepOne = useForm({
    resolver: yupResolver(CampaignStepOneCreateFormSchema)
  })

  const methodsStepTwo = useForm({
    resolver: yupResolver(CampaignStepTwoCreateFormSchema)
  })
  const activityCampaigns = [
    'ActivityUser',
    'ActivityUser1',
    'ActivityUser2',
    'ActivityUser3',
    'ActivityUser4',
    'ActivityUser5',
    'ActivityUser6',
    'BaseUser',
    'BaseUser1',
    'BaseUser2',
    'BaseUser3',
    'BaseUser4',
    'BaseUser5',
    'BaseUser6',
    'States',
    'States1',
    'States2',
    'States3',
    'States4',
    'States5',
    'States6',
    'LastCategory',
    'LastCategory1',
    'LastCategory2',
    'LastCategory3',
    'LastCategory4',
    'LastCategory5',
    'LastCategory6'
  ]
  const methodsStepThree = useForm()

  const navigate = useNavigate()
  const campaignCreateSteps: CampaignSteps[] = [
    {
      position: 1,
      key: 'setup',
      name: intl.formatMessage({
        defaultMessage: 'Configuración',
        id: 'app.emailmarketing.campaigns.dynamic.createcontainer.stepone'
      }),
      back: false,
      next: true
    },
    {
      position: 2,
      key: 'editor',
      name: intl.formatMessage({
        defaultMessage: 'Editor',
        id: 'app.emailmarketing.campaigns.dynamic.createcontainer.steptwo'
      }),
      back: true,
      next: true
    },
    {
      position: 3,
      key: 'summary',
      name: intl.formatMessage({
        defaultMessage: 'Resumen',
        id: 'app.emailmarketing.campaigns.dynamic.createcontainer.stepthree'
      }),
      back: true,
      next: true
    }
  ]

  const [step, setStep] = useState<CampaignSteps>(campaignCreateSteps[0])
  const nextStep = useCallback((step: CampaignSteps) => {
    try {
      if (step.position < 3 && step.position >= 1) {
        setStep(
          campaignCreateSteps.find(
            (stp) => stp.position === step.position + 1
          ) ?? step
        )
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const prevStep = useCallback((step: CampaignSteps) => {
    try {
      if (step.position <= 3 && step.position > 1) {
        setStep(
          campaignCreateSteps.find(
            (stp) => stp.position === step.position - 1
          ) ?? step
        )
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const submitFormStepOne = useCallback(
    async (formData) => {
      if (
        formData.sender?.timeStart?.length != 4 ||
        !formData.sender.timeStart
      ) {
        methodsStepOne.setError('sender.timeStart', {
          type: 'value',
          message: 'invalid'
        })
        return
      }
      if (formData.sender?.timeEnd?.length != 4 || !formData.sender.timeEnd) {
        methodsStepOne.setError('sender.timeEnd', {
          type: 'value',
          message: 'invalid'
        })
        return
      }
      if (formData.campaignSetting.enableExpire == 1) {
        if (!formData.sender.startDate) {
          methodsStepOne.setError('sender.startDate', { type: 'required' })
          methodsStepOne.setFocus('sender.startDate')

          return
        }
        if (!formData.sender.endDate) {
          methodsStepOne.setError('sender.endDate', { type: 'required' })
          methodsStepOne.setFocus('sender.endDate')

          return
        }
        const dateStart = formData.sender.startDate.split('-')
        const dateEnd = formData.sender.endDate.split('-')
        if (
          new Date() >
          new Date(
            parseInt(dateEnd[0]),
            parseInt(dateEnd[1]) - 1,
            parseInt(dateEnd[2]),
            23,
            59
          )
        ) {
          methodsStepOne.setError('sender.endDate', { type: 'required' })
          methodsStepOne.setFocus('sender.endDate')

          return
        }
        if (
          new Date(
            parseInt(dateStart[0]),
            parseInt(dateStart[1]) - 1,
            parseInt(dateStart[2]),
            23,
            59
          ) >
          new Date(
            parseInt(dateEnd[0]),
            parseInt(dateEnd[1]) - 1,
            parseInt(dateEnd[2]),
            23,
            59
          )
        ) {
          methodsStepOne.setError('sender.startDate', { type: 'required' })
          methodsStepOne.setFocus('sender.startDate')
          return
        }

        if (formData.sender.startDateHour.length !== 4) {
          methodsStepOne.setError('sender.startDateHour', {
            type: 'required'
          })
          methodsStepOne.setFocus('sender.startDateHour')
          return
        }

        if (formData.sender.endDateHour.length !== 4) {
          methodsStepOne.setError('sender.endDateHour', { type: 'required' })
          methodsStepOne.setFocus('sender.endDateHour')
          return
        }
        if (
          formData.sender.startDate === formData.sender.endDate &&
          parseInt(formData.sender.startDate) >=
            parseInt(formData.sender.endDate)
        ) {
          methodsStepOne.setError('sender.startDate', { type: 'required' })
          methodsStepOne.setFocus('sender.startDate')
          return
        }
      }
      try {
        let cc: string | null = null
        if (formData.sender.cc.length > 0) {
          if (formData.sender.cc.indexOf(',')) {
            const emails: string[] = formData.sender.cc
              .trim()
              .toLowerCase()
              .split(',')
            if (
              emails.every((mail) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail))
            ) {
              cc = emails.toString()
            } else {
              methodsStepOne.setError('sender.cc', {
                message: 'invalid Emails'
              })
              methodsStepOne.setFocus('sender.cc')
              return
            }
          } else {
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.sender.cc)) {
              methodsStepOne.setError('sender.cc', {
                message: 'invalid Emails'
              })
              methodsStepOne.setFocus('sender.cc')
              return
            }
          }
          cc = formData.sender.cc
        }
        if (!isActivityCampaign && !formData.campaignSetting?.Segment?.value) {
          methodsStepOne.setError('campaignSetting.Segment', {
            message: 'required',
            type: 'required'
          })
          window.scrollTo(0, 0)
          return
        }

        const payload: any = {
          campaignSetting: {
            // idRule: id && id !== 'new' ? parseInt(id) : null,
            Name: formData.campaignSetting.Name,
            Description: formData.campaignSetting.Description,
            ForceSend: 1,
            GroupSend: formData.campaignSetting.GroupSend
          },
          utms: {
            utmCampaign: formData.utms.utmCampaign
          },
          sender: {
            senderName: formData.sender.senderName,
            senderEmail: formData.sender.senderEmail,
            subject: formData.sender.subject,
            preHeaderText: formData.sender.preHeaderText,
            IdAutomationTemplate: formData.sender.IdAutomationTemplate.value,
            hourRun: formData.sender.timeStart
              ? `${formData.sender.timeStart.slice(
                  0,
                  2
                )}:${formData.sender.timeStart.slice(2)}:00`
              : '',
            hourStop: formData.sender.timeEnd
              ? `${formData.sender.timeEnd.slice(
                  0,
                  2
                )}:${formData.sender.timeEnd.slice(2)}:00`
              : '',
            StartDate: formData.sender.startDate
              ? `${formData.sender.startDate}T${
                  formData.sender.startDateHour
                    ? `${formData.sender.startDateHour.slice(
                        0,
                        2
                      )}:${formData.sender.startDateHour.slice(2)}:00.00`
                    : ''
                }`
              : null,
            EndDate: formData.sender.endDate
              ? `${formData.sender.endDate}T${
                  formData.sender.endDateHour
                    ? `${formData.sender.endDateHour.slice(
                        0,
                        2
                      )}:${formData.sender.endDateHour.slice(2)}:00.00`
                    : ''
                }`
              : null,
            listEmailAlert: cc,
            MondayActive: formData.campaignSetting.daysActive.Monday,
            TuesdayActive: formData.campaignSetting.daysActive.Tuesday,
            WednesdayActive: formData.campaignSetting.daysActive.Wednesday,
            ThursdayActive: formData.campaignSetting.daysActive.Thursday,
            FridayActive: formData.campaignSetting.daysActive.Friday,
            SaturdayActive: formData.campaignSetting.daysActive.Saturday,
            SundayActive: formData.campaignSetting.daysActive.Sunday
          }
        }
        if (isActivityCampaign) {
          const activityFormatted: any[] =
            formData.campaignSetting.Parameters.reduce((acc: any, cc: any) => {
              acc[cc.key] = cc
              acc[cc.key].value = formData.params[cc.key] ?? cc.value
              return acc
            }, {})
          payload.campaignSetting.IdSegment = 0
          payload.campaignSetting.Parameters = Object.values(activityFormatted)
        } else {
          payload.campaignSetting.IdSegment =
            formData.campaignSetting.Segment.value
        }
        if (id && id !== 'new') {
          payload.campaignSetting.idRule = parseInt(id)
          delete payload.campaignSetting.Name
        }

        const { data, status } = await axios.post(
          `${process.env.REACT_APP_BASE_PATH_SERVER}/Automation/CreateNewCampaign?idHashScript=${selectedScript?.idHashScript}`,
          JSON.stringify(payload),
          {
            headers: {
              token: user.token,
              'Content-Type': 'application/json'
            }
          }
        )
        if (!data.IdRule) {
          return
        }
        if (status == 200 && data.IdRule) {
          // methodsStepOne.reset()
          navigate(`/dashboard/campaign/campaign-create/${data.IdRule}`)
          getCampaignById(data.IdRule)
        }
        const template = templates.find(
          (temp: any) => temp.Id === formData.sender.IdAutomationTemplate.value
        )
        if (/\bhtml\b/gi.test(template?.urlTemplate)) {
          setStep(campaignCreateSteps.find((stp) => stp.position === 3) ?? step)
          setIsDynamicTemplate(false)
          return
        }
        setIsDynamicTemplate(true)
        nextStep(step)
      } catch (error) {
        ShowAlert({
          title: 'Error',
          text: intl.formatMessage({
            id: 'app.common.modal.error',
            defaultMessage:
              'Ops, algo va mal. Refresca la pagina o vuelve en unos minutos'
          }),
          icon: 'warning',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
        console.log(error)
      }
    },
    [
      selectedScript,
      user,
      methodsStepOne,
      id,
      step,
      templates,
      campaign,
      isActivityCampaign
    ]
  )
  const submitFormStepTwo = useCallback(
    async (formData) => {
      try {
        if (!id) {
          // no hay id de identificación
          return
        }
        const data = new FormData()
        data.append('IdRule', id)
        // header
        data.append('HeaderImage', formData.header)
        data.append('HeaderLink', formData['header-url'])
        data.append('HeaderUTMTerm', formData['header-utm-term'])
        data.append('HeaderUTMContent', formData['header-utm-content'])
        // body
        data.append('BodyImage', formData.body)
        data.append('BodyLink', formData['body-url'])
        data.append('BodyUTMTerm', formData['body-utm-term'])
        data.append('BodyUTMContent', formData['body-utm-content'])
        // footer
        data.append('FooterImage', formData.footer)
        data.append('FooterLink', formData['footer-url'])
        data.append('FooterUTMTerm', formData['footer-utm-term'])
        data.append('FooterUTMContent', formData['footer-utm-content'])

        // disclaimer
        data.append('Disclaimer', formData?.disclaimer)

        // endpoints
        data.append('IdAutomationEndpoint', formData?.endPointOne?.value)
        data.append('IdAutomationEndpoint2', formData?.endPointTwo?.value)

        // endpoints source
        data.append('EndPointSource', formData?.endPointOne?.endPointSource)
        data.append('EndPointSource2', formData?.endPointTwo?.endPointSource)

        const { data: dataResponse } = await Service.post<any, any>(
          `Automation/CreateBlocksCampaign?idHashScript=${selectedScript?.idHashScript}`,
          data,
          {
            method: 'POST',
            headers: {
              token: user.token,
              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
        console.log(dataResponse)
        getCampaignById(id)
        nextStep(step)
        methodsStepTwo.reset()
      } catch (error) {
        ShowAlert({
          title: 'Error',
          text: intl.formatMessage({
            id: 'app.common.modal.error',
            defaultMessage:
              'Ops, algo va mal. Refresca la pagina o vuelve en unos minutos'
          }),
          icon: 'warning',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
        console.log(error)
      }
    },
    [methodsStepTwo, selectedScript, user, id, campaign, step]
  )

  const submitFormStepThree = useCallback(async () => {
    try {
      if (!selectedScript || !id) {
        return
      }
      const { data } = await Service.post<any, any>(
        `Automation/ConfirmCampaign?idHashScript=${selectedScript?.idHashScript}&idRule=${id}&isActive=true`,
        {},
        {
          headers: {
            token: user.token,
            'Content-Type': 'application/json'
          }
        }
      )
      if (data?.Status === 'Ok') {
        navigate('/dashboard/campaign/campaigns')
      }
    } catch (error) {
      ShowAlert({
        title: 'Error',
        text: intl.formatMessage({
          id: 'app.common.modal.error',
          defaultMessage:
            'Ops, algo va mal. Refresca la pagina o vuelve en unos minutos'
        }),
        icon: 'warning',
        showConfirmButton: true,
        timer: 2500,
        closeButtonHtml: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        confirmButtonText: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        toast: true,
        width: 500
      })
      console.log(error)
    }
  }, [selectedScript, id, user])

  const ComponentStep = useCallback(() => {
    switch (step.position) {
      case 1: {
        return (
          <>
            <FormProvider {...methodsStepOne}>
              <form onSubmit={methodsStepOne.handleSubmit(submitFormStepOne)}>
                <ConfigCampaign
                  campaign={campaign}
                  loadingCampaign={loadingCampaign}
                  setTemplatesParent={setTemplates}
                  isActivityCampaign={isActivityCampaign}
                  listOfActivityCampaign={activityCampaigns}
                />
                <StepButtonContainer style={{ justifyContent: 'center' }}>
                  {campaign?.IdRule ? (
                    <StepButton
                      type="button"
                      style={{ marginInline: 'inherit' }}
                      onClick={(e) => {
                        e.preventDefault()
                        if (!isDynamicTemplate) {
                          setStep(
                            campaignCreateSteps.find(
                              (stp) => stp.position === 3
                            ) ?? step
                          )
                          return
                        }
                        if (
                          !methodsStepOne.getValues(
                            'sender.IdAutomationTemplate'
                          ).isDynamic
                        ) {
                          setStep(
                            campaignCreateSteps.find(
                              (stp) => stp.position === 3
                            ) ?? step
                          )
                          return
                        }
                        nextStep(step)
                      }}
                    >
                      {intl.formatMessage({
                        id: 'app.common.next',
                        defaultMessage: 'Siguiente'
                      })}
                      <FaArrowRight />
                    </StepButton>
                  ) : null}
                  <StepButton type="submit" style={{ marginInline: 'inherit' }}>
                    {intl.formatMessage({
                      id: 'app.common.save.next',
                      defaultMessage: 'Guardar y Seguir'
                    })}
                    <FaArrowRight />
                  </StepButton>
                </StepButtonContainer>
              </form>
            </FormProvider>
          </>
        )
      }
      case 2: {
        return (
          <FormProvider {...methodsStepTwo}>
            <form onSubmit={methodsStepTwo.handleSubmit(submitFormStepTwo)}>
              <EditTemplateStep
                endPoints={endPoints}
                setEndPoints={setEndPoints}
                campaign={campaign}
              />
              <StepButtonContainer style={{ justifyContent: 'center' }}>
                <StepButton
                  type="button"
                  style={{ marginInline: 'inherit' }}
                  onClick={(e) => {
                    e.preventDefault()
                    prevStep(step)
                  }}
                >
                  <FaArrowLeft />
                  {intl.formatMessage({
                    id: 'app.common.back.2',
                    defaultMessage: 'Atrás'
                  })}
                </StepButton>
                {campaign?.EndPointSource || campaign?.EndPointSource ? (
                  <StepButton
                    type="button"
                    style={{ marginInline: 'inherit' }}
                    onClick={(e) => {
                      e.preventDefault()
                      nextStep(step)
                    }}
                  >
                    {intl.formatMessage({
                      id: 'app.common.next',
                      defaultMessage: 'Siguiente'
                    })}
                    <FaArrowRight />
                  </StepButton>
                ) : null}
                <StepButton type="submit" style={{ marginInline: 'inherit' }}>
                  {intl.formatMessage({
                    id: 'app.common.save.next',
                    defaultMessage: 'Guardar y Seguir'
                  })}
                  <FaArrowRight />
                </StepButton>
              </StepButtonContainer>
            </form>
          </FormProvider>
        )
      }
      case 3: {
        return (
          <FormProvider {...methodsStepThree}>
            <form onSubmit={methodsStepThree.handleSubmit(submitFormStepThree)}>
              <CampaignSummary
                campaign={campaign}
                isDynamicTemplate={isDynamicTemplate}
              />
              <StepButtonContainer
                style={{ justifyContent: 'center', gap: '20px' }}
              >
                <StepButton
                  type="button"
                  style={{ marginInline: 'inherit' }}
                  onClick={(e) => {
                    e.preventDefault()
                    if (!isDynamicTemplate) {
                      setStep(
                        campaignCreateSteps.find((stp) => stp.position === 1) ??
                          step
                      )
                      return
                    }
                    if (
                      !methodsStepOne.getValues('sender.IdAutomationTemplate')
                        .isDynamic
                    ) {
                      setStep(
                        campaignCreateSteps.find((stp) => stp.position === 1) ??
                          step
                      )
                      return
                    }
                    prevStep(step)
                  }}
                >
                  <FaArrowLeft />
                  {intl.formatMessage({
                    id: 'app.common.back.2',
                    defaultMessage: 'Atrás'
                  })}
                </StepButton>
                <StepButton
                  type="button"
                  style={{ marginInline: 'inherit' }}
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/dashboard/campaign/campaigns')
                  }}
                >
                  {intl.formatMessage({
                    id: 'app.common.next',
                    defaultMessage: 'Siguiente'
                  })}
                </StepButton>
                <StepButton type="submit" style={{ marginInline: 'inherit' }}>
                  {intl.formatMessage({
                    id: 'app.common.save.enable',
                    defaultMessage: 'Guardar y Activar'
                  })}
                </StepButton>
              </StepButtonContainer>
            </form>
          </FormProvider>
        )
      }
      default: {
        return (
          <>
            <ConfigCampaign
              campaign={campaign}
              loadingCampaign={loadingCampaign}
              setTemplatesParent={setTemplates}
              isActivityCampaign={isActivityCampaign}
              listOfActivityCampaign={activityCampaigns}
            />
            <StepButtonContainer style={{ justifyContent: 'center' }}>
              {campaign?.EndPointSource || campaign?.EndPointSource ? (
                <StepButton
                  type="button"
                  style={{ marginInline: 'inherit' }}
                  onClick={(e) => {
                    e.preventDefault()
                    if (!isDynamicTemplate) {
                      setStep(
                        campaignCreateSteps.find((stp) => stp.position === 3) ??
                          step
                      )
                      return
                    }
                    nextStep(step)
                  }}
                >
                  {intl.formatMessage({
                    id: 'app.common.next',
                    defaultMessage: 'Siguiente'
                  })}
                  <FaArrowRight />
                </StepButton>
              ) : null}
              <StepButton type="submit" style={{ marginInline: 'inherit' }}>
                {intl.formatMessage({
                  id: 'app.common.save.next',
                  defaultMessage: 'Guardar y Seguir'
                })}
                <FaArrowRight />
              </StepButton>
            </StepButtonContainer>
          </>
        )
      }
    }
  }, [
    step,
    endPoints,
    campaign,
    loadingCampaign,
    isDynamicTemplate,
    templates,
    isActivityCampaign,
    activityCampaigns
  ])

  const getCampaignById = useCallback(
    async (idParam: number | string) => {
      try {
        setLoadingCampaign(true)
        const { data } = await Service.get<any>(
          `/Automation/GetCampaignById?idHashScript=${selectedScript?.idHashScript}&idRule=${idParam}`,
          {
            headers: {
              token: user.token,
              'Content-Type': 'application/json'
            }
          }
        )
        if (data.Status == 'Ok') {
          methodsStepOne.setValue('campaignSetting.Name', data.Entity.Name)
          methodsStepOne.setValue(
            'campaignSetting.Description',
            data.Entity.Description
          )
          methodsStepOne.setValue(
            'campaignSetting.ForceSend',
            data.Entity.ForceSend
          )
          methodsStepOne.setValue(
            'campaignSetting.GroupSend',
            data.Entity.GroupSend
          )

          methodsStepOne.setValue('campaignSetting.Segment', {
            label: data.Entity.SegmentAliasName,
            value: data.Entity.IdSegment
          })

          if (data.Entity?.HourRun) {
            methodsStepOne.setValue(
              'sender.timeStart',
              data.Entity.HourRun.replaceAll(':', '').slice(0, -2)
            )
          }
          if (data.Entity?.HourStop) {
            methodsStepOne.setValue(
              'sender.timeEnd',
              data.Entity.HourStop.replaceAll(':', '').slice(0, -2)
            )
          }
          if (data.Entity?.StartDate) {
            const startDate = data.Entity?.StartDate.split('T')
            methodsStepOne.setValue('sender.startDate', startDate[0])
            methodsStepOne.setValue(
              'sender.startDateHour',
              startDate[1].replaceAll(':', '').slice(0, -2)
            )
          }
          if (data.Entity?.EndDate) {
            const endDate = data.Entity?.EndDate.split('T')
            methodsStepOne.setValue('sender.endDate', endDate[0])
            methodsStepOne.setValue(
              'sender.endDateHour',
              endDate[1].replaceAll(':', '').slice(0, -2)
            )
          }
          methodsStepOne.setValue('campaignSetting.Name', data.Entity.Name)
          methodsStepOne.setValue('sender.cc', data.Entity.ListEmailAlert)
          methodsStepOne.setValue('sender.subject', data.Entity.Subject)
          methodsStepOne.setValue('sender.senderName', data.Entity.SenderName)
          methodsStepOne.setValue(
            'sender.preHeaderText',
            data.Entity.PreHeaderText
          )
          methodsStepOne.setValue('sender.senderEmail', data.Entity.SenderEmail)

          methodsStepOne.setValue('sender.IdAutomationTemplate', {
            label: 'loading...',
            value: data.Entity.IdAutomationTemplate
          })
          // days active
          methodsStepOne.setValue(
            'campaignSetting.daysActive.Monday',
            data.Entity.MondayActive
          )
          methodsStepOne.setValue(
            'campaignSetting.daysActive.Tuesday',
            data.Entity.TuesdayActive
          )
          methodsStepOne.setValue(
            'campaignSetting.daysActive.Wednesday',
            data.Entity.WednesdayActive
          )
          methodsStepOne.setValue(
            'campaignSetting.daysActive.Thursday',
            data.Entity.ThursdayActive
          )
          methodsStepOne.setValue(
            'campaignSetting.daysActive.Friday',
            data.Entity.FridayActive
          )
          methodsStepOne.setValue(
            'campaignSetting.daysActive.Saturday',
            data.Entity.SaturdayActive
          )
          methodsStepOne.setValue(
            'campaignSetting.daysActive.Sunday',
            data.Entity.SundayActive
          )
          if (activityCampaigns.some((act) => act === data.Entity.Name)) {
            methodsStepOne.setValue(
              'campaignSetting.Parameters',
              data.Entity.Parameters
            )
            setIsActivityCampaign(true)
            const params = data.Entity.Parameters.reduce(
              (acc: any, cc: any) => {
                acc[cc.key] = cc.value
                return acc
              },
              {}
            )
            methodsStepOne.setValue('params', params)
          }
        }
        if (!data.Entity) {
          methodsStepOne.reset()
          navigate(`/dashboard/campaign/campaign-create/new`)
        }
        setCampaign(data.Entity)
        setLoadingCampaign(false)
      } catch (error) {
        setLoadingCampaign(false)
        ShowAlert({
          title: 'Error',
          text: intl.formatMessage({
            id: 'app.common.modal.error',
            defaultMessage:
              'Ops, algo va mal. Refresca la pagina o vuelve en unos minutos'
          }),
          icon: 'warning',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
        console.log(error)
      }
    },
    [user, selectedScript]
  )
  useEffect(() => {
    // console.log('render')
    if (id && id != 'new') {
      getCampaignById(id)
    }
    return () => {}
  }, [id])

  if (loadingCampaign) {
    return (
      <div
        className="container"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh'
        }}
      >
        <Loading textLoading="Cargando datos" />
      </div>
    )
  }
  return (
    <div className="container">
      <StepsContainer>
        {campaignCreateSteps.map((stp) => (
          <Step className="step" key={stp.key}>
            {stp.position !== 1 && (
              <IoIosArrowDroprightCircle
                size={25}
                color="#6529A1"
                style={{
                  opacity: step.position === stp.position - 1 ? 1 : 0.5
                }}
              />
            )}
            <StepText
              style={{ opacity: step.position === stp.position ? 1 : 0.5 }}
            >
              {stp.name}
            </StepText>
          </Step>
        ))}
      </StepsContainer>

      {ComponentStep()}
    </div>
  )
}

export default CampaignCreateContainer
