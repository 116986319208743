import { useEffect, useState } from 'react'
import { IntlProvider, MessageFormatElement } from 'react-intl'
import Spanish from '../../utils/es.json'
import English from '../../utils/en.json'
import Br from '../../utils/br.json'
import { useSelector } from 'react-redux'
import { RootState } from '../../state/store'

let lang: Record<string, string> | Record<string, MessageFormatElement[]>

const LanguageProvider = ({ children }: any) => {
  const local = useSelector<RootState, Lang>((state) => state.langSelected)

  const [messages, setMessages] = useState(lang)

  useEffect(() => {
    switch (local.lang) {
      case 'es':
        lang = Spanish
        setMessages(lang)
        break
      case 'en':
        lang = English
        setMessages(lang)
        break
      case 'br':
        lang = Br
        setMessages(lang)
        break
      default:
        lang = Spanish
        setMessages(lang)
        break
    }
  }, [local.lang])

  return (
    <IntlProvider messages={messages} locale={local.lang}>
      {children}
    </IntlProvider>
  )
}

export default LanguageProvider
