/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import { Modal } from 'react-bootstrap'
import { ImCancelCircle } from 'react-icons/im'
import {
  ModalImgProductPreview,
  ModalProductPreviewContainer,
  ModalProductsPreviewContainer,
  ModalProductsPreviewContainerSlider,
  ModalProductsPreviewContentLabel,
  ModalProductsPreviewContentText
} from '../css/EditTemplateComponents'
import { LoadingMetrics } from '../css/CampaignCreateContainerComponents'

export const EndpointPreview = ({
  setOpenPreviewModal,
  open,
  products,
  loading
}: {
  setOpenPreviewModal: any
  products: any[]
  open: boolean
  loading: boolean
}) => {
  if (!open) {
    return null
  }
  return (
    <Modal
      show={open}
      onHide={() => setOpenPreviewModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalProductsPreviewContainer>
        <ImCancelCircle
          size={20}
          style={{
            position: 'absolute',
            top: 5,
            right: 5,
            cursor: 'pointer',
            fill: '#6529A1'
          }}
          onClick={() => {
            setOpenPreviewModal(null)
          }}
        />
        <ModalProductsPreviewContainerSlider>
          {loading ? (
            <LoadingMetrics
              size={40}
              color="#D9D9D9"
              style={{ margin: 'auto' }}
            />
          ) : products.length ? (
            products.map((prod, idx) => {
              return (
                <ModalProductPreviewContainer key={prod.Sku || idx}>
                  <ModalImgProductPreview
                    src={prod.UrlImagen}
                    alt={prod.Descripcion}
                  />
                  {prod?.Descripcion ? (
                    <ModalProductsPreviewContentText>
                      {prod?.Descripcion}
                    </ModalProductsPreviewContentText>
                  ) : null}
                  {prod?.PrecioAnterior ? (
                    <div>
                      <ModalProductsPreviewContentLabel htmlFor="">
                        Precio Anterior
                      </ModalProductsPreviewContentLabel>
                      <ModalProductsPreviewContentText
                        style={{ textDecorationLine: 'line-through' }}
                      >
                        {prod?.PrecioAnterior}
                      </ModalProductsPreviewContentText>
                    </div>
                  ) : null}
                  {prod?.CucardaOferta ? (
                    <div>
                      <ModalProductsPreviewContentLabel htmlFor="">
                        Cucarda
                      </ModalProductsPreviewContentLabel>
                      <ModalProductsPreviewContentText>
                        {prod?.CucardaOferta}
                      </ModalProductsPreviewContentText>
                    </div>
                  ) : null}

                  {prod?.Precio ? (
                    <div>
                      <ModalProductsPreviewContentLabel htmlFor="">
                        Precio Actual
                      </ModalProductsPreviewContentLabel>
                      <ModalProductsPreviewContentText>
                        {prod?.Precio}
                      </ModalProductsPreviewContentText>
                    </div>
                  ) : null}
                </ModalProductPreviewContainer>
              )
            })
          ) : (
            <div>no hay productos</div>
          )}
        </ModalProductsPreviewContainerSlider>
      </ModalProductsPreviewContainer>
    </Modal>
  )
}
