/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useCallback } from 'react'
import Select from 'react-select'
import './style.css'
import { useClientContext } from '../../contexts/ClientContext'

const ClientSelector = () => {
  const { clients, selectClient, selectedClient } = useClientContext()

  const handleChange = useCallback(
    (clientId: number) => {
      const client = clients?.find((x) => x.idClient === clientId)
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (client) {
        selectClient(client)
      }
    },
    [clients]
  )
  return (
    <div className="">
      {clients?.length && selectedClient && (
        <Select
          classNamePrefix="select"
          value={{
            value: selectedClient.idClient,
            label: selectedClient.clientName.replace('megusta', 'On City')
          }}
          isDisabled={!clients}
          isSearchable={true}
          options={clients.map((client) => ({
            label: client.clientName.replace('megusta', 'On City'),
            value: client.idClient
          }))}
          onChange={(e) => e && handleChange(e?.value)}
          styles={{
            control: (prevStyles) => ({
              ...prevStyles,
              // minHeight: '33.5px',
              ':hover': {
                ...prevStyles[':hover'],
                borderColor: 'hsl(0, 0%, 80%)'
              },
              // height: '33.5px',
              backgroundColor: '#fff !important',
              boxShadow: 'none'
            }),
            valueContainer: (prevStyles) => ({
              ...prevStyles,
              padding: '0px 8px'
            }),
            placeholder: (prevStyles) => ({
              ...prevStyles,
              fontSize: 14
            }),
            singleValue: (prevStyles) => ({
              ...prevStyles,
              fontSize: 14
            }),
            option: (prevStyles, { isSelected }) => ({
              ...prevStyles,
              ':hover': {
                ...prevStyles[':hover'],
                backgroundColor: isSelected
                  ? '#6529A1'
                  : 'rgb(101 41 161 / 70%)',
                color: '#fff'
              },
              backgroundColor: isSelected ? '#6529A1' : '#fff',
              ':active': {
                ...prevStyles[':active'],
                backgroundColor: '#6529A1',
                color: '#fff'
              }
            })
          }}
        />
      )}
    </div>
  )
}

export default ClientSelector
