/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import Button from 'react-bootstrap/Button'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

interface imgI {
  urlmain: string
  id: number
  title: string
  urlmobile: string
  tags: string[]
  selected?: boolean
}

interface Props {
  images: Record<string, imgI[]>
  selectedImages: any[]
  selectImages: (img: any, tag: string) => void
  tagFilter: boolean
  loadingImages: boolean
}
const CustomFigureSelect = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: background-color 800ms;
  cursor: pointer;
  &:hover {
    background-color: #666666;
  }
`
const ListImagesByTag = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  overflow-y: auto;
  gap: 10px 35px;
  &::-webkit-scrollbar {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #d9d9d9;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
  }
`
const CustomButtonSelect = styled(Button)`
  background-color: #2bae7e;
  border: none;
  font-size: 14px;
  &:hover {
    border: none;
  }
  &:focus-visible {
    box-shadow: none;
  }
`

function ImagesContainer({
  images,
  selectImages,
  selectedImages,
  tagFilter,
  loadingImages
}: Props) {
  const intl = useIntl()
  if (loadingImages) {
    return null
  }
  if (tagFilter && images.images) {
    return (
      <div
        style={{
          background: '#fff',
          border: '1px solid #dbdbdb',
          padding: '1rem',
          borderRadius: '8px',
          height: 260,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: 18
        }}
      >
        <div style={{ marginBottom: 14, alignSelf: 'center' }}>
          {images.images.length
            ? intl.formatMessage({
                id: 'app.smart.images.creteform.form.images.text.positive',
                defaultMessage: 'Imágenes Encontradas'
              })
            : intl.formatMessage({
                id: 'app.smart.images.creteform.form.images.text.negative',
                defaultMessage: 'No hay Imágenes Disponibles'
              })}
        </div>
        <ListImagesByTag>
          {images.images.map((img: any) => {
            return (
              <CustomFigureSelect
                style={{
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  backgroundColor: selectedImages.some(
                    (i: any) => i.id === img.id
                  )
                    ? '#666'
                    : ' #fff'
                }}
                key={img.id}
                onClick={() => selectImages(img, 'images')}
              >
                <img
                  src={img.urlmain}
                  alt={img.title}
                  style={{
                    width: '80%',
                    marginTop: 12,
                    marginBottom: 12
                  }}
                />
                <figcaption style={{ textAlign: 'center', marginBottom: 12 }}>
                  <CustomButtonSelect>
                    {intl.formatMessage({
                      id: 'app.common.select',
                      defaultMessage: 'Seleccionar'
                    })}
                  </CustomButtonSelect>
                </figcaption>
              </CustomFigureSelect>
            )
          })}
        </ListImagesByTag>
      </div>
    )
  }
  return (
    <div className="ctn">
      {Object.keys(images)
        .filter((img) => img != 'ids')
        .map((tag) => {
          if (!images[tag].length) return null
          return (
            <div
              key={tag}
              style={{
                background: '#fff',
                border: '1px solid #dbdbdb',
                padding: '1rem',
                borderRadius: '8px',
                height: 260,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: 18
              }}
            >
              <div style={{ marginBottom: 14 }}>
                {intl.formatMessage({
                  id: 'app.smart.images.creteform.form.images.tag',
                  defaultMessage: 'Etiqueta'
                })}{' '}
                {tag}
              </div>
              <ListImagesByTag>
                {images[tag].map((img: any) => {
                  return (
                    <CustomFigureSelect
                      style={{
                        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                        backgroundColor: selectedImages.some(
                          (i: any) => i.id === img.id
                        )
                          ? '#666'
                          : ' #fff'
                      }}
                      key={img.id}
                      onClick={() => selectImages(img, tag)}
                    >
                      <img
                        src={img.urlmain}
                        alt={img.title}
                        style={{
                          width: '80%',
                          marginTop: 12,
                          marginBottom: 12
                        }}
                      />
                      <figcaption
                        style={{ textAlign: 'center', marginBottom: 12 }}
                      >
                        <CustomButtonSelect>
                          {intl.formatMessage({
                            id: 'app.common.select',
                            defaultMessage: 'Seleccionar'
                          })}
                        </CustomButtonSelect>
                      </figcaption>
                    </CustomFigureSelect>
                  )
                })}
              </ListImagesByTag>
            </div>
          )
        })}
    </div>
  )
}

export default ImagesContainer
