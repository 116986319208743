import React from 'react'
import { ButtonSpan, Button } from '../Campain/css/DashboardCampaignComponents'

interface Props {
  text: string
  to: string
  cb?: () => void
}

export const UtilLinkButton = (props: Props) => {
  return (
    <Button style={{ borderColor: '#6529A1' }} to={props.to}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
      >
        <path
          d="M8.49007 0.819702C4.24266 0.819702 0.801331 4.27558 0.801331 8.54093C0.801331 12.8063 4.24266 16.2622 8.49007 16.2622C12.7375 16.2622 16.1788 12.8063 16.1788 8.54093C16.1788 4.27558 12.7375 0.819702 8.49007 0.819702ZM12.9545 9.41268C12.9545 9.61817 12.7871 9.78629 12.5825 9.78629H9.73019V12.6506C9.73019 12.8561 9.56278 13.0242 9.35816 13.0242H7.62199C7.41737 13.0242 7.24995 12.8561 7.24995 12.6506V9.78629H4.39768C4.19306 9.78629 4.02564 9.61817 4.02564 9.41268V7.66918C4.02564 7.4637 4.19306 7.29557 4.39768 7.29557H7.24995V4.43125C7.24995 4.22576 7.41737 4.05764 7.62199 4.05764H9.35816C9.56278 4.05764 9.73019 4.22576 9.73019 4.43125V7.29557H12.5825C12.7871 7.29557 12.9545 7.4637 12.9545 7.66918V9.41268Z"
          fill="#6529A1"
        />
      </svg>
      <ButtonSpan style={{ color: '#7424A5' }}>{props.text}</ButtonSpan>
    </Button>
  )
}
