/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select, { components, OptionProps, SingleValueProps } from 'react-select'

import { langSelected } from '../../state/actions/langAction'
import { RootState } from '../../state/store'
import './style.css'

const { Option, SingleValue } = components

interface LanguageSelectorOption {
  value: string
  label: string
  icon: string
}

const IconOption = ({
  children,
  ...props
}: OptionProps<LanguageSelectorOption>) => (
  <Option {...props}>
    <img src={props.data.icon} style={{ width: 20 }} alt={props.data.label} />
  </Option>
)

const InputCustom = (props: SingleValueProps<LanguageSelectorOption>) => (
  <SingleValue {...props}>
    <img src={props.data.icon} style={{ width: 20 }} alt={props.data.label} />
  </SingleValue>
)

const LanguageSelector = ({ outside = false }: { outside?: boolean }) => {
  const dispatch = useDispatch()
  const initLang = useSelector<RootState, Lang>((state) => state.langSelected)
  const options = [
    { value: 'es', label: 'Español', icon: require('./es.png') },
    { value: 'en', label: 'English', icon: require('./us.png') },
    { value: 'br', label: 'Português', icon: require('./br.png') }
  ]
  const [defaultOption, setDefaultOption] = useState(() => {
    if (initLang) {
      const opt = options.find((op) => op.value == initLang.lang)
      return opt
    }
    return { value: 'es', label: 'Español', icon: require('./es.png') }
  })

  const handleLanguageChange = useCallback((val: string) => {
    try {
      dispatch(langSelected(val))
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <div className={outside ? '' : ``}>
      <Select
        options={options}
        defaultValue={defaultOption}
        components={{ Option: IconOption, SingleValue: InputCustom }}
        onChange={(e: any) => {
          if (e.value) {
            handleLanguageChange(e.value)
          }
        }}
        isSearchable={false}
        styles={{
          control: (prevStyles) => ({
            ...prevStyles,
            ':hover': {
              ...prevStyles[':hover'],
              borderColor: 'hsl(0, 0%, 80%)'
            },
            backgroundColor: '#fff !important',
            boxShadow: 'none'
          })
        }}
      />
    </div>
  )
}

export default LanguageSelector
