import styled from 'styled-components'
import {
  components,
  SingleValueProps,
  OptionProps,
  CSSObjectWithLabel
} from 'react-select'
import { FormattedMessage } from 'react-intl'
export const DayContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, auto);
  gap: 6px;
  margin-block: 25px;
`
export const DayInput = styled.input`
  position: absolute;
  display: none;
  color: #fff !important;
`
// export const TimeInput = styled.input.attrs({ type: 'time' })`
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   padding: 2px 10px;
//   margin: 0px 0;
//   color: #37393a;
//   width: 130px !important;
//   &::-webkit-calendar-picker-indicator {
//     filter: invert(37%) sepia(16%) saturate(5545%) hue-rotate(150deg)
//       brightness(91%) contrast(104%);
//     width: 20px;
//     height: 20px;
//     cursor: pointer;
//   }
// `
export const DaySpan = styled.span`
  background-color: #e8e8ed;
  color: #dbdbdb;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  border-radius: 8px;
  justify-content: center;
  opacity: 0.5;
`
export const DayLabel = styled.label`
  /* border: 1px solid #f4f4f4; */
  border-radius: 4px;
  cursor: pointer;
  ${DayInput}:checked & {
    /* border: 1px solid #00868b; */
  }

  > ${DayInput}:checked + ${DaySpan} {
    color: #00868b;
    background-color: rgba(0, 134, 139, 0.1);
    /* border: 1px solid #00868b; */
    opacity: 1;
  }
`
export const ExpireContainer = styled(DayContainer)`
  grid-template-columns: repeat(5, 1fr);
`

export const InitTimeContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  margin-top: 16px;
`
export const ImageListPopContainer = styled.div`
  max-height: 550px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  row-gap: 31px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 40px;
  border-radius: 8px;
`
export const ImageGalleryContainer = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  grid-auto-rows: 1fr;
  row-gap: 31px;
  column-gap: 25px;
`
export const ImageGallery = styled.img`
  cursor: pointer;
`

interface TemplateOption {
  readonly value: string
  readonly label: string
  readonly isDynamic: boolean
}

const TemplateSelectTag = styled.span`
  text-transform: capitalize;
  font-size: 10px;
  color: #444;
  background-color: #f4f4f4;
  border-radius: 4px;
  padding: 0px 6px;
`

export const SingleValueTemplateSelect = ({
  children,
  ...props
}: SingleValueProps<TemplateOption>) => (
  <components.SingleValue {...props}>
    {children}
    {props.data.isDynamic ? (
      <TemplateSelectTag style={{ backgroundColor: '#d9d9d9' }}>
        <FormattedMessage
          id="app.emailmarketing.create.campaign.step.one.label.template.tag.op"
          defaultMessage={'Dinámica'}
        />
      </TemplateSelectTag>
    ) : (
      <TemplateSelectTag style={{ backgroundColor: '#d9d9d9' }}>
        <FormattedMessage
          id="app.emailmarketing.create.campaign.step.one.label.template.tag"
          defaultMessage={'Estática'}
        />
      </TemplateSelectTag>
    )}
  </components.SingleValue>
)

export const OptionsTemplateSelect = ({
  children,
  ...props
}: OptionProps<TemplateOption>) => (
  <components.Option {...props}>
    {children}
    {props.data.isDynamic ? (
      <TemplateSelectTag>
        <FormattedMessage
          id="app.emailmarketing.create.campaign.step.one.label.template.tag.op"
          defaultMessage={'Dinámica'}
        />
      </TemplateSelectTag>
    ) : (
      <TemplateSelectTag>
        <FormattedMessage
          id="app.emailmarketing.create.campaign.step.one.label.template.tag"
          defaultMessage={'Estática'}
        />
      </TemplateSelectTag>
    )}
  </components.Option>
)

// campaign setup param select style
export const styleSelectParams = {
  container: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    maxWidth: '230px',
    marginTop: '0.5rem',
    marginBottom: '8px'
  }),
  control: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    minHeight: '30px',
    border: `1px solid #ced4da !important`,
    height: '30px',
    backgroundColor: '#F4F4F4 !important',
    boxShadow: 'none'
  }),
  valueContainer: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    padding: '0px 8px'
  }),
  placeholder: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    fontSize: 14,
    fontStyle: 'normal',
    color: 'hsl(0, 0%, 50%)'
  }),
  input: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    marginBlock: 0,
    paddingBlock: 0
  }),
  singleValue: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    color: '#666',
    fontSize: 14
  }),
  dropdownIndicator: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    paddingBlock: 4
  }),
  clearIndicator: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    paddingBlock: 4
  }),
  option: (
    prevStyles: CSSObjectWithLabel,
    { isSelected }: { isSelected: boolean }
  ) => ({
    ...prevStyles,
    fontSize: 14,
    ':hover': {
      ...prevStyles[':hover'],
      backgroundColor: isSelected ? '#6529A1' : 'rgb(101 41 161 / 70%)',
      color: '#fff'
    },
    backgroundColor: isSelected ? '#6529A1' : '#fff',
    ':active': {
      ...prevStyles[':active'],
      backgroundColor: '#6529A1',
      color: '#fff'
    }
  })
}
