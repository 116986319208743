/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { ImCancelCircle } from 'react-icons/im'
import { FormattedMessage, useIntl } from 'react-intl'
import { useController } from 'react-hook-form'
import { RequiredMessage } from './TagsInput'
import './styleSmartImgs.css'
interface GetColorProps {
  isDragAccept: boolean
  isDragReject: boolean
  isFocused: boolean
  isRequired: boolean
}

const getColor = (props: GetColorProps) => {
  if (props.isDragAccept) {
    return '#FFB245'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused) {
    return '#F29718'
  }
  if (props.isRequired) {
    return '#FFD293'
  }
  return '#eeeeee'
}

const Container = styled.div<GetColorProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 2px;
  border-color: ${({ isDragAccept, isDragReject, isFocused, isRequired }) =>
    getColor({ isDragAccept, isDragReject, isFocused, isRequired })};
  border-style: solid;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  &:hover {
    border-color: #ffd293;
  }
`
const Thumb = styled.div`
  width: 400px;
  position: relative;
  margin: 30px auto;
`

const MAX_SIZE = 450000
export const UploadImage = (props: {
  name: string
  isUpdate: boolean
  src?: string
}) => {
  const intl = useIntl()
  const [files, setFiles] = useState<any>(
    // props.isUpdate ?? false ? [props.src] : []
    props.isUpdate && props.src ? [props.src] : []
  )
  const {
    field: { onChange, ref, name, value },
    formState: { errors }
  } = useController({ name: props.name })
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },
    maxSize: MAX_SIZE,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
      onChange(acceptedFiles.length ? acceptedFiles : null)
    }
  })
  const thumbs = files.map((file: any) => (
    <img
      key={file.name}
      src={file.preview}
      // style={img}
      // Revoke data uri after image is loaded
      onLoad={() => {
        URL.revokeObjectURL(file.preview)
      }}
    />
  ))
  useEffect(() => {
    if (!props.isUpdate) {
      setFiles([])
    }
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview))
  }, [props.isUpdate])
  const isFileTooLarge =
    fileRejections.length > 0 &&
    fileRejections[0].errors[0].code === 'file-too-large'
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (props.isUpdate && files.length && !value) {
    return (
      <Thumb>
        <ImCancelCircle
          size={20}
          style={{
            position: 'absolute',
            top: -20,
            right: -20,
            cursor: 'pointer',
            fill: '#6529A1'
          }}
          onClick={() => {
            // files.forEach((file: any) => URL.revokeObjectURL(file.preview))
            setFiles([])
            onChange()
          }}
        />
        <img
          src={props.src}
          // style={img}
          // Revoke data uri after image is loaded
          style={{ height: 'inherit' }}
        />
      </Thumb>
    )
  }
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (files.length) {
    return (
      <Thumb>
        <ImCancelCircle
          size={20}
          style={{
            position: 'absolute',
            top: -20,
            right: -20,
            cursor: 'pointer',
            fill: '#6529A1'
          }}
          onClick={() => {
            files.forEach((file: any) => URL.revokeObjectURL(file.preview))
            setFiles([])
            onChange()
          }}
        />
        {thumbs}
      </Thumb>
    )
  }
  return (
    <>
      <Container
        {...getRootProps({
          className: 'dropzone',
          isDragActive,
          isFocused,
          isDragAccept,
          isDragReject
        })}
        isRequired={errors?.[name] != null}
      >
        <input {...getInputProps()} name={name} ref={ref} />
        <p style={{ margin: '0' }}>
          <FormattedMessage
            id="app.smart-image.drop-image"
            defaultMessage="Arrastra la imagen aca"
          />
        </p>
        <p style={{ fontSize: 10, fontStyle: 'italic', margin: 0 }}>
          {intl.formatMessage({
            id: 'app.smart.images.uploadImages.upload.one',
            defaultMessage: 'La Imagen debe tener un peso máximo de 500kb'
          })}
        </p>
        <p style={{ fontSize: 10, fontStyle: 'italic', margin: 0 }}>
          {intl.formatMessage({
            id: 'app.smart.images.uploadImages.upload.two',
            defaultMessage:
              'Se recomienda que la imagen tenga una resolución minima de 600px'
          })}
        </p>
        <p style={{ fontSize: 10, fontStyle: 'italic', margin: 0 }}>
          {intl.formatMessage({
            id: 'app.smart.images.uploadImages.upload.three',
            defaultMessage: 'El formato debe ser PNG/JPG/JPEG'
          })}
        </p>
      </Container>
      {errors?.[name] != null && (
        <RequiredMessage>{errors[name]?.message}</RequiredMessage>
      )}
      {isFileTooLarge && (
        <RequiredMessage>
          {intl.formatMessage({
            id: 'app.smart.images.uploadImages.upload.error.large',
            defaultMessage: 'La imagen sobrepasa el limite'
          })}
        </RequiredMessage>
      )}
    </>
  )
}
