import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../state/actions'
import { RootState } from '../../state/store'
import Dropdown from 'react-bootstrap/Dropdown'
import { useIntl } from 'react-intl'
import './style.css'
import { Link } from 'react-router-dom'

import { BsPersonCircle } from 'react-icons/bs'

const User = () => {
  const token = useSelector<RootState, any>((state) => state.userAuth)
  const userAuth = useSelector<RootState, StateResponse<UserLoggedIn>>(
    (state: RootState) => state.userAuth
  )
  const dispatch = useDispatch()
  const intl = useIntl()

  const handleLogout = () => {
    dispatch(logout(token.data.token))
  }
  const customLink = {
    textDecoration: 'none',
    background: 'none',
    color: 'black'
  }
  return (
    <div
      className={`avatar-container`}
      style={{
        gridColumnStart: userAuth.data?.Platform === 'MercadoShop' ? 5 : 'auto'
      }}
    >
      {/* <button className="avatar-button">
        <UserLogoSvg />
      </button> 
      <button className="avatar-button" onClick={handleLogout}>
        <LogoutLogoSvg />
      </button> */}
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          style={{ backgroundColor: '#fff', border: 'none', color: '#666666' }}
        >
          <BsPersonCircle size={30} style={{ marginRight: 8 }} />
          &nbsp;
          {userAuth.data?.name} {userAuth.data?.lastname}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Link
            style={customLink}
            to={
              userAuth.data?.Platform !== 'MercadoShop'
                ? '/dashboard/system/user-profile'
                : '/dashboardmshop/profile'
            }
          >
            <Dropdown.Item as="button">
              {intl.formatMessage(
                {
                  id: 'app.carrousel.user.dropdown.perfil'
                },
                { defaultMessage: 'Perfil' }
              )}
            </Dropdown.Item>
          </Link>
          {userAuth.data?.Platform !== 'MercadoShop' &&
            userAuth.data?.userType === 1 && (
              <Link style={customLink} to={'/dashboard/system/user-users'}>
                <Dropdown.Item as="button">
                  {intl.formatMessage({
                    id: 'app.submenu.user',
                    defaultMessage: 'Usuarios'
                  })}
                </Dropdown.Item>
              </Link>
            )}

          {userAuth.data?.Platform === 'MercadoShop' && (
            <>
              <Link
                style={customLink}
                to={'/dashboardmshop/mshop-subscriptions'}
              >
                <Dropdown.Item as="button">Planes</Dropdown.Item>
              </Link>
              <Link style={customLink} to={'/dashboardmshop/facturacion'}>
                <Dropdown.Item as="button">Facturación</Dropdown.Item>
              </Link>
            </>
          )}
          <Dropdown.Divider />
          <Dropdown.Item as="button" onClick={handleLogout}>
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
export default User
