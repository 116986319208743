import React from 'react'
import { useSelector } from 'react-redux'

import { MenuContainer } from '../Menu'
import MenuOptions from '../Menu/MenuOptions'
import { subCampainOptions } from '../../utils/secondMenuOptions'
import { RootState } from '../../state/store'

const SubMenuCampain = () => {
  const user = useSelector<RootState, any>((state: any) => state.userAuth)

  const handleAction = () => {
    console.log()
  }

  return (
    <>
      <MenuContainer isSubmenu={true}>
        <MenuOptions
          tag="BrainDW | Campaña"
          options={
            user.data.userType === 1
              ? subCampainOptions
              : subCampainOptions.filter(
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
                  (op) => op.protected === false
                )
          }
          onAction={handleAction}
        />
      </MenuContainer>
    </>
  )
}

export default SubMenuCampain
