/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useController } from 'react-hook-form'
import { ImCancelCircle } from 'react-icons/im'
import { IoImageOutline } from 'react-icons/io5'
import { useIntl } from 'react-intl'

import { FileUploadContainer, Thumb } from '../css/EditTemplateComponents'

const MAX_SIZE = 450000
const acceptedMimeTypes = ['jpeg', 'png', 'jpg']
interface FileI extends File {
  preview?: string | null
}
export const UploadImage = ({
  name,
  isUpdate,
  src = null,
  height,
  width
}: {
  name: string
  isUpdate: boolean
  src?: string | null
  height?: string
  width?: string
}) => {
  const [file, setFile] = useState<FileI | null | string>(src)

  // const [files, setFiles] = useState<any>(
  //   // props.isUpdate ?? false ? [props.src] : []
  // )
  const intl = useIntl()
  const {
    field: { onChange, ref, name: fieldName, value },
    formState: { errors }
  } = useController({ name })
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject
    // fileRejections
  } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },
    maxSize: MAX_SIZE,
    onDrop: (acceptedFiles) => {
      if (
        acceptedFiles.length &&
        acceptedMimeTypes.includes(acceptedFiles[0]?.type?.split('/')[1])
      ) {
        setFile(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0])
          })
        )
        onChange(acceptedFiles.length > 0 ? acceptedFiles[0] : null)
      }
    }
  })

  const thumbs = useMemo(() => {
    if (typeof file === 'string' || !file?.preview) {
      return null
    }
    return (
      <img
        key={file.name}
        src={file.preview}
        style={{
          width: width ?? '100%',
          height: height ?? 'auto',
          objectFit: 'contain'
        }}
        // style={img}
        // Revoke data uri after image is loaded
        onLoad={() => {
          if (file.preview) {
            URL.revokeObjectURL(file.preview)
          }
        }}
      />
    )
  }, [file, width, height])

  useEffect(() => {
    if (!isUpdate && (value === null || value === undefined)) {
      setFile(null)
    }
    if (value && !isUpdate && typeof value !== 'string') {
      setFile(
        Object.assign(value, {
          preview: URL.createObjectURL(value)
        })
      )
    }
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      if (typeof file !== 'string') {
        file?.preview && URL.revokeObjectURL(file.preview)
      }
    }
  }, [value, src])

  // validation large file if needs
  // const isFileTooLarge =
  //   fileRejections.length > 0 &&
  //   fileRejections[0].errors[0].code === 'file-too-large'

  if (src && typeof src === 'string') {
    return (
      <Thumb height={height} width={width}>
        <ImCancelCircle
          size={20}
          style={{
            position: 'absolute',
            top: -20,
            right: -20,
            cursor: 'pointer',
            fill: '#6529A1'
          }}
          onClick={() => {
            // files.forEach((file: any) => URL.revokeObjectURL(file.preview))
            setFile(null)
            onChange()
          }}
        />
        <img
          src={src}
          // style={img}
          // Revoke data uri after image is loaded
          style={{ height: '250px', objectFit: 'contain', width: '100%' }}
        />
      </Thumb>
    )
  }

  if (isUpdate && file && !value && src) {
    return (
      <Thumb height={height} width={width}>
        <ImCancelCircle
          size={20}
          style={{
            position: 'absolute',
            top: -20,
            right: -20,
            cursor: 'pointer',
            fill: '#6529A1'
          }}
          onClick={() => {
            // files.forEach((file: any) => URL.revokeObjectURL(file.preview))
            setFile(null)
            onChange()
          }}
        />
        <img
          src={src}
          // style={img}
          // Revoke data uri after image is loaded
          style={{ height: 'inherit' }}
        />
      </Thumb>
    )
  }

  if (file && typeof file !== 'string') {
    return (
      <Thumb height={height} width={width}>
        <ImCancelCircle
          size={20}
          style={{
            position: 'absolute',
            top: -20,
            right: -20,
            cursor: 'pointer',
            fill: '#6529A1'
          }}
          onClick={() => {
            if (file.preview) {
              URL.revokeObjectURL(file.preview)
            }
            setFile(null)
            onChange()
          }}
        />
        {thumbs}
      </Thumb>
    )
  }
  return (
    <>
      <FileUploadContainer
        {...getRootProps({
          className: 'dropzone',
          isDragActive,
          isFocused,
          isDragAccept,
          isDragReject
        })}
        height={height}
        width={width}
        isRequired={errors?.[name] != null}
      >
        <input {...getInputProps()} name={fieldName} ref={ref} />
        <IoImageOutline size={40} />
        <div>
          <p style={{ margin: '0' }}>
            {intl.formatMessage({
              id: 'app.emailmarketing.create.campaign.step.two.upload.image.drop-image',
              defaultMessage: 'Arrastra la imagen aca'
            })}
          </p>
          {/* <p style={{ fontSize: 10, fontStyle: 'italic', margin: 0 }}>
          La de tener un peso máximo de 500kb
        </p>
        <p style={{ fontSize: 10, fontStyle: 'italic', margin: 0 }}>
          Se recomienda que la imagen tenga una resolución minima de 600px
        </p> */}
          <p style={{ fontSize: 10, fontStyle: 'italic', margin: 0 }}>
            {intl.formatMessage({
              id: 'app.emailmarketing.create.campaign.step.two.upload.image.text.three',
              defaultMessage: 'El formato debe ser PNG/JPG/JPEG'
            })}
          </p>
        </div>
      </FileUploadContainer>
      {/* {errors?.[name] != null && (
        <RequiredMessage>{errors[name]?.message}</RequiredMessage>
      )}
      {isFileTooLarge && (
        <RequiredMessage>La imagen sobrepasa el limite</RequiredMessage>
      )} */}
    </>
  )
}
