/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable  @typescript-eslint/restrict-plus-operands */
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Controller, useForm } from 'react-hook-form'
import { BsLockFill, BsPersonCircle } from 'react-icons/bs'
import styled from 'styled-components'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { ShowAlert } from '../../Globals'
import { RootState } from '../../../state/store'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { Loading } from '../../Globals/Loading'
import { useClientContext } from '../../../contexts/ClientContext'
import { CountryStateCity } from '../../../utils/countryStateCity'
import MaskedInput from '../Images/imageBinds/maskInput'
// import { TooltipPanel } from '../../Globals/TooltipPanel'

const Button = styled.button`
  background-color: #00868b !important;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin: 10px 8px;
  width: 175px;
  min-height: 40px;
  color: #fff !important;
  &:hover {
    color: white !important;
  }
`

export const FormGroup = styled.div`
  position: relative;
  padding: 25px 0 0;
  margin-top: 10px;
`

export const FormField = styled.input`
  height: 38px;
  font-family: inherit;
  width: 100%;
  border: 0 !important;
  border-bottom: 1px solid #dbdbdb !important;
  outline: 0;
  font-size: 16px;
  color: #666 !important;
  padding: 7px 0;
  background: transparent !important;
  border-radius: 0 !important;
  transition: border-color 0.2s;
  &::placeholder {
    color: transparent;
  }
  &::placeholder-shown + label {
    font-size: 16px;
    cursor: text;
    top: 25px;
  }
  &:focus + label {
    color: #00868b;
  }
  &:focus {
    padding-bottom: 6px;
    border-bottom: 2px solid #00868b;
  }
`
export const TextAreaField = styled.textarea`
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #dbdbdb;
  outline: 0;
  font-size: 16px;
  color: #666;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  &::placeholder {
    color: transparent;
  }
  &::placeholder-shown + label {
    font-size: 16px;
    cursor: text;
    top: 25px;
  }
  &:focus ~ label {
    color: #00868b;
  }
  &:focus {
    padding-bottom: 6px;
    border-bottom: 2px solid #00868b;
  }
`
export const FormLabel = styled.label`
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: #666;
`
interface TabHeaderProps {
  enabled: boolean
  // tab: number
}

export const TabHeader = styled.div<TabHeaderProps>`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 90px;
`

export const TabHeaderTitle = styled.h5`
  margin: 0;
`
export const TextHeaders = styled.h5`
  color: #666666;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #dbdbdb;
  display: inline-block;
  padding: 6px 0px;
  margin-bottom: 20px;
`
interface FormDataChangePassword {
  oldPass: string
  newPass: string
  newPassConfirm: string
}
interface FormDataMshop {
  name: string
  lastname: string
  address: string
  cellphone: string
  city: string
  country: string
  postalCode: string
  state: string
  companyName: string
  cuit: string
  taxCondition: string
}

const ProfileMshopSchema = yup.object().shape({
  name: yup.string().required(),
  lastname: yup.string().required(),
  cellphone: yup.string().required(),
  companyName: yup.string().required(),
  cuit: yup.string().required().length(11),
  taxCondition: yup
    .mixed()
    .oneOf(['Inscripto', 'Consumidor Final', 'Monotributista'])
    .required(),
  country: yup.string().required(),
  state: yup.string().required(),
  postalCode: yup.string().required(),
  city: yup.string().required(),
  address: yup.string().required()
})

const FormDataChangePasswordSchema = yup.object().shape({
  oldPass: yup.string().required(),
  newPass: yup.string().required(),
  newPassConfirm: yup.string().required()
})

const ProfileMshopContainer = () => {
  const intl = useIntl()
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const [tabProfile, setTabProfile] = useState<boolean>(true)
  const [country, setCountry] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleCountrySelect = useCallback((key: string) => {
    setCountry(key)
    setValue('country', key)
  }, [])

  const MyAlert = (text: string, iconStr: number) => {
    ShowAlert({
      title: text,
      icon: iconStr == 1 ? 'success' : iconStr == 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: 3000,
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }
  const [userInfo, setUserInfo] = useState<any>({})
  const editableUserFields = [
    'name',
    'lastname',
    'address',
    'cellphone',
    'city',
    'country',
    'postalCode',
    'state',
    'companyName',
    'cuit',
    'taxCondition'
  ]

  const getUserInfo = useCallback(async () => {
    try {
      setLoading(true)
      const { data, headers } = await Service.get<any>('/Account/UserProfile', {
        headers: { Token: user.data.token }
      })
      if (headers.statuscode === '0') {
        if (!data.isActiveAppMshop) {
          dispatch(logout(user.data.token))
          return
        }
        setUserInfo(data)
        editableUserFields.forEach((key) => {
          if (key === 'country') {
            setCountry(data[key])
          }
          return setValue(key as keyof FormDataMshop, data[key])
        })
        setLoading(false)
      } else {
        setLoading(false)
        dispatch(logout(user.data.token))
      }
    } catch (error) {
      setLoading(false)

      MyAlert('Ocurrió un Error.', 2)
    }
  }, [user])

  const Alert = () => {
    ShowAlert({
      html: '<p> En caso de requerir Factura A:</p><p> Enviar constancia de inscripción en IVA y constancia de inscripción en ingresos brutos a administración@braindw.com, indicando razón social y CUIT en el asunto del email.</p>',
      showConfirmButton: true,
      closeButtonHtml: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      cancelButtonText: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      width: 500,
      timerProgressBar: true
    })
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState,
    control,
    setError
  } = useForm({
    resolver: async (data, context, options) => {
      if (tabProfile) {
        return await yupResolver(ProfileMshopSchema)(data, context, options)
      }
      return await yupResolver(FormDataChangePasswordSchema)(
        data,
        context,
        options
      )
    }
  })

  const HandleGoogleTokenRequest = useCallback((data) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      },
      width: 600
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        SavePassword(result.value, data)
      }
    })
  }, [])

  const SavePassword = (tokenGoogle: string, data: FormDataChangePassword) => {
    if (!selectedScript) return
    Service.post<any, any>(
      `/Account/UpdatePasswordUser?idHashScript=${selectedScript?.idHashScript}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          oldPassword: data.oldPass,
          password: data.newPass,
          retrypassword: data.newPassConfirm,
          userName: user.data.username,
          tokenGoogle,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((res) => {
        if (res.data?.StatusCode < 0 || res.data < 0) {
          if (res.data?.StatusCode == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          if (res.data?.StatusCode != -5 && res.data?.StatusCode != -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
          if (res.data == -2) {
            MyAlert(
              intl.formatMessage(
                { id: 'app.system.profile-form-pass-actual-bad' },
                { defaultMessage: 'La Contraseña Actual no es correcta.' }
              ),
              2
            )
          }
        } else {
          MyAlert(
            intl.formatMessage(
              { id: 'app.api.update.ok' },
              { defaultMessage: 'Se actualizaron con éxito los datos.' }
            ),
            1
          )
          dispatch(logout(user.data.token))
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
          return
        }
        if (error.response.data === -2) {
          MyAlert(
            intl.formatMessage(
              { id: 'app.system.profile-form-pass-actual-bad' },
              { defaultMessage: 'La Contraseña Actual no es correcta.' }
            ),
            2
          )
          return
        }
        if (error.response.data == -5) {
          MyAlert(
            intl.formatMessage(
              { id: 'app.token-invalido' },
              { defaultMessage: 'El token ingresado es inválido.' }
            ),
            3
          )
          return
        }
        MyAlert('API-ERROR: ' + `${error}`, 3)
      })
  }

  const SaveUserData = useCallback((data: FormDataMshop, userInfoProp) => {
    if (!userInfoProp && !userInfoProp.IdDashboardUser) return
    const payload = { ...data, IdDashboardUser: userInfoProp.IdDashboardUser }
    Service.post<any, any>(`/Account/UpdateProfile`, payload, {
      method: 'POST',
      headers: {
        token: user.data.token,
        'Content-Type': 'application/json',
        Accept: '*/*',
        credentials: 'include'
      }
    })
      .then((res) => {
        if (res.data?.StatusCode < 0 || res.data < 0) {
          if (res.data?.StatusCode == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          if (res.data?.StatusCode != -5 && res.data?.StatusCode != -6)
            MyAlert(
              'Ocurrió un Error al actualizar los datos.' + res.data.Message,
              2
            )
        } else {
          MyAlert(
            intl.formatMessage(
              { id: 'app.api.update.ok' },
              { defaultMessage: 'Se actualizaron con éxito los datos.' }
            ),
            1
          )
          if (res.data.Status !== 'Error') {
            navigate('/dashboardmshop/carruselesmshop')
          }
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }, [])

  const onSubmit = useCallback(
    (data) => {
      if (tabProfile) {
        return SaveUserData(data, userInfo)
      }
      if (data.newPassConfirm !== data.newPass) {
        setError('newPass', {
          type: 'custom',
          message: 'Las contraseñas no coinciden'
        })
        return
      }
      return HandleGoogleTokenRequest(data)
    },
    [tabProfile, userInfo]
  )

  useEffect(() => {
    getUserInfo()
  }, [])
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="container mg-t-0 mt-4"
        style={{ backgroundColor: 'white', paddingBottom: 24 }}
      >
        <div className="row" style={{ marginBottom: 24 }}>
          <TabHeader
            style={{ backgroundColor: tabProfile ? 'white' : '#E8E8ED' }}
            className="col d-flex col-sm-6"
            enabled={tabProfile}
            onClick={() => {
              reset({
                oldPass: '',
                newPass: '',
                newPassConfirm: '',
                ...userInfo
              })
              setTabProfile(true)
            }}
          >
            <BsPersonCircle color="#00868B" size="35px" />
            <TabHeaderTitle style={{ margin: 0 }}>Perfil</TabHeaderTitle>
          </TabHeader>
          <TabHeader
            style={{ backgroundColor: !tabProfile ? 'white' : '#E8E8ED' }}
            className="col d-flex col-sm-6"
            enabled={tabProfile}
            onClick={() => {
              reset()
              setTabProfile(false)
            }}
          >
            <BsLockFill color="#00868B" size="35px" />
            <TabHeaderTitle style={{ margin: 0 }}>
              <FormattedMessage
                id="app.system-tab-pass"
                defaultMessage="Cambiar contraseña"
              />
            </TabHeaderTitle>
          </TabHeader>
        </div>
        {!loading ? (
          tabProfile ? (
            <>
              <TextHeaders>Datos de contacto</TextHeaders>{' '}
              <div
                style={{
                  color: '#d32f2f',
                  fontSize: 12,
                  marginBottom: '12px'
                }}
              >
                * Campos obligatorios
              </div>
              <div className="row" style={{ marginBottom: 40 }}>
                <div className="col-sm-4">
                  <FormGroup>
                    <FormField
                      type="text"
                      id="name"
                      placeholder="Nombre"
                      {...register('name')}
                      style={{
                        borderBottom:
                          formState.errors?.name && '1px solid #d32f2f'
                      }}
                    />
                    <FormLabel
                      style={{ color: formState.errors?.name && '#d32f2f' }}
                    >
                      <FormattedMessage
                        id="app.system.profile-form-nombre"
                        defaultMessage="Nombre"
                      />
                      <span style={{ color: 'red' }}> *</span>
                    </FormLabel>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <FormGroup>
                    <FormField
                      type="text"
                      id="lastname"
                      placeholder="Apellido"
                      {...register('lastname')}
                      style={{
                        borderBottom:
                          formState.errors?.lastname && '1px solid #d32f2f'
                      }}
                    />
                    <FormLabel
                      style={{ color: formState.errors?.lastname && '#d32f2f' }}
                    >
                      <FormattedMessage
                        id="app.system.profile-form-apellido"
                        defaultMessage="Apellido"
                      />
                      <span style={{ color: 'red' }}> *</span>
                    </FormLabel>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <FormGroup>
                    <FormField
                      type="text"
                      id="cellphone"
                      placeholder="Celular"
                      {...register('cellphone')}
                      style={{
                        borderBottom:
                          formState.errors?.cellphone && '1px solid #d32f2f'
                      }}
                    />
                    <FormLabel
                      style={{
                        color: formState.errors?.cellphone && '#d32f2f'
                      }}
                    >
                      <FormattedMessage
                        id="app.system.profile-form-phone"
                        defaultMessage="Celular"
                      />
                      <span style={{ color: 'red' }}> *</span>
                    </FormLabel>
                  </FormGroup>
                </div>
              </div>
              <div className="row" style={{ marginBottom: 40 }}>
                <div className="col-sm-4">
                  <FormGroup>
                    <div
                      style={{
                        fontSize: 13,
                        color: '#666',
                        height: 38,
                        borderBottom: '1px solid #dbdbdb',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {userInfo.email}
                    </div>
                    <FormLabel>
                      <FormattedMessage
                        id="app.system.profile-form-email"
                        defaultMessage="Email"
                      />
                    </FormLabel>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <FormGroup>
                    <div
                      style={{
                        fontSize: 13,
                        color: '#666',
                        height: 38,
                        borderBottom: '1px solid #dbdbdb',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {userInfo.urlCompany}
                    </div>
                    <FormLabel>
                      <FormattedMessage
                        id="app.system.profile-form-url"
                        defaultMessage="Url de la tienda"
                      />
                    </FormLabel>
                  </FormGroup>
                </div>
              </div>
              <TextHeaders>Datos de Facturación</TextHeaders>
              <div className="row" style={{ marginBottom: 40 }}>
                <div className="col-sm-4">
                  <FormGroup>
                    <FormField
                      type="text"
                      id="companyName"
                      placeholder="Razón social"
                      {...register('companyName')}
                      style={{
                        borderBottom:
                          formState.errors?.companyName && '1px solid #d32f2f'
                      }}
                    />
                    <FormLabel
                      style={{
                        color: formState.errors?.companyName && '#d32f2f'
                      }}
                    >
                      <FormattedMessage
                        id="app.system.profile-form-companyName"
                        defaultMessage="Razón social"
                      />
                      <span style={{ color: 'red' }}> *</span>
                    </FormLabel>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <FormGroup>
                    <Controller
                      name="cuit"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <MaskedInput
                          // as={FormField}
                          // type="text"
                          // id="cuit"
                          defaultValue={value}
                          onChange={onChange}
                          mask={[{ mask: '00-00000000-0' }]}
                          placeholder="xx-xxxxxxxx-x"
                          // {...register('cuit')}
                          style={{
                            borderTop: 0,
                            borderRight: 0,
                            borderLeft: 0,
                            borderBottom: formState.errors?.cuit
                              ? '1px solid #d32f2f'
                              : '1px solid #dbdbdb',
                            height: 38,
                            fontFamily: 'inherit',
                            width: '100%',
                            outline: 0,
                            fontSize: '16px',
                            color: '#666',
                            padding: '7px 0',
                            background: 'transparent',
                            transition: 'border-color 0.2s'
                          }}
                        />
                      )}
                    />
                    <FormLabel
                      style={{
                        color: formState.errors?.cuit && '#d32f2f'
                      }}
                    >
                      CUIT
                      <span style={{ color: 'red' }}> *</span>
                    </FormLabel>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <FormGroup>
                    <Select
                      defaultValue={{
                        label: userInfo.taxCondition,
                        value: userInfo.taxCondition
                      }}
                      styles={{
                        singleValue: (prev) => ({
                          ...prev,
                          cursor: 'pointer',
                          fontSize: '13px'
                        }),
                        option: (prev) => ({
                          ...prev,
                          cursor: 'pointer',
                          fontSize: '13px'
                        }),
                        control: (prev) => ({
                          ...prev,
                          borderColor: formState.errors?.taxCondition
                            ? '#d32f2f'
                            : 'hsl(0, 0%, 80%)'
                        }),
                        placeholder: (prev) => ({
                          ...prev,
                          fontSize: 13
                        })
                      }}
                      placeholder="Seleccionar"
                      options={[
                        'Inscripto',
                        'Consumidor Final',
                        'Monotributista'
                      ].map((op) => ({ label: op, value: op }))}
                      onChange={(e) => {
                        if (!e) return
                        setValue('taxCondition', e.value)
                        if (e.value == 'Inscripto') {
                          Alert()
                        }
                      }}
                    />
                    <FormLabel>
                      <FormattedMessage
                        id="app.system.profile-form-provincia"
                        defaultMessage="Condición"
                      />
                      <span style={{ color: 'red' }}> *</span>
                    </FormLabel>
                  </FormGroup>
                </div>
              </div>
              <div className="row" style={{ marginBottom: 40 }}>
                <div className="col-sm-4">
                  <FormGroup>
                    <Select
                      options={Object.keys(CountryStateCity).map((key) => {
                        if (key === 'Argentina') {
                          return {
                            label: key,
                            value: key
                          }
                        }
                        return {
                          label: key,
                          value: key,
                          isDisabled: true
                        }
                      })}
                      defaultValue={{
                        label: userInfo.country,
                        value: userInfo.country
                      }}
                      styles={{
                        singleValue: (prev) => ({
                          ...prev,
                          cursor: 'pointer',
                          fontSize: '13px'
                        }),
                        option: (prev) => ({
                          ...prev,
                          cursor: 'pointer',
                          fontSize: '13px'
                        }),
                        control: (prev) => ({
                          ...prev,
                          borderColor: formState.errors?.country
                            ? '#d32f2f'
                            : 'hsl(0, 0%, 80%)'
                        }),
                        placeholder: (prev) => ({
                          ...prev,
                          fontSize: 13
                        })
                      }}
                      placeholder="Selecciona tu país"
                      isSearchable={false}
                      onChange={(e) => e && handleCountrySelect(e.value)}
                    />
                    <FormLabel>
                      <FormattedMessage
                        id="app.system.profile-form-pais"
                        defaultMessage="País"
                      />
                      <span style={{ color: 'red' }}> *</span>
                    </FormLabel>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <FormGroup>
                    <Select
                      isDisabled={!country}
                      defaultValue={{
                        label: userInfo.state,
                        value: userInfo.state
                      }}
                      styles={{
                        singleValue: (prev) => ({
                          ...prev,
                          cursor: 'pointer',
                          fontSize: '13px'
                        }),
                        option: (prev) => ({
                          ...prev,
                          cursor: 'pointer',
                          fontSize: '13px'
                        }),
                        control: (prev) => ({
                          ...prev,
                          borderColor: formState.errors?.state
                            ? '#d32f2f'
                            : 'hsl(0, 0%, 80%)'
                        }),
                        placeholder: (prev) => ({
                          ...prev,
                          fontSize: 13
                        })
                      }}
                      placeholder="Selecciona tu estado/provincia"
                      options={
                        country
                          ? Object.values(
                              CountryStateCity[
                                country as keyof typeof CountryStateCity
                              ]
                            ).map((val) => ({
                              label: val,
                              value: val
                            }))
                          : []
                      }
                      isSearchable={true}
                      onChange={(e) => {
                        if (!e) return
                        setValue('state', e.value)
                      }}
                    />
                    <FormLabel>
                      <FormattedMessage
                        id="app.system.profile-form-provincia"
                        defaultMessage="Estado"
                      />
                      <span style={{ color: 'red' }}> *</span>
                    </FormLabel>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <FormGroup>
                    <FormField
                      type="text"
                      id="city"
                      placeholder="Ciudad"
                      {...register('city')}
                      style={{
                        borderBottom:
                          formState.errors?.city && '1px solid #d32f2f'
                      }}
                    />
                    <FormLabel
                      style={{
                        color: formState.errors?.city && '#d32f2f'
                      }}
                    >
                      <FormattedMessage
                        id="app.system.profile-form-ciudad"
                        defaultMessage="Ciudad"
                      />
                      <span style={{ color: 'red' }}> *</span>
                    </FormLabel>
                  </FormGroup>
                </div>
              </div>
              <div className="row" style={{ marginBottom: 40 }}>
                <div className="col-sm-4">
                  <FormGroup>
                    <FormField
                      type="text"
                      id="Dirección"
                      placeholder="Dirección"
                      {...register('address')}
                    />
                    <FormLabel>
                      <FormattedMessage
                        id="app.system..profile-form.direccion"
                        defaultMessage="Dirección"
                      />
                    </FormLabel>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <FormGroup>
                    <FormField
                      type="text"
                      id="postalCode"
                      placeholder="CP"
                      {...register('postalCode')}
                    />
                    <FormLabel>
                      <FormattedMessage
                        id="app.system.profile-form-codigo-postal"
                        defaultMessage="CP"
                      />
                    </FormLabel>
                  </FormGroup>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row mb-4">
                <div className="col-sm-8 offset-sm-2">
                  <FormGroup>
                    <FormField
                      type="password"
                      id="oldPass"
                      placeholder="Contraseña Actual"
                      {...register('oldPass')}
                    />
                    <FormLabel>
                      <FormattedMessage
                        id="app.system.profile-form-pass-actual"
                        defaultMessage="Contraseña Actual"
                      />
                    </FormLabel>
                  </FormGroup>
                  {formState.errors?.newPass && (
                    <div
                      style={{
                        color: '#d32f2f',
                        fontSize: 12,
                        marginBlock: '12px'
                      }}
                    >
                      {formState.errors?.newPass.message}
                    </div>
                  )}
                  <FormGroup>
                    <FormField
                      type="password"
                      id="newPass"
                      placeholder="Contraseña Nueva"
                      {...register('newPass')}
                    />
                    <FormLabel>
                      <FormattedMessage
                        id="app.system.profile-form-pass-nueva"
                        defaultMessage="Contraseña Nueva"
                      />
                    </FormLabel>
                  </FormGroup>
                  <FormGroup>
                    <FormField
                      type="password"
                      id="newPassConfirm"
                      placeholder="Repetir Contraseña"
                      {...register('newPassConfirm')}
                    />
                    <FormLabel>
                      <FormattedMessage
                        id="app.system..profile-form.pass-repeat"
                        defaultMessage="Repetir Contraseña"
                      />
                    </FormLabel>
                  </FormGroup>
                </div>
              </div>
            </>
          )
        ) : (
          <Loading textLoading="Cargando información de Usuario..." />
        )}
        <div
          className="d-flex"
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Button
            className="btn"
            id="saveBrandPriority"
            style={{ textTransform: 'uppercase' }}
          >
            <FormattedMessage
              id={
                tabProfile
                  ? 'app.profile.mshop.btn.actualizar'
                  : 'app.profile.mshop.btn.update-pass'
              }
              defaultMessage={
                tabProfile ? 'ACTUALIZAR PERFIL' : 'Actualizar contraseña'
              }
            />
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ProfileMshopContainer
