import React from 'react'
import MenuContainer from '../../Menu/MenuContainer'
import MenuOptions from '../../Menu/MenuOptions'
import { SubMenuSmartImagesOptions } from '../../../utils/secondMenuOptions'

const SubMenuSmartImages = () => {
  const handleAction = () => {
    console.log()
  }

  return (
    <>
      <MenuContainer isSubmenu={true}>
        <MenuOptions
          tag="BrainDW | System"
          options={SubMenuSmartImagesOptions}
          onAction={handleAction}
        />
      </MenuContainer>
    </>
  )
}

export default SubMenuSmartImages
