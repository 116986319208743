/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect, useCallback } from 'react'
import packageJson from '../package.json'

const buildDateGreaterThan = (latestDate: number, currentDate: number) => {
  if (latestDate > currentDate) {
    return true
  } else {
    return false
  }
}

const withClearCache = <P extends object>(
  Component: React.ComponentType<P>
) => {
  const ClearCacheComponent = (props: any) => {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)
    const [version, setVersion] = useState()
    const getMetadata = useCallback(async () => {
      try {
        const response = fetch('/meta.json')
        const meta = await (await response).json()
        const latestVersionDate = meta.buildDateTime
        const currentVersionDate = packageJson.buildDateTime

        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        )
        if (shouldForceRefresh) {
          setIsLatestBuildDate(false)
          refreshCacheAndReload()
        } else {
          setIsLatestBuildDate(true)
        }
        setVersion(meta.version)
      } catch (error) {
        console.log(error)
      }
    }, [])

    useEffect(() => {
      getMetadata()
    }, [])

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name)
          }
        })
      }
      // delete browser cache and hard reload
      window.location.reload()
    }

    return (
      <React.Fragment>
        {isLatestBuildDate ? (
          <Component
            {...(props as P)}
            shouldRerender={true}
            version={version}
          />
        ) : null}
      </React.Fragment>
    )
  }

  return ClearCacheComponent
}

export default withClearCache
