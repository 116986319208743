/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react'
import {
  BsPencilSquare,
  BsDownload,
  BsPlusCircleFill,
  BsSearch,
  BsTrash
} from 'react-icons/bs'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useAtom } from 'jotai'
import DataTable, { Alignment } from 'react-data-table-component'
import { useIntl } from 'react-intl'

import AbmModal from './AbmTemplatesModal'
import './Automation.css'
import Service from '../../services'
import { useClientContext } from '../../contexts/ClientContext'
import { RootState } from '../../state/store'
import {
  idAtom,
  nameAtom,
  newTemplateAtom,
  rulesUseItAtom
} from '../../atoms/abmTemplates'
import { Loading } from '../Globals/Loading'
import {
  BtnTemplate,
  FirstRowButtonsContainer
} from './css/DashboardCampaignComponents'
import {
  InputSearchTableHeader,
  InputSearchTableHeaderContainer,
  subHeaderTableSelectStyle
} from '../Globals/table/css/TableComponents'
import { PaginationComponentMemo } from '../Globals/table/Pagination'

interface BocksI {
  id: number
  name: string
  date: string
  url: string
}

export const CDPButton = styled.button`
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  align-items: center;
  border: 1px solid #6529a1;
  background-color: transparent;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 600;
  color: #6529a1;
  padding: 5px 12px;
  border-radius: 8px;
  text-transform: capitalize;
`

const AbmTemplates = () => {
  const [loading] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)
  const [newTemplate, setNewTemplate] = useAtom<boolean>(newTemplateAtom)
  const [nameTemplate, setNameTemplate] = useAtom(nameAtom)
  const [idTemplate, setIdTemplate] = useAtom(idAtom)
  const [rulesUseIt, setRulesUseIt] = useAtom(rulesUseItAtom)
  const [searchTerm, setSearchTerm] = useState('')
  const [rows, setRows] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { selectedScript, selectedClient } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const [blocks, setBlocks] = useState<BocksI[]>([])
  const [filteredData, setFilteredData] = useState(blocks)
  const intl = useIntl()
  const columns = [
    { name: 'ID', selector: (row: any) => row.id, sortable: true },
    {
      name: intl.formatMessage({
        id: 'app.common.name',
        defaultMessage: 'NOMBRE'
      }),
      selector: (row: any) => row.name,
      sortable: true,
      center: true,
      id: 'name'
    },
    {
      name: intl.formatMessage({
        id: 'app.common.date',
        defaultMessage: 'Fecha'
      }),
      cell: (row: any) => row.date,
      sortable: true,
      center: true,
      id: 'date'
    },
    {
      name: intl.formatMessage({
        id: 'app.emailmarketing.ambtemplate.table.header.inuse',
        defaultMessage: 'En uso'
      }),
      selector: (row: any) =>
        row.rulesUseIt.length ? <span>SI</span> : <span>NO</span>,
      sortable: true,
      center: true,
      id: 'url'
    },
    {
      name: intl.formatMessage({
        id: 'app.common.actions',
        defaultMessage: 'Acciones'
      }),
      id: 'actions',
      center: true,
      cell: (row: any) => (
        <>
          <BsPencilSquare
            className="smart-icons"
            size={20}
            cursor={'pointer'}
            data-tip={'Update'}
            style={{ marginRight: 15 }}
            color="#00868B"
            onClick={() => {
              handleOpenModal()
              setNewTemplate(false)
              setNameTemplate(row.name)
              setIdTemplate(row.id)
              setRulesUseIt(row.rulesUseIt)
            }}
          />
          <BsDownload
            className="smart-icons"
            size={20}
            cursor={'pointer'}
            data-tip={'Download'}
            style={{ marginRight: 15 }}
            color="#00868B"
            onClick={async () => await downloadTemplate(row.id)}
          />
          <BsTrash
            className="smart-icons"
            size={20}
            cursor={'pointer'}
            data-tip={'Delete'}
            style={{ marginRight: 15 }}
            color="#F29718"
          />
        </>
      )
    }
  ]

  const handleOpenModal = () => {
    setModalVisible(true)
  }

  const handleCloseModal = () => {
    setModalVisible(false)
  }

  const GetAutomationTemplatesWithRules = () => {
    Service.get<any>(
      `/Automation/GetAutomationTemplatesWithRules?idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          'Content-Type': 'application/json',
          token: user.data.token
        }
      }
    )
      .then((response) => {
        setBlocks(
          response.data.map((item: any) => ({
            id: item.Id,
            name: item.Description,
            date: '25/09/2024',
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            url: item.urlTemplate.slice(0, 50) + '...',
            rulesUseIt: item.rulesUseIt
          }))
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const downloadTemplate = async (id: any) => {
    try {
      const response = await Service.get<any>(
        `/Automation/DownloadTemplate?idHashScript=${selectedScript?.idHashScript}&idTemplate=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            token: user.data.token
          }
        }
      )

      if (response.data !== null) {
        console.log('ResponseList', response)

        const contentType = response.headers['content-type']
        const extension = contentType.split('/')[1] || 'unknown'

        const blob = new Blob([response.data], { type: contentType })

        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Template_ABM_Brain.${extension}`)
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const subHeaderComponentMemo = useMemo(
    () => (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'end',
          backgroundColor: '#fff'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
          {intl.formatMessage({
            id: 'app.common.show',
            defaultMessage: 'Mostrar'
          })}{' '}
          <Select
            classNamePrefix="select"
            name="order"
            menuPosition="fixed"
            defaultValue={{ label: rowsPerPage.toString(), value: rowsPerPage }}
            isSearchable={false}
            options={[
              {
                label: '5',
                value: 5
              },
              {
                label: '10',
                value: 10
              },
              {
                label: '20',
                value: 20
              },
              {
                label: '50',
                value: 50
              },
              {
                label: '100',
                value: 100
              }
            ]}
            onChange={(e) => {
              if (e != null) {
                setRowsPerPage(e?.value)
              }
            }}
            styles={subHeaderTableSelectStyle}
          />{' '}
          {intl.formatMessage({
            id: 'app.common.results',
            defaultMessage: 'Resultados'
          })}
        </div>
        <div>
          <InputSearchTableHeaderContainer>
            <InputSearchTableHeader
              type="text"
              placeholder={intl.formatMessage({
                id: 'app.common.searchbyname',
                defaultMessage: 'Buscar por nombre'
              })}
              onChange={handleSearchChange}
              value={searchTerm}
              // onKeyDown={handleSubmitSearch}
            />
            <BsSearch
              size={18}
              color="#444"
              cursor={'pointer'}
              style={{ position: 'absolute', right: '12px' }}
              // onClick={handleSubmitSearch}
            />
          </InputSearchTableHeaderContainer>
        </div>
      </div>
    ),
    [rowsPerPage, searchTerm, page]
  )

  useEffect(() => {
    GetAutomationTemplatesWithRules()
  }, [])

  useEffect(() => {
    if (searchTerm) {
      const filtered = blocks.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setFilteredData(filtered)
    } else {
      setFilteredData(blocks)
    }
  }, [searchTerm, blocks])

  return (
    <div className="justify-content-evenly align-items-center mt-3 mx-5">
      <AbmModal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        GetAutomationTemplatesWithRules={GetAutomationTemplatesWithRules}
      />
      <div>
        <h2>
          {intl.formatMessage({
            id: 'app.emailmarketing.ambtemplate.title',
            defaultMessage: 'Listado de Plantillas'
          })}
        </h2>
      </div>
      <div className="row" style={{ marginBlock: '23px' }}>
        <FirstRowButtonsContainer>
          <BtnTemplate
            style={{ borderColor: '#6529a1', color: '#6529a1' }}
            onClick={() => {
              handleOpenModal()
              setNewTemplate(true)
            }}
          >
            <BsPlusCircleFill size={20} color="#6529A1" />
            {intl.formatMessage({
              id: 'app.emailmarketing.ambtemplate.upload.btn.text',
              defaultMessage: 'Subir Plantilla'
            })}
          </BtnTemplate>
        </FirstRowButtonsContainer>
      </div>

      <DataTable
        key={rowsPerPage}
        subHeaderAlign={Alignment.RIGHT}
        columns={columns}
        data={filteredData}
        progressPending={loading}
        progressComponent={<Loading textLoading={'Espere'} />}
        pagination
        paginationServer={false}
        paginationPerPage={rowsPerPage}
        paginationComponent={(props) => {
          return <PaginationComponentMemo {...props} />
        }}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
        paginationTotalRows={rows}
        subHeaderComponent={subHeaderComponentMemo}
        paginationResetDefaultPage={true}
        onChangePage={(e) => setPage(e)}
        customStyles={{
          cells: {
            style: {
              borderLeft: 'none !important',
              paddingBlock: 24
            }
          },
          headRow: {
            style: {
              justifyContent: 'space-between'
            }
          },
          subHeader: {
            style: {
              justifyContent: 'space-between',
              // marginBottom: '1rem',
              padding: '0.5rem 2rem !important'
            }
          },
          head: {
            style: {
              height: 90
            }
          },
          pagination: {
            style: {
              color: '#000',
              fontSize: '14px',
              minHeight: '56px',
              backgroundColor: 'transparent',
              // borderTopStyle: 'solid',
              borderTopWidth: '1px',
              borderTopColor: '#ddd'
            },
            pageButtonsStyle: {
              borderRadius: '50%',
              height: '40px',
              width: '40px',
              padding: '8px',
              margin: '5px',
              cursor: 'pointer',
              transition: '0.4s',
              color: '#fff',
              // fill: theme.button.default,
              backgroundColor: '#e9ecef',
              '&:disabled': {
                cursor: 'unset',
                color: '#2fad7e'
                // fill: theme.button.disabled,
              },
              '&:hover:not(:disabled)': {
                backgroundColor: '#2fad7e',
                color: '#fff'
              },
              '&:focus': {
                outline: 'none',
                backgroundColor: '#fff'
              }
            }
          }
        }}
        responsive
        sortServer
        subHeader
      />
    </div>
  )
}

export default AbmTemplates
