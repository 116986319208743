/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import debounce from 'lodash.debounce'
import { useSelector } from 'react-redux'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import { IoCalendarClearOutline, IoTimeOutline } from 'react-icons/io5'
import { IMask } from 'react-imask'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useIntl } from 'react-intl'
import { BsEmojiSmile } from 'react-icons/bs'

import './Campain.css'
import './ConfigCampain.css'
import Service from '../../services'
import { useClientContext } from '../../contexts/ClientContext'
import { RootState } from '../../state/store'
import {
  DayContainer,
  DayInput,
  DayLabel,
  DaySpan,
  ExpireContainer,
  InitTimeContainer,
  OptionsTemplateSelect,
  SingleValueTemplateSelect,
  styleSelectParams
} from './css/CampaignSetup'
import MaskedInput from '../Home/Images/imageBinds/maskInput'
import { TooltipPanel } from '../Globals/TooltipPanel'
import { ThumbContentInputError } from './css/EditTemplateComponents'
import { ShowAlert } from '../Globals'

const ConfigCampaign = ({
  campaign,
  loadingCampaign,
  setTemplatesParent,
  isActivityCampaign,
  listOfActivityCampaign
}: {
  campaign: any
  loadingCampaign: boolean
  setTemplatesParent: Dispatch<SetStateAction<string | null>>
  isActivityCampaign: boolean
  listOfActivityCampaign: string[]
}) => {
  const {
    register,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch
  } = useFormContext()
  const userData = useSelector<RootState, any>(
    (state: RootState) => state.userAuth
  )
  const intl = useIntl()
  const user = userData.data
  const [CDP, setCDP] = useState([])
  const { selectedScript } = useClientContext()
  const [templates, setTemplates] = useState([])
  const [templatesLoading, setTemplatesLoading] = useState<boolean>(false)
  const [emoji, setEmoji] = useState<boolean>(false)
  const { id } = useParams()
  const getDataWithDebounce = debounce((cdpFilterText, cb) => {
    getDataCDP(cdpFilterText, cb)
  }, 800)

  const initConfig = useCallback(() => {
    if (id) {
      return
    }
    setValue('campaignSetting.ForceSend', 0)
    setValue('campaignSetting.enableExpire', 0)
  }, [id])

  const getDataCDP = async (cdpFilterText: string, cb: any) => {
    try {
      const { data } = await Service.get<any>(
        `Automation/GetAllSegmentsCDP?idHashScript=${
          selectedScript?.idHashScript
        }&page=1&per_page=10&filterText=${cdpFilterText.toLowerCase()}&sortField=idSegment&sortDirection=asc`,
        {
          headers: {
            token: user.token
          }
        }
      )
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (data?.Entities) {
        return cb(
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          data.Entities.length
            ? data.Entities.map((cdp: any) => {
                return { value: cdp.IdSegment, label: cdp.AliasName }
              })
            : []
        )
      }
      // return cb([])
    } catch (error) {
      ShowAlert({
        title: 'Error',
        text: intl.formatMessage({
          id: 'app.common.modal.error',
          defaultMessage:
            'Ops, algo va mal. Refresca la pagina o vuelve en unos minutos'
        }),
        icon: 'warning',
        showConfirmButton: true,
        timer: 2500,
        closeButtonHtml: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        confirmButtonText: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        toast: true,
        width: 500
      })
      console.log(error)
    }
  }
  const getTemplates = useCallback(async () => {
    try {
      setTemplatesLoading(true)
      const { data } = await Service.get<any>(
        `Automation/GetAutomationTemplates?idHashScript=${selectedScript?.idHashScript}`,
        {
          headers: {
            token: user.token
          }
        }
      )

      if (data.length) {
        setTemplates(data)
        if (campaign?.IdAutomationTemplate) {
          const template: any = data.find(
            (temp: any) => temp.Id == campaign.IdAutomationTemplate
          )
          if (template) {
            setValue('sender.IdAutomationTemplate', {
              label: template.Description,
              value: template.Id,
              isDynamic: /\bcshtml\b/gi.test(template.urlTemplate)
            })
          }
        }
        setTemplatesParent(data)
      }
      setTemplatesLoading(false)
    } catch (error) {
      setTemplatesLoading(false)
      ShowAlert({
        title: 'Error',
        text: intl.formatMessage({
          id: 'app.common.modal.error',
          defaultMessage:
            'Ops, algo va mal. Refresca la pagina o vuelve en unos minutos'
        }),
        icon: 'warning',
        showConfirmButton: true,
        timer: 2500,
        closeButtonHtml: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        confirmButtonText: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        toast: true,
        width: 500
      })
      console.log(error)
    }
  }, [campaign])

  const TIME_MASK = [
    {
      mask: ''
    },
    {
      overwrite: true,
      autofix: true,
      mask: 'HH:MM',
      blocks: {
        HH: {
          mask: IMask.MaskedRange,
          placeholderChar: 'HH',
          from: 0,
          to: 23,
          maxLength: 2
        },
        MM: {
          mask: IMask.MaskedRange,
          placeholderChar: 'MM',
          from: 0,
          to: 59,
          maxLength: 2
        }
      }
    }
  ]

  const campaignNameSkuChange = useCallback((e, onChange) => {
    if (e.target.value.length >= 51) {
      return
    }
    onChange(e)
    let campaignStr: string = ''
    campaignStr = e.target.value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s/g, '_')
      .replace(/[^a-zA-Z0-9_-]/g, '')
      .toLocaleLowerCase()

    setValue('utms.utmCampaign', campaignStr)
  }, [])

  const generateActivityParamsDynamic = useCallback((name: string) => {
    const formattedName = name.replace(/[0-9]/g, '')
    switch (formattedName) {
      case 'ActivityUser': {
        const params = [
          {
            key: 'checkCountBeforeDays',
            value: '1',
            type: 'text',
            title: 'Dias a contemplar'
          },
          // {
          //   key: 'limitproduct',
          //   value: '6',
          //   type: 'text',
          //   title: 'Limite de productos'
          // },
          // {
          //   key: 'minstock',
          //   value: '1',
          //   type: 'text',
          //   title: 'Stock mínimo'
          // },
          {
            key: 'dontsendsameuserdays',
            value: '1',
            type: 'list',
            options: [
              {
                label: '0',
                value: '0'
              },
              {
                label: '1',
                value: '1'
              },

              {
                label: '2',
                value: '2'
              }
            ],
            title: 'Cada cuántos días se envía mail'
          }
        ]
        return params.map((block) => {
          if (block.type == 'list') {
            return (
              <>
                <label htmlFor={`params.${block.key}`} className="form-label">
                  {block.title}{' '}
                  <TooltipPanel
                    ObjectTooltip={
                      user.tooltips.find(
                        (t: any) =>
                          t.idTooltip ===
                          `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                      ) ??
                      `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                    }
                  />
                </label>
                <Controller
                  name={`params.${block.key}`}
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    formState: { errors }
                  }) => (
                    <Select
                      name={name}
                      isClearable
                      value={value ? { label: value, value } : null}
                      options={block.options}
                      placeholder={'Seleccionar'}
                      styles={styleSelectParams}
                      onChange={(e) => {
                        if (e == null) {
                          onChange(null)
                        }
                        if (e?.value != undefined) {
                          onChange(e?.value)
                        }
                      }}
                    />
                  )}
                />
              </>
            )
          }
          return (
            <>
              <label htmlFor={`params.${block.key}`} className="form-label">
                {block.title}{' '}
                <TooltipPanel
                  ObjectTooltip={
                    user.tooltips.find(
                      (t: any) =>
                        t.idTooltip ===
                        `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                    ) ??
                    `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                  }
                />
              </label>
              <input
                type="text"
                {...register(`params.${block.key}`)}
                style={{ marginBottom: 8, width: 300 }}
              />
            </>
          )
        })
      }
      case 'BaseUser': {
        const params = [
          {
            key: 'checkCountBeforeDaysLastClicked',
            value: '90',
            type: 'text',
            title: 'Días a contemplar del click'
          },
          {
            key: 'checkCountBeforeDaysLastOpened',
            value: null,
            type: 'text',
            title: 'Días a contemplar de la apertura '
          },
          {
            key: 'dontsendsameuserdays',
            value: '1',
            type: 'list',
            options: [
              {
                label: '0',
                value: '0'
              },
              {
                label: '1',
                value: '1'
              },

              {
                label: '2',
                value: '2'
              }
            ],
            title: 'Cada cuántos días se envía mail'
          }
        ]
        return params.map((block) => {
          if (block.type == 'list') {
            return (
              <>
                <label htmlFor={`params.${block.key}`} className="form-label">
                  {block.title}{' '}
                  <TooltipPanel
                    ObjectTooltip={
                      user.tooltips.find(
                        (t: any) =>
                          t.idTooltip ===
                          `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                      ) ??
                      `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                    }
                  />
                </label>
                <Controller
                  name={`params.${block.key}`}
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    formState: { errors }
                  }) => (
                    <Select
                      name={name}
                      isClearable
                      value={value ? { label: value, value } : null}
                      options={block.options}
                      placeholder={'Seleccionar'}
                      styles={styleSelectParams}
                      onChange={(e) => {
                        if (e == null) {
                          onChange(null)
                        }
                        if (e?.value != undefined) {
                          onChange(e?.value)
                        }
                      }}
                    />
                  )}
                />
              </>
            )
          }
          return (
            <>
              <label htmlFor={`params.${block.key}`} className="form-label">
                {block.title}{' '}
                <TooltipPanel
                  ObjectTooltip={
                    user.tooltips.find(
                      (t: any) =>
                        t.idTooltip ===
                        `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                    ) ??
                    `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                  }
                />
              </label>
              <input
                type="text"
                {...register(`params.${block.key}`)}
                style={{ marginBottom: 8, width: 300 }}
              />
            </>
          )
        })
      }
      case 'States': {
        const params = [
          {
            key: 'dontsendsameuserdays',
            value: '1',
            type: 'list',
            options: [
              {
                label: '0',
                value: '0'
              },
              {
                label: '1',
                value: '1'
              },

              {
                label: '2',
                value: '2'
              }
            ],
            title: 'Cada cuántos días se envía mail'
          }
        ]
        return params.map((block) => {
          if (block.type == 'list') {
            return (
              <>
                <label htmlFor={`params.${block.key}`} className="form-label">
                  {block.title}{' '}
                  <TooltipPanel
                    ObjectTooltip={
                      user.tooltips.find(
                        (t: any) =>
                          t.idTooltip ===
                          `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                      ) ??
                      `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                    }
                  />
                </label>
                <Controller
                  name={`params.${block.key}`}
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    formState: { errors }
                  }) => (
                    <Select
                      name={name}
                      isClearable
                      value={value ? { label: value, value } : null}
                      options={block.options}
                      placeholder={'Seleccionar'}
                      styles={styleSelectParams}
                      onChange={(e) => {
                        if (e == null) {
                          onChange(null)
                        }
                        if (e?.value != undefined) {
                          onChange(e?.value)
                        }
                      }}
                    />
                  )}
                />
              </>
            )
          }
          return (
            <>
              <label htmlFor={`params.${block.key}`} className="form-label">
                {block.title}{' '}
                <TooltipPanel
                  ObjectTooltip={
                    user.tooltips.find(
                      (t: any) =>
                        t.idTooltip ===
                        `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                    ) ??
                    `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                  }
                />
              </label>
              <input
                type="text"
                {...register(`params.${block.key}`)}
                style={{ marginBottom: 8, width: 300 }}
              />
            </>
          )
        })
      }
      case 'LastCategory': {
        const params = [
          {
            key: 'enabledspecifications',
            value: '0',
            type: 'boolean',
            title: 'Activar uso de especificaciones'
          },
          {
            key: 'checkCountBeforeDays',
            value: '120',
            type: 'list',
            options: [
              {
                label: '0',
                value: '0'
              },
              {
                label: '1',
                value: '1'
              },

              {
                label: '2',
                value: '2'
              },
              {
                label: '3',
                value: '3'
              }
            ],
            title: 'Dias a contemplar'
          },
          {
            key: 'dontsendsameuserdays',
            value: '1',
            type: 'list',
            options: [
              {
                label: '0',
                value: '0'
              },
              {
                label: '1',
                value: '1'
              },

              {
                label: '2',
                value: '2'
              }
            ],
            title: 'Cada cuántos días se envía mail'
          }
        ]
        return params.map((block) => {
          if (block.type == 'boolean') {
            return (
              <>
                <label htmlFor={`params.${block.key}`} className="form-label">
                  {block.title}{' '}
                  <TooltipPanel
                    ObjectTooltip={
                      user.tooltips.find(
                        (t: any) =>
                          t.idTooltip ===
                          `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                      ) ??
                      `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                    }
                  />
                </label>
                <Controller
                  control={control}
                  name={`params.${block.key}`}
                  render={({ field: { name, onChange, value } }) => (
                    <label
                      className="cfc_switch"
                      style={{ marginBottom: '8px' }}
                    >
                      <input
                        type="checkbox"
                        name={name}
                        checked={value == 1 ? true : false}
                        onChange={(e) => onChange(e.target.checked ? 1 : 0)}
                      />
                      <span className="cfc_slider"></span>
                    </label>
                  )}
                />
              </>
            )
          }
          if (block.type == 'list') {
            return (
              <>
                <label htmlFor={`params.${block.key}`} className="form-label">
                  {block.title}{' '}
                  <TooltipPanel
                    ObjectTooltip={
                      user.tooltips.find(
                        (t: any) =>
                          t.idTooltip ===
                          `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                      ) ??
                      `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                    }
                  />
                </label>
                <Controller
                  name={`params.${block.key}`}
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    formState: { errors }
                  }) => (
                    <Select
                      name={name}
                      isClearable
                      value={value ? { label: value, value } : null}
                      options={block.options}
                      placeholder={'Seleccionar'}
                      styles={styleSelectParams}
                      onChange={(e) => {
                        if (e == null) {
                          onChange(null)
                        }
                        if (e?.value != undefined) {
                          onChange(e?.value)
                        }
                      }}
                    />
                  )}
                />
              </>
            )
          }
          return (
            <>
              <label htmlFor={`params.${block.key}`} className="form-label">
                {block.title}{' '}
                <TooltipPanel
                  ObjectTooltip={
                    user.tooltips.find(
                      (t: any) =>
                        t.idTooltip ===
                        `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                    ) ??
                    `app.emailmarketing.create.campaign.step.one.label.params.${block.key}`
                  }
                />
              </label>
              <input
                type="text"
                {...register(`params.${block.key}`)}
                style={{ marginBottom: 8, width: 300 }}
              />
            </>
          )
        })
      }
      default:
        break
    }
  }, [])

  useEffect(() => {
    initConfig()
    getTemplates()
    getDataCDP('', setCDP)
  }, [])

  useEffect(() => {
    if (campaign && id) {
      if (templates?.length) {
        const template: any = templates.find(
          (temp: any) =>
            temp.Id == getValues('sender.IdAutomationTemplate')?.value
        )
        if (template) {
          setValue('sender.IdAutomationTemplate', {
            label: template.Description,
            value: template.Id,
            isDynamic: /\bcshtml\b/gi.test(template.urlTemplate)
          })
        }
      }
      setValue('campaignSetting.ForceSend', campaign.ForceSend)
      setValue('campaignSetting.enableExpire', campaign.StartDate ? 1 : 0)
      campaignNameSkuChange({ target: { value: campaign.Name } }, () => {})
      if (campaign?.HourRun) {
        setValue(
          'sender.timeStart',
          campaign.HourRun.replaceAll(':', '').slice(0, -2)
        )
      }
      if (campaign?.HourStop) {
        setValue(
          'sender.timeEnd',
          campaign.HourStop.replaceAll(':', '').slice(0, -2)
        )
      }
    } else {
      setValue('campaignSetting.ForceSend', 0)
      setValue('campaignSetting.enableExpire', 0)
    }
  }, [campaign, id])

  if (loadingCampaign) {
    return null
  }
  return (
    <div className="d-flex justify-content-end align-items-center mt-3 mr-5">
      <div className="regla_frame container mb-3">
        <div className="col_rt">
          {campaign && (
            <div style={{ marginBottom: '16px' }}>
              <div style={{ fontSize: 13, color: '#666' }}>
                {intl.formatMessage({
                  id: 'app.common.createdby',
                  defaultMessage: 'Creado por'
                })}{' '}
                <span style={{ fontSize: 15, color: '#444', fontWeight: 700 }}>
                  {campaign?.UserCreated?.UserName}
                </span>{' '}
                {intl.formatMessage({
                  id: 'app.common.the',
                  defaultMessage: 'el'
                })}{' '}
                {format(campaign?.DateAdded, 'dd/MM/yyyy HH:mm')}
              </div>
              {campaign?.UserUpdated?.UserName && (
                <div style={{ fontSize: 13, color: '#666' }}>
                  {intl.formatMessage({
                    id: 'app.common.lastupdatedby',
                    defaultMessage: ' Ultima vez actualizado por'
                  })}{' '}
                  <span
                    style={{ fontSize: 15, color: '#444', fontWeight: 700 }}
                  >
                    {campaign?.UserUpdated?.UserName}
                  </span>{' '}
                  {intl.formatMessage({
                    id: 'app.common.the',
                    defaultMessage: 'el'
                  })}{' '}
                  {format(campaign?.DateUpdated, 'dd/MM/yyyy HH:mm')}
                </div>
              )}
            </div>
          )}
          <h1>
            {intl.formatMessage({
              id: 'app.emailmarketing.create.campaign.step.one.title',
              defaultMessage: 'Configuración de Campaña'
            })}
          </h1>
          <div className="configuracion">
            <h2 className="mt-3">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.name',
                defaultMessage: 'Nombra esta campaña'
              })}{' '}
              <span style={{ color: '#DB164B' }}>*</span>
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      `app.emailmarketing.create.campaign.step.one.label.name`
                  ) ?? `app.emailmarketing.create.campaign.step.one.label.name`
                }
              />
            </h2>
            <div className="config_claim">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.name.text',
                defaultMessage: 'Sólo para uso interno. Ej.: “NewsletterAbril”'
              })}
            </div>
            <Controller
              name="campaignSetting.Name"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <input
                  value={value}
                  name={name}
                  disabled={campaign}
                  onChange={(e) => campaignNameSkuChange(e, onChange)}
                  className={`mt-2 ${
                    (errors.campaignSetting as any)?.Name ? 'invalid' : ''
                  }`}
                />
              )}
            />
            <div
              className="config_claim text-right mr-6"
              style={{
                color:
                  watch('campaignSetting.Name')?.length == 50
                    ? '#DB164B'
                    : '#666'
              }}
            >
              {50 - watch('campaignSetting.Name')?.length || 50}{' '}
              {intl.formatMessage({
                id: 'app.common.lastchars',
                defaultMessage: 'caracteres restantes'
              })}
            </div>
            <h2 className="mt-3">
              {intl.formatMessage({
                id: 'app.common.description',
                defaultMessage: 'Descripción'
              })}
              : <span style={{ color: '#DB164B' }}>*</span>
            </h2>
            <Controller
              name="campaignSetting.Description"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <input
                  value={value}
                  name={name}
                  onChange={onChange}
                  className={`mt-2 ${
                    (errors.campaignSetting as any)?.Description
                      ? 'invalid'
                      : ''
                  }`}
                />
              )}
            />
            <div
              className="config_claim text-right mr-6 mb-2"
              style={{
                color:
                  watch('campaignSetting.Description')?.length == 200
                    ? '#DB164B'
                    : '#666'
              }}
            >
              {200 - watch('campaignSetting.Description')?.length || 200}{' '}
              {intl.formatMessage({
                id: 'app.common.lastchars',
                defaultMessage: 'caracteres restantes'
              })}
            </div>
            <div className="d-flex gap-4">
              <div className="mb-3 w-25 mr-2">
                <label className="form-label">
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.group',
                    defaultMessage: 'Grupo'
                  })}{' '}
                  <span style={{ color: '#DB164B', marginLeft: 4 }}>*</span>
                  <TooltipPanel
                    ObjectTooltip={
                      user.tooltips.find(
                        (t: any) =>
                          t.idTooltip ===
                          `app.emailmarketing.create.campaign.step.one.label.group`
                      ) ??
                      `app.emailmarketing.create.campaign.step.one.label.group`
                    }
                  />
                </label>
                <Controller
                  name="campaignSetting.GroupSend"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    formState: { errors }
                  }) => (
                    <Select
                      name={name}
                      isClearable
                      value={value ? { label: value, value } : null}
                      options={Array(7)
                        .fill('')
                        .map((_, idx) => ({
                          label: idx + 2,
                          value: idx + 2
                        }))}
                      placeholder={'Seleccionar'}
                      styles={{
                        container: (prevStyles) => ({
                          ...prevStyles,
                          maxWidth: '620px',
                          marginTop: '0.5rem'
                        }),
                        control: (prevStyles) => ({
                          ...prevStyles,
                          minHeight: '30px',
                          border: `1px solid ${
                            (errors.campaignSetting as any)?.GroupSend != null
                              ? '#DB164B'
                              : '#ced4da !important'
                          }`,
                          height: '30px',
                          backgroundColor: '#F4F4F4 !important',
                          boxShadow: 'none'
                        }),
                        valueContainer: (prevStyles) => ({
                          ...prevStyles,
                          padding: '0px 8px'
                        }),
                        placeholder: (prevStyles) => ({
                          ...prevStyles,
                          fontSize: 14,
                          fontStyle:
                            (errors.campaignSetting as any)?.GroupSend != null
                              ? 'italic'
                              : 'normal',
                          color:
                            (errors.campaignSetting as any)?.GroupSend != null
                              ? '#DB164B'
                              : 'hsl(0, 0%, 50%)'
                        }),
                        input: (prevStyles) => ({
                          ...prevStyles,
                          marginBlock: 0,
                          paddingBlock: 0
                        }),
                        singleValue: (prevStyles) => ({
                          ...prevStyles,
                          color: '#666',
                          fontSize: 14
                        }),
                        dropdownIndicator: (prevStyles) => ({
                          ...prevStyles,
                          paddingBlock: 4
                        }),
                        clearIndicator: (prevStyles) => ({
                          ...prevStyles,
                          paddingBlock: 4
                        }),
                        option: (prevStyles, { isSelected }) => ({
                          ...prevStyles,
                          fontSize: 14,
                          ':hover': {
                            ...prevStyles[':hover'],
                            backgroundColor: isSelected
                              ? '#6529A1'
                              : 'rgb(101 41 161 / 70%)',
                            color: '#fff'
                          },
                          backgroundColor: isSelected ? '#6529A1' : '#fff',
                          ':active': {
                            ...prevStyles[':active'],
                            backgroundColor: '#6529A1',
                            color: '#fff'
                          }
                        })
                      }}
                      onChange={(e) => {
                        if (e == null) {
                          onChange(null)
                        }
                        if (e?.value != undefined) {
                          onChange(e?.value)
                        }
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {isActivityCampaign ? (
              generateActivityParamsDynamic(campaign.Name)
            ) : (
              <>
                <h2 className="mt-3">
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.segment',
                    defaultMessage: 'Seleccione un segmento'
                  })}{' '}
                  <span style={{ color: '#DB164B' }}>*</span>
                  <TooltipPanel
                    ObjectTooltip={
                      user.tooltips.find(
                        (t: any) =>
                          t.idTooltip ===
                          `app.emailmarketing.create.campaign.step.one.label.cdp`
                      ) ??
                      `app.emailmarketing.create.campaign.step.one.label.cdp`
                    }
                  />
                </h2>
                <div className="config_claim">
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.segment.text',
                    defaultMessage:
                      'Segmentar la campaña aumenta el % de click en un 14% aprox. contra campañas no segmentadas.'
                  })}
                </div>
                <Controller
                  name="campaignSetting.Segment"
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    formState: { errors }
                  }) => (
                    <AsyncSelect
                      cacheOptions
                      name={name}
                      isClearable
                      value={value}
                      defaultOptions={CDP}
                      loadOptions={getDataWithDebounce}
                      placeholder={intl.formatMessage({
                        id: 'app.emailmarketing.create.campaign.step.one.label.segment',
                        defaultMessage: 'Seleccione un segmento'
                      })}
                      styles={{
                        container: (prevStyles) => ({
                          ...prevStyles,
                          maxWidth: '620px',
                          marginTop: '0.5rem'
                        }),
                        control: (prevStyles) => ({
                          ...prevStyles,
                          minHeight: '30px',
                          border: `1px solid ${
                            (errors.campaignSetting as any)?.Segment != null
                              ? '#DB164B'
                              : '#ced4da !important'
                          }`,
                          height: '30px',
                          backgroundColor: '#F4F4F4 !important',
                          boxShadow: 'none'
                        }),
                        valueContainer: (prevStyles) => ({
                          ...prevStyles,
                          padding: '0px 8px'
                        }),
                        placeholder: (prevStyles) => ({
                          ...prevStyles,
                          fontSize: 14,
                          fontStyle:
                            (errors.campaignSetting as any)?.Segment != null
                              ? 'italic'
                              : 'normal',
                          color:
                            (errors.campaignSetting as any)?.Segment != null
                              ? '#DB164B'
                              : 'hsl(0, 0%, 50%)'
                        }),
                        input: (prevStyles) => ({
                          ...prevStyles,
                          marginBlock: 0,
                          paddingBlock: 0
                        }),
                        singleValue: (prevStyles) => ({
                          ...prevStyles,
                          color: '#666',
                          fontSize: 14
                        }),
                        dropdownIndicator: (prevStyles) => ({
                          ...prevStyles,
                          paddingBlock: 4
                        }),
                        clearIndicator: (prevStyles) => ({
                          ...prevStyles,
                          paddingBlock: 4
                        }),
                        option: (prevStyles, { isSelected }) => ({
                          ...prevStyles,
                          fontSize: 14,
                          ':hover': {
                            ...prevStyles[':hover'],
                            backgroundColor: isSelected
                              ? '#6529A1'
                              : 'rgb(101 41 161 / 70%)',
                            color: '#fff'
                          },
                          backgroundColor: isSelected ? '#6529A1' : '#fff',
                          ':active': {
                            ...prevStyles[':active'],
                            backgroundColor: '#6529A1',
                            color: '#fff'
                          }
                        })
                      }}
                      onChange={(e: any) => {
                        if (e == null) {
                          onChange(null)
                        }
                        if (e?.value != undefined) {
                          onChange(e)
                        }
                      }}
                    />
                  )}
                />
              </>
            )}
            <h2 className="mt-3">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.template',
                defaultMessage: 'Seleccione una plantilla'
              })}{' '}
              <span style={{ color: '#DB164B' }}>*</span>
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      `app.emailmarketing.create.campaign.step.one.label.template`
                  ) ??
                  `app.emailmarketing.create.campaign.step.one.label.template`
                }
              />
            </h2>
            <div className="config_claim">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.template.text',
                defaultMessage: 'plantilla de correo utilizada'
              })}
            </div>
            <Controller
              name="sender.IdAutomationTemplate"
              control={control}
              render={({
                field: { onChange, value, name },
                formState: { errors }
              }) => (
                <AsyncSelect
                  cacheOptions
                  name={name}
                  isClearable
                  value={value}
                  isLoading={templatesLoading}
                  components={{
                    SingleValue: SingleValueTemplateSelect,
                    Option: OptionsTemplateSelect
                  }}
                  // defaultValue={value}
                  defaultOptions={
                    templates.length
                      ? templates.map((temp: any) => ({
                          label: temp.Description,
                          value: temp.Id,
                          isDynamic: /\bcshtml\b/gi.test(temp.urlTemplate)
                        }))
                      : []
                  }
                  // loadOptions={getDataWithDebounce}
                  placeholder={intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.template',
                    defaultMessage: 'Seleccione una plantilla'
                  })}
                  styles={{
                    container: (prevStyles) => ({
                      ...prevStyles,
                      maxWidth: '620px',
                      marginTop: '0.5rem'
                    }),
                    control: (prevStyles) => ({
                      ...prevStyles,
                      minHeight: '30px',
                      border: `1px solid ${
                        (errors.sender as any)?.IdAutomationTemplate != null
                          ? '#DB164B'
                          : '#ced4da !important'
                      }`,
                      height: '30px',
                      backgroundColor: '#F4F4F4 !important',
                      boxShadow: 'none'
                    }),
                    valueContainer: (prevStyles) => ({
                      ...prevStyles,
                      padding: '0px 8px'
                    }),
                    placeholder: (prevStyles) => ({
                      ...prevStyles,
                      fontSize: 14,
                      fontStyle:
                        (errors.sender as any)?.IdAutomationTemplate != null
                          ? 'italic'
                          : 'normal',
                      color:
                        (errors.sender as any)?.IdAutomationTemplate != null
                          ? '#DB164B'
                          : 'hsl(0, 0%, 50%)'
                    }),
                    input: (prevStyles) => ({
                      ...prevStyles,
                      marginBlock: 0,
                      paddingBlock: 0
                    }),
                    singleValue: (prevStyles) => ({
                      ...prevStyles,
                      justifyContent: 'space-between',
                      display: 'flex',
                      color: '#666',
                      fontSize: 14
                    }),
                    dropdownIndicator: (prevStyles) => ({
                      ...prevStyles,
                      paddingBlock: 4
                    }),
                    clearIndicator: (prevStyles) => ({
                      ...prevStyles,
                      paddingBlock: 4
                    }),
                    option: (prevStyles, { isSelected }) => ({
                      ...prevStyles,
                      fontSize: 14,
                      ':hover': {
                        ...prevStyles[':hover'],
                        backgroundColor: isSelected
                          ? '#6529A1'
                          : 'rgb(101 41 161 / 70%)',
                        color: '#fff'
                      },
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: isSelected ? '#6529A1' : '#fff',
                      ':active': {
                        ...prevStyles[':active'],
                        backgroundColor: '#6529A1',
                        color: '#fff'
                      }
                    })
                  }}
                  onChange={(e: any) => {
                    if (e == null) {
                      onChange(null)
                    }
                    if (e?.value != undefined) {
                      onChange(e)
                    }
                  }}
                />
              )}
            />
            <h2 className="mt-3">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.dailyinterval.title',
                defaultMessage: 'Intervalo de envío diario'
              })}{' '}
              <span style={{ color: '#DB164B' }}>*</span>
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      `app.emailmarketing.create.campaign.step.one.label.dailySend`
                  ) ??
                  `app.emailmarketing.create.campaign.step.one.label.dailySend`
                }
              />
            </h2>
            {(errors.sender as any)?.timeEnd ||
            (errors.sender as any)?.timeStart ? (
              <ThumbContentInputError>
                {intl.formatMessage({
                  id: 'app.emailmarketing.create.campaign.step.one.label.dailyinterval.error',
                  defaultMessage: 'hora incorrecta, el formato debe ser HH:mm'
                })}
              </ThumbContentInputError>
            ) : null}
            <InitTimeContainer className="">
              <span className="">
                {intl.formatMessage({
                  id: 'app.emailmarketing.create.campaign.step.one.label.dailyinterval.init',
                  defaultMessage: 'hora de inicio'
                })}
                :
              </span>
              <Controller
                name="sender.timeStart"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => (
                  <MaskedInput
                    defaultValue={value}
                    onChange={onChange}
                    mask={TIME_MASK}
                    placeholder="HH:MM"
                    style={{
                      border: `1px solid ${error ? '#db164b' : '#ccc'}`,
                      borderRadius: '5px',
                      padding: '2px 10px',
                      margin: '0px 0',
                      color: '#37393a',
                      width: '72px',
                      gridArea: '1 / 5 / 2 / 6'
                    }}
                  />
                )}
              />
              <IoTimeOutline size={25} color="#00868b" />
              <span className="">
                {intl.formatMessage({
                  id: 'app.emailmarketing.create.campaign.step.one.label.dailyinterval.end',
                  defaultMessage: 'hasta'
                })}
                :
              </span>
              <Controller
                name="sender.timeEnd"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => (
                  <MaskedInput
                    defaultValue={value}
                    onChange={onChange}
                    mask={TIME_MASK}
                    placeholder="HH:MM"
                    style={{
                      border: `1px solid ${error ? '#db164b' : '#ccc'}`,
                      borderRadius: '5px',
                      padding: '2px 10px',
                      margin: '0px 0',
                      color: '#37393a',
                      width: '72px',
                      gridArea: '1 / 5 / 2 / 6'
                    }}
                  />
                )}
              />
              <IoTimeOutline size={25} color="#00868b" />
            </InitTimeContainer>

            <DayContainer>
              <DayLabel>
                <Controller
                  control={control}
                  name="campaignSetting.daysActive.Monday"
                  render={({ field: { name, onChange, value } }) => (
                    <DayInput
                      type="checkbox"
                      name={name}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />

                <DaySpan>
                  <IoCalendarClearOutline size={20} />{' '}
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.day.monday',
                    defaultMessage: 'Lunes'
                  })}
                </DaySpan>
              </DayLabel>
              <DayLabel>
                <Controller
                  control={control}
                  name="campaignSetting.daysActive.Tuesday"
                  render={({ field: { name, onChange, value } }) => (
                    <DayInput
                      type="checkbox"
                      name={name}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />
                <DaySpan>
                  <IoCalendarClearOutline size={20} />
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.day.tuesday',
                    defaultMessage: 'Martes'
                  })}
                </DaySpan>
              </DayLabel>
              <DayLabel>
                <Controller
                  control={control}
                  name="campaignSetting.daysActive.Wednesday"
                  render={({ field: { name, onChange, value } }) => (
                    <DayInput
                      type="checkbox"
                      name={name}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />
                <DaySpan>
                  <IoCalendarClearOutline size={20} />
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.day.wednesday',
                    defaultMessage: 'Miércoles'
                  })}
                </DaySpan>
              </DayLabel>
              <DayLabel>
                <Controller
                  control={control}
                  name="campaignSetting.daysActive.Thursday"
                  render={({ field: { name, onChange, value } }) => (
                    <DayInput
                      type="checkbox"
                      name={name}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />
                <DaySpan>
                  <IoCalendarClearOutline size={20} />
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.day.thursday',
                    defaultMessage: 'Jueves'
                  })}
                </DaySpan>
              </DayLabel>
              <DayLabel>
                <Controller
                  control={control}
                  name="campaignSetting.daysActive.Friday"
                  render={({ field: { name, onChange, value } }) => (
                    <DayInput
                      type="checkbox"
                      name={name}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />
                <DaySpan>
                  <IoCalendarClearOutline size={20} />
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.day.friday',
                    defaultMessage: 'Viernes'
                  })}
                </DaySpan>
              </DayLabel>
              <DayLabel>
                <Controller
                  control={control}
                  name="campaignSetting.daysActive.Saturday"
                  render={({ field: { name, onChange, value } }) => (
                    <DayInput
                      type="checkbox"
                      name={name}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />
                <DaySpan>
                  <IoCalendarClearOutline size={20} />
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.day.saturday',
                    defaultMessage: 'Sábado'
                  })}
                </DaySpan>
              </DayLabel>
              <DayLabel>
                <Controller
                  control={control}
                  name="campaignSetting.daysActive.Sunday"
                  render={({ field: { name, onChange, value } }) => (
                    <DayInput
                      type="checkbox"
                      name={name}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />
                <DaySpan>
                  <IoCalendarClearOutline size={20} />
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.day.sunday',
                    defaultMessage: 'Domingo'
                  })}
                </DaySpan>
              </DayLabel>
            </DayContainer>
            <ExpireContainer>
              {(errors.sender as any)?.startDate ||
              (errors.sender as any)?.endDate ||
              (errors.sender as any)?.startDateHour ||
              (errors.sender as any)?.endDateHour ? (
                <ThumbContentInputError
                  style={{
                    gridArea: '1 / 1 / 1 / 6'
                  }}
                >
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.expire.error',
                    defaultMessage: 'fecha/hora de expiración incorrecta.'
                  })}
                </ThumbContentInputError>
              ) : null}
              <div className="d-flex align-items-center gap-3">
                <label className="form-label col-form-label">
                  {intl.formatMessage({
                    id: 'app.emailmarketing.create.campaign.step.one.label.expire',
                    defaultMessage: 'Vencimiento'
                  })}
                  <TooltipPanel
                    ObjectTooltip={
                      user.tooltips.find(
                        (t: any) =>
                          t.idTooltip ===
                          `app.emailmarketing.create.campaign.step.one.label.expire`
                      ) ??
                      `app.emailmarketing.create.campaign.step.one.label.expire`
                    }
                  />
                </label>
                <Controller
                  name="campaignSetting.enableExpire"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <label className="cfc_switch">
                      <input
                        type="checkbox"
                        name={name}
                        checked={value == 1 ? true : false}
                        onChange={(e) => onChange(e.target.checked ? 1 : 0)}
                      />
                      <span className="cfc_slider"></span>
                    </label>
                  )}
                />
              </div>
              {watch('campaignSetting.enableExpire') == 1 ? (
                <>
                  <div className="d-flex align-items-center gap-3">
                    <label htmlFor="startDate" className="form-label mb-0">
                      {intl.formatMessage({
                        id: 'app.emailmarketing.create.campaign.step.one.label.expire.from',
                        defaultMessage: 'Desde'
                      })}
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="startDate"
                      {...register('sender.startDate')}
                      style={{
                        width: 'unset',
                        border: `1px solid ${
                          (errors.sender as any)?.startDate ? '#db164b' : '#ccc'
                        }`
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <Controller
                      name="sender.startDateHour"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error }
                      }) => (
                        <MaskedInput
                          defaultValue={value}
                          onChange={onChange}
                          mask={TIME_MASK}
                          placeholder="HH:MM"
                          style={{
                            border: `1px solid ${error ? '#db164b' : '#ccc'}`,

                            borderRadius: '5px',
                            padding: '2px 10px',
                            margin: '0px 0',
                            color: '#37393a',
                            width: '72px',

                            gridArea: '1 / 5 / 2 / 6'
                          }}
                        />
                      )}
                    />
                    <IoTimeOutline size={25} color="#00868b" />
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <label htmlFor="endDate" className="form-label mb-0">
                      {intl.formatMessage({
                        id: 'app.emailmarketing.create.campaign.step.one.label.expire.to',
                        defaultMessage: 'Hasta'
                      })}
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="endDate"
                      {...register('sender.endDate')}
                      style={{
                        width: 'unset',
                        border: `1px solid ${
                          (errors.sender as any)?.endDate ? '#db164b' : '#ccc'
                        }`
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <Controller
                      name="sender.endDateHour"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error }
                      }) => (
                        <MaskedInput
                          defaultValue={value}
                          onChange={onChange}
                          mask={TIME_MASK}
                          placeholder="HH:MM"
                          style={{
                            border: `1px solid ${error ? '#db164b' : '#ccc'}`,

                            borderRadius: '5px',
                            padding: '2px 10px',
                            margin: '0px 0',
                            color: '#37393a',
                            width: '72px',

                            gridArea: '1 / 5 / 2 / 6'
                          }}
                        />
                      )}
                    />
                    <IoTimeOutline size={25} color="#00868b" />
                  </div>
                </>
              ) : null}
            </ExpireContainer>
          </div>
          <div className="configuracion mt-6">
            <h1 className="hor_line">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.email.title',
                defaultMessage: 'Configuración de E-mail'
              })}
            </h1>
            <h2 className="mt-3">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.email.subject',
                defaultMessage: 'Asunto'
              })}
              : <span style={{ color: '#DB164B' }}>*</span>
            </h2>
            <div className="config_claim">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.email.subject.text',
                defaultMessage:
                  'Un buen asunto incrementa el % de apertura. No te olvides de personalizarlo.'
              })}
            </div>
            <Controller
              name="sender.subject"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div style={{ position: 'relative', width: 620 }}>
                  <input
                    onChange={onChange}
                    value={value}
                    className={`mt-2 ${
                      (errors.sender as any)?.subject ? 'invalid' : ''
                    }`}
                  />
                  <BsEmojiSmile
                    size={20}
                    style={{
                      position: 'absolute',
                      top: '13px',
                      right: '12px',
                      cursor: 'pointer'
                    }}
                    onClick={() => setEmoji(!emoji)}
                  />
                  {emoji && (
                    <div style={{ position: 'absolute', left: '620px' }}>
                      <Picker
                        data={data}
                        locale="es"
                        onEmojiSelect={(obj: any) => {
                          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                          setValue('sender.subject', value + obj.native)
                          setEmoji(false)
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            />
            <h2 className="mt-3">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.email.preheader',
                defaultMessage: 'Vista preliminar del texto'
              })}
              : <span style={{ color: '#DB164B' }}>*</span>
            </h2>
            <div className="config_claim">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.email.preheader.text',
                defaultMessage:
                  'Este texto aparece en la bandeja de entrada después del asunto'
              })}
            </div>
            <input
              {...register('sender.preHeaderText')}
              placeholder=""
              className={`mt-2 ${
                (errors.sender as any)?.preHeaderText ? 'invalid' : ''
              }`}
            />
            <div className="config_claim text-right mr-6">
              {200 - watch('sender.preHeaderText')?.length || 200}{' '}
              {intl.formatMessage({
                id: 'app.common.lastchars',
                defaultMessage: 'caracteres restantes'
              })}
            </div>
            <h2 className="mt-3">
              {intl.formatMessage({
                id: 'app.common.of',
                defaultMessage: 'De'
              })}
              :
            </h2>

            <span className="mr-2">
              {intl.formatMessage({
                id: 'app.common.mail',
                defaultMessage: 'E-mail'
              })}
              :
            </span>
            <input
              {...register('sender.senderEmail')}
              placeholder="hola@nombre.com"
              className={`mt-2 tres ${
                (errors.sender as any)?.senderEmail ? 'invalid' : ''
              }`}
            />

            <span className="ml-2 mr-2">
              {intl.formatMessage({
                id: 'app.common.name',
                defaultMessage: 'Nombre'
              })}
              :
            </span>
            <input
              {...register('sender.senderName')}
              placeholder="NombreEmpresa"
              className={`mt-2 tres ${
                (errors.sender as any)?.senderName ? 'invalid' : ''
              }`}
            />

            <h2 className="mt-3">
              {' '}
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.utm.title',
                defaultMessage: 'Campos UTM'
              })}
              :
            </h2>

            <div style={{ display: 'flex', width: '100%', columnGap: '0.5em' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  columnGap: '0.5em',
                  justifyContent: 'center',
                  marginTop: '0.5em',
                  // flexBasis: '30%',
                  rowGap: '0.5em',
                  width: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    columnGap: '0.5em',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <span className="" style={{ width: '80px' }}>
                    {intl.formatMessage({
                      id: 'app.common.campaign',
                      defaultMessage: 'Campaña'
                    })}
                    :
                  </span>
                  <div
                    style={{ color: '#666', fontSize: '15px', fontWeight: 500 }}
                  >
                    {watch('utms.utmCampaign')}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    columnGap: '0.5em',
                    alignItems: 'center'
                  }}
                >
                  <span className="" style={{ width: '80px' }}>
                    {intl.formatMessage({
                      id: 'app.emailmarketing.create.campaign.step.one.label.utm.source',
                      defaultMessage: 'Campaña'
                    })}
                    :
                  </span>
                  <div
                    style={{ color: '#666', fontSize: '15px', fontWeight: 500 }}
                  >
                    BrainDW
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    columnGap: '0.5em',
                    alignItems: 'center'
                  }}
                >
                  <span className="" style={{ width: '80px' }}>
                    {intl.formatMessage({
                      id: 'app.emailmarketing.create.campaign.step.one.label.utm.environment',
                      defaultMessage: 'Medio'
                    })}
                    :
                  </span>
                  <div
                    style={{ color: '#666', fontSize: '15px', fontWeight: 500 }}
                  >
                    Email
                  </div>
                </div>
              </div>
            </div>
            <h2 className="mt-3">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.utm.emails',
                defaultMessage: 'Email Testigos'
              })}
              :
              <TooltipPanel
                ObjectTooltip={
                  user.tooltips.find(
                    (t: any) =>
                      t.idTooltip ===
                      `app.emailmarketing.create.campaign.step.one.label.emailcc`
                  ) ??
                  `app.emailmarketing.create.campaign.step.one.label.emailcc`
                }
              />
            </h2>
            <div className="config_claim">
              {intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.one.label.utm.emails.text',
                defaultMessage:
                  'Datos del emisor (opcional). Si deja esta opción vacía se enviará con los datos de la configuración.'
              })}
            </div>
            <span className="mr-2">
              {intl.formatMessage({
                id: 'app.common.mails',
                defaultMessage: 'E-mails'
              })}
              :
            </span>
            <input
              {...register('sender.cc')}
              placeholder="hola@nombre.com,hola2@nombre.com,hola3@nombre.com"
              className={`mt-2 ${(errors.sender as any)?.cc ? 'invalid' : ''}`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfigCampaign
