import { useCallback } from 'react'
import { useController } from 'react-hook-form'
import Select from 'react-select'
import { Modal } from 'react-bootstrap'
import { ImCancelCircle } from 'react-icons/im'
import { useIntl } from 'react-intl'

import { monthsES, monthsBR, monthsEN } from '../../../utils/dateUtils'
import {
  ImageGallery,
  ImageGalleryContainer,
  ImageListPopContainer
} from '../css/CampaignSetup'
import {
  ModalGalleryFilterContainer,
  modalGalleryFilterSelectStyle,
  ModalGalleryTitle
} from '../css/EditTemplateComponents'
import { LoadingMetrics } from '../css/CampaignCreateContainerComponents'

interface GalleryFilterI {
  year?: string | number
  month?: string | number
  day?: string
}

export const Gallery = ({
  open,
  gallery,
  setOpenGallery,
  name,
  title,
  filter,
  setFilter,
  loading,
  handleGalleryFilter
}: {
  open: boolean
  gallery: any[]
  setOpenGallery: any
  name: string
  title: string
  filter: GalleryFilterI
  setFilter: any
  loading: boolean
  handleGalleryFilter: any
}) => {
  if (!open) {
    return null
  }
  const {
    field: { onChange }
    // formState: { errors }
  } = useController({ name })
  const intl = useIntl()
  const selectImage = useCallback((url: string) => {
    onChange(url)
  }, [])

  const getMonths = useCallback((locale: string) => {
    switch (locale) {
      case 'es':
        return monthsES
      case 'en':
        return monthsEN
      case 'br':
        return monthsBR
      default:
        return monthsES
    }
  }, [])

  const date = new Date()
  // days if in any moment need
  // const daysOptions = useCallback((year, month) => {
  //   try {
  //     const days = getDaysInMonth(year, month)
  //     return Array.from(Array(days).keys()).map((_, idx) => ({
  //       label: idx + 1,
  //       value: idx + 1
  //     }))
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }, [])

  const yearsDate = [date.getFullYear() - 1, date.getFullYear()]
  return (
    <Modal
      show={open}
      onHide={() => setOpenGallery(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ImageListPopContainer>
        <ImCancelCircle
          size={20}
          style={{
            position: 'absolute',
            top: 5,
            right: 5,
            cursor: 'pointer',
            fill: '#6529A1'
          }}
          onClick={() => {
            setOpenGallery(null)
          }}
        />
        <ModalGalleryTitle>
          {intl.formatMessage({
            id: 'app.emailmarketing.create.campaign.step.two.gallery.title',
            defaultMessage: 'Galería de imágenes para'
          })}{' '}
          {title}
        </ModalGalleryTitle>
        <ModalGalleryFilterContainer>
          <div style={{ color: '#666' }}>
            {intl.formatMessage({
              id: 'app.emailmarketing.create.campaign.step.two.gallery.filtertitle',
              defaultMessage: 'Filtrar por fecha'
            })}
          </div>
          <div style={{ display: 'flex', columnGap: 4 }}>
            <Select
              options={yearsDate.map((e: any) => ({
                label: e,
                value: e
              }))}
              defaultValue={{
                label: date.getFullYear(),
                value: date.getFullYear()
              }}
              placeholder={intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.two.gallery.filter.month',
                defaultMessage: 'Año'
              })}
              styles={modalGalleryFilterSelectStyle}
              onChange={(e: any) => {
                if (e == null) {
                  return
                }
                setFilter((prev: any) => ({ ...prev, year: e.value }))
                handleGalleryFilter(e.value, filter.month)
              }}
            />
            <Select
              options={getMonths(intl.locale).map((e: any, idx) => ({
                label: e,
                value: idx,
                isDisabled:
                  date.getMonth() < idx && date.getFullYear() === filter.year
              }))}
              defaultValue={{
                label: getMonths(intl.locale)[
                  typeof filter.month === 'number'
                    ? filter.month
                    : date.getMonth() + 1
                ],
                value:
                  typeof filter.month === 'number'
                    ? filter.month
                    : date.getMonth() + 1
              }}
              placeholder={intl.formatMessage({
                id: 'app.emailmarketing.create.campaign.step.two.gallery.filter.month',
                defaultMessage: 'Seleccione un mes'
              })}
              styles={modalGalleryFilterSelectStyle}
              onChange={(e: any) => {
                if (e == null) {
                  return
                }
                setFilter((prev: any) => ({ ...prev, month: e.value }))
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                handleGalleryFilter(filter.year, e.value + 1)
              }}
            />
            {/* {filter?.year && filter?.month && (
              <Select
                options={daysOptions(filter.year, filter.month)}
                defaultValue={{
                  label: date.getDate(),
                  value: date.getDate()
                }}
                isClearable
                placeholder={'Seleccione un dia'}
                styles={modalGalleryFilterSelectStyle}
                onChange={(e: any) => {
                  if (e == null) {
                    setFilter((prev: any) => ({ ...prev, day: null }))
                    return
                  }
                  setFilter((prev: any) => ({ ...prev, day: e.value }))
                }}
              />
            )} */}
          </div>
        </ModalGalleryFilterContainer>
        {loading ? (
          <LoadingMetrics
            size={40}
            color="#D9D9D9"
            style={{ margin: 'auto' }}
          />
        ) : null}
        <ImageGalleryContainer>
          {gallery.map((img: { urlImage: string }, idx: any) => (
            <ImageGallery
              src={img.urlImage}
              key={idx}
              onClick={() => {
                selectImage(img.urlImage)
                setOpenGallery(null)
              }}
            />
          ))}
        </ImageGalleryContainer>
      </ImageListPopContainer>
    </Modal>
  )
}
