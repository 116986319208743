/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ShowAlert } from '../../Globals'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'

import './system.css'
import { useClientContext } from '../../../contexts/ClientContext'
import { FormField, FormGroup, FormLabel } from './ProfileMshopContainer'
const UserPass = () => {
  interface FormDataPass {
    oldPass: string
    newPass: string
    newPassConfirm: string
  }
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()

  const intl = useIntl()

  const dispatch = useDispatch()
  const [tokenValue, setTokenValue] = useState<string>('')

  const MyAlert = (text: string, iconStr: number) => {
    ShowAlert({
      title: text,
      icon: iconStr == 1 ? 'success' : iconStr == 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: 3000,
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertToken = (data: FormDataPass) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      },
      width: 600
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        setTokenValue(result.value)
        SavePassword(result.value, data)
      }
    })
  }

  const SavePassword = (tokenGoogle: string, data: FormDataPass) => {
    Service.post<any, any>(
      `/Account/UpdatePasswordUser?idHashScript=${selectedScript?.idHashScript}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          oldPassword: data.oldPass,
          password: data.newPass,
          retrypassword: data.newPassConfirm,
          userName: user.data.username,
          tokenGoogle,
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((res) => {
        if (res.data?.StatusCode < 0 || res.data < 0) {
          if (res.data?.StatusCode == -5)
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
          if (res.data?.StatusCode != -5 && res.data?.StatusCode != -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
          if (res.data == -2) {
            MyAlert(
              intl.formatMessage(
                { id: 'app.system.profile-form-pass-actual-bad' },
                { defaultMessage: 'La Contraseña Actual no es correcta.' }
              ),
              2
            )
          }
        } else {
          MyAlert(
            intl.formatMessage(
              { id: 'app.api.update.ok' },
              { defaultMessage: 'Se actualizaron con éxito los datos.' }
            ),
            1
          )
          setTokenValue('')
          dispatch(logout(user.data.token))
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const formSchema = yup.object().shape({
    oldPass: yup
      .string()
      .required(
        intl.formatMessage(
          {
            id: 'app.system.user.changepass.msg.passrequired'
          },
          { defaultMessage: 'La contraseña nueva es obligatoria' }
        )
      )
      .min(
        4,
        intl.formatMessage(
          {
            id: 'app.system.user.changepass.msg.minpass'
          },
          { defaultMessage: 'La contraseña debe tener al menos 4 caracteres' }
        )
      ),
    newPassConfirm: yup
      .string()
      .required(
        intl.formatMessage(
          {
            id: 'app.system.user.changepass.msg.passnewrequierd'
          },
          { defaultMessage: 'La confirmacion de contraseña es obligatoria ' }
        )
      )
      .min(
        4,
        intl.formatMessage(
          {
            id: 'app.system.user.changepass.msg.minpass'
          },
          { defaultMessage: 'La contraseña debe tener al menos 4 caracteres' }
        )
      )
      .oneOf(
        [yup.ref('newPass')],
        intl.formatMessage(
          {
            id: 'app.system.user.changepass.msg.passconfirm'
          },
          { defaultMessage: 'Las contraseñas no coinciden' }
        )
      )
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataPass>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      oldPass: '',
      newPass: '',
      newPassConfirm: ''
    }
  })

  const onSubmit = (data: FormDataPass) => {
    AlertToken(data)
  }

  return (
    <div
      className="container mg-t-0 table-bordered "
      style={{ display: 'block' }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="row mb-4"
          style={{
            backgroundColor: '#fff',
            paddingInline: 20,
            paddingBlock: 40,
            boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 20%)'
          }}
        >
          <div className="col-sm-8 offset-sm-2">
            <FormGroup>
              <FormField
                type="password"
                id="oldPass"
                placeholder="Contraseña Actual"
                {...register('oldPass')}
              />
              <FormLabel>
                <FormattedMessage
                  id="app.system.profile-form-pass-actual"
                  defaultMessage="Contraseña Actual"
                />
              </FormLabel>
            </FormGroup>
            {errors?.newPass && (
              <div
                style={{
                  color: '#d32f2f',
                  fontSize: 12,
                  marginBlock: '12px'
                }}
              >
                {errors?.newPass.message}
              </div>
            )}
            <FormGroup>
              <FormField
                type="password"
                id="newPass"
                placeholder="Contraseña Nueva"
                {...register('newPass')}
              />
              <FormLabel>
                <FormattedMessage
                  id="app.system.profile-form-pass-nueva"
                  defaultMessage="Contraseña Nueva"
                />
              </FormLabel>
            </FormGroup>
            <FormGroup>
              <FormField
                type="password"
                id="newPassConfirm"
                placeholder="Repetir Contraseña"
                {...register('newPassConfirm')}
              />
              <FormLabel>
                <FormattedMessage
                  id="app.system..profile-form.pass-repeat"
                  defaultMessage="Repetir Contraseña"
                />
              </FormLabel>
            </FormGroup>
            {errors?.newPassConfirm && (
              <div
                style={{
                  color: '#d32f2f',
                  fontSize: 12,
                  marginBlock: '12px'
                }}
              >
                {errors?.newPassConfirm.message}
              </div>
            )}
          </div>
        </div>
        <div className="row shelves button botonera">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 shelves__buttons-container">
            <div className="shelves__preview-button">
              <div className="shelves__preview-button">
                <button
                  className="ppal_button btn btn-primary"
                  id="saveBrandPriority"
                >
                  <FormattedMessage
                    id="app.related-categories.add-cross-selling.guardar"
                    defaultMessage="GUARDAR"
                  />
                </button>
                <div className="shelves__preview-button"></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UserPass
