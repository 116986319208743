/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { UploadImage } from './UploadImage'
import TagInput from './TagsInput'
import Service from '../../../services'
import { RootState } from '../../../state/store'
import { Loading } from '../../Globals/Loading'
import './styleSmartImgs.css'
import { TooltipPanel } from '../../Globals/TooltipPanel'
import { ShowAlert } from '../../Globals'
import { useClientContext } from '../../../contexts/ClientContext'

export const UploadImageForm = () => {
  const methods = useForm()
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()
  const intl = useIntl()
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const [isUpdate, setIsUpdate] = useState<boolean>(id ? true : false)
  const [dataImage, setDataImage] = useState<any>()
  const [loading, setLoading] = useState<boolean>(id ? true : false)
  const [tagLoading, setTagLoading] = useState<boolean>(true)
  const [tags, setTags] = useState([])

  const getTagsByClient = useCallback(async () => {
    try {
      setTagLoading(true)
      const { data } = await Service.get<any>(
        `Automation/Tags/GetAll?idHashScript=${selectedScript?.idHashScript}`,
        {
          headers: {
            token: user.data.token
          }
        }
      )
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (data) {
        setTags(data)
      }
      setTagLoading(false)
    } catch (error) {
      setTagLoading(false)
      console.log(error)
    }
  }, [selectedScript, user])

  const filterOptions = (inputValue: string) => {
    return tags
      .map((tag) => {
        return { value: tag, label: tag }
      })
      .filter((i: any) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      )
  }

  const promiseOptions = async (inputValue: string) =>
    await new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(inputValue))
      }, 1000)
    })

  const submitForm = useCallback(
    async (data) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!isUpdate && data.main === undefined) {
        methods.setError('main', { type: 'custom', message: 'Image required' })
        return
      }
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!isUpdate && data.mobile == undefined) {
        methods.setError('mobile', {
          type: 'custom',
          message: 'Image required'
        })
        return
      }
      if (data.tags == undefined || data.tags.length === 0) {
        methods.setError('tags', {
          type: 'custom',
          message: 'At least one tag is required'
        })
        return
      }
      data.title.trim()
      if (data.title == undefined || data.title.length === 0) {
        methods.setError('title', {
          type: 'custom',
          message: 'Title is required'
        })
        return
      }
      const formData = new FormData()
      formData.append('main', data.main === undefined ? null : data.main[0])
      formData.append(
        'mobile',
        data.mobile === undefined ? null : data.mobile[0]
      )
      try {
        let url = `/Automation/UploadSmartImages?idHashScript=${
          selectedScript?.idHashScript
        }&tags=${data.tags
          .filter((tag: any) => typeof tag.value === 'number')
          .map((tag: any) => tag.value)}&title=${data.title}&newTags=${data.tags
          .filter((tag: any) => typeof tag.value === 'string')
          .map(
            (tag: any) =>
              tag.label
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/[&\/\\#,_!+()$~%.'":*?<>{}-]/g, '') // eslint-disable-line
          )}`
        if (isUpdate) {
          url = `/Automation/UpdateSmartImage?idHashScript=${
            selectedScript?.idHashScript
          }&tags=${data.tags
            .filter((tag: any) => typeof tag.value === 'number')
            .map((tag: any) => tag.value)}&id=${id}&title=${
            data.title
          }&newTags=${data.tags
            .filter((tag: any) => typeof tag.value === 'string')
            .map(
              (tag: any) =>
                tag.label
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .replace(/[&\/\\#,_!+()$~%.'":*?<>{}-]/g, '') // eslint-disable-line
            )}`
        }
        const { data: dataResponse, status } = await Service.post<any, any>(
          url,
          formData,
          {
            method: 'POST',
            headers: {
              token: user.data.token,
              userName: user.data.username,
              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
        if (status === 200) {
          methods.reset()
          ShowAlert({
            title: intl.formatMessage({
              id: 'app.smart.images.uploadImages.modal.title',
              defaultMessage: 'Imágenes inteligentes'
            }),
            text: intl.formatMessage({
              id: 'app.smart.images.uploadImages.modal.description',
              defaultMessage: 'Las imágenes se han subido correctamente'
            }),
            icon: 'success',
            showConfirmButton: true,
            timer: 2500,
            closeButtonHtml: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            confirmButtonText: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            toast: true,
            width: 500
          })
          return navigate('/dashboard/smart-images/smart-images-list')
        }
      } catch (error) {
        console.log(error)
      }
    },
    [isUpdate]
  )

  const getSmartImage = useCallback(async () => {
    try {
      setLoading(true)
      setIsUpdate(true)
      const { data } = await Service.get<any>(
        `/Automation/GetOneSmartImage?idHashScript=${selectedScript?.idHashScript}&id=${id}`,
        {
          headers: {
            token: user.data.token,
            'Content-Type': 'application/json',
            credentials: 'include',
            Accept: '*/*'
          }
        }
      )
      setDataImage(data)
      methods.setValue('title', data.title)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }, [id])

  useEffect(() => {
    if (!id) {
      methods.reset()
      setIsUpdate(false)
      setDataImage({})
    }
    getTagsByClient()
    if (id != null) {
      getSmartImage()
    }
  }, [id, location])
  return (
    <div
      className="container mg-t-30"
      style={{ display: 'block', height: '80vh' }}
    >
      <div className="row justify-content-md-center mb-5">
        {loading ? (
          <Loading
            textLoading={intl.formatMessage({
              id: 'app.vitrinas-spinner.espere',
              defaultMessage: 'Espere...'
            })}
          />
        ) : (
          <FormProvider {...methods}>
            <Form
              style={{
                backgroundColor: '#fff',
                padding: '1rem  2rem',
                display: 'flex',
                flexDirection: 'column'
              }}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={methods.handleSubmit(submitForm)}
            >
              <div className="row">
                <div className="col">
                  <Form.Group className="m-3" controlId="formPrincipalImage">
                    <Form.Label>
                      <FormattedMessage
                        id="app.smart-image.main-image"
                        defaultMessage={'Imagen Principal'}
                      />
                      <TooltipPanel
                        ObjectTooltip={user.data.tooltips.find(
                          (t: any) =>
                            t.idTooltip ===
                            'app.form.smart.image.upload.label.main'
                        )}
                      />
                    </Form.Label>
                    <UploadImage
                      name="main"
                      isUpdate={isUpdate}
                      src={dataImage?.urlmain}
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="m-3" controlId="formMobileImage">
                    <Form.Label>
                      <FormattedMessage
                        id="app.smart-image.mobile-image"
                        defaultMessage={'Imagen Movil'}
                      />
                      <TooltipPanel
                        ObjectTooltip={user.data.tooltips.find(
                          (t: any) =>
                            t.idTooltip ===
                            'app.form.smart.image.upload.label.mobile'
                        )}
                      />
                    </Form.Label>
                    <UploadImage
                      name="mobile"
                      isUpdate={isUpdate}
                      src={dataImage?.urlmobile}
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Group className="m-3">
                <Form.Label>
                  <FormattedMessage
                    id="app.smart.images.table.column2"
                    defaultMessage={'Etiquetas'}
                  />

                  <TooltipPanel
                    ObjectTooltip={user.data.tooltips.find(
                      (t: any) =>
                        t.idTooltip === 'app.form.smart.image.upload.label.tags'
                    )}
                  />
                </Form.Label>
                {tagLoading ? null : (
                  <TagInput
                    name="tags"
                    isUpdate={isUpdate}
                    updateSelectedTags={
                      isUpdate && tags.length > 0
                        ? tags
                            .filter((tag: any) =>
                              dataImage.tags.includes(tag.Name)
                            )
                            .map((tag: any) => ({
                              value: tag.Id,
                              label: tag.Name
                            }))
                        : []
                    }
                    tags={
                      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                      tags.length
                        ? tags.map((tag: any) => {
                            return {
                              value: tag.Id,
                              label: tag.Name
                            }
                          })
                        : []
                    }
                    promiseOptions={promiseOptions}
                  />
                )}
              </Form.Group>
              <Form.Group className="m-3">
                <Form.Label>
                  <FormattedMessage
                    id="app.smart.images.identifyText"
                    defaultMessage={'Texto identificador'}
                  />
                  <TooltipPanel
                    ObjectTooltip={user.data.tooltips.find(
                      (t: any) =>
                        t.idTooltip ===
                        'app.form.smart.image.upload.label.title'
                    )}
                  />
                </Form.Label>
                <Form.Control
                  type="text"
                  {...methods.register('title')}
                  aria-describedby="identify text"
                />
              </Form.Group>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Button
                  style={{ backgroundColor: '#444', marginRight: 16 }}
                  onClick={() =>
                    navigate(`/dashboard/smart-images/smart-images-list`)
                  }
                >
                  {intl.formatMessage({
                    id: 'app.common.back',
                    defaultMessage: 'Volver'
                  })}
                </Button>
                <Button
                  style={{
                    backgroundColor: '#2BAE7E',
                    // width: '100px',
                    border: 'none'
                    // margin: '0 auto'
                  }}
                  type="submit"
                  className="ppal_button"
                  disabled={methods.formState.isSubmitting}
                >
                  {!isUpdate ? (
                    <FormattedMessage
                      id="app.common.save"
                      defaultMessage={'Guardar'}
                    />
                  ) : (
                    <FormattedMessage
                      id="app.common.update"
                      defaultMessage={'Actualizar'}
                    />
                  )}
                </Button>
              </div>
            </Form>
          </FormProvider>
        )}
      </div>
    </div>
  )
}
