import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Button = styled(Link)`
  background-color: #f4f4f4;
  box-shadow: 0px 4px 20px rgba(238, 238, 238, 0.5);
  height: 40px;
  padding: 0 10px;
  border: none;
  border-radius: 3px;
  border: 1px solid #dbdbdb;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`

export const BtnTemplate = styled.button`
  background-color: #f4f4f4;
  box-shadow: 0px 4px 20px rgba(238, 238, 238, 0.5);
  height: 40px;
  padding: 0 10px;
  border: none;
  border-radius: 3px;
  border: 1px solid #dbdbdb;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-weight: 600;
`

export const ButtonSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #666666;
`

export const FirstRowButtonsContainer = styled.div`
  display: flex;
  gap: 13px;
  justify-content: end;
`
export const BoxCard = styled.div`
  display: flex;
  padding: 10px;
  gap: 8px;
  flex-direction: column;
  background-color: white;
  position: relative;
`

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Poppins';
  font-weight: 600;
  color: #444444;
  font-size: 15px;
`
export const BoxContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`
export const BoxContentText = styled.div`
  font-size: 24px;
  font-family: 'Poppins';
  font-weight: 600;
  color: #444444;
`
export const BoxFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 14px;
  position: relative;
  &::after {
    content: ' ';
    width: 108%;
    height: 2px;
    background-color: #f4f4f4;
    position: absolute;
    top: 3px;
  }
`
export const BoxFooterText = styled.div`
  font-family: 'Raleway';
  font-size: 12px;
  font-weight: 300;
  color: #444;
`
export const BoxFooterTextSpan = styled.span`
  font-family: 'Raleway';
  font-size: 12px;
  font-weight: 600;
  color: #444;
`

export const ContainerIcon = styled.div`
  background-color: #00868b;
  padding: 0.54rem;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const TableCampaignContent = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`
export const ParagraphTableCampaign = styled.p`
  margin: 0px;
  font-family: 'Raleway';
  font-size: 14px;
  font-weight: 400;
  color: #666;
`
export const SpanInsideParagraphTableCampaign = styled.span`
  font-family: 'Raleway';
  font-size: 14px;
  font-weight: 500;
  color: #666;
`
export const TableCampaignTitle = styled.div`
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 300;
  color: #444444;
`
export const StatusTag = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  align-items: center;
  border: 1px solid #dbdbdb;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 400;
  color: #666;
  padding: 5px 12px;
  border-radius: 8px;
  text-transform: capitalize;
  /* min-width: 115px; */
`
export const GenericContentWrapperCell = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-items: center;
  justify-content: center;
`
export const CDPButton = styled.button`
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  align-items: center;
  border: 1px solid #99e1e3;
  background-color: #fff;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 400;
  color: #00868b;
  padding: 5px 12px;
  border-radius: 8px;
  text-transform: capitalize;
`
export const ExportButton = styled.button`
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  align-items: center;
  border: 1px solid #dbdbdb;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  padding: 5px 12px;
  border-radius: 8px;
  text-transform: capitalize;
`

export const dynamicCampaignTable = {
  cells: {
    style: {
      borderLeft: 'none !important',
      paddingBlock: 24
    }
  },

  headRow: {
    style: {
      justifyContent: 'space-between'
    }
  },
  subHeader: {
    style: {
      justifyContent: 'space-between',
      marginBottom: '1rem',
      padding: '0 1rem !important'
    }
  },
  head: {
    style: {
      height: 90
    }
  },
  pagination: {
    style: {
      color: '#000',
      fontSize: '14px',
      minHeight: '56px',
      backgroundColor: 'transparent',
      borderTopWidth: '1px',
      borderTopColor: '#ddd'
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      padding: '8px',
      margin: '5px',
      cursor: 'pointer',
      transition: '0.4s',
      color: '#fff',
      backgroundColor: '#e9ecef',
      '&:disabled': {
        cursor: 'unset',
        color: '#2fad7e'
      },
      '&:hover:not(:disabled)': {
        backgroundColor: '#2fad7e',
        color: '#fff'
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: '#fff'
      }
    }
  }
}
