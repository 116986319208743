import React from 'react'
import MenuContainer from '../Menu/MenuContainer'
import MenuOptions from '../Menu/MenuOptions'
import { subOptions } from '../../utils/secondMenuOptions'

const HomeMenu = () => {
  const handleAction = () => {
    console.log()
  }

  return (
    <>
      <MenuContainer isSubmenu={true}>
        <MenuOptions
          tag="BrainDW | Dashboard - Homes"
          options={subOptions}
          onAction={handleAction}
        />
      </MenuContainer>
    </>
  )
}

export default HomeMenu
