import { PaginationComponent } from 'react-data-table-component/dist/src/DataTable/types'
import {
  NavPaginationTable,
  PaginationSpanTable,
  PaginationTableButton,
  PaginationTableButtonContainer
} from './css/TableComponents'

export const PaginationComponentMemo: PaginationComponent = (props) => {
  // const pagination = props.rowsPerPage % 2 == 0 ? 9 : 4
  return (
    <div>
      <NavPaginationTable className="rdt_Pagination">
        {/* <PaginationSpanTable>Mostrando :</PaginationSpanTable> */}
        <PaginationSpanTable>
          {/* {props.currentPage * props.rowsPerPage - pagination}-
          {props.rowCount < props.currentPage * props.rowsPerPage
            ? props.rowCount
            : props.currentPage * props.rowsPerPage}{' '}
          De {props.rowCount} */}
          {`${props.currentPage}  de  ${Math.ceil(
            props.rowCount / props.rowsPerPage
          )}`}
        </PaginationSpanTable>
        <PaginationTableButtonContainer>
          <PaginationTableButton
            id="pagination-first-page"
            type="button"
            aria-label="First Page"
            aria-disabled={props.currentPage == 1 ? 'true' : 'false'}
            disabled={props.currentPage == 1}
            onClick={() => props.onChangePage(1, props.rowsPerPage)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
              <path fill="none" d="M24 24H0V0h24v24z"></path>
            </svg>
          </PaginationTableButton>
          <PaginationTableButton
            id="pagination-previous-page"
            type="button"
            aria-label="Previous Page"
            aria-disabled={props.currentPage == 1 ? 'true' : 'false'}
            disabled={props.currentPage == 1}
            onClick={() =>
              props.onChangePage(props.currentPage - 1, props.rowsPerPage)
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </PaginationTableButton>
          <PaginationTableButton
            id="pagination-next-page"
            type="button"
            aria-label="Next Page"
            aria-disabled="false"
            disabled={
              props.rowCount <= props.rowsPerPage ||
              Math.ceil(props.rowCount / props.rowsPerPage) ===
                props.currentPage
            }
            onClick={() =>
              props.onChangePage(props.currentPage + 1, props.rowsPerPage)
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </PaginationTableButton>
          <PaginationTableButton
            id="pagination-last-page"
            type="button"
            aria-label="Last Page"
            aria-disabled="false"
            disabled={
              props.rowCount <= props.rowsPerPage ||
              Math.ceil(props.rowCount / props.rowsPerPage) ===
                props.currentPage
            }
            onClick={() =>
              props.onChangePage(
                Math.ceil(props.rowCount / props.rowsPerPage),
                props.rowsPerPage
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
              <path fill="none" d="M0 0h24v24H0V0z"></path>
            </svg>
          </PaginationTableButton>
        </PaginationTableButtonContainer>
      </NavPaginationTable>
    </div>
  )
}
