import { USER_LOGIN_FAIL, USER_LOGIN_SUCCESS } from '../constants/userConstants'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import store, { RootState } from '../store'
import { useUser } from '../../hooks/useUsers'
import { ShowAlert } from '../../components/Globals'
import { alertLogin } from '../../utils/alertLanguage'
import { USER_AUTH_TOOLTIPS } from '../constants/authConstants'
import { useTooltips } from '../../hooks/useTooltips'

export const login =
  (
    data: LoginData
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const initLang = store.getState().langSelected

      const alertResponse = alertLogin(initLang)

      const getData = await useUser(data)

      if (getData?.loading ?? false) {
        dispatch({
          type: USER_LOGIN_SUCCESS
        })

        await ShowAlert({
          title: `${alertResponse.titleResponse} ${
            data.username !== '' ? data.username : ''
          }!`,
          text: `${alertResponse.textRedirectResponse}`,
          icon: 'success',
          showConfirmButton: false,
          timer: 2000
        })
      } else {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: {
            error: 'Login unsuccessful'
          }
        })

        await ShowAlert({
          title: 'Oopss....!!!',
          text: `${alertResponse.errorTextResponse}`,
          icon: 'error',
          showConfirmButton: false,
          timer: 2000
        })
      }
    } catch (error: any) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response.data.message !== ''
            ? error.response.data.message
            : error.message
      })
    }
  }

export const getTooltips =
  (token: string): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const getDataTooltips = await useTooltips(token)
      dispatch({
        type: USER_AUTH_TOOLTIPS,
        payload: getDataTooltips
      })
    } catch (error: any) {
      console.log(error)
    }
  }
