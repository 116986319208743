import { CSSObjectWithLabel } from 'react-select'
import styled from 'styled-components'

interface FileUploadContainerThumbProps {
  height?: string | number
  width?: string | number
}

interface FileUploadContainerProps {
  isDragAccept: boolean
  isDragReject: boolean
  isFocused: boolean
  isRequired: boolean
  height?: string | number
  width?: string | number
}

const getColor = (props: FileUploadContainerProps) => {
  if (props.isDragAccept) {
    return '#FFB245'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused) {
    return '#6529A1'
  }
  if (props.isRequired) {
    return '#FFD293'
  }
  return '#e8e8ed'
}

export const EditStepContainer = styled.div`
  max-width: 1280px;
  padding-inline: 40px;
  padding-block: 30px;
  background-color: #fff;
`

export const EditStepWrapper = styled.div`
  max-width: 1140px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

export const FileUploadContainer = styled.div<FileUploadContainerProps>`
  width: ${({ width }) =>
    typeof width == 'number' ? `${width}px` : width ?? '100%'};
  height: ${({ height }) =>
    typeof height == 'number' ? `${height}px` : height ?? 'auto'};
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  /* padding: 20px; */
  border-width: 1px;
  border-radius: 8px;
  border-color: ${({ isDragAccept, isDragReject, isFocused, isRequired }) =>
    getColor({ isDragAccept, isDragReject, isFocused, isRequired })};
  border-style: solid;
  background-color: #e8e8ed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  &:hover {
    border-color: #6529a1;
  }
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`
export const Thumb = styled.div<FileUploadContainerThumbProps>`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: ${({ width }) =>
    typeof width == 'number' ? `${width}px` : width ?? '100%'};
  height: ${({ height }) =>
    typeof height == 'number' ? `${height}px` : height ?? 'auto'};
  position: relative;
  /* margin: 30px auto; */
`
export const ThumbContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #ccc;
`

export const ThumbContentEdit = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  row-gap: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`
export const EndpointSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 100px;
  background-color: #fff;
  padding: 40px;
`
export const ThumbContentInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ThumbContentInput = styled.input`
  border: none;
  background: #f4f4f4;
  color: var(--greys-grey-800, #666);
  font-size: 14px;
  font-weight: 400;
  outline: none;
  height: 30px;
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
`
export const ThumbContentInputError = styled.div`
  color: #db164b;
  font-size: 10px;
  font-weight: 300;
  margin-top: 5px;
`

export const DisclaimerTextArea = styled.textarea`
  border: none;
  background: #f4f4f4;
  color: var(--greys-grey-800, #666);
  font-size: 14px;
  font-weight: 400;
  outline: none;
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
`
export const EndpointParametersContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-auto-rows: 1fr; */
  row-gap: 20px;
  column-gap: 20px;
  margin-block: 20px;
`

export const EndpointButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
`
export const CustomInputGroup = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: column;
  > label {
    font-size: 14px;
  }
`

export const Label = styled.label`
  color: #666;
  font-weight: 600;
  font-size: 15px;
`
export const LabelKey = styled.label`
  color: #666;
  font-weight: 300;
  font-size: 11px;
`

// gallery modal

export const ModalGalleryTitle = styled.div`
  color: #444;
  font-size: 20px;
  font-weight: 500;
`
export const ModalGalleryFilterContainer = styled.div`
  display: flex;
  row-gap: 20px;
  flex-direction: column;
`
// preview modal
export const ModalImgProductPreview = styled.img`
  height: 170px;
  max-width: 260px;
  object-fit: contain;
`

export const ModalProductsPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 26px;
`
export const ModalProductPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 260px;
`

export const ModalProductsPreviewContainerSlider = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  column-gap: 20px;
`

export const ModalProductsPreviewContentText = styled.div`
  color: #666;
  background-color: #f4f4f4;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
`

export const ModalProductsPreviewContentLabel = styled.label`
  color: #666;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 400;
  margin: 0;
  margin-left: 5px;
`

// modal gallery select style

export const modalGalleryFilterSelectStyle = {
  container: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    maxWidth: '620px',
    width: '150px'
  }),
  control: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    minHeight: '30px',
    border: `1px solid #ced4da !important`,
    height: '30px',
    backgroundColor: '#F4F4F4 !important',
    boxShadow: 'none'
  }),
  valueContainer: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    padding: '0px 8px'
  }),
  placeholder: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    fontSize: 14,
    fontStyle: 'normal',
    color: 'hsl(0, 0%, 50%)'
  }),
  input: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    marginBlock: 0,
    paddingBlock: 0
  }),
  singleValue: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    color: '#666',
    fontSize: 14
  }),
  dropdownIndicator: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    paddingBlock: 4
  }),
  clearIndicator: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    paddingBlock: 4
  }),
  option: (
    prevStyles: CSSObjectWithLabel,
    { isSelected }: { isSelected: boolean }
  ) => ({
    ...prevStyles,
    fontSize: 14,
    ':hover': {
      ...prevStyles[':hover'],
      backgroundColor: isSelected ? '#6529A1' : 'rgb(101 41 161 / 70%)',
      color: '#fff'
    },
    backgroundColor: isSelected ? '#6529A1' : '#fff',
    ':active': {
      ...prevStyles[':active'],
      backgroundColor: '#6529A1',
      color: '#fff'
    }
  })
}

export const automationEndpointSelectStyle = {
  container: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    // maxWidth: '620px',
    width: '100%',
    marginTop: '0.5rem'
  }),
  control: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    minHeight: '30px',
    border: `1px solid #ced4da !important`,
    height: '30px',
    backgroundColor: '#F4F4F4 !important',
    boxShadow: 'none'
  }),
  valueContainer: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    padding: '0px 8px'
  }),
  placeholder: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    fontSize: 14,
    fontStyle: 'normal',
    color: 'hsl(0, 0%, 50%)'
  }),
  input: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    marginBlock: 0,
    paddingBlock: 0
  }),
  singleValue: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    color: '#666',
    fontSize: 14
  }),
  dropdownIndicator: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    paddingBlock: 4
  }),
  clearIndicator: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    paddingBlock: 4
  }),
  option: (
    prevStyles: CSSObjectWithLabel,
    { isSelected }: { isSelected: boolean }
  ) => ({
    ...prevStyles,
    fontSize: 14,
    ':hover': {
      ...prevStyles[':hover'],
      backgroundColor: isSelected ? '#6529A1' : 'rgb(101 41 161 / 70%)',
      color: '#fff'
    },
    backgroundColor: isSelected ? '#6529A1' : '#fff',
    ':active': {
      ...prevStyles[':active'],
      backgroundColor: '#6529A1',
      color: '#fff'
    }
  })
}

// endpoint select style
export const endPointPreviewSelectStyle = {
  container: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    maxWidth: '620px',
    width: '100%',
    gridColumn: '1/3'
  }),
  control: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    minHeight: '30px',
    border: `1px solid #ced4da !important`,
    height: '30px',
    backgroundColor: '#F4F4F4 !important',
    boxShadow: 'none'
  }),
  valueContainer: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    padding: '0px 8px'
  }),
  placeholder: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    fontSize: 14,
    fontStyle: 'normal',
    color: 'hsl(0, 0%, 50%)'
  }),
  input: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    marginBlock: 0,
    paddingBlock: 0
  }),
  singleValue: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    color: '#666',
    fontSize: 14
  }),
  dropdownIndicator: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    paddingBlock: 4
  }),
  clearIndicator: (prevStyles: CSSObjectWithLabel) => ({
    ...prevStyles,
    paddingBlock: 4
  }),
  option: (
    prevStyles: CSSObjectWithLabel,
    { isSelected }: { isSelected: boolean }
  ) => ({
    ...prevStyles,
    fontSize: 14,
    ':hover': {
      ...prevStyles[':hover'],
      backgroundColor: isSelected ? '#6529A1' : 'rgb(101 41 161 / 70%)',
      color: '#fff'
    },
    backgroundColor: isSelected ? '#6529A1' : '#fff',
    ':active': {
      ...prevStyles[':active'],
      backgroundColor: '#6529A1',
      color: '#fff'
    }
  })
}
