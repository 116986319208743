/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../state/store'
import { TooltipPanel } from '../Globals/TooltipPanel'

interface Rules {
  key: string
  value: string
}

interface Props {
  rules: Rules[]
  nameRule: any
  formValues: Record<string, any>
  labelMap: Record<string, string>
  handleInputChange: (key: string, value: any) => void
}

const AutomationRules: React.FC<Props> = ({
  rules,
  nameRule,
  formValues,
  handleInputChange,
  labelMap
}) => {
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const adminFields = [
    'minstock',
    'mstimeoutwsproducts',
    'geographicsCompanyEnabled'
  ]

  const filteredRules =
    nameRule.utmCampaign == 'CartUnClosedDay' && user.data.userType == 1
      ? rules.filter((rule) => !adminFields.includes(rule.key))
      : rules

  const optionsMap: Record<string, string[]> = {
    excludelastdays: ['Sin exclusión', 'Excluye un día', 'Excluye dos días'],
    excludeLastDays: ['Sin exclusión', 'Excluye un día', 'Excluye dos días'],
    checkcountbeforedays: [
      'Actual',
      '1 día atrás',
      '2 días atrás',
      '3 días atrás'
    ],
    checkCountBeforeDays: [
      'Actual',
      '1 día atrás',
      '2 días atrás',
      '3 días atrás'
    ],
    dontsendsameuserdays: [
      'Se repite siempre',
      'Día por medio',
      'Cada dos días'
    ],
    dontSendSameUserDays: [
      'Se repite siempre',
      'Día por medio',
      'Cada dos días'
    ]
  }

  const renderInputField = (rule: Rules) => {
    if (optionsMap[rule.key]) {
      return (
        <>
          <select
            value={formValues[rule.key] || ''}
            onChange={(e) => handleInputChange(rule.key, e.target.value)}
            className="select-automation"
          >
            {optionsMap[rule.key].map((option, index) => (
              <option key={index} value={index}>
                {option}
              </option>
            ))}
          </select>
        </>
      )
    } else {
      return (
        <input
          type="text"
          value={formValues[rule.key]}
          defaultValue={rule.value}
          onChange={(e) => handleInputChange(rule.key, e.target.value)}
          placeholder="Ingresa un valor"
          className="input-automation"
        />
      )
    }
  }

  return (
    <div className="automation-rules">
      {filteredRules.map((rule) => (
        <div key={rule.key} className="automation-rule">
          <label className="label-automation">
            {labelMap[rule.key] || rule.key}{optionsMap[rule.key] && (
            <TooltipPanel
              ObjectTooltip={
                user.data.tooltips.find(  
                  (t: any) =>
                    t.idTooltip ===
                    `app.emailmarketing.automation.title.${rule.key}`
                ) ?? `app.emailmarketing.automation.title.${rule.key}`
              }
            />
          )}
          </label>
          {renderInputField(rule)}
        </div>
      ))}
    </div>
  )
}

export default AutomationRules
