/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { BsCheck, BsPencilSquare } from 'react-icons/bs'
import { Button, Modal } from 'react-bootstrap'
import { FaRegEye } from 'react-icons/fa'
import { ShowAlert } from '../Globals'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

const HeadBlock = styled.div`
  margin: auto;
  font-weight: 500;
  color: #777;
  text-transform: uppercase;
  font-size: 14px;
`

const BodyBlock = styled.div`
  margin: auto;
  font-weight: 300;
  color: #444;
  font-size: 12px;
`

const StyledTableContainer = styled.div`
  background-color: #fff;
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`

const StyledRowContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
`

const StyledRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  position: relative;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
`

const GrupoEnviosTable = ({
  campaignGroups,
  setCampaignGroups,
  selectedTableIndex
}: {
  campaignGroups: any[]
  setCampaignGroups: React.Dispatch<React.SetStateAction<any[]>>
  selectedTableIndex: any
}) => {
  const [pendingChanges, setPendingChanges] = useState<
    Record<number, number | undefined>
  >({})
  const [modalData, setModalData] = useState<{
    name: string
    priority: number
    rowIndex: number
  } | null>(null)
  const intl = useIntl()

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number
  ) => {
    const newPriority = Number(e.target.value)
    setPendingChanges((prev) => ({ ...prev, [rowIndex]: newPriority }))
  }

  const handleOpenModal = (
    rowIndex: number,
    name: string,
    priority: number
  ) => {
    setModalData({ name, priority, rowIndex })
  }

  const handleConfirmChange = () => {
    if (modalData == null) return

    const { rowIndex, priority } = modalData

    setCampaignGroups((prev) => {
      return prev.map((group, idx) => {
        if (idx === selectedTableIndex) {
          const updatedTable = [...group]

          const updatedRow = { ...updatedTable[rowIndex], Priority: priority }

          updatedTable.splice(rowIndex, 1)

          const reorderedTable = updatedTable
            .map((item) =>
              item.Priority >= priority
                ? { ...item, Priority: item.Priority + 1 }
                : item
            )
            .concat(updatedRow)
            .sort((a, b) => a.Priority - b.Priority)
            .map((item, index) => ({
              ...item,
              Priority: index + 1
            }))

          return reorderedTable
        }
        return group
      })
    })

    setPendingChanges((prev) => {
      const { [modalData.rowIndex]: _, ...rest } = prev
      return rest
    })

    setModalData(null)
  }

  const showDescription = useCallback((title: string, description: string) => {
    ShowAlert({
      title,
      html: description,
      showConfirmButton: true,
      closeButtonHtml: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      confirmButtonText: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      width: 500
    })
  }, [])

  const formatearFecha = (fechaInicial: string): string => {
    const fechaObj = new Date(fechaInicial)
    const dia = String(fechaObj.getDate()).padStart(2, '0')
    const mes = String(fechaObj.getMonth() + 1).padStart(2, '0')
    const año = fechaObj.getFullYear()
    const horas = String(fechaObj.getHours()).padStart(2, '0')
    const minutos = String(fechaObj.getMinutes()).padStart(2, '0')
    return `${dia}/${mes}/${año} ${horas}:${minutos}`
  }

  const renderTable = (tableData: any[]) => {
    return (
      <StyledTableContainer key={selectedTableIndex}>
        <h3 style={{ fontSize: '16px', marginBottom: '10px' }}>
          Grupo de envío {selectedTableIndex + 1}
        </h3>
        <StyledRowContainerHeader>
          <HeadBlock>Fecha de Actualización</HeadBlock>
          <HeadBlock>Nombre</HeadBlock>
          <HeadBlock>Prioridad</HeadBlock>
          <HeadBlock>Acciones</HeadBlock>
        </StyledRowContainerHeader>
        <div style={{ height: '75vh', overflowY: 'auto' }}>
          {tableData.map((i, x) => (
            <StyledRowContainer key={x} style={{ height: 80 }}>
              <BodyBlock style={{ fontSize: 14, textAlign: 'center' }}>
                {formatearFecha(i.LastDateUpdated)}
              </BodyBlock>
              <BodyBlock style={{ fontSize: 14, textAlign: 'center' }}>
                {i.Name}
              </BodyBlock>
              <BodyBlock style={{ position: 'relative' }}>
                <input
                  type="text"
                  value={
                    pendingChanges[x] !== undefined
                      ? pendingChanges[x]
                      : i.Priority
                  }
                  onChange={(e) => handleInputChange(e, x)}
                  style={{
                    width: '60px',
                    padding: '5px',
                    textAlign: 'center',
                    border: '1px solid #ccc',
                    borderRadius: '4px'
                  }}
                />
                {pendingChanges[x] !== undefined &&
                  Number(pendingChanges[x]) !== Number(i.Priority) && (
                    <BsCheck
                      onClick={() =>
                        handleOpenModal(x, i.Name, pendingChanges[x] as number)
                      }
                      style={{
                        marginLeft: '10px',
                        cursor: 'pointer',
                        color: 'green',
                        fontSize: '18px'
                      }}
                    />
                  )}
              </BodyBlock>
              <BodyBlock>
                <Link to={`/dashboard/campaign/campaign-create/${i.id}`}>
                  <BsPencilSquare
                    size={20}
                    color="#00868B"
                    cursor={'pointer'}
                    data-tip={'Update'}
                    style={{ marginRight: 8 }}
                  />
                </Link>
                <FaRegEye
                  size={20}
                  color="#7424a5"
                  cursor={'pointer'}
                  data-tip={'Descripción'}
                  onClick={() => showDescription(i.Name, formatearFecha(i.LastDateUpdated))}
                />
              </BodyBlock>
            </StyledRowContainer>
          ))}
        </div>
      </StyledTableContainer>
    )
  }

  return (
    <div>
      <div className="cfa-grupos-envio">
        {campaignGroups !== undefined &&
          campaignGroups.map((tableData: any) => renderTable(tableData))}
      </div>

      {modalData != null && (
        <Modal show={true} onHide={() => setModalData(null)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar cambio de prioridad</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              ¿Está seguro de cambiar la prioridad de{' '}
              <strong>{modalData.name}</strong> a{' '}
              <strong>{modalData.priority}</strong>?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalData(null)}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleConfirmChange}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default GrupoEnviosTable
