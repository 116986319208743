import Footer from './components/Footer/Footer'
import AppRoutes from './routes/index'

const App: React.FC = (props: any) => {
  return (
    <>
      <AppRoutes {...props} />
      <Footer version={props.version} shouldRerender={props.shouldRerender} />
    </>
  )
}

export default App
