import React, { useState } from 'react'
import { BsFillBookmarkPlusFill, BsPencilSquare } from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import CDPForm from './CDPForm'
import CDPTableGrid from './CDPTableGrid'
import './style.css'

import { TooltipPanel } from '../../Globals/TooltipPanel'
import {  useSelector } from 'react-redux'
import { RootState } from '../../../state/store'

const CDPContainer = () => {
  const [addNewAudience, setAddNewAudience] = useState<boolean>(false)
  const [audienceName, setAudienceName] = useState<string>('')
  const [audienceDescription, setAudienceDescription] = useState<string>('')
  const [queryEditValues, setQueryEditValues] = useState<string>('')
  const [audienceId, setAudienceId] = useState<string>('')
  const user = useSelector<RootState, any>((state) => state.userAuth)
  
  const toggleAudience = () => {
    setAddNewAudience(!addNewAudience)
    setQueryEditValues('')
    setAudienceName('')
    setAudienceDescription('')
    setAudienceId('')
  }
  const toggleEdit = () => {
    setAddNewAudience(true)
  }
  const intl = useIntl()
  return (
    <div className="container mg-t-30 container " style={{ display: 'block' }}>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          {!addNewAudience ? (
            <>
              <div className="container p-0">
                <div className="alert-heading">
                  {intl.formatMessage(
                    { id: 'app.tooltips.header.title.cdp-crearAudiencia' },
                    { defaultMessage: 'CDP' }
                  )}
                </div>
                {/* <p>
                  {intl.formatMessage(
                    { id: 'app.tooltips.header.p.cdp-crearAudiencia' },
                    { defaultMessage: 'CDP' }
                  )}
                </p> */}
              </div>
              <div>
                <button
                  className="ppal_button btn btn-primary"
                  id="add-new-audience"
                  onClick={() => toggleAudience()}
                >
                  <BsFillBookmarkPlusFill className="m-rig" width={20} />
                  <FormattedMessage
                    id="app.cdp.create-audience"
                    defaultMessage="Crear Audiencia"
                  />
                </button>
                <TooltipPanel 
                  ObjectTooltip={user.data.tooltips.filter((
                    (t:any) => t.idTooltip === 'app.cdp.btn.create.audience.tooltip')
                  )[0]}
                />
              </div>
            </>
          ) : (
            ''
          )}
          <div className="cdp_title-container  mg-t-30">
            <BsPencilSquare className="manage-grid-button related_categories__title-icon" />
            <h1 className="related_categories__title-text">
              {addNewAudience ? (
                queryEditValues == '' ? (
                  <FormattedMessage
                    id="app.cdp.create-audience"
                    defaultMessage="Crear Audiencia"
                  />
                ) : (
                  <FormattedMessage
                    id="app.cdp.edit-audience"
                    defaultMessage="Editar Audiencia"
                  />
                )
              ) : (
                <FormattedMessage
                  id="app.cdp.audience-list"
                  defaultMessage="Lista de audiencias"
                />
              )}
            </h1>
          </div>
        </div>
      </div>
      {addNewAudience ? (
        <CDPForm
          back={toggleAudience}
          audienceId={audienceId}
          audienceName={audienceName}
          audienceDescription={audienceDescription}
          queryEditValues={queryEditValues}
        />
      ) : (
        <CDPTableGrid
          edit={toggleEdit}
          setAudienceId={setAudienceId}
          setAudienceName={setAudienceName}
          setAudienceDescription={setAudienceDescription}
          setQueryEditValues={setQueryEditValues}
        />
      )}
    </div>
  )
}

export default CDPContainer
