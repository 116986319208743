/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react'
import Col from 'react-bootstrap/Col'
import { CiCirclePlus, CiImageOn } from 'react-icons/ci'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { BsPencilSquare, BsTrash } from 'react-icons/bs'
import Form from 'react-bootstrap/Form'
import { RxDragHandleDots2 } from 'react-icons/rx'
import ReactTooltip from 'react-tooltip'
import { IoReturnUpBack } from 'react-icons/io5'
import { useSprings, animated, config } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import clamp from 'lodash.clamp'
import swap from 'lodash-move'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Loading } from '../../../Globals/Loading'
import Service from '../../../../services'
import { RootState } from '../../../../state/store'
import { ShowAlert } from '../../../Globals'
import { useClientContext } from '../../../../contexts/ClientContext'
import { FirstRowButtonsContainer } from '../../../Campain/css/DashboardCampaignComponents'
import { UtilLinkButton } from '../../../Globals/Button'

const HeadBlock = styled.div`
  margin: auto;
  font-weight: 500;
`
const BodyBlock = styled.div`
  margin: auto;
  font-weight: 300;
  color: #444;
  font-size: 12px;
`
const BodyBlockStatus = styled.div`
  margin: auto;
  font-weight: 300;
  font-size: 12px;
`
interface CDPI {
  id: number
  name: string
}
interface BocksI {
  id: number
  name: string
  title: string
  dateUpdated: string
  priority: number
  enabled: boolean
  status: string
  saleschannels: {
    codes: string[]
  }
  whitelabels: {
    codes: string[]
  }
  categories: {
    codes: string[]
  }
  audiences: CDPI[]
}

const fn =
  (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
  (index: number) =>
    active && index === originalIndex
      ? {
          y: curIndex * 90 + y,
          scale: 1.05,
          zIndex: 1,
          shadow: 15,
          immediate: (key: string) => key === 'zIndex',
          config: (key: string) => (key === 'y' ? config.stiff : config.default)
        }
      : {
          y: order.indexOf(index) * 90,
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: false
        }
const ListByBlock = () => {
  const [blocks, setBlocks] = useState<BocksI[]>([])
  const { selectedScript } = useClientContext()
  const userData = useSelector<RootState, any>(
    (state: RootState) => state.userAuth
  )
  const user = userData.data
  const [loading, setLoading] = useState<boolean>(false)
  const intl = useIntl()
  const navigate = useNavigate()
  const { block } = useParams()

  const order = useMemo(
    () => ({ current: blocks.map((_, index) => index) }),
    [blocks.length]
  )

  const [springs, api] = useSprings(blocks.length, fn(order.current))
  const [activeRowIndex, setActiveRowIndex] = useState(false)

  const handleImgOrder = useCallback(
    (newOrder: number[], lastOrder: number[]) => {
      if (lastOrder.toString() === newOrder.toString()) {
        return
      }
      newOrder.forEach((img, idx) => {
        blocks[img].priority = idx + 1
      })
      setBlocks([...blocks])
      handleUpdatePriority([...blocks])
    },
    [blocks]
  )

  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    if (activeRowIndex) {
      const curIndex = order.current.indexOf(originalIndex)
      const curRow = clamp(
        Math.round((curIndex * 100 + y) / 100),
        0,
        blocks.length - 1
      )
      const newOrder = swap(order.current, curIndex, curRow)
      api.start(fn(newOrder, active, originalIndex, curIndex, y))
      if (!active) {
        const lastOrder = order.current
        order.current = newOrder
        handleImgOrder(newOrder, lastOrder)
      }
    }
  })
  const handleDeleteCarousel = useCallback(
    async (id) => {
      try {
        const { value, isConfirmed } = await ShowAlert({
          title: intl.formatMessage({
            id: 'app.modal.token.title',
            defaultMessage: 'Ingrese el <strong>token</strong>'
          }),
          input: 'text',
          html:
            '<span class="m-4 text-left">' +
            intl.formatMessage({
              id: 'app.modal.token.texto',
              defaultMessage: 'Ingrese el <strong>token</strong>'
            }) +
            '</span>',
          inputLabel: '',
          inputPlaceholder: 'Token',
          color: '#1c684c',
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.enviar',
            defaultMessage: 'Enviar'
          }),
          showCancelButton: true,
          cancelButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          width: 600,
          inputValidator: (result) => {
            if (result === '') {
              return intl.formatMessage({
                id: 'app.token-inexistente',
                defaultMessage: 'El token no fue informado.'
              })
            } else {
              return ''
            }
          }
        })
        if (!isConfirmed) return null
        const { data } = await Service.post<any, any>(
          `/SmartImage/DeleteCarousel?idHashScript=${selectedScript?.idHashScript}&id=${id}`,
          {},
          {
            headers: {
              token: user.token,
              userName: user.username,
              tokenGoogle: value,
              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
        if (data.StatusCode == 0) {
          const filterBlocks = blocks
            .filter((block) => block.id !== id)
            .sort((a: BocksI, b: BocksI) => a.priority - b.priority)
          filterBlocks.forEach((img, idx) => {
            img.priority = idx + 1
          })
          setBlocks(filterBlocks)
          handleUpdatePriority([...filterBlocks])
          ShowAlert({
            title: 'Imágenes inteligentes',
            text: data.Message,
            icon: 'success',
            showConfirmButton: true,
            timer: 2500,
            closeButtonHtml: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            confirmButtonText: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            toast: true,
            width: 500
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    [blocks, selectedScript]
  )

  const handleUpdatePriority = useCallback(
    async (blockParam: BocksI[]) => {
      try {
        const reOrder = blockParam.map((block) => ({
          id: block.id,
          priority: block.priority
        }))

        const { data } = await Service.post<any, any>(
          `/SmartImage/ReorderPriorityCarousel?idHashScript=${selectedScript?.idHashScript}`,
          reOrder,
          {
            headers: {
              token: user.token,
              userName: user.username,
              // tokenGoogle: value,
              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
        console.log(data)
      } catch (error) {
        console.log(error)
      }
    },
    [selectedScript]
  )

  const showMoreCdp = useCallback((cdps) => {
    const cdpsList = cdps.reduce(
      (acc: string, cc: any) => acc.concat(`${cc.name} <br>`),
      ''
    )
    ShowAlert({
      title: intl.formatMessage({
        id: 'app.smart.images.listbyblock.modal.title',
        defaultMessage: 'Listado de Audiencias'
      }),
      html: cdpsList,
      icon: 'info',
      showConfirmButton: true,
      // timer: 2500,
      closeButtonHtml: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      confirmButtonText: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      // toast: true,
      width: 500
    })
  }, [])

  const getBlocks = useCallback(
    async (block) => {
      try {
        setLoading(true)
        const { data, status, headers } = await Service.get<any>(
          `/SmartImage/GetAllBlocksByBlock?idHashScript=${selectedScript?.idHashScript}&name=${block}`,
          {
            headers: {
              token: user.token
            }
          }
        )
        if (headers.statuscode == '0') {
          setBlocks(
            data.Entities.sort(
              (a: BocksI, b: BocksI) => a.priority - b.priority
            )
          )
        }
        if (data.StatusCode == -1) {
          ShowAlert({
            title: intl.formatMessage({
              id: 'app.smart.images.listbyblock.modal.smartimage.title',
              defaultMessage: 'Bloques de imágenes inteligentes'
            }),
            text: intl.formatMessage({
              id: 'app.smart.images.listbyblock.modal.smartimage.description',
              defaultMessage:
                'Ops! ocurrió un error al solicitar la data, refresca la pagina o vuelve en unos minutos'
            }),
            icon: 'warning',
            showConfirmButton: true,
            timer: 2500,
            closeButtonHtml: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            confirmButtonText: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            toast: true,
            width: 500
          })
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        ShowAlert({
          title: intl.formatMessage({
            id: 'app.smart.images.listbyblock.modal.smartimage.title',
            defaultMessage: 'Bloques de imágenes inteligentes'
          }),
          text: intl.formatMessage({
            id: 'app.smart.images.listbyblock.modal.smartimage.description',
            defaultMessage:
              'Ops! ocurrió un error al solicitar la data, refresca la pagina o vuelve en unos minutos'
          }),
          icon: 'warning',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
        console.log(error)
      }
    },
    [selectedScript]
  )

  const handleActivationBlock = useCallback(
    async (enabled: boolean, updateBlockId) => {
      try {
        const { value, isConfirmed } = await ShowAlert({
          title: intl.formatMessage({
            id: 'app.modal.token.title',
            defaultMessage: 'Ingrese el <strong>token</strong>'
          }),
          input: 'text',
          html:
            '<span class="m-4 text-left">' +
            intl.formatMessage({
              id: 'app.modal.token.texto',
              defaultMessage: 'Ingrese el <strong>token</strong>'
            }) +
            '</span>',
          inputLabel: '',
          inputPlaceholder: 'Token',
          color: '#1c684c',
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.enviar',
            defaultMessage: 'Enviar'
          }),
          showCancelButton: true,
          cancelButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          width: 600,
          inputValidator: (result) => {
            if (result === '') {
              return intl.formatMessage({
                id: 'app.token-inexistente',
                defaultMessage: 'El token no fue informado.'
              })
            } else {
              return ''
            }
          }
        })
        if (!isConfirmed) return null
        const url: string = `/SmartImage/UpdateEnableCarousel?idHashScript=${selectedScript?.idHashScript}`
        const { data: dataResponse, status } = await Service.post<any, any>(
          url,
          {
            enabled: enabled ? 1 : 0,
            id: updateBlockId
          },
          {
            method: 'POST',
            headers: {
              token: user.token,
              userName: user.username,
              tokenGoogle: value,

              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
        if (status == 200 && dataResponse.StatusCode == 0) {
          ShowAlert({
            title: intl.formatMessage({
              id: 'app.smart.images.listbyblock.modal.smartimage.handle.title',
              defaultMessage: 'Imágenes inteligentes'
            }),
            text: dataResponse.Message,
            icon: 'success',
            showConfirmButton: true,
            timer: 2500,
            closeButtonHtml: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            confirmButtonText: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            toast: true,
            width: 500
          })
        }
        if (dataResponse.StatusCode == -1) {
          ShowAlert({
            title: intl.formatMessage({
              id: 'app.smart.images.listbyblock.modal.smartimage.title',
              defaultMessage: 'Bloques de imágenes inteligentes'
            }),
            text: intl.formatMessage({
              id: 'app.smart.images.listbyblock.modal.smartimage.description',
              defaultMessage:
                'Ops! ocurrió un error al solicitar la data, refresca la pagina o vuelve en unos minutos'
            }),
            icon: 'warning',
            showConfirmButton: true,
            timer: 2500,
            closeButtonHtml: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            confirmButtonText: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            toast: true,
            width: 500
          })
        }
        await getBlocks(block)
        return
      } catch (error) {
        ShowAlert({
          title: intl.formatMessage({
            id: 'app.smart.images.listbyblock.modal.smartimage.title',
            defaultMessage: 'Bloques de imágenes inteligentes'
          }),
          text: intl.formatMessage({
            id: 'app.smart.images.listbyblock.modal.smartimage.description',
            defaultMessage:
              'Ops! ocurrió un error al solicitar la data, refresca la pagina o vuelve en unos minutos'
          }),
          icon: 'warning',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
        console.log(error)
      }
    },
    [block]
  )

  const backgroundStatusBadges = (status: string) => {
    switch (status.toLocaleLowerCase()) {
      case 'sin vencimiento':
        return 'badge badge-info'
      case 'vencida':
        return 'badge badge-warning'
      case 'vigente':
        return 'badge badge-success'
      default:
        return 'badge badge-dark'
    }
  }

  useEffect(() => {
    getBlocks(block)
    return () => {}
  }, [block])

  return (
    <div className="container-fluid" style={{ minHeight: '80dvh' }}>
      <div>
        <div
          className="container mg-t-30"
          style={{ display: 'block', maxWidth: '100%' }}
        >
          <div className="row justify-content-md-center">
            <Col>
              <div>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      height: 40,
                      width: 40,
                      borderRadius: '100%',
                      backgroundColor: '#00868B',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 12
                    }}
                  >
                    <CiImageOn size={24} color="#fff" />
                  </div>
                  <h2 style={{ fontSize: 18, margin: 0, color: '#444' }}>
                    {intl.formatMessage({
                      id: 'app.smart.images.listbyblock.title',
                      defaultMessage: 'Carruseles del bloque'
                    })}{' '}
                    {block}
                  </h2>
                </div>
              </div>

              <div className="row" style={{ marginBlock: 23 }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row'
                  }}
                >
                  <div
                    style={{
                      height: 30,
                      width: 30,
                      borderRadius: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 0,
                      border: '1px solid #F29718',
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      navigate(`/dashboard/smart-images/setup-smart-images`)
                    }
                  >
                    <IoReturnUpBack size={20} color="#F29718" />
                  </div>
                  <FirstRowButtonsContainer>
                    <UtilLinkButton
                      text={intl.formatMessage({
                        id: 'app.smart.images.blocks.page.images.create.btn.tooltip',
                        defaultMessage: 'Configurar un nuevo carrusel'
                      })}
                      to={`/dashboard/smart-images/setup-smart-images-block-create/${block}`}
                    />
                  </FirstRowButtonsContainer>
                </div>
              </div>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Loading
                    textLoading={intl.formatMessage({
                      id: 'app.common.loading',
                      defaultMessage: 'Cargando'
                    })}
                  />
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: '#fff',
                    padding: '.5rem 2rem',
                    marginTop: 20,
                    height: (blocks.length + 1) * 100,
                    position: 'relative'
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      height: '80px',
                      width: '100%',
                      display: 'grid',
                      gridTemplateColumns: `repeat(${
                        block?.includes('BDW-PLP-IMAGES-') ? 10 : 9
                      },1fr)`
                    }}
                  >
                    <HeadBlock>
                      {intl.formatMessage({
                        id: 'app.smart.images.listbyblock.table.head.move'
                      })}
                    </HeadBlock>
                    <HeadBlock>
                      {intl.formatMessage({
                        id: 'app.smart.images.listbyblock.table.head.title'
                      })}
                    </HeadBlock>
                    <HeadBlock>
                      {intl.formatMessage({
                        id: 'app.smart.images.listbyblock.table.head.status'
                      })}
                    </HeadBlock>
                    <HeadBlock>
                      {intl.formatMessage({
                        id: 'app.smart.images.listbyblock.table.head.cdp'
                      })}
                    </HeadBlock>
                    <HeadBlock>
                      {intl.formatMessage({
                        id: 'app.smart.images.listbyblock.table.head.channels'
                      })}
                    </HeadBlock>
                    <HeadBlock>
                      {intl.formatMessage({
                        id: 'app.smart.images.listbyblock.table.head.whitelabel'
                      })}
                    </HeadBlock>
                    {block?.includes('BDW-PLP-IMAGES-') ? (
                      <HeadBlock>
                        {intl.formatMessage({
                          id: 'app.smart.images.listbyblock.table.head.categories'
                        })}
                      </HeadBlock>
                    ) : null}
                    <HeadBlock>
                      {intl.formatMessage({
                        id: 'app.smart.images.listbyblock.table.head.priority'
                      })}
                    </HeadBlock>
                    <HeadBlock>
                      {intl.formatMessage({
                        id: 'app.smart.images.listbyblock.table.head.lastupdate'
                      })}
                    </HeadBlock>
                    <HeadBlock>
                      {intl.formatMessage({
                        id: 'app.smart.images.listbyblock.table.head.actions'
                      })}
                    </HeadBlock>
                  </div>
                  {springs.map(({ zIndex, shadow, y, scale }, i) => (
                    <animated.div
                      {...bind(i)}
                      key={i}
                      style={{
                        zIndex,
                        boxShadow: shadow.to(
                          (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                        ),
                        y,
                        scale,
                        position: 'absolute',
                        height: '80px',
                        width: '95%',
                        backgroundColor:
                          blocks[i].priority % 2 === 0 ? '#E8E8ED' : '#F9FAFB',
                        touchAction: 'none'
                      }}
                    >
                      <div
                        style={{
                          height: 80,
                          display: 'grid',
                          gridTemplateColumns: `repeat(${
                            block?.includes('BDW-PLP-IMAGES-') ? 10 : 9
                          },1fr)`
                        }}
                      >
                        <BodyBlock>
                          <RxDragHandleDots2
                            cursor={'grab'}
                            onMouseDown={() => setActiveRowIndex(true)}
                            onMouseUp={() => setActiveRowIndex(false)}
                          />
                        </BodyBlock>
                        <BodyBlock style={{ fontSize: 12, textAlign: 'left' }}>
                          {blocks[i].title.length >= 49 &&
                          window.innerWidth <= 1400
                            ? `${blocks[i].title.slice(0, 48)} ...`
                            : blocks[i].title}
                        </BodyBlock>
                        <BodyBlockStatus
                          style={{ fontSize: 12, textAlign: 'left' }}
                          className={backgroundStatusBadges(blocks[i].status)}
                        >
                          {blocks[i].status}
                        </BodyBlockStatus>
                        <BodyBlock style={{ position: 'relative' }}>
                          {blocks[i].audiences !== null
                            ? blocks[i].audiences.map((cdp: CDPI, idx) => {
                                if (idx <= 3) {
                                  return (
                                    <BodyBlock
                                      key={idx}
                                      style={{
                                        fontSize: 12,
                                        textAlign: 'left'
                                      }}
                                    >
                                      {cdp.name.length >= 13 &&
                                      window.innerWidth <= 1650
                                        ? `${cdp.name.slice(0, 13)}...`
                                        : cdp.name}
                                    </BodyBlock>
                                  )
                                }
                                return null
                              })
                            : intl.formatMessage({
                                id: 'app.common.donthave',
                                defaultMessage: 'No tiene'
                              })}
                          {blocks[i].audiences !== null &&
                            blocks[i].audiences.length > 4 && (
                              <>
                                <CiCirclePlus
                                  size={20}
                                  cursor={'pointer'}
                                  data-tip={intl.formatMessage({
                                    id: 'app.common.seemore',
                                    defaultMessage: 'Ver mas'
                                  })}
                                  style={{
                                    position: 'absolute',
                                    right: -20,
                                    top: 25
                                  }}
                                  color="#2eac7e"
                                  onClick={() =>
                                    showMoreCdp(blocks[i].audiences)
                                  }
                                />
                              </>
                            )}
                        </BodyBlock>
                        <BodyBlock>
                          {blocks[i].saleschannels !== null &&
                          blocks[i].saleschannels.codes.length > 0
                            ? blocks[i].saleschannels.codes.map(
                                (ch: string) => `(${ch}) `
                              )
                            : intl.formatMessage({
                                id: 'app.common.donthave',
                                defaultMessage: 'No tiene'
                              })}
                        </BodyBlock>
                        <BodyBlock>
                          {blocks[i].whitelabels !== null &&
                          blocks[i].whitelabels.codes.length > 0
                            ? blocks[i].whitelabels.codes.map((w, idx) => (
                                <BodyBlock
                                  key={idx}
                                  style={{ fontSize: 12, textAlign: 'left' }}
                                >
                                  {w}
                                </BodyBlock>
                              ))
                            : intl.formatMessage({
                                id: 'app.common.donthave',
                                defaultMessage: 'No tiene'
                              })}
                        </BodyBlock>
                        {block?.includes('BDW-PLP-IMAGES-') ? (
                          <BodyBlock>
                            {blocks[i].categories !== null &&
                            blocks[i].categories.codes.length > 0
                              ? blocks[i].categories.codes.map(
                                  (c, idx) => `(${c}) `
                                )
                              : intl.formatMessage({
                                  id: 'app.common.donthave',
                                  defaultMessage: 'No tiene'
                                })}
                          </BodyBlock>
                        ) : null}
                        <BodyBlock>{blocks[i].priority}</BodyBlock>
                        <BodyBlock style={{ fontSize: 12, textAlign: 'left' }}>
                          {blocks[i].dateUpdated.replace('T', ' ')}
                        </BodyBlock>
                        <BodyBlock style={{ display: 'flex' }}>
                          <Form.Check // prettier-ignore
                            type="switch"
                            style={{ marginRight: 10, cursor: 'pointer' }}
                            id="enable-switch"
                            data-tip={intl.formatMessage({
                              id: 'app.common.enabledisabled',
                              defaultMessage: 'Activar / Desactivar'
                            })}
                            checked={blocks[i].enabled}
                            onChange={(e) => {
                              handleActivationBlock(
                                e.target.checked,
                                blocks[i].id
                              )
                            }}
                            // label="Check this switch"
                          />
                          <BsPencilSquare
                            className="smart-icons"
                            size={20}
                            cursor={'pointer'}
                            data-tip={intl.formatMessage({
                              id: 'app.common.update'
                            })}
                            style={{ marginRight: 15 }}
                            color="#00868B"
                            onClick={() =>
                              navigate(
                                `/dashboard/smart-images/setup-smart-images-update/${blocks[i].name}/${blocks[i].id}`
                              )
                            }
                          />

                          <BsTrash
                            className="smart-icons"
                            cursor={'pointer'}
                            size={20}
                            color="#F29718"
                            onClick={() => {
                              handleDeleteCarousel(blocks[i].id)
                            }}
                            data-tip={intl.formatMessage({
                              id: 'app.common.delete'
                            })}
                          />
                          <ReactTooltip
                            delayShow={10}
                            data-backgroundColor={'#2fad7e'}
                          />
                        </BodyBlock>
                      </div>
                    </animated.div>
                  ))}
                </div>
              )}
            </Col>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListByBlock
