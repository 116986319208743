/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useCallback, useEffect } from 'react'
import Select from 'react-select'

import './style.css'
import { useClientContext } from '../../contexts/ClientContext'

const HashSelector = () => {
  const { selectedClient, selectScript, selectedScript } = useClientContext()

  const handleScriptChange = useCallback(
    (prefix) => {
      if (!selectedClient?.scripts) {
        return
      }
      const script = selectedClient.scripts.find((s) => s.prefix === prefix)
      if (script) {
        selectScript(script)
      }
    },
    [selectedClient?.scripts]
  )
  useEffect(() => {
    handleScriptChange(selectedScript?.prefix)
  }, [selectedClient])

  return (
    <div className="">
      {selectedClient?.scripts?.length && (
        <Select
          classNamePrefix="select"
          value={{
            value: selectedScript?.prefix,
            label: selectedScript?.prefix.replace('megusta', 'on_city')
          }}
          isDisabled={!selectedClient}
          // isSearchable={true}
          options={selectedClient.scripts.map((client) => ({
            label: client.prefix.replace('megusta', 'on_city'),
            value: client.prefix
          }))}
          onChange={(e) => e && handleScriptChange(e?.value)}
          styles={{
            control: (prevStyles) => ({
              ...prevStyles,
              // minHeight: '33.5px',
              ':hover': {
                ...prevStyles[':hover'],
                borderColor: 'hsl(0, 0%, 80%)'
              },
              // height: '33.5px',
              backgroundColor: '#fff !important',
              boxShadow: 'none'
            }),
            valueContainer: (prevStyles) => ({
              ...prevStyles,
              padding: '0px 8px'
            }),
            placeholder: (prevStyles) => ({
              ...prevStyles,
              fontSize: 14
            }),
            singleValue: (prevStyles) => ({
              ...prevStyles,
              fontSize: 14
            }),
            option: (prevStyles, { isSelected }) => ({
              ...prevStyles,
              ':hover': {
                ...prevStyles[':hover'],
                backgroundColor: isSelected
                  ? '#6529A1'
                  : 'rgb(101 41 161 / 70%)',
                color: '#fff'
              },
              backgroundColor: isSelected ? '#6529A1' : '#fff',
              ':active': {
                ...prevStyles[':active'],
                backgroundColor: '#6529A1',
                color: '#fff'
              }
            })
          }}
        />
      )}
    </div>
  )
}

export default HashSelector
