import { useCallback, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import { CiImageOn } from 'react-icons/ci'
import DataTable, { Alignment, TableColumn } from 'react-data-table-component'
import { HiOutlineCog8Tooth } from 'react-icons/hi2'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { Loading } from '../../../Globals/Loading'
import Service from '../../../../services'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'
import ReactTooltip from 'react-tooltip'
import { useClientContext } from '../../../../contexts/ClientContext'
import { FirstRowButtonsContainer } from '../../../Campain/css/DashboardCampaignComponents'
import { UtilLinkButton } from '../../../Globals/Button'

interface BocksI {
  id: number
  name: string
  ids: number[]
  dateUpdated: string
}

const SmartImagesBindsContainer = () => {
  const { selectedScript } = useClientContext()
  const userData = useSelector<RootState, any>(
    (state: RootState) => state.userAuth
  )
  const user = userData.data
  const [blocks, setBlocks] = useState<BocksI[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const intl = useIntl()
  const navigate = useNavigate()
  const getBlocks = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await Service.get<any>(
        `/SmartImage/GetAllBlocks?idHashScript=${selectedScript?.idHashScript}`,
        {
          headers: {
            token: user.token
          }
        }
      )
      if (data.StatusCode === 0) {
        setBlocks(data.Entities)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }, [])
  useEffect(() => {
    getBlocks()
    return () => {}
  }, [])

  const columns: Array<TableColumn<any>> = [
    {
      id: 'block',
      name: intl.formatMessage({
        id: 'app.smart.images.blocks.page.images.table.name',
        defaultMessage: 'Bloque de Carrusel'
      }),
      selector: (row: any) => row.name,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'id',
      minWidth: '80px'
    },
    {
      id: 'lastUpdate',
      name: intl.formatMessage({
        id: 'app.smart.images.blocks.page.images.table.lastupdate',
        defaultMessage: 'Bloque de Carrusel'
      }),
      selector: (row: any) => row.dateUpdated.replace('T', ' '),
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'title',
      minWidth: '80px'
    },
    {
      id: 'actions',
      name: intl.formatMessage({
        id: 'app.smart.images.blocks.page.images.table.action',
        defaultMessage: 'ACCIÓN'
      }),
      cell: (row) => {
        return (
          <>
            <HiOutlineCog8Tooth
              className="smart-icons"
              size={38}
              cursor={'pointer'}
              data-tip={intl.formatMessage({
                id: 'app.smart.images.blocks.page.images.table.action.tooltip',
                defaultMessage: 'Ver carruseles configurados'
              })}
              style={{ marginRight: 8, strokeWidth: 1 }}
              color="#00868B"
              onClick={() =>
                navigate(
                  `/dashboard/smart-images/setup-smart-images-listByBlock/${row.name}`
                )
              }
            />
            <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
          </>
        )
      },
      reorder: true,
      center: true,
      sortField: 'mobile-thumb',
      minWidth: '80px'
    }
  ]
  return (
    <div className="container-fluid" style={{ minHeight: '80dvh' }}>
      <div>
        <div className="container mg-t-30" style={{ display: 'block' }}>
          <div className="row justify-content-md-center">
            <Col>
              <div>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      height: 40,
                      width: 40,
                      borderRadius: '100%',
                      backgroundColor: '#00868B',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 12
                    }}
                  >
                    <CiImageOn size={24} color="#fff" />
                  </div>
                  <h2 style={{ fontSize: 18, margin: 0, color: '#444' }}>
                    <FormattedMessage
                      defaultMessage={'Carruseles de Imágenes'}
                      id="app.smart.images.blocks.page.title"
                    />
                  </h2>
                </div>
              </div>

              <div className="row" style={{ marginBlock: 23 }}>
                <FirstRowButtonsContainer>
                  <UtilLinkButton
                    text={intl.formatMessage({
                      defaultMessage: 'Configurar un nuevo carrusel',
                      id: 'app.smart.images.blocks.page.images.create.btn.tooltip'
                    })}
                    // text={'Configurar un nuevo carrusel'}
                    to={`/dashboard/smart-images/setup-smart-images-create`}
                  />
                </FirstRowButtonsContainer>
              </div>
              <DataTable
                subHeaderAlign={Alignment.RIGHT}
                columns={columns}
                data={blocks}
                progressPending={loading}
                progressComponent={
                  <Loading
                    textLoading={intl.formatMessage({
                      id: 'app.vitrinas-spinner.espere',
                      defaultMessage: 'Espere...'
                    })}
                  />
                }
                customStyles={{
                  subHeader: {
                    style: {
                      justifyContent: 'space-between',
                      marginBottom: '1rem',
                      padding: '0 1rem !important'
                    }
                  },
                  rows: {
                    style: {
                      minHeight: 80
                    }
                  }
                }}
                noDataComponent={intl.formatMessage({
                  id: 'app.vitrinas.nodata',
                  defaultMessage: 'Sin Resultados.'
                })}
                responsive
              />
            </Col>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmartImagesBindsContainer
