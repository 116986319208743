import { useCallback, useEffect, useState, useMemo } from 'react'
import DataTable, {
  Alignment,
  PaginationComponentProps,
  TableColumn
} from 'react-data-table-component'
import Badge from 'react-bootstrap/Badge'
import { useIntl } from 'react-intl'
import { BsPencilSquare, BsSearch, BsTrash } from 'react-icons/bs'
import ReactTooltip from 'react-tooltip'
import { GrSearchAdvanced } from 'react-icons/gr'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { CiImageOn } from 'react-icons/ci'
import Col from 'react-bootstrap/Col'
import Select from 'react-select'

import { Loading } from '../../Globals/Loading'
import { RootState } from '../../../state/store'
import Service from '../../../services'
import { ShowAlert } from '../../Globals'
// import { FilterComponent } from './FilterComponent'
import './styleSmartImgs.css'
import { useClientContext } from '../../../contexts/ClientContext'
import { FirstRowButtonsContainer } from '../../Campain/css/DashboardCampaignComponents'
import { UtilLinkButton } from '../../Globals/Button'
import { PaginationComponentMemo } from '../../Globals/table/Pagination'
import {
  InputSearchTableHeader,
  InputSearchTableHeaderContainer,
  subHeaderTableSelectStyle
} from '../../Globals/table/css/TableComponents'

export default function SmartImagesList() {
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const [rows, setRows] = useState<number>(0)
  const [page, setPage] = useState<number>(1)

  const [sortDir, setSortDir] = useState<string>('desc')
  const [sortField, setSortField] = useState<string>('dateAdded')
  const [filterText, setFilterText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [perPage, setPerPage] = useState<number>(100)
  const [data, setData] = useState<any[]>([])
  const intl = useIntl()
  const navigate = useNavigate()

  const handleSubmitSearch = useCallback(
    (e) => {
      if (e?.type == 'keydown' && e?.code !== 'Enter') {
        return
      }
      getSmartImages()
    },
    [perPage]
  )

  const handleSearch = useCallback(({ target: { value } }) => {
    setFilterText(value)
  }, [])

  const subHeaderComponentMemo = useMemo(
    () => (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'end',
          backgroundColor: '#fff'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
          {intl.formatMessage({
            id: 'app.common.show',
            defaultMessage: 'Mostrar'
          })}{' '}
          <Select
            classNamePrefix="select"
            name="order"
            menuPosition="fixed"
            defaultValue={{ label: perPage.toString(), value: perPage }}
            isSearchable={false}
            options={[
              {
                label: '5',
                value: 5
              },
              {
                label: '10',
                value: 10
              },
              {
                label: '20',
                value: 20
              },
              {
                label: '50',
                value: 50
              },
              {
                label: '100',
                value: 100
              }
            ]}
            onChange={(e) => {
              if (e != null) {
                setPerPage(e?.value)
                // getAllCampaigns(page, e?.value, false, filter, search)
              }
            }}
            styles={subHeaderTableSelectStyle}
          />{' '}
          {intl.formatMessage({
            id: 'app.common.results',
            defaultMessage: 'Resultados'
          })}
        </div>
        <div>
          <InputSearchTableHeaderContainer>
            <InputSearchTableHeader
              type="text"
              placeholder={intl.formatMessage({
                id: 'app.common.searchbyname',
                defaultMessage: 'Buscar por nombre'
              })}
              onChange={handleSearch}
              value={filterText}
              onKeyDown={handleSubmitSearch}
            />
            <BsSearch
              size={18}
              color="#444"
              cursor={'pointer'}
              style={{ position: 'absolute', right: '12px' }}
              onClick={handleSubmitSearch}
            />
          </InputSearchTableHeaderContainer>
        </div>
      </div>
    ),
    [perPage, setPerPage, page, filterText, handleSearch, handleSubmitSearch]
  )

  const deleteSmartImage = useCallback(
    async (id, tokenGoogle) => {
      try {
        setLoading(true)
        const { data, status } = await Service.post<any, any>(
          `/Automation/DeleteSmartImage?idHashScript=${selectedScript?.idHashScript}&id=${id}`,
          {},
          {
            headers: {
              token: user.data.token,
              username: user.data.username,
              tokenGoogle,
              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
        if (data.StatusCode === 0 && status == 200) {
          getSmartImages()
        }
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (error?.response?.data?.Error?.Detail?.length) {
          const errors = error?.response?.data?.Error.Detail.map(
            (block: any) =>
              `<li style="text-align:left;margin-bottom:8px;"><p style="margin:0px;"><span style="margin-right:5px;font-size: 20px;">&#9900</span> Bloque: ${block.Bloque}</p><p style="padding-left:18px;">Título: ${block['Título']}</p></li>`
          )
            .toString()
            .replaceAll(',', '')
          ShowAlert({
            title: intl.formatMessage({
              id: 'app.smart.images.listimages.get.images.modal.title',
              defaultMessage: 'Listado de Imágenes inteligentes'
            }),
            html: `<div>
            <div style="margin-bottom:8px;"> ${error?.response?.data?.Error?.Message}</div>
            <ul>${errors}</ul>
            </div>
            `,
            icon: 'warning',
            showConfirmButton: true,
            // timer: 2500,
            closeButtonHtml: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            confirmButtonText: intl.formatMessage({
              id: 'app.modal.btn.cerrar',
              defaultMessage: 'Cerrar'
            }),
            // toast: true,
            width: 500
          })
          return
        }
        ShowAlert({
          title: 'Error',
          text: intl.formatMessage({
            id: 'app.common.modal.error',
            defaultMessage:
              'Ops, algo va mal. Refresca la pagina o vuelve en unos minutos'
          }),
          icon: 'warning',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
      }
    },
    [selectedScript]
  )

  const getNewToken = useCallback(async (id) => {
    try {
      const { value, isConfirmed } = await ShowAlert({
        title: intl.formatMessage({
          id: 'app.modal.token.title',
          defaultMessage: 'Ingrese el <strong>token</strong>'
        }),
        input: 'text',
        html:
          '<span class="m-4 text-left">' +
          intl.formatMessage({
            id: 'app.modal.token.texto',
            defaultMessage: 'Ingrese el <strong>token</strong>'
          }) +
          '</span>',
        inputLabel: '',
        inputPlaceholder: 'Token',
        color: '#1c684c',
        confirmButtonText: intl.formatMessage({
          id: 'app.modal.btn.enviar',
          defaultMessage: 'Enviar'
        }),
        showCancelButton: true,
        cancelButtonText: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        width: 600,
        inputValidator: (result) => {
          if (result === '') {
            return intl.formatMessage({
              id: 'app.token-inexistente',
              defaultMessage: 'El token no fue informado.'
            })
          } else {
            return ''
          }
        }
      })
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (isConfirmed && value !== '') {
        deleteSmartImage(id, value)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const getSmartImages = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await Service.get<any>(
        `Automation/GetSmartImages?idHashScript=${selectedScript?.idHashScript}&page=${page}&per_page=${perPage}&filterText=${filterText}&sortField=${sortField}&sortDirection=${sortDir}`,
        {
          headers: {
            token: user.data.token
          }
        }
      )
      if (data.StatusCode < 1) {
        setData(data.Entities)
        setRows(parseInt(data.Message))
      }
      if (data.StatusCode === -9) {
        ShowAlert({
          title: intl.formatMessage({
            id: 'app.smart.images.listimages.get.images.modal.title',
            defaultMessage: 'Listado de Imágenes inteligentes'
          }),
          text: intl.formatMessage({
            id: 'app.smart.images.listimages.get.images.modal.description',
            defaultMessage: 'Modulo en uso por otro usuario'
          }),
          icon: 'info',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (
        error.response.data === -9 ||
        error.response.headers.statuscode === '-9'
      ) {
        ShowAlert({
          title: intl.formatMessage({
            id: 'app.smart.images.listimages.get.images.modal.title',
            defaultMessage: 'Listado de Imágenes inteligentes'
          }),
          text: intl.formatMessage({
            id: 'app.smart.images.listimages.get.images.modal.description',
            defaultMessage: 'Modulo en uso por otro usuario'
          }),
          icon: 'info',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
      }
    }
  }, [selectedScript, filterText, perPage, page, sortDir, sortField])

  const zoomImg = useCallback((url) => {
    ShowAlert({
      imageHeight: 'auto',
      imageUrl: url,
      imageWidth: 600
    })
  }, [])

  const columns: Array<TableColumn<any>> = [
    {
      id: 'id',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column1',
        defaultMessage: 'Id'
      }),
      selector: (row: any) => row.id,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'id',
      minWidth: '80px'
    },
    {
      id: 'title',
      name: intl.formatMessage({
        id: 'app.smart.images.identifyText',
        defaultMessage: 'Texto identificador'
      }),
      selector: (row: any) => row.title,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'title',
      minWidth: '80px'
    },
    {
      id: 'tags',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column2',
        defaultMessage: 'Etiquetas'
      }),
      cell: (row: any) => {
        return (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {row.tags.map((tag: string, idx: number) => (
              <Badge
                key={idx}
                bg="secondary"
                style={{
                  fontWeight: 300,
                  marginRight: 6,
                  fontSize: 12,
                  cursor: 'pointer',
                  marginBottom: 4
                }}
                onClick={() => setFilterText(tag)}
              >
                {tag}
              </Badge>
            ))}
          </div>
        )
      },
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'tags',
      minWidth: '80px'
    },
    {
      id: 'userAdd',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column5',
        defaultMessage: 'Usuario'
      }),
      selector: (row: any) => row.userAdd,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'userAdd',
      minWidth: '80px'
    },
    {
      id: 'main-thumb',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column3',
        defaultMessage: 'Imagen principal'
      }),
      // selector: (row: any) => row.mainThumb,
      cell: (row) => {
        return (
          <div
            style={{
              height: 80,
              display: 'flex',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: 50,
                width: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                cursor: 'pointer'
              }}
              onClick={() => zoomImg(row.urlmain)}
            >
              <GrSearchAdvanced size={20} color="rgb(47, 173, 126)" />
            </div>
            <img
              src={row.urlmain.replace(/.jpg/i, '_th.jpg')}
              alt=""
              style={{
                height: 50,
                borderRadius: '50%'
              }}
            />
          </div>
        )
      },
      reorder: true,
      center: true,
      sortField: 'main-thumb',
      minWidth: '80px'
    },
    {
      id: 'mobile-thumb',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column4',
        defaultMessage: 'Imagen móvil'
      }),
      cell: (row) => {
        return (
          <div
            style={{
              height: 80,
              display: 'flex',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: 50,
                width: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                cursor: 'pointer'
              }}
              onClick={() => zoomImg(row.urlmobile)}
            >
              <GrSearchAdvanced size={20} color="rgb(47, 173, 126)" />
            </div>
            <img
              src={row.urlmobile.replace(/.jpg/i, '_th.jpg')}
              alt=""
              style={{
                height: 50,
                borderRadius: '50%'
              }}
            />
          </div>
        )
      },
      reorder: true,
      center: true,
      sortField: 'mobile-thumb',
      minWidth: '80px'
    },
    {
      id: 'dateAdded',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column6',
        defaultMessage: 'Fecha'
      }),
      selector: (row: any) => row.dateAdded,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'dateAdded',
      minWidth: '80px'
    },
    {
      id: 'actions',
      name: intl.formatMessage({
        id: 'app.cdp.audience.table.column8',
        defaultMessage: 'Acciones'
      }),
      cell: (row) => {
        return (
          <>
            <BsPencilSquare
              className="smart-icons"
              size={20}
              cursor={'pointer'}
              data-tip={intl.formatMessage({
                id: 'app.common.update',
                defaultMessage: 'Actualizar'
              })}
              style={{ marginRight: 8 }}
              color="#00868B"
              onClick={() =>
                navigate(
                  `/dashboard/smart-images/upload-images-update/${row.id}`
                )
              }
            />

            <BsTrash
              className="smart-icons"
              cursor={'pointer'}
              size={20}
              color="#F29718"
              onClick={() => {
                getNewToken(row.id)
              }}
              data-tip={intl.formatMessage({
                id: 'app.common.delete',
                defaultMessage: 'Borrar'
              })}
            />
            <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
          </>
        )
      },
      reorder: true,
      center: true,
      sortField: 'mobile-thumb',
      minWidth: '80px'
    }
  ]

  useEffect(() => {
    getSmartImages()
  }, [perPage, filterText, page, sortDir, sortField])

  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <Col>
          <div>
            <div
              style={{ display: 'flex', marginBottom: 8, alignItems: 'center' }}
            >
              <div
                style={{
                  height: 40,
                  width: 40,
                  borderRadius: '100%',
                  backgroundColor: '#00868B',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 12
                }}
              >
                <CiImageOn size={24} color="#fff" />
              </div>
              <h2 style={{ fontSize: 18, margin: 0, color: '#444' }}>
                {intl.formatMessage({
                  id: 'app.smart.images.listimages.title',
                  defaultMessage: 'Listado de Imágenes'
                })}
              </h2>
            </div>
          </div>
          <div className="row" style={{ marginBlock: 23 }}>
            <FirstRowButtonsContainer>
              <UtilLinkButton
                text={'Subir Imágenes'}
                to="/dashboard/smart-images/upload-images/"
              />
            </FirstRowButtonsContainer>
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              padding: '.5rem 2rem',
              marginTop: 20
            }}
          >
            <DataTable
              subHeaderAlign={Alignment.RIGHT}
              columns={columns}
              data={data}
              progressPending={loading}
              progressComponent={
                <Loading
                  textLoading={intl.formatMessage({
                    id: 'app.vitrinas-spinner.espere',
                    defaultMessage: 'Espere...'
                  })}
                />
              }
              pagination
              paginationServer
              paginationPerPage={100}
              paginationTotalRows={rows}
              paginationResetDefaultPage={true}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 100]}
              paginationComponent={(props) => {
                const customProps: PaginationComponentProps = {
                  ...props,
                  // currentPage: page,
                  rowsPerPage: perPage
                }
                return <PaginationComponentMemo {...customProps} />
              }}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChangeRowsPerPage={(first, second) => {
                setPerPage(first)
                setPage(second)
              }}
              onChangePage={(e) => setPage(e)}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              customStyles={{
                subHeader: {
                  style: {
                    justifyContent: 'space-between',
                    marginBottom: '1rem',
                    padding: '0 1rem !important'
                  }
                },
                pagination: {
                  style: {
                    color: '#000',
                    fontSize: '14px',
                    minHeight: '56px',
                    backgroundColor: 'transparent',
                    // borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: '#ddd'
                  },
                  pageButtonsStyle: {
                    borderRadius: '50%',
                    height: '40px',
                    width: '40px',
                    padding: '8px',
                    margin: '5px',
                    cursor: 'pointer',
                    transition: '0.4s',
                    color: '#fff',
                    // fill: theme.button.default,
                    backgroundColor: '#e9ecef',
                    '&:disabled': {
                      cursor: 'unset',
                      color: '#2fad7e'
                      // fill: theme.button.disabled,
                    },
                    '&:hover:not(:disabled)': {
                      backgroundColor: '#2fad7e',
                      color: '#fff'
                    },
                    '&:focus': {
                      outline: 'none',
                      backgroundColor: '#fff'
                    }
                  }
                }
              }}
              // defaultSortFieldId={1}
              noDataComponent={intl.formatMessage({
                id: 'app.vitrinas.nodata',
                defaultMessage: 'Sin Resultados.'
              })}
              responsive
              sortServer
              onSort={(column: any, order) => {
                setSortField(column.sortField)
                setSortDir(order)
              }}
            />
          </div>
        </Col>
      </div>
    </div>
  )
}
