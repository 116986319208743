/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import './Prioridades.css'
import GrupoEnviosTable from './GrupoEnviosTable'
import Service from '../../services'
import { useSelector } from 'react-redux'
import { RootState } from '../../state/store'
import { useClientContext } from '../../contexts/ClientContext'
import styled from 'styled-components'
import { ShowAlert } from '../Globals'

interface BocksI {
  id: number
  name: string
  priority: string
  groupSend: number
}

const SaveButton = styled.button`
  width: fit-content;
  border: 1px solid #6529a1;
  background: transparent;
  padding: 5px;
  color: #6529a1;
  text-transform: uppercase;
`

const GrupoEnvios = () => {
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const { selectedScript } = useClientContext()
  const [selectedTableIndex, setSelectedTableIndex] = useState<number | null>(
    null
  )
  const [campaignGroups, setCampaignGroups] = useState<BocksI[][]>([])

  const MyAlertSave = (text: string, iconStr: number, time = 3000) => {
    ShowAlert({
      title: 'Grupo de Envios',
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: 'Cerrar',
      confirmButtonText: 'Cerrar',
      width: 500,
      timerProgressBar: true
    })
  }

  const getCampaignGroups = () => {
    Service.get<any>(
      `Automation/GetCampaignGroups?idHashScript=${selectedScript?.idHashScript}`,
      {
        headers: {
          'Content-Type': 'application/json',
          token: user.data.token
        }
      }
    )
      .then((response) => {
        const formattedData = response.data.Entity.map((group: any) =>
          group.CampaignsGroup.map((campaign: any) => ({
            id: campaign.IdRule,
            Name: campaign.Name,
            Priority: campaign.Priority,
            GroupSend: group.GroupSend,
            LastDateUpdated: campaign.LastDateUpdated
          }))
        )
        setCampaignGroups(formattedData)
      })
      .catch((error) => console.error(error))
  }

  const saveGroups = (index: any) => {
    const requestBody = {
      CampaignsPriorityGroup: campaignGroups[index].map((item: any) => ({
        IdRule: item.id,
        GroupSend: item.GroupSend,
        Priority: item.Priority
      }))
    }

    Service.post<any, any>(
      `/automation/UpdatePriorityGroup?idHashScript=${selectedScript?.idHashScript}`,
      JSON.stringify(requestBody),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: user.data.token
        }
      }
    )
      .then(() => {
        console.log('Datos enviados correctamente')
        MyAlertSave('Se guardó correctamente', 1)
      })
      .catch((error) => {
        console.error('Error al enviar los datos:', error)
        MyAlertSave(`Error al enviar los datos: ${error}`, 2)
      })
  }

  useEffect(() => {
    if (selectedScript?.idHashScript != null && Boolean(user.data.token)) {
      getCampaignGroups()
    }
  }, [selectedScript?.idHashScript, user.data.token])
  useEffect(() => {
    if (selectedTableIndex === null && campaignGroups.length > 0) {
      setSelectedTableIndex(0)
    }
  }, [campaignGroups, selectedTableIndex])
  return (
    <div>
      <div className="cfa-prioridad-container">
        <div className="cfa-prioridad-header">
          <h1>Establecer Prioridad</h1>
        </div>
        <div className="d-flex w-100 text-center">
          <div className="w-100">
            <label htmlFor="table-selector" className='mr-2'>Seleccionar tabla:</label>
            <select
              id="table-selector"
              value={selectedTableIndex ?? 0}
              onChange={(e) => setSelectedTableIndex(Number(e.target.value))}
            >
              {campaignGroups.map((_, index) => (
                <option key={index} value={index}>
                  Grupo de Envío {index + 1}
                </option>
              ))}
            </select>
          </div>
          {selectedTableIndex !== null && (
            <div>
              <SaveButton onClick={() => saveGroups(selectedTableIndex)}>
                Guardar
              </SaveButton>
            </div>
          )}
        </div>
        <div className="cfa-grupos-envio">
          {selectedTableIndex !== null && (
            <GrupoEnviosTable
              campaignGroups={[campaignGroups[selectedTableIndex]]}
              setCampaignGroups={setCampaignGroups}
              selectedTableIndex={selectedTableIndex}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default GrupoEnvios
