import React, { useEffect } from 'react'
import AsrticlesSliderContainer from './AsrticlesSliderContainer'
import './Style.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import Service from '../../../services'
import { useNavigate } from 'react-router-dom'

const HomeSiteContainer = () => {
  const token = useSelector<RootState, any>((state) => state.userAuth)
  const navigate = useNavigate()
  useEffect(() => {
    Service.get<any>(`/Account/ProfileStatus`, {
      headers: { token: token.data.token }
    })
      .then((response) => {
        if (
          response.data.Status !== 'OK' &&
          response.data.Response.Platform === 'MercadoShop' &&
          response.data.Response.Message !== 'Los datos están completos'
        ) {
          navigate('/dashboardmshop/profile')
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [])

  return (
    <div className="container mg-t-30">
      {/* SLIDER */}
      <AsrticlesSliderContainer />
      <br />

      {/* --ARTICULOS -- 
      <ArticlesContainer /> */}
    </div>
  )
}

export default HomeSiteContainer
