import { ImSpinner8 } from 'react-icons/im'
import styled, { keyframes } from 'styled-components'

export const StepsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: center;
  column-gap: 50px;
  margin-block: 30px;
`
export const Step = styled.div`
  display: flex;
  column-gap: 50px;
  align-items: center;
`
export const StepText = styled.div`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #444444;
  background-color: #fff;
  padding-inline: 12px;
  padding-block: 6px;
  opacity: 0.5;
`

export const StepButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
export const StepButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 186px;
  height: 34px;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #fff;
  background-color: #00868b;
  text-transform: capitalize;
  margin-inline: auto;
  margin-block: 20px;
`

const loadingSpin = keyframes`
  to {
    transform: rotate(360deg);
}
`
export const LoadingMetrics = styled(ImSpinner8)`
  animation: ${loadingSpin} 1.5s linear infinite;
`
