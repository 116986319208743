import React from 'react'
import { SubMenuMshopOptions } from '../../utils/secondMenuOptions'
import MenuOptions from '../Menu/MenuOptions'
import MenuContainer from '../Menu/MenuContainer'

const SubMenusMshop = () => {
  const handleAction = () => {
    console.log()
  }

  return (
    <>
      <MenuContainer isSubmenu={true}>
        <MenuOptions
          tag="BrainDW | Mshop"
          options={SubMenuMshopOptions}
          onAction={handleAction}
        />
      </MenuContainer>
    </>
  )
}

export default SubMenusMshop
