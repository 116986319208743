/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import DataTable, {
  Alignment,
  PaginationComponentProps,
  TableColumn
} from 'react-data-table-component'
import { format } from 'date-fns'

import Select from 'react-select'
import { BsPencilSquare, BsSearch } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { FaRegEye } from 'react-icons/fa'

import { RootState } from '../../state/store'
import {
  FirstRowButtonsContainer,
  ParagraphTableCampaign,
  TableCampaignContent,
  TableCampaignTitle,
  GenericContentWrapperCell,
  CDPButton,
  dynamicCampaignTable
} from './css/DashboardCampaignComponents'
import { useClientContext } from '../../contexts/ClientContext'
import Service from '../../services'
import { LoadingMetrics } from './css/CampaignCreateContainerComponents'
import { ShowAlert } from '../Globals'
import { UtilLinkButton } from '../Globals/Button'
import { PaginationComponentMemo } from '../Globals/table/Pagination'
import {
  InputSearchTableHeader,
  InputSearchTableHeaderContainer,
  subHeaderTableSelectStyle
} from '../Globals/table/css/TableComponents'

export const GetCDPButton = ({ id }: { id: any }) => {
  const { selectedScript } = useClientContext()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const intl = useIntl()
  const userData = useSelector<RootState, any>(
    (state: RootState) => state.userAuth
  )
  const user = userData.data

  const getCDPData = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await Service.get<any>(
        `/Automation/GetTotalAudience?idHashScript=${selectedScript?.idHashScript}&idRule=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            token: user.token
          }
        }
      )
      if (data) {
        setData(data.Audience)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }, [selectedScript])
  if (data == null) {
    return loading ? (
      <LoadingMetrics size={18} />
    ) : (
      // eslint-disable-next-line @typescript-eslint/promise-function-async
      <CDPButton onClick={() => getCDPData()}>
        {intl.formatMessage({
          defaultMessage: 'Calcular',
          id: 'app.automation.table.calculate'
        })}
      </CDPButton>
    )
  }
  return <div>{data}</div>
}

const DashboardCampaigns = () => {
  const userData = useSelector<RootState, any>(
    (state: RootState) => state.userAuth
  )
  const { selectedScript } = useClientContext()
  const user = userData.data
  const intl = useIntl()

  const [perPage, setPerPage] = useState<number>(10)
  const [loadingRows, setLoadingRows] = useState<boolean>(false)
  const [filteredTableData, setFilteredTableData] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])

  const [search, setSearch] = useState()
  const activityCampaigns = [
    'ActivityUser',
    'ActivityUser1',
    'ActivityUser2',
    'ActivityUser3',
    'ActivityUser4',
    'ActivityUser5',
    'ActivityUser6',
    'BaseUser',
    'BaseUser1',
    'BaseUser2',
    'BaseUser3',
    'BaseUser4',
    'BaseUser5',
    'BaseUser6',
    'States',
    'States1',
    'States2',
    'States3',
    'States4',
    'States5',
    'States6',
    'LastCategory',
    'LastCategory1',
    'LastCategory2',
    'LastCategory3',
    'LastCategory4',
    'LastCategory5',
    'LastCategory6'
  ]
  const getAllEnabledCampaigns = useCallback(async () => {
    try {
      if (!selectedScript) {
        return
      }
      setLoadingRows(true)
      // get all campaigns
      // this api don't provide details
      const { data: campaigns } = await Service.get<any>(
        `/Automation/GetAllCampaigns?idHashScript=${selectedScript?.idHashScript}&staticRules=false`,
        {
          headers: {
            'Content-Type': 'application/json',
            token: user.token
          }
        }
      )
      if (campaigns) {
        setTableData(() => {
          return campaigns.Entities.map((item: any) => {
            return {
              id: item.IdRule,
              name: item.Name,
              status: item.EnabledStatus,
              Priority: item.Priority,
              EmailsSentYesterday: item.EmailsSentYesterday,
              EmailsSentToday: item.EmailsSentToday,
              group: item.Group,
              date: item.LastDateUpdated,
              description: item.Description
            }
          })
        })
        setFilteredTableData(() => {
          return campaigns.Entities.map((item: any) => {
            return {
              id: item.IdRule,
              name: item.Name,
              status: item.EnabledStatus,
              Priority: item.Priority,
              EmailsSentYesterday: item.EmailsSentYesterday,
              EmailsSentToday: item.EmailsSentToday,
              group: item.Group,
              date: item.LastDateUpdated,
              description: item.Description
            }
          })
        })
      }

      setLoadingRows(false)
    } catch (error) {
      setLoadingRows(false)
      ShowAlert({
        title: 'Error',
        text: intl.formatMessage({
          id: 'app.common.modal.error',
          defaultMessage:
            'Ops, algo va mal. Refresca la pagina o vuelve en unos minutos'
        }),
        icon: 'warning',
        showConfirmButton: true,
        timer: 2500,
        closeButtonHtml: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        confirmButtonText: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        toast: true,
        width: 500
      })
      console.log(error)
    }
  }, [selectedScript, perPage])

  const handleSearch = useCallback(
    ({ target: { value } }) => {
      setSearch(value)
      setFilteredTableData(() =>
        tableData.filter((t: any) =>
          t.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        )
      )
    },
    [tableData, filteredTableData]
  )
  const handleFilter = useCallback(
    (opt) => {
      switch (opt) {
        case 0:
          setFilteredTableData(() => tableData)
          break
        case 1:
          setFilteredTableData(() =>
            tableData.filter((t: any) => !activityCampaigns.includes(t.name))
          )
          break
        case 2:
          setFilteredTableData(() =>
            tableData.filter((t: any) => activityCampaigns.includes(t.name))
          )
          break

        default:
          setFilteredTableData(() => tableData)
          break
      }
    },
    [tableData, filteredTableData, activityCampaigns]
  )

  const subHeaderComponentMemo = useMemo(
    () => (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'end',
          backgroundColor: '#fff'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
          {intl.formatMessage({
            id: 'app.common.show',
            defaultMessage: 'Mostrar'
          })}{' '}
          <Select
            classNamePrefix="select"
            name="order"
            menuPosition="fixed"
            defaultValue={{ label: perPage.toString(), value: perPage }}
            isSearchable={false}
            options={[
              {
                label: '5',
                value: 5
              },
              {
                label: '10',
                value: 10
              },
              {
                label: '20',
                value: 20
              },
              {
                label: '50',
                value: 50
              },
              {
                label: '100',
                value: 100
              }
            ]}
            onChange={(e) => {
              if (e) {
                setPerPage(e?.value)
              }
            }}
            styles={subHeaderTableSelectStyle}
          />{' '}
          {intl.formatMessage({
            id: 'app.common.results',
            defaultMessage: 'Resultados'
          })}
        </div>
        <div style={{ display: 'flex', gap: '10px', width: '35%' }}>
          <Select
            classNamePrefix="select"
            menuPosition="fixed"
            defaultValue={{ label: 'Todo', value: 0 }}
            isSearchable={false}
            options={[
              {
                label: 'Todo',
                value: 0
              },
              {
                label: 'Audiencias',
                value: 1
              },
              {
                label: 'Actividad ',
                value: 2
              }
            ]}
            onChange={(e) => {
              if (e) {
                handleFilter(e?.value)
              }
            }}
            styles={subHeaderTableSelectStyle}
          />
          <InputSearchTableHeaderContainer>
            <InputSearchTableHeader
              type="text"
              placeholder={intl.formatMessage({
                id: 'app.common.searchbyname',
                defaultMessage: 'Buscar por nombre'
              })}
              onChange={handleSearch}
              value={search}
            />
            <BsSearch
              size={18}
              color="#444"
              cursor={'pointer'}
              style={{ position: 'absolute', right: '12px' }}
            />
          </InputSearchTableHeaderContainer>
        </div>
      </div>
    ),
    [perPage, setPerPage, search, handleSearch]
  )

  const statusCampaignHandler = useCallback(
    async (id, e) => {
      try {
        if (!selectedScript || !id) {
          return
        }

        const { isConfirmed } = await ShowAlert({
          title: intl.formatMessage({
            id: 'app.emailmarketing.campaigns.dynamic.table.modal.actions.changestatus',
            defaultMessage: 'Cambiar estatus de Campaña'
          }),
          color: '#1c684c',
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.enviar',
            defaultMessage: 'Encender'
          }),
          showCancelButton: true,
          cancelButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          width: 600
        })

        if (!isConfirmed) return null

        const { data } = await Service.post<any, any>(
          `Automation/ConfirmCampaign?idHashScript=${
            selectedScript?.idHashScript
          }&idRule=${id}&isActive=${!e.target.checked}`,
          {},
          {
            headers: {
              token: user.token,
              'Content-Type': 'application/json'
            }
          }
        )
        if (data?.Status === 'Ok') {
          const updatedTableData = tableData.map((el: any) => {
            if (el.id == id) {
              el.status = !el.status
            }
            return el
          })
          setLoadingRows(true)
          setFilteredTableData(updatedTableData)
          setLoadingRows(false)
        }
      } catch (error) {
        ShowAlert({
          title: 'Error',
          text: intl.formatMessage({
            id: 'app.common.modal.error',
            defaultMessage:
              'Ops, algo va mal. Refresca la pagina o vuelve en unos minutos'
          }),
          icon: 'warning',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
        console.log(error)
      }
    },
    [selectedScript, filteredTableData, user, tableData]
  )

  const showDescription = useCallback((title: string, description: string) => {
    ShowAlert({
      title,
      html: description,
      // icon: 'info',
      showConfirmButton: true,
      // timer: 2500,
      closeButtonHtml: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      confirmButtonText: intl.formatMessage({
        id: 'app.modal.btn.cerrar',
        defaultMessage: 'Cerrar'
      }),
      // toast: true,
      width: 500
    })
  }, [])

  const activityCampaignName = useCallback((name) => {
    switch (name) {
      case 'ActivityUser':
      case 'ActivityUser1':
      case 'ActivityUser2':
      case 'ActivityUser3':
      case 'ActivityUser4':
      case 'ActivityUser5':
      case 'ActivityUser6':
        return `${intl.formatMessage({
          id: 'app.emailmarketing.campaigns.dynamic.activity.activityUser.name',
          defaultMessage: 'Actividad en el sitio'
        })} ${name.replace(/[^0-9]/g, '')}`
      case 'BaseUser':
      case 'BaseUser1':
      case 'BaseUser2':
      case 'BaseUser3':
      case 'BaseUser4':
      case 'BaseUser5':
      case 'BaseUser6':
        return `${intl.formatMessage({
          id: 'app.emailmarketing.campaigns.dynamic.activity.Base.name',
          defaultMessage: 'Actividad en el email'
        })} ${name.replace(/[^0-9]/g, '')}`
      case 'States':
      case 'States1':
      case 'States2':
      case 'States3':
      case 'States4':
      case 'States5':
      case 'States6':
        return `${intl.formatMessage({
          id: 'app.emailmarketing.campaigns.dynamic.activity.State.name',
          defaultMessage: 'Base completa'
        })} ${name.replace(/[^0-9]/g, '')}`
      default:
        return name
    }
  }, [])

  const columns: Array<TableColumn<any>> = useMemo(
    () => [
      {
        name: intl.formatMessage({
          defaultMessage: 'Actualización',
          id: 'app.emailmarketing.campaigns.dynamic.table.date'
        }),
        id: 'date',
        selector: (row) => row.date,
        cell: (row: any) => {
          return (
            <GenericContentWrapperCell>
              <ParagraphTableCampaign>
                {format(row?.date, 'dd/MM/yyyy HH:mm')}
              </ParagraphTableCampaign>
            </GenericContentWrapperCell>
          )
        },
        center: true,
        sortable: true
      },
      {
        id: 'campaign',
        name: intl.formatMessage({
          defaultMessage: 'campaign',
          id: 'app.emailmarketing.campaigns.dynamic.table.campaign'
        }),
        cell: (row: any) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: 8
              }}
            >
              <TableCampaignContent>
                <TableCampaignTitle>
                  {activityCampaignName(row.name)}
                </TableCampaignTitle>
              </TableCampaignContent>
            </div>
          )
        },
        center: false,
        sortable: true,
        selector: (row) => row.name,
        minWidth: '25%'
      },
      {
        name: intl.formatMessage({
          defaultMessage: 'Estado',
          id: 'app.emailmarketing.campaigns.dynamic.table.status'
        }),
        id: 'status',
        selector: (row) => row.status,
        cell: (row: any) => {
          return (
            <div className="d-flex align-items-end gap-3">
              <label className="cfc_switch">
                <input
                  type="checkbox"
                  name={'status-action'}
                  checked={row.status}
                  onChange={async (e) => await statusCampaignHandler(row.id, e)}
                />
                <span
                  className="cfc_slider"
                  style={{ lineHeight: 0, wordBreak: 'initial' }}
                ></span>
              </label>
            </div>
          )
        },
        sortable: true,
        center: true
      },
      {
        name: intl.formatMessage({
          defaultMessage: 'Grupo',
          id: 'app.emailmarketing.campaigns.dynamic.table.group'
        }),
        id: 'group',
        selector: (row) => row.group,
        cell: (row: any) => {
          return (
            <GenericContentWrapperCell>
              <ParagraphTableCampaign>{row.group}</ParagraphTableCampaign>
            </GenericContentWrapperCell>
          )
        },
        center: true,
        sortable: true
      },
      {
        name: intl.formatMessage({
          defaultMessage: 'Prioridad',
          id: 'app.emailmarketing.campaigns.dynamic.table.priority'
        }),
        id: 'priority',
        selector: (row) => row.Priority,
        cell: (row: any) => {
          return (
            <GenericContentWrapperCell>
              <ParagraphTableCampaign>{row.Priority}</ParagraphTableCampaign>
            </GenericContentWrapperCell>
          )
        },
        center: true,
        sortable: true
      },

      {
        name: intl.formatMessage({
          defaultMessage: 'Audiencias',
          id: 'app.emailmarketing.campaigns.dynamic.table.cdp'
        }),
        id: 'cdp',
        cell: (row: any) => {
          return <GetCDPButton id={row.id} />
        },
        center: true
      },
      {
        name: intl.formatMessage({
          defaultMessage: 'Acciones',
          id: 'app.emailmarketing.campaigns.dynamic.table.actions'
        }),
        id: 'reports',
        cell: (row: any) => {
          return (
            <>
              <Link to={`/dashboard/campaign/campaign-create/${row.id}`}>
                <BsPencilSquare
                  size={20}
                  color="#00868B"
                  cursor={'pointer'}
                  data-tip={'Update'}
                  style={{ marginRight: 8 }}
                />
              </Link>
              <FaRegEye
                size={20}
                color="#7424a5"
                cursor={'pointer'}
                data-tip={'Descripción'}
                onClick={() => showDescription(row.name, row.description)}
              />
            </>
          )
        },
        center: true
      }
    ],
    [perPage, search, tableData]
  )

  useEffect(() => {
    getAllEnabledCampaigns()
  }, [])

  return (
    <div className="container">
      <div className="row" style={{ marginBlock: 23 }}>
        <FirstRowButtonsContainer>
          <UtilLinkButton
            text={intl.formatMessage({
              id: 'app.emailmarketing.campaigns.dynamic.btn.create',
              defaultMessage: 'Nueva Campaña'
            })}
            to="/dashboard/campaign/campaign-create/new"
          />
        </FirstRowButtonsContainer>
      </div>

      <div
        className="row"
        style={{
          marginTop: 40,
          backgroundColor: '#fff',
          padding: '0.5rem 2rem'
        }}
      >
        <DataTable
          subHeaderAlign={Alignment.RIGHT}
          columns={columns}
          data={filteredTableData}
          progressPending={loadingRows}
          pagination
          key={perPage}
          // paginationServer
          paginationPerPage={perPage}
          // paginationTotalRows={totalRows}
          defaultSortAsc={false}
          defaultSortFieldId={'date'}
          paginationResetDefaultPage={true}
          paginationComponent={(props) => {
            const customProps: PaginationComponentProps = {
              ...props,
              // currentPage: page,
              rowsPerPage: perPage
            }
            return <PaginationComponentMemo {...customProps} />
          }}
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
          subHeader
          striped={false}
          subHeaderComponent={subHeaderComponentMemo}
          customStyles={dynamicCampaignTable}
          noDataComponent={'Sin Resultados.'}
          responsive
        />
      </div>
    </div>
  )
}

export default DashboardCampaigns
