/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { NUMBER_BINARY_OPERATORS } from '@babel/types'
import React, { useEffect, useMemo, useState } from 'react'
import DataTable, { Alignment, TableStyles } from 'react-data-table-component'
import {
  BsDashCircleDotted,
  BsEnvelopeCheck,
  BsEraser,
  BsPencilSquare,
  BsPersonCheck,
  BsPersonCircle,
  BsPersonExclamation,
  BsSearch,
  BsTrash
} from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { config } from '../../../config'
import Service from '../../../services'
import { logout } from '../../../state/actions'
import { RootState } from '../../../state/store'
import { ShowAlert } from '../../Globals'
import { Loading } from '../../Globals/Loading'

import axios from 'axios'
import { useClientContext } from '../../../contexts/ClientContext'

const UsersGrid = ({
  toggleeditUser,
  // handlerReloadDataTable,
  setIdUser
}: any) => {
  const [users, setUsers] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalRows, setTotalRows] = useState(0)
  const [totalRowsAux, setTotalRowsAux] = useState(0)
  const [perPage, setPerPage] = useState(25)
  const [filterText, setFilterText] = useState<any>('')
  const [filterTextAux, setFilterTextAux] = useState<any>('')
  const [sortField, setSortField] = useState<any>('id')
  const [sortDirection, setSortDirection] = useState<any>('desc')
  const [loading, setLoading] = useState(false)
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState<boolean>(false)
  const [tokenValue, setTokenValue] = useState<string>('')
  const { selectedScript, selectedClient } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)

  const dispatch = useDispatch()
  const intl = useIntl()

  const getDataReload = async (
    page: any,
    textSearch: string,
    sortFie: string,
    sortDir: string
  ) => {
    setLoading(true)
    if (!selectedClient) {
      return
    }
    Service.get<any>(
      `/Account/GetAllUsersByClient?idHashScript=${selectedScript?.idHashScript}
        &page=${page}&per_page=${perPage}&filterText=${filterText.toLowerCase()}&sortField=${sortFie}&sortDirection=${sortDir}`,
      {
        method: 'GET',
        headers: {
          token: user.data.token,
          idClient: selectedClient.idClient.toString(),
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((response) => {
        if (response.headers.statuscode === '0') {
          if (response.data.Entities.length > 0) {
            setUsers(response.data.Entities)
            setTotalRows(parseInt(response.data.Message))
            setTotalRowsAux(parseInt(response.data.Message))
          } else {
            setUsers([
              {
                token: null,
                IdDashboardUser: '',
                username: '',
                dateAdded: '',
                cookie: '',
                userType: '',
                cookieDate: '',
                name: '',
                lastname: '',
                photo: '',
                idClient: 0,
                active: 0,
                email: '',
                cookieToken: '',
                automationUse: ''
              }
            ])
            setTotalRows(0)
            setTotalRowsAux(0)
          }

          setCurrentPage(page)
          setLoading(false)
        } else {
          setUsers('')
          if (response.headers.statuscode === '-5')
            MyAlert(
              intl.formatMessage(
                { id: 'app.token-invalido' },
                { defaultMessage: 'El token ingresado es inválido.' }
              ),
              3
            )
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        }
      })
  }

  const MyAlert = (
    text: string,
    iconStr: number,
    time = 3000,
    isToast = false
  ) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.user' },
        { defaultMessage: 'Usuarios' }
      ),
      text,
      icon: iconStr === 1 ? 'success' : iconStr === 2 ? 'error' : 'warning',
      showConfirmButton: true,
      timer: time,
      loaderHtml: 'espere...',
      closeButtonHtml: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      toast: isToast,
      position: isToast ? 'top-end' : 'center',
      width: 500,
      timerProgressBar: true
    })
  }

  const AlertToken = (userIdDelete: any) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.modal.token.title' },
        { defaultMessage: 'Ingrese el <strong>token</strong>' }
      ),
      input: 'text',
      html:
        '<span class="m-4 text-left">' +
        intl.formatMessage(
          { id: 'app.modal.token.texto' },
          { defaultMessage: 'Ingrese el <strong>token</strong>' }
        ) +
        '</span>',
      inputLabel: '',
      inputPlaceholder: 'Token',
      color: '#1c684c',
      inputValue: tokenValue,
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.enviar' },
        { defaultMessage: 'Enviar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      inputValidator: (result) => {
        if (result === '') {
          return intl.formatMessage(
            { id: 'app.token-inexistente' },
            { defaultMessage: 'Debe ingresar el token.' }
          )
        } else {
          return ''
        }
      },
      width: 600
    }).then((result) => {
      if (result.isConfirmed && result.value !== '') {
        setTokenValue(result.value)
        if (!selectedClient) {
          return
        }
        Service.post<any, any>(
          `${process.env.REACT_APP_BASE_PATH_SERVER}/Account/RemoveUserFromClient?idHashScript=${selectedScript?.idHashScript}`,
          '',
          {
            method: 'POST',
            headers: {
              token: user.data.token,
              tokenGoogle: result.value,
              idUserDelete: userIdDelete,
              idClient: selectedClient?.idClient.toString(),
              'Content-Type': 'multipart/form-data',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
          .then((res) => {
            if (res.data?.StatusCode < 0 || res.data < 0) {
              if (res.data?.StatusCode !== -5 && res.data?.StatusCode !== -6) {
                MyAlert(
                  intl.formatMessage(
                    { id: 'app.api.error' },
                    {
                      defaultMessage:
                        'Ocurrió un Error al actualizar los datos.'
                    }
                  ) +
                    ' Causa: ' +
                    res.data?.Message,
                  2
                )
              }
            } else {
              MyAlert(res.data.Message, 1)
              if (result.value == user.data.IdDashboardUser) {
                // si me borré yo mismo :), me saca
                dispatch(logout(user.data.token))
              } else {
                // solo recarga
                setResetPaginationToggle(!resetPaginationToggle)
              }
            }
          })
          .catch((error) => {
            if (error.response.data === -6) {
              dispatch(logout(user.data.token))
            } else if (error.response.data === -5) {
              MyAlert(
                intl.formatMessage(
                  { id: 'app.token-invalido' },
                  { defaultMessage: 'El token ingresado es inválido.' }
                ),
                3
              )
            } else {
              MyAlert('API-ERROR: ' + `${error}`, 3)
            }
          })
      }
    })
  }

  const sendMailQr = (idUser: any) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_PATH_SERVER}/Account/SendMailQR?idHashScript=${selectedScript?.idHashScript}&idUser=${idUser}`,
        {
          headers: {
            token: user.data.token,
            'Content-Type': 'multipart/form-data',
            Accept: '*/*',
            credentials: 'include'
          }
        }
      )
      .then((response) => {
        if (response.status == 200 && response.data.StatusCode == 0) {
          MyAlert(response.data.Message, 1)
          setResetPaginationToggle(!resetPaginationToggle)
        } else {
          MyAlert(
            intl.formatMessage(
              { id: 'app.api.error' },
              { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
            ),
            2
          )
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const deleteUser = (idUserForDelete: any, nameLastname: string) => {
    ShowAlert({
      title: intl.formatMessage(
        { id: 'app.submenu.user' },
        { defaultMessage: 'Usuarios' }
      ),
      html:
        '<span class="m-4">' +
        intl.formatMessage(
          { id: 'app.system.user.panel.table.add-delete-text' },
          { defaultMessage: '¿Desea Borrar el Usuario?' }
        ) +
        ' ' +
        nameLastname +
        '</span>',
      color: '#1c684c',
      confirmButtonText: intl.formatMessage(
        { id: 'app.modal.btn.borrar' },
        { defaultMessage: 'Borrar' }
      ),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage(
        { id: 'app.modal.btn.cerrar' },
        { defaultMessage: 'Cerrar' }
      ),
      width: 600
    }).then((result) => {
      if (result.isConfirmed) {
        AlertToken(idUserForDelete)
      }
    })
  }

  const enabledUser = (idUser: string, event: any) => {
    Service.post<any, any>(
      `/Account/EnabledUserPanel?idHashScript=${selectedScript?.idHashScript}`,
      '',
      {
        method: 'POST',
        headers: {
          token: user.data.token,
          userId: idUser,
          enabled: event.target.checked == true ? '1' : '0',
          'Content-Type': 'application/json',
          Accept: '*/*',
          credentials: 'include'
        }
      }
    )
      .then((res) => {
        if (res.data?.StatusCode < 0 || res.data < 0) {
          if (res.data?.StatusCode !== -5 && res.data?.StatusCode !== -6)
            MyAlert(
              intl.formatMessage(
                { id: 'app.api.error' },
                { defaultMessage: 'Ocurrió un Error al actualizar los datos.' }
              ),
              2
            )
        } else {
          MyAlert(
            intl.formatMessage(
              { id: 'app.system.user.panel.table.add-enabled-ok' },
              {
                defaultMessage:
                  'Se actualizó correctamente el estado Habilitado/Desabilitado del Usuario'
              }
            ),
            1
          )
        }
      })
      .catch((error) => {
        if (error.response.data === -6) {
          dispatch(logout(user.data.token))
        } else {
          MyAlert('API-ERROR: ' + `${error}`, 3)
        }
      })
  }

  const TextField = styled.input`
    width: 50%;
    border: none;
    background: none;
    border-bottom: 2px solid #ccc !important;
    height: 32px;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `

  const customStyles: TableStyles = {
    subHeader: {
      style: {
        justifyContent: 'space-between',
        // marginBottom: '1rem',
        marginTop: '1rem',
        padding: '0 1rem !important'
      }
    },
    header: {
      style: {
        fontSize: '14px',
        color: '#000',
        backgroundColor: '#fff !important',
        minHeight: '56px',
        paddingLeft: '16px',
        paddingRight: '8px',
        marginBottom: '0px'
      }
    },
    rows: {
      style: {
        minHeight: '72px' // override the row height
      },
      selectedHighlightStyle: {
        // use nth-of-type(n) to override other nth selectors
        '&:nth-of-type(n)': {
          color: '#fff',
          backgroundColor: '#666',
          borderBottomColor: '#ccc'
        }
      },
      highlightOnHoverStyle: {
        color: '#dfdfdf',
        backgroundColor: '#eee',
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        borderBottomColor: '#000',
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: '#efefef'
      }
    },
    headCells: {
      style: {
        paddingLeft: '20px !important', // override the cell padding for head cells
        paddingRight: '20px !important'
      },
      draggingStyle: {
        cursor: 'move'
      }
    },
    cells: {
      style: {
        margingLeft: '10px', // override the cell padding for data cells
        margingRight: '10px',
        wordBreak: undefined,
        overflow: 'wrap'
      },
      draggingStyle: {}
    },

    noData: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    progress: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2fad7e',
        backgroundColor: 'transparent'
      }
    },
    pagination: {
      style: {
        color: '#000',
        fontSize: '14px',
        minHeight: '56px',
        backgroundColor: 'transparent',
        // borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#ddd'
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: '5px',
        cursor: 'pointer',
        transition: '0.4s',
        color: '#fff',
        // fill: theme.button.default,
        backgroundColor: '#e9ecef',
        '&:disabled': {
          cursor: 'unset',
          color: '#2fad7e'
          // fill: theme.button.disabled,
        },
        '&:hover:not(:disabled)': {
          backgroundColor: '#2fad7e',
          color: '#fff'
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: '#fff'
        }
      }
    }
  }

  const handlerSetChangePerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLoading(true)
    setPerPage(parseInt(event.target.value))
    setCurrentPage(1)
    setFilterText('')

    const selc =
      document.querySelectorAll('select[aria-label="MOSTRANDO"]').length > 0
        ? document.querySelectorAll('select[aria-label="MOSTRANDO"]')
        : document.querySelectorAll('select[aria-label="SHOW"]')

    const select = document.getElementById(
      'options-per-page'
    ) as HTMLSelectElement
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].text === event.target.value) {
        const yourSelect = selc[0] as HTMLSelectElement
        yourSelect.options[i].selected = true
        const ev = new Event('change', { bubbles: true })
        yourSelect.dispatchEvent(ev)

        break
      }
    }
    setLoading(false)
  }

  const changeTextSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterTextAux(event.target.value)
    if (event.target.value.length > 2) {
      setFilterText(event.target.value)
    }
  }

  const FilterComponent = ({ filterText, onFilter, onClear }: any) => (
    <>
      <div className="show-options__text">
        <FormattedMessage id="app.vitrinas.mostrar" defaultMessage="MOSTRAR" />
        <select
          onChange={handlerSetChangePerPage}
          className="show-options__selector select_paginas"
          name="options-per-page"
          id="options-per-page"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25" selected>
            25
          </option>
        </select>
        <FormattedMessage
          id="app.vitrinas.entradas"
          defaultMessage="ENTRADAS"
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '50%',
          alignItems: 'center',
          justifyContent: 'end'
        }}
      >
        <TextField
          id="search"
          type="text"
          autoComplete="off"
          placeholder={useIntl().formatMessage(
            { id: 'app.vitrinas.buscar' },
            { defaultMessage: 'BUSCAR POR...' }
          )}
          onChange={changeTextSearch}
          data-tip={useIntl().formatMessage(
            { id: 'app.vitrinas.buscar.tooltip' },
            { defaultMessage: 'Ingresa al meno 3 caracteres para búscar' }
          )}
        />
        <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />

        {filterText === '' ? (
          <BsSearch
            title={
              'Buscar ' +
              intl.formatMessage(
                { id: 'app.system.users-panel.account' },
                { defaultMessage: 'Artículos de Home' }
              )
            }
            onClick={onClear}
            cursor={'pointer'}
            size={25}
          />
        ) : (
          <BsEraser
            title="Limpiar búsqueda"
            size={25}
            onClick={onClear}
            cursor={'pointer'}
          />
        )}
      </div>
    </>
  )

  const columns = [
    {
      id: 'photo',
      name: intl.formatMessage(
        { id: 'app.system.user.panel.table.image' },
        { defaultMessage: 'Foto de Perfil' }
      ),
      selector: (row: any) => {
        return <BsPersonCircle size={30} />
      },
      center: true
    },
    {
      id: 'username',
      name: intl.formatMessage(
        { id: 'app.system.user.panel.table.username' },
        { defaultMessage: 'Usuario' }
      ),
      selector: (row: any) => {
        if (row.userType == 9) {
          return (
            <>
              <BsPersonExclamation size={30} color="green" />
              <span className="badge badge-success">Admin</span>
            </>
          )
        }
        if (row.IdDashboardUser == user.data.IdDashboardUser) {
          return (
            <>
              <BsPersonCheck size={30} color={'warning'} />
              <span className="badge badge-dark">Yo</span>
            </>
          )
        } else {
          return row.username
        }
      },
      reorder: true,
      defaultSortDesc: true,
      center: true,
      sortable: true,
      sortField: 'username',
      width: '210px'
    },
    {
      id: 'name',
      name: intl.formatMessage(
        { id: 'app.system.user.panel.table.name' },
        { defaultMessage: 'Nombre' }
      ),
      selector: (row: any) => row.name,
      center: true,
      width: '210px',
      sortable: true,
      sortField: 'name'
    },
    {
      id: 'lastname',
      name: intl.formatMessage(
        { id: 'app.system.user.panel.table.lastname' },
        { defaultMessage: 'Apellido' }
      ),
      selector: (row: any) => row.lastname,
      center: true
    },
    {
      id: 'active',
      name: intl.formatMessage(
        { id: 'app.system.user.panel.table.active' },
        { defaultMessage: 'Estado' }
      ),
      cell: (row: any) => {
        if (user.data.userType == 9 || user.data.userType == 1) {
          return (
            <label className="switch">
              <input
                type="checkbox"
                key={row.id}
                id={row.id}
                data-chk={row.active}
                defaultChecked={row.active === 1 ? true : false}
                onChange={(e) => enabledUser(row.IdDashboardUser, e)}
              />
              <span className="slider round"></span>
            </label>
          )
        } else {
          if (row.active === 1) {
            return <span className="badge badge-info">activo</span>
          } else {
            return <span className="badge badge-danger">inactivo</span>
          }
        }
      },
      reorder: true,
      defaultSortDesc: true,
      center: true,
      width: '10%'
    },
    {
      id: 'acc_categ',
      name: intl.formatMessage(
        { id: 'app.submenu.logs-table.columns6' },
        { defaultMessage: 'Acciones' }
      ),
      cell: (row: any) => (
        <>
          <button
            className="manage-grid-button btn-grilla-upd"
            disabled={user.data.userType !== 9 && user.data.userType !== 1}
            data-id={row.Id}
            onClick={() => {
              toggleeditUser()
              setIdUser(row.IdDashboardUser)
            }}
          >
            {user.data.userType == 9 || user.data.userType == 1 ? (
              <BsPencilSquare
                data-tip={intl.formatMessage(
                  { id: 'app.system.user.panel.table.add-update' },
                  { defaultMessage: 'Editar Usuario' }
                )}
                color={'gray'}
                fill={'#eeeeee'}
              />
            ) : (
              <>
                <BsDashCircleDotted
                  data-tip={'Sin permisos para esta acción'}
                  size={10}
                />
                <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
              </>
            )}
          </button>
          <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
          <button
            className="manage-grid-button btn-grilla-del"
            data-id={row.IdDashboardUser}
            onClick={() => {
              sendMailQr(row.IdDashboardUser)
            }}
          >
            <BsEnvelopeCheck
              data-tip={
                intl.formatMessage(
                  { id: 'app.system.user.panel.table.reenviarmail' },
                  { defaultMessage: 'Reenviar Mail con QR' }
                ) +
                ' a: ' +
                row.email
              }
            />
          </button>
          <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
          <button
            className="manage-grid-button color-red btn-grilla-del text-danger"
            data-id={row.IdDashboardUser}
            disabled={user.data.userType != 9}
            onClick={() => {
              deleteUser(row.IdDashboardUser, row.name + ' ' + row.lastname)
            }}
          >
            {user.data.userType == 9 || user.data.userType == 1 ? (
              <BsTrash
                data-tip={
                  intl.formatMessage(
                    { id: 'app.system.user.panel.table.add-delete' },
                    { defaultMessage: 'Borrar Usuario' }
                  ) +
                  ': ' +
                  row.name +
                  ' ' +
                  row.lastname
                }
                color={'red'}
                fill={'#db0a0a'}
              />
            ) : (
              <>
                <BsDashCircleDotted
                  data-tip={'Sin permisos para esta acción'}
                  size={10}
                />
                <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
              </>
            )}
          </button>
          <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
        </>
      ),
      allowOverflow: true,
      button: true,
      center: true,
      width: '180px'
    }
  ]

  const paginationComponentOptions = {
    rowsPerPageText: intl.formatMessage(
      { id: 'app.vitrinas.mostrando' },
      { defaultMessage: 'MOSTRANDO' }
    ),
    rangeSeparatorText: intl.formatMessage(
      { id: 'app.vitrinas.de' },
      { defaultMessage: ' DE ' }
    )
  }

  const handlePageChange = (page: any) => {
    setCurrentPage(page)
    getDataReload(page, filterText, sortField, sortDirection).catch((error) =>
      console.log(error)
    )
  }

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
    getDataReload(page, filterText, sortField, sortDirection).catch((error) =>
      console.log(error)
    )
  }

  const filteredItems = users.filter(
    (item: any) =>
      Boolean(
        item.username?.toLowerCase().includes(filterText.toLowerCase())
      ) ||
      Boolean(item.name?.toLowerCase().includes(filterText.toLowerCase())) ||
      Boolean(item.lastname?.toLowerCase().includes(filterText.toLowerCase()))
  )

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      // if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle)
      setFilterText('')
      setFilterTextAux('')
      // }
    }

    if (filterText === '') {
      setTotalRows(totalRowsAux)
    } else {
      getDataReload(1, filterText, sortField, sortDirection).catch((error) =>
        console.log(error)
      )
      // setTotalRows(totalRows); //(parseInt(filteredItems.length));
      setTimeout(() => {
        const input = document.getElementById('search') as HTMLInputElement
        input.value = filterText
        if (input != null) input.focus()
      }, 200)
    }

    setFilterTextAux(filterText)
    return (
      <FilterComponent
        onFilter={(e: any) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  useEffect(() => {
    getDataReload(1, filterText, sortField, sortDirection).catch((error) =>
      console.log(error)
    )
  }, [resetPaginationToggle, toggleeditUser]) // handlerReloadDataTable

  const handleSort = (column: any, sortDirection: any) => {
    setSortField(column.sortField)
    setSortDirection(sortDirection)

    getDataReload(1, filterText, column.sortField, sortDirection).catch(
      (error) => console.log(error)
    )
  }

  return (
    <div className="row">
      {users.length >= 1 ? (
        <>
          <div>
            <DataTable
              subHeaderAlign={Alignment.RIGHT}
              columns={columns}
              data={filteredItems} // users
              progressPending={loading}
              progressComponent={
                <Loading
                  textLoading={intl.formatMessage(
                    { id: 'app.vitrinas-spinner.espere' },
                    { defaultMessage: 'Espere...' }
                  )}
                />
              }
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationResetDefaultPage={resetPaginationToggle}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              customStyles={customStyles}
              // defaultSortFieldId={1}
              noDataComponent={intl.formatMessage(
                { id: 'app.vitrinas.nodata' },
                { defaultMessage: 'Sin Resultados.' }
              )}
              responsive
              sortServer
              onSort={handleSort}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default UsersGrid
